import React, { Component } from "react";
import styled from "styled-components";

import { biometrictFingerDataHistory } from "../../data/data";
import FingerPrint from "../Control/FingerPrint";

// import image
import icClose from "../../images/ic_close_white.svg";
import bgFinger from "../../images/backGround_bioHistory.svg";
// import finger_status from "../../images/finger_white.svg";
import ic_zoom from "../../images/Icons_Zoom.svg";

const StylePopupShowBiometricHistory = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #0000008a;
  font-family: "SVN-Gilroy";
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .popup-header {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 40px;
    cursor: move;
    box-sizing: border-box;
    background: #005993;
    font-family: "SVN-Gilroy";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    color: #ffffff;
    display: flex;
    padding: 9px 10px 7px 20px;
    justify-content: space-between;
    z-index: 0;
    img {
      z-index: 1;
      cursor: pointer;
    }
    .popup-header-title{
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .popup-main {
    position: relative;
    flex-grow: 1;
    height: 300px;
    &-item {
      font-family: "SVN-Gilroy";
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 1.3;
      color: #ffffff;
    }
    .last-modify {
      position:absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: calc(100% - 26px);
      padding:10px 13px;
      .status_bio {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        color: #ffffff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .item {
          display: flex;
          justify-content:space-around;
          align-items: center;
          margin: 0 0 0 10px;
          .oval {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0 5px 0 0;
          } 
        }
        .update {
          .oval {
            background-color: #1477ec;
          }
        }
        .delete {
          .oval {
            background-color: #f31919;
          }
        }
        .new_register {
          .oval {
            background-color: #0fb11f;
          }
        }
      }
    }

    .block_face {
      width: 120px;
      height: 120px;
      border-radius: 5px;
      background-color: #fafafa;
      position:absolute;
      top: 6%;
      left: 3%;
      &:hover{
        .overlay{
          opacity:.5;
          border-radius: 5px;
        }
        .ic_zoom {
          opacity: 1;
        }
      }
      .img_face{
        width:100%;
        height:100%;
        border-radius: 5px;
      }
      .overlay{
        position:absolute;
        top:0;
        width:100%;
        height:100%;
        background-color:#222222;
        opacity:0;
      }
        .ic_zoom{
          cursor: pointer;
          position: absolute;
          bottom:0;
          left:0;
          opacity: 0;
        }
    }

    .title_face{
      position:absolute;
      bottom: 47%;
      left: 3%;
      opacity: 0.5;
    }
    .left-hand {
      bottom: 5%;
      left: 23%;
      opacity: 0.5;
      position: absolute;
    }

    .right-hand {
      bottom: 5%;
      right: 5%;
      opacity: 0.5;
      position: absolute;
    }

    .handle-finger {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 15px;
        span {
          opacity: 0.5;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          color: #ffffff;
        }
      }
  }
`;

const BoundInfo = styled.div`
  width: 100%;
  height:272px;
  background-color:#fafafa;
  font-family: "SVN-Gilroy";
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .title {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: #005993;
    text-transform:uppercase;
    margin: 17px 0 8px 21px;
  }
  .info_customer, .info_biometric {
    display: flex;
    margin: 0 0 0 21px;
    .left_content{
      width:50%;
      height:100%;
    }
    .right_content{
      width:50%;
      height:100%;
      margin: 0 0 0 20px;
    }
    .info_detail {
      margin: 12px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #222222;
      .title_info{
        color: #979797;
      }
    }
    .typeBio{
      color:#005993;
    }
  }
`;


class PopupHistoryFinger extends Component {
  render() {
    return (
      <StylePopupShowBiometricHistory ref={ref => (this.popupShowBiometricHistory = ref)}>
        <div className="popup-header">
          <div className="popup-header-title">09:20, 23/07/2019</div>
          <img
            onClick={() => this.props.onClosePopup()}
            src={icClose}
            alt="close-popup"
          />
        </div>
        <BoundInfo>
          <p className="title">Thông tin khách hàng</p>
          <div className="info_customer">
            <div className="left_content">
              <div className='info_detail'> <span className="title_info">Họ tên KH:</span>&nbsp; Nguyen Ngoc Anh</div>
              <div className='info_detail'> <span className="title_info">Loại dữ liệu:</span>&nbsp; Khách hàng nội bộ</div>
            </div>
            <div className="right_content">
              <div className='info_detail'> <span className="title_info">Số CIF:</span>&nbsp;  0945845126</div>
              <div className='info_detail'> <span className="title_info">Ngày đăng ký khách hàng nội bộ:</span>&nbsp; 15/03/2019</div>
            </div>
          </div>

          <p className="title">Thông tin dữ liệu</p>
          <div className="info_biometric">
            <div className="left_content">
              <div className='info_detail typeBio'> <span className="title_info">Loại tác nghiệp: </span>&nbsp; Đăng ký mới dữ liệu</div>
              <div className='info_detail'> <span className="title_info">Ngày tác nghiệp: </span>&nbsp;15:30 15/03/19 </div>
              <div className='info_detail'> <span className="title_info">KSV:  </span>&nbsp;Ngthuphuong</div>
            </div>
            <div className="right_content">
              <div className='info_detail'> <span className="title_info">Mã điểm giao dịch:</span>&nbsp; 108304</div>
              <div className='info_detail'> <span className="title_info">Giao dịch viên: </span>&nbsp;Nt.hoan</div>
            </div>
          </div>
        </BoundInfo>
        {/* //////// */}
        <div className="popup-main">
          <img src={bgFinger} alt="finger" />
          <div className="popup-main-item last-modify">
            <div className="status_bio">
              <div className="update item">
                <div className="oval" /> Cập nhật
                </div>
              <div className="delete item">
                <div className="oval" /> Xóa
                </div>
              <div className="new_register item">
                <div className="oval" /> Đăng ký mới
                </div>
            </div>
          </div>
          <div className="block_face">
            <img className="img_face" src="http://img.webthethao.vn/images/fullsize/2019/05/09/captain-america-cua-biet-doi-avenger-tap-luyen-nhung-mon-vo-gi.jpg" alt="img_faceID" />
            <div className="overlay">
            </div>
            <img className="ic_zoom" src={ic_zoom} alt="zoom" />
          </div>
          <span className="popup-main-item title_face">Khuôn mặt</span>
          <span className="popup-main-item left-hand">Tay trái</span>
          <span className="popup-main-item right-hand">Tay phải</span>
          <div className="handle-finger">
            <div className="g-finger">
              {biometrictFingerDataHistory.map((item, i) => (
                <FingerPrint
                  key={i}
                  // onShowRegisterFinger={() => this.onShowRegisterFinger(item)}
                  // dataGetTicket={this.props.dataGetTicket}
                  // img_finger={finger_status}
                  alt="finger"
                  {...item}
                />
              ))}
            </div>
          </div>
        </div>
      </StylePopupShowBiometricHistory>
    );
  }

  componentDidMount() {
    // console.dir(this.popupShowBiometricHistory);
    this.dragElement(this.popupShowBiometricHistory, ".popup-header");
  }

  dragElement = (elmnt, headerClass) => {
    // console.log("header", headerClass);
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    const header = elmnt.querySelector(headerClass);
    if (header) {
      /* if present, the header is where you move the DIV from:*/
      header.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
    const that = this;
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      const tmpTop = elmnt.offsetTop - pos2;
      const tmpLeft = elmnt.offsetLeft - pos1;
      const {
        scrollHeight,
        scrollWidth,
        clientHeight,
        clientWidth
      } = that.popupShowBiometricHistory;
      const { innerHeight, innerWidth } = window;
      if (tmpTop < scrollHeight / 2 && pos2 > 0) {
        elmnt.style.top = scrollHeight / 2 + "px";
      } else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
        elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
      } else {
        elmnt.style.top = tmpTop + "px";
      }

      if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
        elmnt.style.left = scrollWidth / 2 + "px";
      } else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
        elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
      } else {
        elmnt.style.left = tmpLeft + "px";
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  };
}

export default PopupHistoryFinger;
