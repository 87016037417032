import React from 'react';


import MainContainer from './ui/MainContainer';
// import LoadingControl from "./ui/Control/LoadingControl";



function App() {
  

  return (
    // <div style={{position: "relative"}}>
      <MainContainer/>
      // <LoadingControl loadingPage={true} />
    // </div>
  );
}

export default App;
