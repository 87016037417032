import styled from "styled-components";

const Wrap = styled.div`
	backdrop-filter: blur(2.63205px);
	border-radius: 58px;
	display: flex;
	align-items: center;
	padding: 0 6px;
	gap: 4px;
	/* z-index: 2; */
`;

const Time = styled.div`
	
`;

const OnlineTime = {
	Wrap,
	Time,
};

export default OnlineTime;
