import React, { Component } from 'react';
import styled from 'styled-components';
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';
import ic_success from "../../../images/ic_counter_notification_success.svg"
import ic_fail from "../../../images/ic_counter_notification_close.svg"

const Bound = styled.div`
  /* padding: 10px 10px 12px 20px;
  width: 270px;
  height: 212px; */
  /* border-radius: 5px; */
  /* background-color: #ffffff; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99; */
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .row_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #000000;
    }
    .close {
      cursor: pointer;
    }
  }
  .row_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 20px 0 0 0;
    .block_img {
      width:52px;
      height:52px;
    }
    .content {
      min-width: 226px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #222222;
      margin: 16px 0 0 0;
    }
  }
  .row_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 17px 0;
  }
`

export default class CheckConfirmFinger extends Component {

  render() {
    return (
      <Bound>
        <div className="row_main">
          <div className="block_img">
            <img className='success' src={this.props.success === true ? ic_success : ic_fail} alt="success" />
          </div>
          <div className="content">
            {this.props.content
              ? this.props.content
              : 'Xác thực dữ liệu vân tay thất bại'}
          </div>
        </div>
        <div className="row_btn">
          <ButtonConfirmControl
            bg_color={this.props.bg_color}
            titleConfirm={this.props.btnName}
            onSubmit={() => this.props.onSubmit()}
          />
        </div>
      </Bound>
    )
  }
}
