import React from 'react'
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
import { WrapPopupEndCounselor } from './index.styles'
import ButtonControl from '../../../controls/ButtonControl'
import IcPopupQuestion from '../../../images/SVG/IcPopupQuestion'
import { useDispatch, useSelector } from 'react-redux'
import { sendEndAdvise } from '../../../../../actions/counselorAction'
import { QUEUETICKET } from '../../../../../constant/keys'

export default function PopupEndCounselor({ onClose, setIsEndSuccess, serviceSelect, textResult }) {
    const dispatch = useDispatch()
    const {
        listServiceCounselor,
        dataTicketCounselor,
        dataConnectDesktop,
        detailsProcessingAdvise
    } = useSelector((state) => ({
        listServiceCounselor: state.counterReducer.listServiceCounselor,
        dataTicketCounselor: state.counselor.dataTicketCounselor,
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        detailsProcessingAdvise: state.counselor.detailsProcessingAdvise
    }))

    const handleEnd = () => {
        const callbackSuccess = () => {
            setIsEndSuccess()
            onClose()
        }
        dispatch(sendEndAdvise(dataConnectDesktop?.OfficeID,dataConnectDesktop?.CounterID,dataTicketCounselor?.TicketID ? dataTicketCounselor?.TicketID : detailsProcessingAdvise?.ID ? detailsProcessingAdvise?.ID : '',dataTicketCounselor?.Number ? String(dataTicketCounselor?.Number) : detailsProcessingAdvise?.Number ? String(detailsProcessingAdvise?.Number) : '',serviceSelect?.length > 0 ? serviceSelect : [],textResult,dataTicketCounselor?.From ? dataTicketCounselor?.From : detailsProcessingAdvise?.From ? detailsProcessingAdvise?.From : QUEUETICKET,callbackSuccess))
        
    }


    return (
        <OverlayFullScreen>
            <WrapPopupEndCounselor>
                <div className="pop-content">
                    <IcPopupQuestion />
                    <div className="bold">
                        Kết thúc tư vấn khách hàng mã vé{' '}
                        <span>“{dataTicketCounselor?.Number || detailsProcessingAdvise?.Number} - {dataTicketCounselor?.Fullname ? dataTicketCounselor?.Fullname : dataTicketCounselor?.CustomerInfos?.FullNameVi ? dataTicketCounselor?.CustomerInfos?.FullNameVi : dataTicketCounselor?.CustomerInfos?.Name ? dataTicketCounselor?.CustomerInfos?.Name : detailsProcessingAdvise?.Fullname ? detailsProcessingAdvise?.Fullname : "KH vãng lai" }”</span>
                    </div>
                    <div className="note">
                        (*) Thông tin & Kết quả tư vấn sẽ được lưu lại
                    </div>
                </div>
                <div className="pop-btn-container">
                    <ButtonControl
                        text="tiếp tục"
                        className="btn-counselor-cancel"
                        onClick={onClose}
                    />
                    <ButtonControl
                        text="kết thúc"
                        className="btn-counselor-confirm"
                        onClick={handleEnd}
                    />
                </div>
            </WrapPopupEndCounselor>
        </OverlayFullScreen>
    )
}
