import * as types from "../../actions/inBioCollector/types";

export const initialState = {
    tellers: [],
    inbioCollectors: [],
    inSupervisors: [],
    exSupervisors: [],
    supervisors: []
}


export default function listUsername(state = initialState, action) {
    switch (action.type) {
        case types.GET_LIST_USERNAME: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
}