import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import html2canvas from 'html2canvas'
//css
import {
    WrapIssueTicket,
    WrapContentPage,
    WrapFooterPage,
} from './index.styles'
//img
import pattern_vtb from '../../../images/PNG/pattern_vtb.png'

//component

import FormTransactionReceipt from './FormTransactionReceipt'
import StepByStepBookingOnline from '../../StepByStepBookingOnline'
import StepByStepGetNumber from '../../StepByStepGetNumber'
import ButtonControl from '../../../controls/ButtonControl'
import IcDownload from '../../../images/SVG/IcDownload'

const IssueTicket = ({ isBookingOnline }) => {
    const refForm = useRef(null)
    const dispatch = useDispatch()
    const { dataTicket } = useSelector(state => state.counterReducer)

    //---
    const handleDownloadImage = async () => {
        const element = refForm.current
        const canvas = await html2canvas(element)
        const data = canvas.toDataURL('image/jpg')
        const link = document.createElement('a')
        //---
        if (typeof link.download === 'string') {
            link.href = data
            link.download = 'ticket.jpg'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } else {
            window.open(data)
        }
    }

    const handleNext = () => {}

    return (
        <WrapIssueTicket>
            {isBookingOnline ? (
                <div className="title-page">
                    <div className="bold">Lấy số khách hàng online</div>
                    <div className="normal">Thông tin vé & khách hàng đang được tư vấn</div>
                </div>
            ) : (
                <div className="title-page">
                    <div className="bold">Lấy số xếp hàng</div>
                    <div className="normal">Lấy số cho khách hàng vào quầy</div>
                </div>
            )}
            {isBookingOnline ? (
                <StepByStepBookingOnline />
            ) : (
                <StepByStepGetNumber />
            )}
            <WrapContentPage>
                <div className="wrap-container">
                    <FormTransactionReceipt
                        refForm={refForm}
                        data={dataTicket}
                    />
                </div>
            </WrapContentPage>
            <WrapFooterPage>
                {/* <img src={pattern_vtb} alt='pattern_vtb' /> */}
                <div className="wrap-btn">
                    <div className="btn-download" onClick={handleDownloadImage}>
                        <IcDownload />
                        <span>Tải xuống</span>
                    </div>
                    <ButtonControl
                        text="In vé"
                        className="btn-next"
                        onClick={handleNext}
                    />
                </div>
            </WrapFooterPage>
        </WrapIssueTicket>
    )
}

export default IssueTicket
