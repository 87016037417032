import React from 'react'

const IcNextBlue = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.27006 13L14 8.5L8.27006 4V6.69996H2.00004V10.3H8.27006V13Z" fill="#005993" />
        </svg>
    )
}

export default IcNextBlue