import styled from "styled-components";

const ButtonWrapper = styled.button`
    pointer-events: ${props => props.isDisable ? "none" : "auto"};
    width: 188px;
    height: 52px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    padding: 15px 0px;
    box-sizing: border-box;
    background: #4BB4FF;
    border-radius: 26px;
    cursor: pointer;
    border: none;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: 'SVN-Gilroy';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.36px;
    &.disable{
        background: #F7F7F7!important;
        color: #C4C4C4!important;
        pointer-events: none!important;
    }
   
`

export { ButtonWrapper };