import { useCallback, useMemo, useState } from "react";


const useStepStack = (initial) => {
    const [stepStack, setStepStack] = useState([...initial]);

    const pushStep = useCallback(
        (step) => {
            setStepStack((pre) => [step, ...pre]);
        },
        [setStepStack]
    );

    const popStep = useCallback(
        (num) => {
            if (num) {
                setStepStack((pre) => pre.slice(num));
            } else {
                setStepStack((pre) => pre.slice(1));
            }
        },
        [setStepStack, stepStack]
    );

    const lastStep = useMemo(() => stepStack[0], [stepStack]);

    return {
        stepStack,
        setStepStack,
        pushStep,
        popStep,
        lastStep,
    };
};
export default useStepStack;
