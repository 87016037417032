import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components'
import FingerprintJs from "fingerprintjs";

// import data
import { counter } from '../../data/mockData';

// import image
import close from '../../images/ic_close_gray.svg'

// import action
import { connectDesktop } from '../../actions/counterAction';
import { clearError } from "../../actions/commonAction";

// import type action
import * as typesAction from "../../actions/types";

// import helper
import { buildErrorContent } from "../../helper/tool";
// import * as statusCode from "../../helper/statusCodeResponse";

const Bound = styled.div`
  position:absolute;
  top:61px;
  left:60px;
  padding: 16px 12px;
  width: 216px;
  /* height: 356px; */
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  font-family: "SVN-Gilroy";
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  animation: mymove .5s;
    transition: 5s all ease-in-out;
    @keyframes mymove {
      from {top: 0px;}
      to {top: 61px;}
    }
  .popupHeader{
    display:flex;
    justify-content:space-between;
    .title{
      font-size: 16px;
      font-weight: bold;
      line-height: 1.19;
      color: #000000;
    }
    .ic_close{
      width:20px;
      height:20px;
      cursor: pointer;
    }
  }
  .popupMain{
    margin: 25px 0 0 0;
    .label{
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      margin-bottom: 5px;
    }
    .label_pin, .label_counter{
      margin-top: 15px;
    }
    input{
      padding: 10px;
      width: calc(100% - 20px);
      height: 24px;
      border-radius: 4px;
      border: solid 0.5px #c8c8c8;
      background-color: #ffffff;
      &::placeholder{
        opacity: 0.25;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.68;
        color: #222222;
      }
    }
    .error{
      margin: 5px 0 0 0;
      height: 24px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.68;
      color: #f31919;
    }
  }
  .popupFooter{
    margin: 20px 0 0 0;
    display:flex;
    justify-content: center;
    .btn_submit{
      width: 100px;
      height: 36px;
      border-radius: 18px;
      border:none;
      outline:none;
      background-color: #005993;
      color:#ffffff;
      display:flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
`

class PopupCouterLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: counter,
      idTmp: '',
      pinTmp: '',
      counterTmp: '',
      error: ' '
    }
  }

  onChange = (e) => {
    this.setState({
      error: '',
      [e.target.name]: e.target.value
    })
  }

  onSubmit = async (e) => {
    e.preventDefault();
    let { idTmp, pinTmp } = this.state;
    if (!idTmp.trim() || !pinTmp.trim()) {
      this.setState({
        error: 'Vui lòng nhập thông tin'
      })
      return;
    }
    const fingerprint = new FingerprintJs({ canvas: true }).get();
    // 3319139927
    await this.props.connectDesktop(fingerprint.toString(), idTmp, pinTmp)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.counterReducer !== this.props.counterReducer) {
      const { counterReducer } = this.props;
      if (counterReducer.err[typesAction.CONNECT_DESKTOP_ERR]) {
        // console.clear();
        this.setState({
          error: buildErrorContent(typesAction.CONNECT_DESKTOP_ERR, counterReducer.err[typesAction.CONNECT_DESKTOP_ERR]),
          idTmp: '',
          pinTmp: '',
          counterTmp: ''
        }, () => {
          this.props.clearError(typesAction.CONNECT_DESKTOP_ERR)
        })
      }
      if (Object.keys(this.props.counterReducer.dataConnectDesktop).length > 0)
        this.props.onClosePopup()
    }
  }


  render() {
    return (
      <Bound>
        <div className="popupHeader">
          <div className="title">
            Cấu hình quầy
          </div>
          <div className="ic_close"
            onClick={this.props.onClosePopup}
          >
            <img src={close} alt="hinhanh" />
          </div>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="popupMain">
            <div className="form_id">
              <p className="label label_id">MÃ KẾT NỐI QUẦY</p>
              <input
                onChange={this.onChange}
                type="text"
                name='idTmp'
                value={this.state.idTmp}
                className="input_id"
                placeholder='Nhập mã kết nối'
              />
            </div>
            <div className="form_pin">
              <p className="label label_pin">MÃ PINCODE</p>
              <input
                onChange={this.onChange}
                type="text"
                name='pinTmp'
                value={this.state.pinTmp}
                className="input_pin"
                placeholder='Nhập mã PIN'
              />
            </div>
            {/* <div className="form_counter">
              <p className="label label_counter">Mã Quầy</p>
              <input
                onChange={this.onChange}
                type="text"
                name='counterTmp'
                value={this.state.counterTmp}
                className="input_counter"
                placeholder='Nhập ID'
              />
            </div> */}
            <div className="error">
              {this.state.error}
            </div>
          </div>
          <div className="popupFooter">
            <button className="btn_submit" type='submit' >
              XÁC NHẬN
          </button>
          </div>
        </form>
      </Bound>
    )
  }
}

const mapStateFromProps = state => ({
  counterReducer: state.counterReducer
})

const mapDispatchFromProps = dispatch => ({
  connectDesktop: (deviceMac, connectId, pwd) =>
    dispatch(connectDesktop(deviceMac, connectId, pwd)),
  clearError: (typeErr) => dispatch(clearError(typeErr))
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, { forwardRef: true })(PopupCouterLogin)
