import { CORPCUST } from '../data/serviceTypes'

const isDisableEventWithCORPCust = (ticketId, counterReducer) => {
    const currentTicket = counterReducer.listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )
    if (!currentTicket) return false

    const { listCorpCustomer } = counterReducer
    
    if (listCorpCustomer?.length === 0) return false

    const CustType = currentTicket?.CustType || currentTicket?.CustomerInfos?.CustType
    const KH_DOANH_NGHIEP = "1"
    if (CustType === KH_DOANH_NGHIEP) return true

    return false
}

export default isDisableEventWithCORPCust
