import React from 'react';
import ReactDOM from 'react-dom';
import Axios from "axios";
import { Provider } from 'react-redux';
// import moment from "moment";
import packageJson from '../package.json';


// import component
import App from './App';

// import file
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { 
	setCurrentTeller, 
	setDataConnectDesktop,
	changeStateCounter
} from './actions/counterAction';
import setAuth from './helper/setAuth';

import * as counterStates from "./data/counterStates";

// import style
import './index.css';

console.log("version:", packageJson.version);

const store = configureStore()
const TIME_OUT_MILISECOND = 30000;
Axios.defaults.timeout = TIME_OUT_MILISECOND;

const dataLogin = JSON.parse(localStorage.getItem("dataLogin"))
const dataConnectDesktop = JSON.parse(localStorage.getItem("dataConnectDesktop"))
const counterState = localStorage.getItem("counterState");
/**
 * Check dataLogin
 */
if (dataLogin) {
  setAuth(dataLogin)
  store.dispatch(setCurrentTeller(dataLogin))
}
else{
  localStorage.removeItem("dataLogin")
}

/**
 * Check data connect desktop
 */
if (dataConnectDesktop) {
  store.dispatch(setDataConnectDesktop(dataConnectDesktop))  
}
else
  localStorage.removeItem("dataConnectDesktop")

/**
 * Check default status of counter
 */
if(Object.values(counterStates).includes(counterState))
	store.dispatch(changeStateCounter(counterState))

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
