import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

//component
import TickyContainer from './TickyTable/TickyContainer'
import Header from './Header'
import InformTransactionContainerV2 from './Task/InformTransactionContainerV2'
import InformTransactionContainerForCollector from './Task/InformTransactionContainerForCollector'
import PopupALertLogout from '../../Popup/PopupAlertLogut'
import LoadingControl from '../../Control/LoadingControl'
import ListWaitingCollector from './ListWaitingCollector'

//data
import { mainTicket, waittingTicket } from '../../../data/mockData'

import {
    getListTypeCounterServices,
    getCurrentTickets,
    getListAdviseService,
    getTimeWaitConfig,
} from '../../../actions/counterAction'
import { openPopup } from '../../../actions/popupBioAction'
import * as counterStates from '../../../data/counterStates'
import { COORDINATOR, COUNSELOR, TELLER, COORLOBBY, ADVISETICKET, TAKETICKET, INBIOCOLLECTOR } from '../../../data/userRole'
import { clearError } from '../../../actions/commonAction'
import { getProcessingAdvise, setIsShowPopupCounselor, setShowContentCounselorPage } from '../../../actions/counselorAction'
import ContentCounselor from '../../ComponentV2/screens/CounselorPage/ContentCounselor'
import IcNondata from '../../ComponentV2/images/SVG/IcNondata'

const BoundStyle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fafafa;
    overflow: auto;
    scrollbar-width: none;
    .non-data {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .non-data-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 24px;
            width: 210px;
            .non-data-text {
                font-size: 17px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
                color: #636060;
            }
        }
    }
    @media only screen and (min-width: 1301px) {
        padding: 0 68px 20px 68px;
    }
    @media only screen and (min-width: 1251px) and (max-width: 1300px) {
        padding: 0 20px 20px 20px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1250px) {
        padding: 0 60px 20px 60px;
    }
    @media only screen and (min-width: 1151px) and (max-width: 1200px) {
        padding: 0 45px 20px 45px;
    }
    @media only screen and (min-width: 1101px) and (max-width: 1150px) {
        padding: 0 30px 20px 30px;
    }
    @media only screen and (max-width: 1100px) {
        padding: 0 20px 20px 20px;
    }
`
class MainscreenContainer extends Component {
    mainTicket = mainTicket
    state = {
        mainTask: [],
        listTypeServices: [],
        getTicket: {},
        counterState: this.props.counterReducer.counterState,
    }
    toggleCounterStatus = async () => {
        let { counterState } = this.props.counterReducer
        let { counterReducer } = this.props

        if (counterState === counterStates.OPEN_COUNTER) {
            if (this.state.mainTask < 1) {
                await this.setState({
                    mainTask: [],
                })
            }
            if (this.state.mainTask && this.state.mainTask.length > 0) {
                return
            }
        }

        if (counterState === counterStates.STOP_COUNTER) {
            await this.props.getCurrentTickets(
                counterReducer.dataConnectDesktop.CounterID,
                counterReducer.dataConnectDesktop.OfficeID
            )
            if (counterReducer.listCurrentTicket.length > 0) {
                await this.setState({
                    mainTask: this.props.counterReducer.listCurrentTicket,
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps(n) {
        if (n) {
            let counterReducer = n.counterReducer
            this.setState({
                listTypeServices: counterReducer.listTypeCounterServices,
                getTicket: counterReducer.dataGetTicket.TicketData,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { counterReducer } = this.props
        if (
            prevProps.counterReducer.listCurrentTicket !==
            this.props.counterReducer.listCurrentTicket &&
            counterReducer.counterState !== counterStates.STOP_COUNTER
        ) {
            this.setState({
                mainTask: this.props.counterReducer.listCurrentTicket,
            })
        }
        if (this.props.detailsProcessingAdvise.Number) {
            if (counterReducer.dataLogin.Roles.includes(COORLOBBY) && counterReducer.dataLogin.Roles.includes(ADVISETICKET)) {
                this.props.setShowContentCounselorPage(true)
                return
            }
            if (counterReducer.dataLogin.Roles.includes(TELLER)) {
                this.props.setIsShowPopupCounselor(true)
                return
            }
            if (counterReducer.dataLogin.Roles.includes(COUNSELOR)) {
                this.props.setShowContentCounselorPage(true)
                return
            }
        }
    }

    render() {
        const { dataLogin } = this.props.counterReducer
        return (
            <BoundStyle>
                <Header
                    toggleCounterStatus={this.toggleCounterStatus}
                    data={this.state.mainTask}
                />
                {this.props.counterReducer.counterState === 'S' && (dataLogin.Roles.includes(COORLOBBY) || dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COUNSELOR) || dataLogin.Roles.includes(COORDINATOR)) ? (
                    <div className="non-data">
                        <div className="non-data-content">
                            <IcNondata />
                            <div className="non-data-text">
                                Bắt đầu giao dịch để tải danh sách hàng đợi
                            </div>
                        </div>
                    </div>
                ) : this.props.isShowContentCounselorPage ? (
                    <ContentCounselor />
                ) : dataLogin.Roles.includes(TELLER) ? (
                    <React.Fragment>
                        <InformTransactionContainerV2
                            counterState={
                                this.props.counterReducer.counterState
                            }
                            data={this.state.mainTask}
                            listTypeServices={this.state.listTypeServices}
                            onNextTask={this.onNextTask}
                        />
                        {(this.props.counterReducer.counterState ===
                            counterStates.OPEN_COUNTER ||
                            this.state.mainTask.length > 0) && (
                                <TickyContainer
                                    dataWait={mainTicket}
                                    dataMiss={waittingTicket}
                                    showPassTicketPopup={() =>
                                        this.props.showPassTicketPopup()
                                    }
                                    counterReducer={this.props.counterReducer}
                                    isTypeUser={TELLER}
                                />
                            )}
                    </React.Fragment>
                ) : dataLogin.Roles.includes(COORLOBBY) ? (
                    <React.Fragment>
                        {(this.props.counterReducer.counterState ===
                            counterStates.OPEN_COUNTER ||
                            this.state.mainTask.length > 0) && (
                                <TickyContainer
                                    dataWait={mainTicket}
                                    dataMiss={waittingTicket}
                                    counterReducer={this.props.counterReducer}
                                    isTypeUser={COORLOBBY}
                                />
                            )}
                    </React.Fragment>
                ) : dataLogin.Roles.includes(COUNSELOR) ? (
                    <React.Fragment>
                        {(this.props.counterReducer.counterState ===
                            counterStates.OPEN_COUNTER ||
                            this.state.mainTask.length > 0) && (
                                <TickyContainer
                                    dataWait={mainTicket}
                                    dataMiss={waittingTicket}
                                    counterReducer={this.props.counterReducer}
                                    isTypeUser={COUNSELOR}
                                />
                            )}
                    </React.Fragment>
                ) : dataLogin.Roles.includes(COORDINATOR) ? (
                    <React.Fragment>
                        {(this.props.counterReducer.counterState ===
                            counterStates.OPEN_COUNTER ||
                            this.state.mainTask.length > 0) && (
                                <TickyContainer
                                    dataWait={mainTicket}
                                    dataMiss={waittingTicket}
                                    counterReducer={this.props.counterReducer}
                                    isTypeUser={COORDINATOR}
                                />
                            )}
                    </React.Fragment>
                ) : (
                    <>
                        <InformTransactionContainerForCollector />
                        <ListWaitingCollector />
                    </>
                )}

                {this.props.isLogout && <PopupALertLogout />}
                {this.props.isLoading && <LoadingControl size="100px" />}
            </BoundStyle>
        )
    }
    componentDidMount() {
        let { counterReducer } = this.props
        this.props.getListAdviseService(counterReducer.dataLogin.UID)
        this.props.getTimeWaitConfig()
        if (counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) || counterReducer.dataLogin.Roles.includes(TAKETICKET) || counterReducer.dataLogin.Roles.includes(TELLER) || counterReducer.dataLogin.Roles.includes(COUNSELOR) || counterReducer.dataLogin.Roles.includes(COORDINATOR)) {
            this.props.getListTypeCounterServices(counterReducer.dataLogin.UID)
        }
        if (counterReducer.dataLogin.Roles.includes(TELLER) || counterReducer.dataLogin.Roles.includes(COUNSELOR) || counterReducer.dataLogin.Roles.includes(COORDINATOR) || counterReducer.dataLogin.Roles.includes(ADVISETICKET)) {
            this.props.getProcessingAdvise(counterReducer.dataConnectDesktop.OfficeID, counterReducer.dataConnectDesktop.CounterID)
        }
    }
}

const mapStateFromProps = (state) => ({
    counterReducer: state.counterReducer,
    isLogout: state.commonReducer.isLogout,
    isLoading: state.commonReducer.isLoading,
    isShowContentCounselorPage: state.counselor.isShowContentCounselorPage,
    detailsProcessingAdvise: state.counselor.detailsProcessingAdvise
})

const mapDispatchFromProps = (dispatch) => ({
    getListTypeCounterServices: (userId) => dispatch(getListTypeCounterServices(userId)),
    getCurrentTickets: (CounterID, OfficeID) => dispatch(getCurrentTickets(CounterID, OfficeID)),
    openPopup: (type, levelPopup, data) => dispatch(openPopup(type, levelPopup, data)),
    clearError: (typeError) => dispatch(clearError(typeError)),
    getListAdviseService: (uid) => dispatch(getListAdviseService(uid)),
    getTimeWaitConfig: () => dispatch(getTimeWaitConfig()),
    getProcessingAdvise: (OfficeID, CounterID) => dispatch(getProcessingAdvise(OfficeID, CounterID)),
    setIsShowPopupCounselor: (isShow) => dispatch(setIsShowPopupCounselor(isShow)),
    setShowContentCounselorPage: (isShow) => dispatch(setShowContentCounselorPage(isShow))
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, {
    forwardRef: true,
})(MainscreenContainer)
