import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import LoadingControl from '../Control/LoadingControl'
import default_user from '../../images/default_user.svg'
import ic_zoom from '../../images/ic_zoom.svg'
import icRefreshWhite from '../../images/Icons_Dashboard_Refresh_White.svg'
import ic_cancel from '../../images/icon_cancel.svg'

import { openPopup } from '../../actions/popupBioAction'
import {
    REGISTER,
    CONFIRM,
    VALIDATE_DATA,
    LIST_ID,
    REMOVE,
} from '../../actions/typeRequestBio'
import {
    apiGetBioFile,
    apiGetBioFaceImage,
    updateFingerInfoInRegisterThread,
} from '../../actions/biometricAction'
import { TELLER } from '../../data/userRole'
import {
    apiGetBioFileCollector,
    apiGetBioFaceImageCollector,
} from '../../actions/biometricWithInBioCollectorAction'

// data
import typesKeyDetailPopup from '../../data/typesKeyDetailPopup'
import { popupBioTypes } from '../../data/PopupTypes'
import bioActionTypes from '../../data/bioActionTypes'

// helper
import { openPopupDeleteBioRegistered } from '../../helper/actionOpenPopup'
import isDisableEventClick from '../../helper/isDisableEventWhenHaveSupervisor'

const Bound = styled.div`
    width: fit-content;
    display: inline-block;
    height: 200px;
    margin: 0 auto;
    border-radius: 5px;
    border: solid 0.5px #c8c8c8;
    background-color: #ffffff;
    font-family: 'SVN-Gilroy';
    position: relative;
    &:hover {
        cursor: pointer;
        .overlay {
            visibility: visible;
        }
    }
    .cover-faceID {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        .img-default {
            overflow: hidden;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 100%;
                object-fit: contain;
            }
            .block_loading {
                padding: 20px 0 0 0;
            }
        }
        .content {
            font-size: 12px;
            font-weight: 500;
            color: #787878;
            position: absolute;
            bottom: 25px;
        }
    }
    .overlay {
        z-index: 25;
        width: 100%;
        height: 100%;
        visibility: hidden;
        background-color: rgba(34, 34, 34, 0.5);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        .register {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 1.68;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            text-decoration: underline;
        }
        .infor-note {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            line-height: 1.68;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 23%;
            width: 77px;
            transform: translateY(-50%);
        }
    }
    .block_zoom {
        z-index: 26;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        cursor: pointer;
    }
`
const FACE = 'FACE'
const REGISTER_FACEID = 'REGISTER_FACEID'
const CONFIRM_FACEID = 'CONFIRM_FACEID'
const CHANGE_REGISTER = 'CHANGE_REGISTER'
const DELETE_BIO_UPDATE = 'DELETE_BIO_UPDATE'
const SUCCESS_REGISTER = 'SUCCESS_REGISTER'
const LOADING = 'LOADING'
const FLOW_UPDATE = 'FLOW_UPDATE'
const FLOW_UPDATE_FACE_IMAGE = 'FLOW_UPDATE_FACE_IMAGE'

const detailStep = {
    [REGISTER_FACEID]: {
        textDetail: 'Đăng ký',
    },

    [CONFIRM_FACEID]: {
        textDetail: 'Xác thực',
    },
    [CHANGE_REGISTER]: {
        textDetail: 'Thay đổi',
    },
    [DELETE_BIO_UPDATE]: {
        textDetail: '',
    },
    [SUCCESS_REGISTER]: {
        textDetail: '',
    },
    [FLOW_UPDATE]: {
        textDetail: 'Xem chi tiết',
    },
    [FLOW_UPDATE_FACE_IMAGE]: {
        textDetail: 'Xem chi tiết',
    },
}

class FaceIDControlV3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: REGISTER_FACEID,
            faceID: default_user,
            content: 'Hình ảnh khuôn mặt',
        }
    }

    registerFaceID = () => {
        const { ticketId } = this.props
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.props.registerFaceID()
    }

    confirmFaceID = () => {
        const { ticketId } = this.props
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.props.confirmFaceID()
    }

    SubmitRemoveFaceID = () => {
        const { ticketId } = this.props
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.props.SubmitRemoveFaceID()
    }

    showPopupFaceID = () => {
        const { ticketId } = this.props
        switch (this.state.step) {
            case SUCCESS_REGISTER:
            case DELETE_BIO_UPDATE:
                this.props.openPopup(popupBioTypes.ShowFaceImage, 2, {
                    typeDetail: typesKeyDetailPopup.faceBioInfoRegister,
                    faceID: this.state.faceID,
                    dataGetTicket: { TicketID: ticketId },
                    flowUpdate: this.props.flowUpdate,
                })
                break

            case FLOW_UPDATE:
                this.props.openPopup(popupBioTypes.ShowFaceImage, 2, {
                    typeDetail: typesKeyDetailPopup.faceBioInfoUpdate,
                    faceID: this.state.faceID,
                    dataGetTicket: { TicketID: ticketId },
                    flowUpdate: this.props.flowUpdate,
                })
                break
            case FLOW_UPDATE_FACE_IMAGE:
                this.props.openPopup(popupBioTypes.PopupFaceUpdateFlow, 2, {
                    ticketId,
                })
                break
            default:
                break
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.biometricReducer !== this.props.biometricReducer)
            this.handleShowUIFace()
    }

    componentDidMount() {
        this.handleShowUIFace()
    }

    handleShowUIFace = () => {
        const { ticketId, biometricReducer, counterReducer } = this.props

        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        const currentTicket = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )

        if (!currentTicket) return

        // 1. check waitingConfirm FACE
        // 2. check FACE was delete
        // 3. check CONFIRM FACE success
        // 4. check CONFIRM FACE failed
        // 5. check REGISTER FACE
        // 6. check validatedData FACE

        const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId)

        const isTicketWaitingGetBio =
            isTicketHaveBio &&
            Object.keys(biometricReducer[ticketId]).includes('waitingConfirm')
        // check waitingConfirm FACE
        const isTicketWaitingGetBioFace =
            isTicketWaitingGetBio &&
            biometricReducer[ticketId].waitingConfirm.typeBio === FACE

        const isTicketHaveFaceBio =
            isTicketHaveBio &&
            Object.keys(biometricReducer[ticketId]).includes(FACE)
        // check FACE was delete
        const isTicketHaveFaceBioDelete =
            isTicketHaveFaceBio &&
            biometricReducer[ticketId][FACE].bioActionType ===
                bioActionTypes.REMOVE
        const isTicketHaveFaceConfirm =
            isTicketHaveFaceBio &&
            Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM)
        // check CONFIRM FACE success
        const isTicketHaveFaceConfirmSuccess =
            isTicketHaveFaceConfirm &&
            biometricReducer[ticketId][FACE][CONFIRM] === 0
        // check CONFIRM FACE failed
        const isTicketHaveFaceConfirmFailed =
            isTicketHaveFaceConfirm &&
            biometricReducer[ticketId][FACE][CONFIRM] !== 0
        // check REGISTER FACE
        const isTicketHaveFaceRegister =
            isTicketHaveFaceBio &&
            Object.keys(biometricReducer[ticketId][FACE]).includes(REGISTER)
        // check validatedData FACE
        const isTicketHaveFaceValidatedData =
            isTicketHaveFaceBio &&
            Object.keys(biometricReducer[ticketId][FACE]).includes(
                'validatedData'
            )

        switch (true) {
            case isTicketWaitingGetBioFace && !isTicketHaveFaceValidatedData: {
                const isTicketWaitingRegister =
                    isTicketWaitingGetBio &&
                    biometricReducer[ticketId].waitingConfirm.typeRequestBio ===
                        REGISTER
                this.setState({
                    step: LOADING,
                    content: isTicketWaitingRegister
                        ? 'Đang lấy hình ảnh...'
                        : 'Đang xác thực hình ảnh...',
                })
                break
            }
            case isTicketHaveFaceBioDelete: {
                const imgFace = biometricReducer[ticketId][FACE][REGISTER]
                const linkFace = userIsTeller
                    ? apiGetBioFaceImage
                    : apiGetBioFaceImageCollector
                this.setState({
                    step: DELETE_BIO_UPDATE,
                    content: '',

                    faceID: linkFace(imgFace),
                })
                break
            }
            case isTicketHaveFaceConfirmSuccess: {
                const imgFace = biometricReducer[ticketId][FACE][REGISTER]
                this.setState({
                    step: SUCCESS_REGISTER,
                    content: '',
                    faceID: userIsTeller
                        ? apiGetBioFile(imgFace)
                        : apiGetBioFileCollector(imgFace),
                })
                break
            }
            case isTicketHaveFaceConfirmFailed &&
                !isTicketHaveFaceValidatedData: {
                const imgFace = biometricReducer[ticketId][FACE][REGISTER]
                this.setState({
                    step: CHANGE_REGISTER,
                    content: '',
                    faceID: userIsTeller
                        ? apiGetBioFile(imgFace)
                        : apiGetBioFileCollector(imgFace),
                })
                this.props.openPopup(popupBioTypes.PopupNotify, 3, {
                    titlePopup: 'Đăng ký khuôn mặt',
                    detailNotify:
                        'Khuôn mặt xác thực không trùng khớp. Bạn vui lòng thử lại.',
                    titleBtn: 'Thử lại',
                })
                break
            }
            case isTicketHaveFaceRegister && !isTicketHaveFaceValidatedData: {
                const imgFace = biometricReducer[ticketId][FACE][REGISTER]
                this.setState({
                    step: CONFIRM_FACEID,
                    content: '',
                    faceID: userIsTeller
                        ? apiGetBioFile(imgFace)
                        : apiGetBioFileCollector(imgFace),
                })
                break
            }
            case isTicketHaveFaceValidatedData &&
                isTicketHaveFaceRegister &&
                !isTicketHaveFaceConfirm:
            case isTicketHaveFaceValidatedData &&
                isTicketHaveFaceRegister &&
                isTicketHaveFaceConfirmFailed: {
                const linkFace = userIsTeller
                    ? apiGetBioFaceImage
                    : apiGetBioFaceImageCollector
                const imgFace =
                    biometricReducer[ticketId][FACE][VALIDATE_DATA][LIST_ID]
                this.setState({
                    step: FLOW_UPDATE_FACE_IMAGE,
                    faceID: linkFace(imgFace),
                    content: '',
                })
                break
            }
            case isTicketHaveFaceValidatedData: {
                const linkFace = userIsTeller
                    ? apiGetBioFaceImage
                    : apiGetBioFaceImageCollector
                const imgFace =
                    biometricReducer[ticketId][FACE][VALIDATE_DATA][LIST_ID]
                this.setState({
                    step: FLOW_UPDATE,
                    faceID: linkFace(imgFace),
                    content: '',
                })
                break
            }
            default:
                this.setState({
                    step: REGISTER_FACEID,
                    faceID: default_user,
                    content: 'Hình ảnh khuôn mặt',
                })
                break
        }
    }

    clearDataFace = () => {
        const { ticketId, biometricReducer, counterReducer } = this.props
        if (isDisableEventClick(ticketId, counterReducer, this)) return

        const bioOfCus = biometricReducer[ticketId]
        const faceId = bioOfCus[FACE][REGISTER]
        const enrollId = counterReducer.enrollId[ticketId]
        if (!Object.keys(bioOfCus[FACE]).includes(VALIDATE_DATA)) {
            const haveAllFingerDeleted = Object.keys(bioOfCus)
                .filter((item) => item !== FACE)
                .every((item) => bioOfCus[item].bioActionType === REMOVE)

            if (!haveAllFingerDeleted) {
                this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
                    icon: ic_cancel,
                    label:
                        'Đề nghị xoá dấu vân tay của khách hàng trước khi lấy lại sinh trắc học khuôn mặt',
                    width: '226px',
                })
                return
            }
        }

        if (Object.keys(bioOfCus[FACE]).includes(VALIDATE_DATA)) {
            const haveFingerNewRegiter = Object.keys(bioOfCus)
                .filter((item) => item !== FACE)
                .find(
                    (item) =>
                        !Object.keys(bioOfCus[item]).includes(VALIDATE_DATA)
                )

            if (haveFingerNewRegiter) {
                // debugger;
                this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
                    icon: ic_cancel,
                    label: (
                        <>
                            <div>Bạn Vui lòng xóa ngón tay đang lấy mẫu</div>
                            <div>chưa được phê duyệt trước khi</div>
                            <div>lấy lại hình ảnh khuôn mặt</div>
                        </>
                    ),
                    width: '340px',
                })
                return
            }
        }

        const data = {
            ticketId,
            faceId,
            enrollId,
            bioType: FACE,
        }
        openPopupDeleteBioRegistered(
            this,
            data,
            'clearDataFace - FaceIDControl.js'
        )
        // debugger;
    }

    handleActionMainCall = () => {
        const { step } = this.state
        // debugger;
        switch (step) {
            case REGISTER_FACEID:
                this.registerFaceID()
                break
            // case UPDATE_AFTER_DELETE_BIO:
            //   break;
            case CONFIRM_FACEID:
                this.confirmFaceID()
                break
            case CHANGE_REGISTER:
                this.SubmitRemoveFaceID()
                break
            case SUCCESS_REGISTER:
            case DELETE_BIO_UPDATE:
            case FLOW_UPDATE:
            case FLOW_UPDATE_FACE_IMAGE:
                this.showPopupFaceID()
                break
            default:
                break
        }
    }

    render() {
        // console.log(this.props.flowUpdate);

        let { step, content, faceID } = this.state
        return (
            <Bound>
                {Object.keys(detailStep).includes(step) && !this.props.counterReducer.dataConnectDesktop.VirtualTablet ? (
                    <div
                        className="overlay"
                        onClick={this.handleActionMainCall}
                    >
                        <div className="register">
                            {detailStep[step].textDetail}
                        </div>
                    </div>
                ) : null}
                <div
                    className="cover-faceID"
                    style={
                        [REGISTER_FACEID, LOADING].includes(step)
                            ? { width: '200px' }
                            : {}
                    }
                >
                    <div className="img-default">
                        {
                            // step === UPDATE_AFTER_DELETE_BIO ||
                            step === REGISTER_FACEID ? (
                                <img
                                    src={default_user}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        marginTop: '10px',
                                        objectFit: 'contain',
                                    }}
                                    alt="faceID"
                                />
                            ) : (
                                step === LOADING && (
                                    <div className="block_loading">
                                        <LoadingControl
                                            size="42px"
                                            loadingPage={false}
                                        />
                                    </div>
                                )
                            )
                        }
                        {[
                            CONFIRM_FACEID,
                            CHANGE_REGISTER,
                            SUCCESS_REGISTER,
                            DELETE_BIO_UPDATE,
                            FLOW_UPDATE,
                            FLOW_UPDATE_FACE_IMAGE,
                        ].includes(step) ? (
                            <img src={faceID} alt="faceID" />
                        ) : null}
                    </div>
                    {content && <div className="content">{content}</div>}
                </div>
                {[
                    SUCCESS_REGISTER,
                    DELETE_BIO_UPDATE,
                    FLOW_UPDATE,
                    FLOW_UPDATE_FACE_IMAGE,
                    CONFIRM_FACEID,
                ].includes(step) ? (
                    <div className="block_zoom">
                        {step !== CONFIRM_FACEID ? (
                            <img
                                style={{ marginLeft: '4px' }}
                                src={ic_zoom}
                                alt="zoom"
                                onClick={() => this.showPopupFaceID()}
                            />
                        ) : (
                            <div></div>
                        )}
                        {![
                            FLOW_UPDATE,
                            DELETE_BIO_UPDATE,
                            FLOW_UPDATE_FACE_IMAGE,
                        ].includes(step) ? (
                            <img
                                src={icRefreshWhite}
                                alt="refresh"
                                onClick={this.clearDataFace}
                            />
                        ) : (
                            <div></div>
                        )}
                    </div>
                ) : null}
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    biometricReducer: state.biometricReducer,
    counterReducer: state.counterReducer,
})
const mapDispatchToProps = (dispatch) => ({
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    confirmFaceTransaction: (ticketId, enrollId, bioType, isAuthenticate) =>
        dispatch(
            updateFingerInfoInRegisterThread(
                ticketId,
                enrollId,
                bioType,
                isAuthenticate
            )
        ),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(FaceIDControlV3)
