import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { connect } from 'react-redux'

// import data
import { typeNotice } from '../../data/data'

// import action
import { addNewNotify } from '../../actions/notifyAction'
import { clearDataCounselor } from '../../actions/counselorAction'
import { cancelAppointmentOnline } from '../../actions/counterAction'

const StyledCountDownTimeControl = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    line-height: 1.68;
    color: #222222;
`

const MAX_HOURS_WAIT = 0
const MAX_MINUTES_WAIT = 10

class CountDownTimeControl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            time: '',
            wasNotified: false,
        }

        this.objInterval = {}
        this._isMounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            clearInterval(this.objInterval)
            this.countTime.style.color = '#222222'
            console.log('countdown-nextprops')
            this.getTimeCountDown(nextProps.data)
        }
    }

    setTwoNumFormat = (num) => {
        if (num < 0) {
            let newNum = num * -1
            return ('0' + newNum).slice(-2)
        } else {
            return ('0' + num).slice(-2)
        }
    }

    setTimeCount = (getStartTime) => {
        //check riêng cho TH countdown
        if (this.props.isTimeCountdown) {
            if (!moment().isBefore(moment(getStartTime)) || !getStartTime) {
                this.setState({ time: `00:00:00` })
                clearInterval(this.objInterval)
                return
            }
        }

        const currentTime = moment()

        const duration = moment.duration(currentTime.diff(getStartTime))
        const hrs = this.setTwoNumFormat(duration.hours())
        const mins = this.setTwoNumFormat(duration.minutes())
        let secs = this.setTwoNumFormat(duration.seconds())

        //nếu là list đặt vé online khi thời gian đếm ngược = 0 thì dừng lại và gọi api cancel vé đó
        if (
            this.props.isTimeCountdown &&
            hrs === '00' &&
            mins === '00' &&
            secs === '00'
        ) {
            this._isMounted = false
            clearInterval(this.objInterval)
        }

        this.setState({ time: `${hrs}:${mins}:${secs}` }, () => {
            const time = this.state.time.split(':')
            const [hours, minutes] = time
            if (this.props.isCounselor) return
            if (
                Number(hours) > MAX_HOURS_WAIT ||
                Number(minutes) >= MAX_MINUTES_WAIT
            ) {
                this.countTime.style.color = '#d71249'
                if (!this.state.wasNotified) {
                    const dataNotify = {
                        type: typeNotice.beyondTime,
                        ticketNum: this.props.ticketNumber,
                        time: moment().format('HH:mm, DD/MM/YYYY'),
                    }
                    this.props.addNewNotify(dataNotify)
                    this.setState({ wasNotified: true })
                }
            }
        })
    }

    getTimeCountDown = (startTime) => {
        let getStartTime
        if (this.props.isTimeCountdown) {
            if (!moment().isBefore(moment(startTime))) {
                this.setState({ time: `00:00:00` })
                return
            }
            getStartTime = moment(startTime)
                
        } else {
            getStartTime = moment().isBefore(moment(startTime))
                ? moment()
                : moment(startTime)
        }
        
        this.setTimeCount(getStartTime)
        if (!this.props.isStart && this.props.isTeller) return
        this.objInterval = setInterval(() => {
            this.setTimeCount(getStartTime)
        }, 1000)
    }

    render() {
        return (
            <StyledCountDownTimeControl
                className={this.props.class}
                ref={(ref) => (this.countTime = ref)}
            >
                {this.state.time}
            </StyledCountDownTimeControl>
        )
    }

    componentDidMount() {
        if (!this.props.isStart && this.props.isTeller) {
            // load lần đầu role Teller thì set về default
            this.setState({ time: `00:00:00` })
            return
        }
        //nếu load lần đầu mà quá hạn thời gian đặt hẹn thì call cancel vé đó đi
        if (this.props.isTimeCountdown) {
            if (!moment().isBefore(moment(this.props.data))) {
                this.setState({ time: `00:00:00` })
                return
            }
        }
        
        this._isMounted = true
        this._isMounted && this.getTimeCountDown(this.props.data)
    }

    componentWillUnmount() {
        this._isMounted = false
        clearInterval(this.objInterval)
    }

    componentDidUpdate() {
        if (
            (this.props.isEndSuccess ||
                (!this.props.isStart && this.props.isTeller)) &&
            !this.props.isNonTicket
        ) {
            this._isMounted = false
            clearInterval(this.objInterval)
        }

        if (this._isMounted) return

        if (this.props.isTimeCountdown) {
            if (!moment().isBefore(moment(this.props.data))) {
                this._isMounted = true
                this.setState({ time: `00:00:00` })
                return
            }
        }

        // nếu mà ko có ticket trong mainTask thì set default
        if (
            this.props.isNonTicket &&
            !this._isMounted &&
            !this.props.isStart &&
            this.props.isTeller
        ) {
            this._isMounted = true
            this.setState({ time: `00:00:00` })
        }
        // nếu có props data thì đếm tiếp tục
        if (this.props.isStart && this.props.isTeller && this.props.data) {
            this._isMounted = true
            this.getTimeCountDown(this.props.data)
            return
        }
        // ko có props data thì đếm từ 0
        if (!this.props.isTeller) return
        let objEndTask = this.props.counselor.isEndAdviseTeller.filter(task => task?.ticketId === this.props.ticketId)[0]
        if (
            this.props.isStart &&
            this.props.isTeller &&
            !objEndTask?.isEnd
        ) {
            const getStartTime = moment()
            this._isMounted = true
            this.objInterval = setInterval(() => {
                this.setTimeCount(getStartTime)
            }, 1000)
        }
    }
}

const mapStateFromProps = (state) => ({
    counterReducer: state.counterReducer,
    counselor: state.counselor,
})

const mapDispatchToProps = (dispatch) => ({
    addNewNotify: (data) => dispatch(addNewNotify(data)),
    clearDataCounselor: () => dispatch(clearDataCounselor()),
    cancelAppointmentOnline: (officeId, counterId, appoitmentId) =>
        dispatch(cancelAppointmentOnline(officeId, counterId, appoitmentId)),
})

export default connect(
    mapStateFromProps,
    mapDispatchToProps
)(CountDownTimeControl)
