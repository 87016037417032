import React, { Component } from 'react'
import styled from "styled-components";
import moment from "moment";
import { connect } from 'react-redux'


// component
import LoadingControl from "../Control/LoadingControl";

const Bound = styled.div`
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: #FAFAFA;
    border-radius: 10px;
    /* box-shadow: 0 0 20px #000; */
    padding: 0 63px;

    & * {
        text-align: center;
        font-family: SVN-Gilroy;
    } 

    .title {
        margin-top: 28px;
        margin-bottom: 32px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #005993;
        width: 19ch;
    }

    .content {
        margin-bottom: 39px;

        .time-count {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: gray;

            span {
                color: #222222;
            }
        }
    }

    .footer {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        color: #222222;
        margin-bottom: 21px;
    }
`;

const WAIT_TIME_IN_SECONDS = 30;

class PopupWaitingEvalueateQualityService extends Component {
    state = {
        time: ""
    }
    
    
    
    render() {
        return (
            <Bound>
                <div className="title">Đang chờ khách hàng đánh giá dịch vụ</div>
                <div className="content">
                    <LoadingControl size="36px" loadingPage={false} />
                    <div className="time-count">Thời gian chờ: <span>{` 00:${this.state.time}`}</span></div>
                </div>
                <div className="footer">Bạn vui lòng chờ trong giây lát...</div>
            </Bound>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.counterReducer.dataConnectDesktop.VirtualTablet){
            this.props.closePopup()
            return
        }
        if(prevState.time !== this.state.time && this.state.time==="00"){
            this.props.closePopup();
        }
        // if(this.props.counterReducer.listCurrentTicket !== prevProps.counterReducer.listCurrentTicket){
        //     // debugger;
        //     // const currentTicket = this.props.counterReducer.listCurrentTicket.find(
        //     //     item => item.TicketID === this.props.ticketId
        //     // )
        //     // if(currentTicket && currentTicket.isFinishWaitingEvalueate)
        //     this.props.closePopup();
        // }
    }
    

    componentDidMount() {
        if (this.props.counterReducer.dataConnectDesktop.VirtualTablet){
            this.props.closePopup()
            return
        }
        const nowTime = moment();
        const timeCall = moment();
        const duration = moment.duration(nowTime.diff(timeCall));
        const hrs = duration.hours();
        const mins = duration.minutes();
        const secs = duration.seconds();
        let range = WAIT_TIME_IN_SECONDS - secs;
        if(hrs > 0 || mins > 0 || range <= 0) {
            this.setState({time: "00"})
            return;
        }
        this.setState({time: ("0" + range).slice(-2)})
        const setTimeOutCall = this.setTimeOutCall = setInterval(() => {
            const range = this.state.time - 1;
            if(range <= 0) {
                this.setState({time: "00"})
                clearInterval(setTimeOutCall);
                return;
            }
            this.setState({time: ("0" + range).slice(-2)})
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.setTimeOutCall);
    }
    
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

export default connect(mapStateToProps)(PopupWaitingEvalueateQualityService)
