import styled from 'styled-components'

const WrapPopupPrintFail = styled.div`
    display: flex;
    width: 340px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 38px;
    border-radius: 10px;
    background: var(--White, #fff);
    box-sizing: border-box;
    .block-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        .bold {
            color: var(--Black, #191919);
            text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 128%;
        }
        .red {
            color: var(--False, #f31919);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 128%;
        }
    }
    .block-btn {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        .btn-print-cancel,
        .btn-print-confirm {
            display: flex;
            width: 96px;
            height: 33px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            border-radius: 18px;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }
        .btn-print-cancel {
            background: var(--Gray-3, #e6e6e6);
            color: var(--Gray, #636060);
        }
        .btn-print-confirm {
            background: var(--primary-1, #005993);
            color: var(--White, #fff);
        }
    }
`

export { WrapPopupPrintFail }
