import React from 'react'

// styles
import { Styles } from './index.styles'

// data
import { registerTypes } from "../index"

const RegisterForm = (props, ref) => {
    const {
        currentTicket,
        registerType,
        arrFingerRegister,
        city,
        nowTime,
        nowDay,
        nowMonth,
        nowYear,
        officeName,
    } = props

    const customerInfos = currentTicket.CustomerInfos;
    
    return (
        <Styles ref={ref}>
            <div className="page">
                <div className="form-infos">
                    <div>Biểu mẫu 01/ĐK</div>
                    <div>CIF: {customerInfos.CustomerNumber}</div>
                </div>
                <div className="country-infos">
                    <div className="name-of-country">
                        CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </div>
                    <div className="motto">Độc lập - Tự do - Hạnh phúc</div>
                </div>
                <div className="date-of-now">
                    {city}, ngày {nowDay} tháng {nowMonth} năm {nowYear}
                </div>
                <div className="name-of-form">
                    GIẤY ĐỀ NGHỊ ĐĂNG KÝ THÔNG TIN SINH TRẮC HỌC
                </div>
                <div className="where-to-send">
                    Kính gửi: Ngân hàng TMCP Công thương Việt Nam (VietinBank) {officeName}
                </div>
                <div className="wrapper">
                    <div className="title p-5">A. THÔNG TIN KHÁCH HÀNG</div>
                    <div className="p-5">
                        Họ tên: {customerInfos.FullNameVi}
                    </div>
                    <div className="grid-wrapper col-3">
                        <div>
                            Số CMND/Hộ chiếu/Thẻ CCCD: {customerInfos.IdNumber}
                        </div>
                        <div>Ngày cấp: {customerInfos.IdIssueDate}</div>
                        <div>Nơi cấp: {customerInfos.IdIssuePlace}</div>
                    </div>
                    <div className="p-5">
                        Địa chỉ hiện tại: {customerInfos.Address}
                    </div>
                    <div className="grid-wrapper col-3">
                        <div>Số điện thoại: {customerInfos.MobileNumber}</div>
                        <div className="email">Email: {customerInfos.Email}</div>
                    </div>
                    <div className="title p-5">
                        B. NỘI DUNG ĐỀ NGHỊ: Đề nghị VietinBank đăng ký:
                    </div>
                    <div className="p-5 title-request">
                        1. Thông tin sinh trắc học
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="checkbox-field">
                            <span
                                className={
                                    registerType === registerTypes.face
                                        ? 'checkbox checked'
                                        : 'checkbox'
                                }
                            />
                            <span className="content">
                                Sinh trắc học khuôn mặt
                            </span>
                        </div>
                        <div className="checkbox-field">
                            <span
                                className={
                                    registerType === registerTypes.faceAndFinger
                                        ? 'checkbox checked'
                                        : 'checkbox'
                                }
                            />
                            <span className="content">
                                Sinh trắc học khuôn mặt và vân tay
                            </span>
                        </div>
                    </div>
                    <div className="grid-wrapper col-2 p-5 border-split title-request">
                        2. Ngón tay đăng ký:
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="hand-title">Bàn tay trái</div>
                        <div className="hand-title">Bàn tay phải</div>
                    </div>
                    {!!arrFingerRegister &&
                        arrFingerRegister.map((item, key) => (
                            <div
                                className="grid-wrapper col-2 border-split"
                                key={key}
                            >
                                <div className="checkbox-field">
                                    <span
                                        className={
                                            /NO_CHECK_/.test(item[0])
                                                ? 'checkbox'
                                                : 'checkbox checked'
                                        }
                                    />
                                    <span className="content">
                                        {/NO_CHECK_/.test(item[0])
                                            ? item[0].slice(9)
                                            : item[0]}
                                    </span>
                                </div>
                                <div className="checkbox-field">
                                    <span
                                        className={
                                            /NO_CHECK_/.test(item[1])
                                                ? 'checkbox'
                                                : 'checkbox checked'
                                        }
                                    />
                                    <span className="content">
                                        {/NO_CHECK_/.test(item[1])
                                            ? item[1].slice(9)
                                            : item[1]}
                                    </span>
                                </div>
                            </div>
                        ))}
                    <div className="title p-5">C. CAM KẾT</div>
                    <ul className="list-commitment">
                        <li>
                            Tôi xác nhận đã đọc, hiểu rõ, đồng ý với các quy
                            định trong “Điều khoản, điều kiện quản lý và sử dụng
                            thông tin sinh trắc học trong quá trình giao dịch
                            tại VietinBank” theo Phụ lục đính kèm và các thông
                            tin theo giấy đề nghị này. Bản Điều khoản, điều kiện
                            này là một phần không thể tách rời của Giấy đề nghị
                            đăng ký thông tin sinh trắc học.
                        </li>
                        <li>
                            Tôi đồng ý sử dụng số điện thoại nêu tại mục A để sử
                            dụng trong các giao dịch điện tử/giao dịch thẻ thanh
                            toán trực tuyến với VietinBank và sử dụng để nhận
                            các thông báo từ VietinBank (bao gồm các thông báo
                            liên quan đến thay đổi thông tin sinh trắc học).
                        </li>
                        <li>
                            Kịp thời thông báo cho VietinBank biết khi thông tin
                            sinh trắc học của tôi có thay đổi và tự chịu trách
                            nhiệm trong trường hợp không thông báo dẫn đến thông
                            tin bị lợi dụng, giả mạo.
                        </li>
                        <li>
                            Tôi cam đoan và hoàn toàn chịu trách nhiệm về tính
                            chính xác, hợp pháp của các thông tin trên Giấy đề
                            nghị đăng ký thông tin sinh trắc học.
                        </li>
                        <li>
                            Giấy đề nghị này có hiệu lực từ ngày ký và được lập
                            thành 02 bản có nội dung như nhau, khách hàng giữ 01
                            bản, VietinBank - {officeName} giữ 01 bản làm căn cứ
                            thực hiện
                        </li>
                    </ul>
                </div>
                <div className="grid-wrapper col-2">
                    <div></div>
                    <div className="sign-range">
                        <div>KHÁCH HÀNG</div>
                        <div>(Ký và ghi rõ họ tên)</div>
                    </div>
                </div>
                <div className="confirm-bank">
                    Xác nhận của Ngân hàng TMCP Công Thương Việt Nam
                </div>
                <div className="time-register">
                    Đã thực hiện đăng ký thông tin sinh trắc học theo đề nghị
                    của khách hàng vào hồi {nowTime} ngày {nowDay} tháng{' '}
                    {nowMonth} năm {nowYear}.
                </div>
                <div className="grid-wrapper col-2">
                    <div className="sign-range">
                        <div>GDV/CBĐT</div>
                        <div>(Ký , ghi rõ họ tên)</div>
                    </div>
                    <div className="sign-range">
                        <div>
                            ĐẠI DIỆN VIETINBANK
                            <span className="details-num">1</span>
                        </div>
                        <div>(Ký, ghi rõ họ tên, đóng dấu)</div>
                    </div>
                </div>
                <br />
                <br />
                <hr className="footer-hr" />
                <div className="details-list">
                    <span className="details-num">1</span>Đại diện VietinBank là
                    lãnh đạo Chi nhánh/Phòng kế toán/Phòng giao dịch/Phòng dịch
                    vụ khách hàng
                </div>
            </div>
            <div className="page">
                <div className="title-note">
                    PHỤ LỤC ĐIỀU KHOẢN, ĐIỀU KIỆN QUẢN LÝ VÀ SỬ DỤNG THÔNG TIN
                    SINH TRẮC HỌC TRONG QUÁ TRINH GIAO DỊCH TẠI VIETINBANK
                </div>
                <div className="wrapper-cols-2">
                    <div className="rule">Giải thích từ ngữ</div>
                    <ul className="list-commitment">
                        <li>
                            Kiosk: là một thiết bị ngân hàng giúp (i) nhận diện{' '}
                            khách hàng thông qua hình ảnh sinh trắc học; (ii){' '}
                            phân luồng khách hàng; (iii) thu thập các thông tin
                            giao dịch của khách hàng; (iv) cung cấp các sản{' '}
                            phẩm, dịch vụ của ngân hàng đến khách hàng bao gồm
                            cả các giao dịch do khách hàng tự thực hiện.
                        </li>
                        <li>
                            Sản phẩm dịch vụ (‘Dịch vụ’) là các sản phẩm dịch vụ{' '}
                            được VietinBank cung cấp cho Khách hàng thông qua{' '}
                            các kênh phân phối (tại quầy, tại kiosk, web...){' '}
                            theo quy định từng thời kỳ.
                        </li>
                        <li>
                            Thông tin Sinh trắc học (STH) của Khách hàng bao gồm
                            hình ảnh khuôn mặt và/hoặc dấu vân tay được Khách
                            hàng đăng ký với Ngân hàng thông qua các thiết bị
                            thu thập thông tin Sinh trắc học của Ngân hàng.
                        </li>
                        <li>
                            Vân tay giao dịch: là hình ảnh STH vân tay của KH{' '}
                            được sử dụng để xác thực GD khi thực hiện các GD tự
                            thực hiện trên Kiosk, xác thực KH tại Kiosk (đối với
                            các KH bị trùng dữ liệu STH khuôn mặt) và xác thực
                            KH khi GD tại quầy đối với các KH đã có thông tin
                            STH. Vân tay giao dịch phải ở trạng thái hoạt động.
                        </li>
                        <li>
                            STH khuôn mặt của KH được sử dụng để: Nhận diện,{' '}
                            định danh KH tại kiosk để giúp phân luồng KH và gia
                            tăng trải nghiệm của KH tại kiosk.
                        </li>
                        <li>
                            Giao dịch tự thực hiện: Là những giao dịch phi tiền
                            mặt theo quy định từng thời kỳ của VietinBank theo
                            đó khách hàng thực hiện giao dịch thông qua bước
                            nhận diện bằng STH khuôn mặt và xác thực giao dịch
                            bằng STH vân tay mà không phải đến quầy giao dịch.
                        </li>
                    </ul>

                    <div className="rule">Điều khoản chung</div>
                    <ul className="list-commitment">
                        <li>
                            Khách hàng cam kết đã được VietinBank cung cấp đầy
                            đủ các điều kiện giao dịch trước khi ký kết giấy đề
                            nghị đăng ký thông tin STH.
                        </li>
                        <li>
                            Thông tin sinh trắc học của khách hàng được đối{' '}
                            chiếu tự động và ngân hàng chỉ chấp nhận xử lý yêu
                            cầu giao dịch của khách hàng khi thông tin sinh trắc
                            học khớp đúng với dữ liệu đã đăng ký với VietinBank.
                        </li>
                        <li>
                            Khi sửa đổi/bổ sung/thay thế các nội dung trong bản
                            “Điều khoản, điều kiện quản lý và sử dụng thông tin
                            sinh trắc học trong quá trình giao dịch tại
                            VietinBank” trong trường hợp quy định của Pháp luật
                            có sự thay đổi hoặc theo thỏa thuận của KH và
                            VietinBank, VietinBank có trách nhiệm thông báo công
                            khai trên website www.vietinbank.vn và thông báo cho
                            KH theo phương thức KH đăng ký hoặc thỏa thuận với{' '}
                            VietinBank (nếu có) tối thiểu 07 ngày trước khi có
                            hiệu lực; Nếu không đồng ý với các nội dung sửa đổi,
                            KH có quyền chấm dứt việc sử dụng thông tin STH
                            và/hoặc các Dịch vụ đã đăng ký; Nếu tiếp tục sử dụng
                            thông tin STH và/hoặc các Dịch vụ đã đăng ký được
                            hiểu là KH chấp thuận hoàn toàn các sửa đổi, bổ
                            sung, thay thế đó.
                        </li>
                        <li>
                            Các điều kiện và điều khoản (‘Các Điều Khoản’) này
                            áp dụng và điều chỉnh việc đăng ký, thay đổi và sử
                            dụng thông tin sinh trắc học cho các giao dịch giữa
                            Khách hàng và Ngân hàng TMCP Công thương Việt Nam
                            (‘Ngân hàng’ hoặc ‘Chúng tôi’ hoặc ‘VietinBank’).
                        </li>
                    </ul>

                    <div className="rule">
                        Quyền và trách nhiệm của khách hàng
                    </div>
                    <div className="small-rule">Quyền của khách hàng</div>
                    <ul className="list-commitment">
                        <li>
                            Được sử dụng hình ảnh sinh trắc học đã đăng ký để
                            nhận diện, xác thực khi giao dịch với ngân hàng.
                        </li>
                        <li>
                            Yêu cầu VietinBank đăng ký, thay đổi, bổ sung, hủy
                            bỏ đăng ký sinh trắc học và các yêu cầu khác có liên
                            quan đến sinh trắc học của khách hàng trong quá
                            trình sử dụng Dịch vụ, bao gồm cả trường hợp khách
                            hàng phát hiện/nghi ngờ có sai sót, rủi ro xảy ra.
                        </li>
                        <li>
                            Yêu cầu VietinBank hướng dẫn, hỗ trợ trong quá trình{' '}
                            đăng ký, sử dụng sinh trắc học để giao dịch tại
                            VietinBank.
                        </li>
                        <li>
                            Khiếu nại về các sai sót hoặc nghi ngờ sai sót liên{' '}
                            quan đến sinh trắc học của khách hàng trong quá
                            trình giao dịch với ngân hàng mà không phải do lỗi
                            của khách hàng.
                        </li>
                        <li>
                            Đối với khiếu nại liên quan đến từng sản phẩm, dịch
                            vụ: khách hàng được phép khiếu nại theo thời gian
                            quy định của từng sản phẩm, dịch vụ.
                        </li>
                    </ul>
                    <div className="small-rule">Trách nhiệm của khách hàng</div>
                    <ul className="list-commitment">
                        <li>
                            Tuân thủ các thủ tục đăng ký/thay đổi sinh trắc học,{' '}
                            trình tự giao dịch và hướng dẫn khác của VietinBank{' '}
                            khi sử dụng Dịch vụ.
                        </li>
                        <li>
                            Cung cấp đầy đủ, chính xác các thông tin và giấy tờ
                            cần thiết theo quy định của VietinBank khi đăng ký,
                            thay đổi thông tin sinh trắc học và (hoặc) yêu cầu
                            liên quan khác; Kịp thời sửa đổi/bổ sung thông tin
                            và giấy tờ cần thiết cho VietinBank khi có sự thay
                            đổi đảm bảo các thông tin khách hàng cung cấp cho
                            VietinBank luôn là thông tin cập nhật nhất và chính
                            xác nhất.
                        </li>
                        <li>
                            Chịu trách nhiệm về tất cả các thông tin được hệ{' '}
                            thống ghi nhận đối với việc xử lý các giao dịch được{' '}
                            thực hiện bởi khách hàng thông qua việc sử dụng
                            thông tin sinh trắc học đã đăng ký.
                        </li>
                        <li>
                            Nghiên cứu mức phí trước khi sử dụng dịch vụ. Thanh
                            toán đầy đủ, đúng hạn và chấp nhận việc VietinBank
                            chủ động trích (ghi Nợ) tài khoản thanh toán của
                            khách hàng để thu các loại phí liên quan đến việc sử
                            dụng dịch vụ và giao dịch của khách hàng theo quy
                            định của VietinBank.
                        </li>
                        <li>
                            Khách hàng có trách nhiệm bảo mật thông tin sinh
                            trắc học của khách hàng và tự chịu trách nhiệm trong
                            trường hợp thông tin sinh trắc học bị lợi dụng cho
                            các giao dịch không hợp pháp hoặc có phát sinh tranh
                            chấp với bên thứ ba, trừ trường hợp do lỗi của
                            VietinBank.
                        </li>
                        <li>
                            Thông báo kịp thời cho VietinBank thông qua tổng đài
                            điện thoại của Trung tâm dịch vụ khách hàng{' '}
                            VietinBank – 1900558868 (có ghi âm) hoặc tại bất kỳ
                            Điểm giao dịch nào của VietinBank khi phát hiện có
                            rủi ro liên quan đến tài khoản của khách hàng hoặc
                            các thông tin nhận diện sinh trắc học (vân tay,
                            khuôn mặt) bị lợi dụng hoặc không chính xác. Hai bên
                            đồng ý sử dụng các thông tin đã được ghi âm trong
                            quá trình giải quyết các rủi ro có liên quan đến tài
                            khoản thanh toán của khách hàng hoặc các thông tin
                            nhận diện sinh trắc học bị lợi dụng hoặc không chính
                            xác.
                        </li>
                        <li>
                            Phối hợp với VietinBank giải quyết, khắc phục các{' '}
                            sai sót, sự cố hoặc tranh chấp trong quá trình sử
                            dụng Dịch vụ.
                        </li>
                        <li>
                            Thực hiện theo quy định của Ngân hàng nhà nước Việt{' '}
                            Nam và của pháp luật đối với các nội dung có liên{' '}
                            quan không quy định tại điều kiện, điều khoản này.
                        </li>
                    </ul>

                    <div className="rule">
                        Quyền và trách nhiệm của VietinBank
                    </div>
                    <div className="small-rule">Quyền của VietinBank</div>
                    <ul className="list-commitment">
                        <li>
                            Tạm dừng cung cấp sản phẩm, dịch vụ khi phát hiện{' '}
                            hoặc nghi ngờ có sai sót, gian lận, sự cố liên quan
                            đến sinh trắc học của khách hàng đối với các sản
                            phẩm, dịch vụ được thực hiện xác thực bằng sinh trắc
                            học.
                        </li>
                        <li>
                            Yêu cầu Khách hàng đăng ký, thay đổi, bổ sung, hủy
                            bỏ sinh trắc học khi phát hiện có sai sót, gian lận,
                            sự cố trong quá trình sử dụng thông tin sinh trắc
                            học của khách hàng.
                        </li>
                        <li>
                            Được miễn trách nhiệm do không thực hiện Giao dịch
                            theo yêu cầu của Khách hàng trong trường hợp hệ
                            thống xử lý, hệ thống truyền tin… bị trục trặc hoặc
                            vì bất kỳ lý do bất khả kháng nào khác;
                        </li>
                        <li>
                            Từ chối thực hiện các giao dịch không hợp pháp, hợp
                            lệ theo quy định của VietinBank và của pháp luật
                            hoặc trong trường hợp ngoài khả năng kiểm soát của
                            VietinBank hoặc trường hợp tài khoản của khách hàng
                            không đáp ứng đủ các điều kiện để thực hiện giao
                            dịch.
                        </li>
                        <li>
                            Trích Nợ từ tài khoản của khách hàng số tiền giao
                            dịch; các khoản thuế phí liên quan theo quy định của
                            VietinBank trong từng thời kỳ.
                        </li>
                        <li>
                            VietinBank có quyền lưu trữ thông tin sinh trắc học của khách hàng, cung cấp thông tin về sinh trắc học, tài khoản, giao dịch và các thông tin khác của khách hàng khi có yêu cầu của cơ quan có thẩm quyền theo đúng quy định của pháp luật.
                        </li>
                        <li>
                            Vietinbank có quyền cung cấp thông tin của NHCT cho các tổ chức/cá nhân khác không phải là CQNN có thẩm quyền nhằm mục đích phục vụ cho việc cung ứng SPDV cho KH (nếu có).
                        </li>
                        <li>
                            Các quyền khác theo quy định của VietinBank và của pháp luật.
                        </li>
                    </ul>
                    <div className="small-rule">Trách nhiệm của VietinBank</div>
                    <ul className="list-commitment">
                        <li>
                            Thực hiện đăng ký/thay đổi thông tin sinh trắc học
                            khi có yêu cầu của khách hàng nhằm gia tăng tính bảo
                            mật và tiện ích cho khách hàng trong quá trình sử
                            dụng Dịch vụ của VietinBank.
                        </li>
                        <li>
                            Cung cấp các Dịch vụ tự thực hiện tại kiosk cho
                            khách hàng với phương thức xác thực giao dịch bằng
                            sinh trắc học vân tay theo điều kiện, điều khoản
                            này.
                        </li>
                        <li>
                            Lưu trữ và bảo mật các thông tin liên quan đến sinh
                            trắc học, tài khoản, giao dịch của khách hàng theo
                            quy định của pháp luật.
                        </li>
                        <li>
                            Tiếp nhận và giải quyết các yêu cầu tra soát, khiếu
                            nại của khách hàng liên quan đến Dịch vụ của
                            VietinBank.
                        </li>
                        <li>
                            Hướng dẫn, hỗ trợ khách hàng trong quá trình đăng
                            ký, sử dụng sinh trắc học để giao dịch tại
                            VietinBank
                        </li>
                        <li>
                            Thực hiện theo quy định của Ngân hàng nhà nước Việt
                            Nam và của pháp luật đối với các nội dung có liên
                            quan không quy định tại điều kiện, điều khoản này.
                        </li>
                    </ul>

                    <div className="rule">
                        Điều khoản miễn trách của VietinBank
                    </div>
                    <ul className="list-commitment">
                        <li>
                            Được miễn trách nhiệm thực hiện các giao dịch theo
                            yêu cầu của khách hàng khi hệ thống của VietinBank
                            hoặc của bên thứ ba gặp sự cố và (hoặc) vì bất cứ lý
                            do bất khả kháng nào khác.
                        </li>
                        <li>
                            Được miễn trách nhiệm trong trường hợp khách hàng
                            khiếu nại các nhà cung cấp hoặc trường hợp khách
                            hàng lựa chọn sai tài khoản thanh toán người thụ
                            hưởng hoặc các thông tin trong giao dịch khách hàng
                            tự thực hiện là không chính xác.
                        </li>
                        <li>
                            Được miễn trách nhiệm đối với những thiệt hại, tổn
                            thất phát sinh trong quá trình sử dụng Dịch vụ của
                            khách hàng trừ khi những tổn thất, thiệt hại do lỗi
                            chủ quan của VietinBank gây ra.
                        </li>
                    </ul>

                    <div className="rule">
                        Luật điều chỉnh, giải quyết tranh chấp
                    </div>
                    <ul className="list-commitment">
                        <li>
                            Điều khoản này được điều chỉnh bởi pháp luật Việt
                            Nam. Nếu có bất kỳ tranh chấp nào phát sinh giữa
                            khách hàng và VietinBank thì tranh chấp đó trước hết
                            sẽ được giải quyết thông qua thương lượng, hòa giải
                            trên tinh thần hợp tác và tôn trọng quyền, lợi ích
                            hợp pháp của các bên.
                        </li>
                        <li>
                            Trường hợp thương lượng không thành thì Khách hàng
                            hoặc VietinBank có quyền đưa tranh chấp đó ra Tòa án
                            có thẩm quyền để giải quyết.
                        </li>
                    </ul>

                    <div className="rule">
                        Thời hạn hiệu lực và điều khoản thi hành
                    </div>
                    <ul className="list-commitment">
                        <li>
                            Các điều kiện, điều khoản này có hiệu lực kể từ ngày
                            khách hàng đăng ký sử dụng cho đến khi khách hàng
                            chấm dứt sử dụng sinh trắc học (khi khách hàng không
                            sử dụng hình ảnh sinh trắc học để xác thực và có đề
                            nghị đóng hình ảnh sinh trắc học)
                        </li>
                        <li>
                            Trường hợp một hoặc một số điều khoản được quy định
                            tại bản Điều khoản, điều kiện này bị vô hiệu thì
                            việc vô hiệu của các điều khoản này không làm ảnh
                            hưởng tới hiệu lực của các điều khoản khác của Phụ
                            lục này, trừ trường hợp các bên có thoả thuận khác,
                            hoặc việc vô hiệu của điều khoản đó dẫn tới việc
                            cung cấp dịch vụ bị chấm dứt
                        </li>
                        <li>
                            Trường hợp khách hàng đã chấm dứt sử dụng sinh trắc
                            học tại VietinBank, các giao dịch đã được thực hiện
                            trong thời hạn hiệu lực của điều kiện, điều khoản
                            này vẫn tiếp tục có giá trị.
                        </li>
                        <li>
                            Khách hàng xác nhận đã đọc, hiểu rõ và đồng ý với
                            các Điều kiện, điều khoản đăng ký sử dụng sinh trắc
                            học trên đây, cam kết thực hiện đúng các điều kiện,
                            điều khoản này cũng như các thoả thuận liên quan
                            khác của VietinBank và của pháp luật Việt Nam.
                        </li>
                        <li>
                            Các điều kiện, điều khoản này là một phần không thể
                            tách rời của Đơn đăng ký thông tin sinh trắc học.
                        </li>
                    </ul>

                    <div className="rule">
                        Thỏa thuận với các giao dịch tự thực hiện
                    </div>
                    <div className="small-rule">
                        Cung cấp và sử dụng sản phẩm dịch vụ
                    </div>
                    <ul className="list-commitment">
                        <li>
                            Trong thời gian vận hành Kiosk, tại một số thời điểm
                            nhất định Khách hàng có thể sẽ không truy cập, sử
                            dụng và thực hiện được một số hoặc tất cả các Dịch
                            vụ mà VietinBank cung cấp do việc bảo trì hệ thống
                            hoặc vì bất kỳ lý do bất khả kháng nào khác, bao gồm
                            nhưng không giới hạn: hỏa hoạn, lũ lụt, động đất,
                            sóng thần, chiến tranh, bạo loạn, cấm vận, bao vây…
                            hoặc các hạn chế khác của cơ quan nhà nước có thẩm
                            quyền mà không có bất kỳ sự khiếu nại nào từ phía
                            khách hàng.
                        </li>
                        <li>
                            Các thông tin liên quan đến giao dịch, số dư tài
                            khoản thanh toán của khách hàng được VietinBank cung
                            cấp qua kênh Kiosk có thể không luôn luôn được cập
                            nhật hoàn toàn bởi có các giao dịch chưa được
                            VietinBank xử lý hoặc điều chỉnh khi có sai sót,
                            nhầm lẫn. Vì vậy, Khách hàng chấp nhận rằng các
                            thông tin về giao dịch, số dư tài khoản của khách
                            hàng được thông báo qua kênh Kiosk của VietinBank có
                            thể chưa phải là thông tin chính xác cuối cùng ở
                            thời điểm thông báo.
                        </li>
                        <li>
                            VietinBank có thể chấm dứt/từ chối/tạm dừng cung cấp
                            các Dịch vụ mà không cần báo trước khi VietinBank
                            thấy là cần thiết, bao gồm nhưng không giới hạn các
                            trường hợp: (i) Khách hàng không tuân thủ các điều
                            kiện, điều khoản, quy định của VietinBank và/hoặc
                            của pháp luật về việc sử dụng Dịch vụ; (ii) Theo
                            quyết định, yêu cầu của pháp luật hoặc cơ quan nhà
                            nước có thẩm quyền; (iii) Các trường hợp liên quan{' '}
                            đến giả mạo, rủi ro hoặc có gian lận; (iv) Có căn
                            cứ/nghi ngờ về hoạt động rửa tiền của khách hàng;
                            (v) Khi lợi ích của VietinBank/khách hàng/bên thứ ba
                            có thể bị tổn hại; (vi) Trường hợp VietinBank tạm
                            ngưng Dịch vụ để bảo trì do có sự cố đột xuất;
                        </li>
                        <li>
                            Các giao dịch tự thực hiện của khách hàng qua kênh
                            Kiosk chỉ được chấp nhận khi thỏa mãn đồng thời các
                            điều kiện sau: (i) Được nhận diện chính xác thông
                            qua sinh trắc học khuôn mặt; (ii) Được xác thực
                            thành công thông qua sinh trắc học vân tay; (iii) Hệ
                            thống xác nhận việc nhận được chỉ thị của khách
                            hàng; (iv) Giao dịch của khách hàng hợp pháp, hợp lệ
                            và không vi phạm bất kỳ quy định nào của VietinBank,
                            của bên thứ ba và của pháp luật; (v) Số dư tài khoản
                            thanh toán của khách hàng đủ để thực hiện các giao
                            dịch đó.
                        </li>
                        <li>
                            Các giao dịch được khách hàng thực hiện qua kênh{' '}
                            Kiosk sẽ được VietinBank xử lý tuân thủ thời gian
                            giao dịch của VietinBank trong từng thời kỳ.
                        </li>
                    </ul>
                    <div className="small-rule">Chứng từ giao dịch</div>
                    <ul className="list-commitment">
                        <li>
                            Các tài liệu, chứng từ liên quan đến việc cung cấp,
                            sử dụng Dịch vụ giữa khách hàng với VietinBank cũng
                            như các số liệu được ghi chép, xác nhận và lưu giữ
                            bởi hệ thống công nghệ thông tin của VietinBank sẽ{' '}
                            là bằng chứng xác nhận Khách hàng đã thực hiện giao
                            dịch với VietinBank.
                        </li>
                        <li>
                            Chứng từ giao dịch được sử dụng đối với các Dịch vụ
                            giữa khách hàng với VietinBank là chứng từ điện tử.
                            Nội dung chứng từ điện tử phải tuân thủ quy định của
                            VietinBank.
                        </li>
                    </ul>
                    <div className="small-rule">Hạn mức giao dịch</div>
                    <ul className="list-commitment">
                        <li>
                            Tùy theo quy định trong từng thời kỳ, VietinBank có
                            thể thay đổi hoặc ấn định hạn mức cho các giao dịch
                            của khách hàng.
                        </li>
                        <li>
                            Trường hợp có sự thay đổi về hạn mức giao dịch,{' '}
                            VietinBank sẽ gửi thông báo hạn mức giao dịch của
                            các Dịch vụ cho khách hàng thông qua: (i) Các chi
                            nhánh, điểm giao dịch của VietinBank; (ii) Trang web
                            và (hoặc) các kênh cung cấp sản phẩm dịch vụ của
                            VietinBank; (iii) Các điểm giao dịch và (hoặc)
                            chương trình ứng dụng khác của bên thứ ba có thỏa
                            thuận hợp tác cung cấp, phát triển Dịch vụ với{' '}
                            VietinBank.
                        </li>
                    </ul>
                    <div className="small-rule">Phí dịch vụ</div>
                    <ul className="list-commitment">
                        <li>
                            Việc áp dụng và thu các loại phí đối với khách hàng
                            sử dụng Dịch vụ được tuân thủ các quy định về phí
                            của VietinBank trong từng thời kỳ.
                        </li>
                        <li>
                            Thông tin về các loại phí sử dụng Dịch vụ được
                            VietinBank niêm yết công khai tại các chi nhánh,
                            trang web chính thức của VietinBank, các kênh cung
                            cấp Dịch vụ hoặc được thông báo cho khách hàng qua{' '}
                            hộp thư điện tử của khách hàng.
                        </li>
                        <li>
                            Tùy theo từng loại Dịch vụ cụ thể, VietinBank sẽ
                            thực hiện thu phí của khách hàng theo gói Dịch vụ,{' '}
                            loại giao dịch, giá trị giao dịch, số lượng giao
                            dịch, đối tượng khách hàng, v.v.
                        </li>
                    </ul>
                    <div className="small-rule">Giao dịch không huỷ ngang</div>
                    <ul className="list-commitment">
                        <li>
                            Khách hàng không thể huỷ, thay đổi, phủ nhận hay từ
                            chối bất kỳ giao dịch tự thực hiện nào đã được thực{' '}
                            hiện qua kênh Kiosk của VietinBank. Trường hợp khách
                            hàng muốn huỷ yêu cầu thực hiện giao dịch,
                            VietinBank sẽ chỉ xem xét với điều kiện: (i) Yêu cầu
                            hủy của Khách hàng được thực hiện tại quầy giao dịch
                            của VietinBank; (ii) VietinBank chưa ghi nhận và
                            (hoặc) chưa xử lý giao dịch trên hệ thống; (iii){' '}
                            Việc huỷ giao dịch không ảnh hưởng tới lợi ích của
                            VietinBank cũng như lợi ích của bất kỳ bên thứ ba
                            nào khác.
                        </li>
                        <li>
                            Mọi giao dịch tự thực hiện của khách hàng qua kênh
                            Kiosk của VietinBank được coi là có hiệu lực và có
                            giá trị ràng buộc, không huỷ ngang và do khách hàng
                            là người duy nhất thực hiện. VietinBank không chấp{' '}
                            nhận bất cứ sự uỷ quyền nào khác cho bên thứ ba.
                        </li>
                    </ul>
                    <div className="small-rule">Tra soát, khiếu nại</div>
                    <ul className="list-commitment">
                        <li>
                            Khách hàng được quyền đề nghị tra soát, khiếu nại
                            trong vòng 60 ngày kể từ ngày phát sinh giao dịch
                            tra soát, khiếu nại theo 2 hình thức: (i) Tổng đài
                            điện thoại (có ghi âm) của Trung tâm dịch vụ khách{' '}
                            hàng VietinBank, hoặc, (ii) trực tiếp tại đơn vị đã
                            thực hiện điểm giao dịch của VietinBank. Trường hợp
                            tiếp nhận tra soát, khiếu nại qua Tổng đài điện
                            thoại, khách hàng bổ sung văn bản yêu cầu tra soát,{' '}
                            khiếu nại tại điểm giao dịch của VietinBank trong
                            vòng 03 ngày làm việc kể từ ngày khách hàng yêu cầu
                            tra soát, khiếu nại.
                        </li>
                        <li>
                            VietinBank tiếp nhận và xử lý tra soát, khiếu nại
                            của khách hàng theo quy định về thanh toán chuyển
                            tiền bằng VNĐ trong hệ thống NH TMCP Công Thương VN
                        </li>
                    </ul>
                </div>
            </div>
        </Styles>
    )
}

export default React.forwardRef(RegisterForm)
