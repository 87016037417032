import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
//css
import {
    WrapConfirmInfomation,
    WrapContentPage,
    WrapFooterPage,
} from './index.styles'
import {
    ACTIVE,
    INDIVIDUAL_CUSTOMER,
    QUEUETICKET,
    SUCCESS,
} from '../../../../../constant/keys'
import StepByStepBookingOnline from '../../StepByStepBookingOnline'
import StepByStepGetNumber from '../../StepByStepGetNumber'

//imgs
import IcUserMini from '../../../images/SVG/IcUserMini'
import IcIdentifyMini from '../../../images/SVG/IcIndentifyMini'
import IcPhoneMini from '../../../images/SVG/IcPhoneMini'
import IcEmailMini from '../../../images/SVG/IcEmailMini'
import IcArrowLeft from '../../../images/SVG/IcArrowLeft'

//action
import { setStepSelectBooking } from '../../../../../actions/bookAppointment'
import { setStepSelect } from '../../../../../actions/getNumberAction'
import ButtonControl from '../../../controls/ButtonControl'
import IcBank from '../../../images/SVG/IcBank'
import {
    apiGetIcon,
    getTicketQueueNumber,
} from '../../../../../actions/counterAction'
import { CORPCUST } from '../../../../../data/serviceTypes'

const ConfirmInfomation = ({
    personalInfo,
    listTransaction,
    onNext,
    onBack,
    setIsFail,
}) => {
    const dispatch = useDispatch()
    const { dataConnectDesktop } = useSelector((state) => ({
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
    }))
    const handleNext = () => {
        const callbackSuccess = () => {
            dispatch(
                setStepSelect({
                    step1: SUCCESS,
                    step2: SUCCESS,
                    step3: SUCCESS,
                    step4: ACTIVE,
                })
            )
            setTimeout(() => {
                onNext()
            }, 200)
        }
        const callbackError = () => {
            setIsFail()
        }

        let arrServiceKey = listTransaction?.map((item) => item.ServiceType)

        dispatch(
            getTicketQueueNumber(
                personalInfo?.accNumber,
                dataConnectDesktop.OfficeID,
                personalInfo?.customerType,
                personalInfo?.fullName,
                personalInfo?.identifyCard,
                personalInfo?.bussinessRegister,
                personalInfo?.phoneNumber,
                personalInfo?.email,
                arrServiceKey,
                '',
                QUEUETICKET,
                callbackSuccess,
                callbackError
            )
        )
    }

    const handleBack = () => {
        dispatch(
            setStepSelect({
                step1: SUCCESS,
                step2: ACTIVE,
                step3: '',
                step4: '',
            })
        )
        setTimeout(() => {
            onBack()
        }, 200)
    }

    return (
        <WrapConfirmInfomation>
            <div className="title-page">
                <div className="bold">Lấy số xếp hàng</div>
                <div className="normal">Lấy số cho khách hàng vào quầy</div>
            </div>
            <StepByStepGetNumber />
            <WrapContentPage>
                <div className="wrap-container">
                    <div className="wrap-list-info">
                        <div className="list-info">
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcUserMini />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        Loại khách hàng
                                    </div>
                                    <div className="value-text">
                                        {personalInfo?.customerType ===
                                            INDIVIDUAL_CUSTOMER
                                            ? 'Khách hàng cá nhân'
                                            : 'Khách hàng doanh nghiệp'}
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcUserMini />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">Họ tên</div>
                                    <div className="value-text">
                                        {/* {personalInfo?.customerType ===
                                        INDIVIDUAL_CUSTOMER ? personalInfo?.fullName ||
                                            'Khách hàng vãng lai' : '-'} */}
                                        {personalInfo?.fullName || 'Khách hàng vãng lai'}
                                    </div>
                                </div>
                            </div>
                            {
                                personalInfo?.customerType === INDIVIDUAL_CUSTOMER ?
                                    <div className='block-info'>
                                        <div className='wrap-icon'>
                                            <IcIdentifyMini />
                                        </div>
                                        <div className='wrap-text'>
                                            <div className='key-text'>GTTT (CCCD/CMND/Passport)</div>
                                            <div className='value-text'>{personalInfo?.identifyCard || "-"}</div>
                                        </div>
                                    </div>
                                    :
                                    <div className='block-info'>
                                        <div className='wrap-icon'>
                                            <IcIdentifyMini />
                                        </div>
                                        <div className='wrap-text'>
                                            <div className='key-text'>{personalInfo?.accNumber ? "Số tài khoản" : "Đăng ký kinh doanh"}</div>
                                            <div className='value-text'>{
                                                personalInfo?.accNumber ? personalInfo?.accNumber
                                                    :
                                                    personalInfo?.bussinessRegister ? personalInfo?.bussinessRegister : "-"}
                                            </div>
                                        </div>
                                    </div>
                            }                            
                            {personalInfo?.customerType ===
                                INDIVIDUAL_CUSTOMER && (
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcPhoneMini />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                Số điện thoại
                                            </div>
                                            <div className="value-text">
                                                {personalInfo?.phoneNumber || '-'}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {personalInfo?.customerType ===
                                INDIVIDUAL_CUSTOMER && (
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcEmailMini />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">Email</div>
                                            <div className="value-text">
                                                {personalInfo?.email || '-'}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcBank />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        PGD/ Chi nhánh
                                    </div>
                                    <div className="value-text">
                                        {dataConnectDesktop?.OfficeName || '-'}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="block-info">
                                <div className="wrap-icon">
                                    <IcBank />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        Phòng giao dịch
                                    </div>
                                    <div className="value-text">
                                        {dataConnectDesktop?.OfficeName || '-'}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="wrap-list-info">
                        <div className="title-content">Giao dịch</div>
                        <div className="list-info">
                            {listTransaction?.map((item, index) => (
                                <div key={index} className="block-info">
                                    {/*<div className="wrap-icon">
                                        <img
                                            src={apiGetIcon(item?.ID, 24)}
                                            alt="service"
                                        />
                                    </div>*/}
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            Giao dịch {index + 1}
                                        </div>
                                        <div className="value-text">
                                            {item?.Name || '-'}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </WrapContentPage>
            <WrapFooterPage>
                {/* <img src={pattern_vtb} alt='pattern_vtb' /> */}
                <div className="wrap-btn">
                    <div className="btn-back" onClick={handleBack}>
                        <IcArrowLeft />
                        <span>Quay lại</span>
                    </div>
                    <ButtonControl
                        text="Xuất vé"
                        className="btn-next"
                        onClick={handleNext}
                    />
                </div>
            </WrapFooterPage>
        </WrapConfirmInfomation>
    )
}

export default ConfirmInfomation
