import React, { useEffect, useState } from 'react'

//style
import { InputWrapper } from './index.styles'
import IcChecked from '../../images/SVG/IcChecked'
import IcUnCheck from '../../images/SVG/IcUnCheck'

const TEXT = "TEXT"
const TEXT_ACTIVE = "TEXT_ACTIVE"
const TEXT_ERROR = "TEXT_ERROR"

const InputControl = ({ className, isCheckBox = false, isShowCheckBox = false, handleCheckBox, isDisabled, inputType, handleValidationInput, maxLength, heightInput, widthInput, error, title, isRequired, type, keyInput, ...props }) => {

    const [typeInput, setTypeInput] = useState(inputType)
    const [errorInput, setErrorInput] = useState("")

    useEffect(() => {
        if (!props.value && !errorInput) return
        if (props.disabled) return
        setTypeInput(TEXT_ACTIVE)
        if (!errorInput) {
            setTypeInput(TEXT_ACTIVE)
        } else {
            setTypeInput(TEXT_ERROR)
        }
    }, [props.value, errorInput])

    useEffect(() => {
        setErrorInput(error)
    }, [error]);

    useEffect(() => {
        if (!props.disabled) return
        setTypeInput(TEXT)
    }, [props.disabled])

    const _handleFocusInput = () => {
        setErrorInput("")
        switch (typeInput) {
            case TEXT:
                setTypeInput(TEXT_ACTIVE)
                break;
            case TEXT_ERROR:
                setTypeInput(TEXT_ACTIVE)
                break;
            default: return;
        }
    }

    const _handleBlurInput = (e) => {
        const valueText = e.target.value
        if (!valueText) {
            setTypeInput(TEXT)
        } else {
            setTypeInput(TEXT_ACTIVE)
        }
        const callback = (textErrorInput) => {
            setErrorInput(textErrorInput)
            setTypeInput(TEXT_ERROR)
        }
        handleValidationInput && handleValidationInput(props.value, callback)
    }

    return (
        <InputWrapper heightInput={heightInput} widthInput={widthInput} className={isDisabled ? `${className} diabled` : className}>

            {title && (
                <div className='title-input'>
                    {isShowCheckBox && (
                        isCheckBox ?
                            <IcChecked className='ic-checkbox' onClick={handleCheckBox} />
                            :
                            <IcUnCheck className='ic-checkbox' onClick={handleCheckBox} />
                    )}
                    <div className='title'>
                        <span>{title}</span>
                        {isRequired && <span className='title-notice'>*</span>}
                    </div>
                </div>
            )}
            <div className="wrap-input">
                <input
                    {...props}
                    type={type}
                    onFocus={_handleFocusInput}
                    onBlur={_handleBlurInput}
                    maxLength={maxLength}
                    className={`${errorInput ? "error-input" : ""}`}
                />
            </div>
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </InputWrapper>
    )
}

export default InputControl
