import { combineReducers } from 'redux'

// import type
import * as types from '../actions/types'

// import reducer
import counterReducer, { initialState as counterInitialState } from "./counterReducer"
import biometricReducer, { initialState as biometricInitialState } from "./biometricReducer"
import popupBioManagerReducer, { initialState as popupBioManagerInitialState } from "./PopupBioManagerReducer"
import notifyReducer, { initialState as notifyInitialState } from "./notifyReducer"
import commonReducer, { initialState as commonReducerInitialState } from "./commonReducer";
import queriesReducer, { initialState as queriesReducerInitialState } from "./queriesReducer";
import inBioCollector, { initialState as inBioCollectorInitialState } from "./inBioCollector";
import getNumber, {initialState as getNumberInitialState} from './getNumber'
import bookAppointment, {initialState as bookAppointmentInitialState} from './bookAppointment'
import counselor, {initialState as counselorInitialState} from './counselor'

export const initialState = {
  counterReducer: counterInitialState,
  biometricReducer: biometricInitialState,
  popupBioManagerReducer: popupBioManagerInitialState,
  notifyReducer: notifyInitialState,
  commonReducer: commonReducerInitialState,
  queriesReducer: queriesReducerInitialState,
  inBioCollector: inBioCollectorInitialState,
  getNumber: getNumberInitialState,
  bookAppointment: bookAppointmentInitialState,
  counselor: counselorInitialState
}

const appReducer = combineReducers({
  counterReducer,
  biometricReducer,
  popupBioManagerReducer,
  notifyReducer,
  commonReducer,
  queriesReducer,
  inBioCollector,
  getNumber,
  bookAppointment,
  counselor
})

const rootReducer = (state, action) => {
  const oldState = state;
  if (action.type === types.COUNTER_LOGOUT) {
    localStorage.removeItem("dataLogin");
    localStorage.removeItem("counterState");
    localStorage.removeItem("biometricData");
    localStorage.removeItem("dataConnectDesktop");
    state = {
      ...initialState
    }
    state.counterReducer.dataConnectDesktop = oldState.counterReducer.dataConnectDesktop
  }
  return appReducer(state, action);
}



export default rootReducer