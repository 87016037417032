import styled from "styled-components";

const WrapSelectTransaction = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .title-page {
        color: var(--Black, #191919);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        padding: 16px 20px;
        .normal {
            color: var(--Gray, #636060);
            font-size: 14px;
            font-weight: 500;
            line-height: 128%;
            margin-top: 2px;
        }
    }

`
const WrapContentPage = styled.div` 
    width: 100%;
    height: 400px;  
   
    .wrap-list-transaction{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;        
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        gap: 4px;
        padding: 20px 20px 0 20px;        
    }
`

const WrapFooterPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    border-top: 1px solid #E6E6E6;
    .wrap-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 20px;
        box-sizing: border-box;
        .btn-back{
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--Gray, #636060);            
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.28px;
            cursor: pointer;
        }
        .wrap-service{
            display: flex;
            align-items: center;
            gap: 16px;
            .service{
                display: flex;
                align-items: center;                
                .wrap-text{
                    .text{
                        color: var(--Gray, #636060);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; 
                        letter-spacing: -0.32px;
                    }
                    .icon{
                        display: none;
                    }
                }
                span{
                    color: var(--primary-1, #005993);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.32px;
                    padding: 2px 0 0 2px;
                    width: 16px;
                }
            }
            .btn-next{
                display: flex;
                height: 52px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                border-radius: 26px;
                background-color: #005993;
            }
        }
    }

`

export { WrapSelectTransaction, WrapContentPage, WrapFooterPage }