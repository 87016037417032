import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";

import { closePopup } from '../../../actions/popupBioAction'
import CheckCustomerActions from './CheckCustomerActions';
import LoadingCheckCustomer from './LoadingCheckCustomer';
import CheckCustomerSuccess from './CheckCustomerSuccess';
import CheckCustomerFailFirst from './CheckCustomerFailFirst';
import AlertFingerHanging from "./AlertFingerHanging";

// import CheckCustomerFailSecond from './CheckCustomerFailSecond';

/////// data test 
import { FACE } from '../../../data/bioTypes';
import { AUTH_TRANS, VALIDATE_DATA, ISHANG } from '../../../actions/typeRequestBio';
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket';
import { removeStatusWaitingVerifyFinger } from '../../../actions/counterAction';
// import { UserDuplicate } from '../../../data/dataCheckCustomer'

const Bound = styled.div`
    width: 626px;
    border-radius: 10px;
    background-color: #fafafa;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    font-family: 'SVN-Gilroy';
    z-index: 9;
    box-shadow: 0 0 20px #222222b0;
    color: #000000;
    .header {
        padding: 0 20px;
        width: calc(100% - 40px);
        /*height: 60px;*/
        background-color: #ffffff;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px 2.5px 0px #a19f9f38;
        /*display: flex;*/
        /*justify-content:space-between;*/
        /*align-items: center;*/
        padding-bottom: 12px;
        padding-top: 19px;
        .titleHeader {
            font-weight: bold;
            line-height: 1.25;
            font-size: 16px;
            /*margin-top: 19px;*/
        }
        .customer-infos {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            /*margin-bottom: 12px;*/
            .block_CIF {
                font-size: 16px;
                font-weight: 500;
                /*line-height: 17.5;*/
                color: #005993;
                display: flex;
                align-items: center;
                justify-content: center;
                .CIF_num {
                    color: #222222;
                }
            }
        }
    }
    .main {
        width: 100%;
        border-radius: 0 0 10px 10px;
    }
`

class PopupCheckCustomer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingCheckCustomer: false,
      isCheckCustomerActions: true,
      isCheckStep: -1,
      numsOfTimeTryVerify: 3,
      reasonFailed: ""
      // isCheckFail: {
      //   fail_1: false,
      //   fail_2: false,
      //   isSuccess: false
      // }
    }
  }
  isStart = () => {
    this.setState({
      isLoadingCheck: true,
      isCheckCustomerActions: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoadingCheck: false,
          isCheckStep: 0,
          // isCheckStep:1,
          // isCheckStep:2,
        })
      }, 5000)
    })
  }
  getTryAgain = () => {
    this.setState({ isCheckCustomerActions: true, isCheckStep: -1, })
  }
  getSendKV = () => {

  }

  setReasonFailed = (reasonFailed) => {
    this.setState({ reasonFailed })
  }

  componentDidUpdate(prevProps, prevState) {

  }

  componentWillUnmount() {
    this.props.removeStatusWaitingVerifyFinger(this.props.data.ticketId, true)
  }


  render() {
    // const { 
    // isLoadingCheck, 
    // isCheckCustomerActions,
    // isCheckStep
    // } = this.state;
    const {
      ticketId,
      isAuthenBeforeCompleteTicket,
      isFingerHanging
    } = this.props.data;
    const currentTicket = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === ticketId);
    // debugger;
    const BioOfTicket = this.props.biometricReducer[ticketId];
    if (!BioOfTicket) return null;

    const fingerBioOfTicket = Object.keys(BioOfTicket).filter(item => item !== FACE);
    if (!fingerBioOfTicket) return null;

    // const isFingerConfirm = fingerBioOfTicket.filter(item => {
    //   return !!BioOfTicket[item][VALIDATE_DATA] && 
    //   BioOfTicket[item][VALIDATE_DATA][AUTH_TRANS]
    // })

    const isFingerConfirm = [];
    const lstFingerHang = [];
    Object.keys(BioOfTicket).forEach(item => {
      const bioIsFace = item === FACE;
      if (bioIsFace) return;
      const fingerBioOfTicketIsRemove = BioOfTicket[item].bioActionType === "REMOVE";
      if (fingerBioOfTicketIsRemove) return;
      const bioAddWasAccept = currentTicket.statusBiometric === typeStatusBiometrics.ACCEPT;
      const registerBioConfirmed = Object.keys(BioOfTicket[item]).includes(AUTH_TRANS);
      const bioUsedToAuth = BioOfTicket[item][AUTH_TRANS];
      const bioHang = BioOfTicket[item][ISHANG];
      if (bioAddWasAccept && registerBioConfirmed && !bioUsedToAuth)
        return;
      if (bioAddWasAccept && registerBioConfirmed && bioUsedToAuth && bioHang) {
        lstFingerHang.push(item)
        return;
      }

      if (bioAddWasAccept && registerBioConfirmed && bioUsedToAuth && !bioHang) {
        isFingerConfirm.push(item)
        return;
      }

      const isBioHaveValidatedData = Object.keys(BioOfTicket[item]).includes("validatedData");
      const isValidatedDataHaveConfirm = isBioHaveValidatedData &&
        Object.keys(BioOfTicket[item].validatedData).includes("usedToAuthenticateTransaction");
      const itemIsHang = Object.keys(BioOfTicket[item]).includes(AUTH_TRANS) &&
        BioOfTicket[item][AUTH_TRANS] ?
        BioOfTicket[item][ISHANG]
        :
        isBioHaveValidatedData && BioOfTicket[item][VALIDATE_DATA][ISHANG]
      if (isValidatedDataHaveConfirm &&
        BioOfTicket[item].validatedData.usedToAuthenticateTransaction
      ) {
        !itemIsHang && isFingerConfirm.push(item)
        itemIsHang && lstFingerHang.push(item)
      }

    })

    // debugger;
    // const isFingerHanging = !isFingerConfirm.length /* && lstFingerHang.length */;

    const { dataWasAuthen } = currentTicket;
    const isFingerHangingLocalCheck = isFingerHanging !== undefined ?
      isFingerHanging
      :
      currentTicket.CustomerInfos && dataWasAuthen && dataWasAuthen.BiometricFinger === "2";
    const { verifyCustomer } = this.props.counterReducer;
    // debugger;
    return (
      <Bound
      >
        <div className="header">
          <p className='titleHeader'>Xác thực khách hàng</p>
          <div className="customer-infos">
            <div className='block_CIF'>
              Họ tên:&nbsp;<p className='CIF_num'>{currentTicket.CustomerInfos.Name}</p>
            </div>
            <div className='block_CIF'>
              GTTT:&nbsp;<p className='CIF_num'>{currentTicket.CustomerInfos.IDNumber || currentTicket.CustomerInfos.IdNumber}</p>
            </div>
            <div className='block_CIF'>
              CIF:&nbsp;<p className='CIF_num'>{currentTicket.CustomerInfos.CustomerNumber}</p>
            </div>
          </div>
        </div>
        <div className="main">
          {
            isFingerHangingLocalCheck ?
              <AlertFingerHanging
                ticketId={ticketId}
              />
              :
              !verifyCustomer[ticketId] ?
                <CheckCustomerActions
                  ticketId={ticketId}
                  isAuthenBeforeCompleteTicket={isAuthenBeforeCompleteTicket}
                />
                :
                verifyCustomer[ticketId] &&
                  verifyCustomer[ticketId].waiting ?
                  <LoadingCheckCustomer ticketId={ticketId} />
                  :
                  verifyCustomer[ticketId] &&
                    !verifyCustomer[ticketId].waiting &&
                    verifyCustomer[ticketId].result &&
                    verifyCustomer[ticketId].result.Code === 0 ?
                    <CheckCustomerSuccess
                    // getConfirmSuccess={this.props.closePopup}
                    />
                    :
                    verifyCustomer[ticketId] &&
                      !verifyCustomer[ticketId].waiting &&
                      verifyCustomer[ticketId].result &&
                      verifyCustomer[ticketId].result.Code !== 0 &&
                      (verifyCustomer[ticketId].result.ValidFingerType || []) ?
                      <CheckCustomerFailFirst
                        ticketId={ticketId}
                        initialReason={this.state.reasonFailed}
                        setReasonFailed={this.setReasonFailed}
                        numsOfTimeTryVerify={this.state.numsOfTimeTryVerify}
                        decreaseNumsOfTimeTryVerify={() =>
                          this.setState(state => ({ numsOfTimeTryVerify: state.numsOfTimeTryVerify - 1 }))
                        }
                      // getTryAgainFail_1={this.getTryAgain} 
                      // getSendKV={()=>this.props.closePopup()}
                      />
                      :
                      null
          }
          {/*{
            isCheckStep===1 &&
            <CheckCustomerFailFirst 
              getTryAgainFail_1={this.getTryAgain} 
              getSendKV={()=>this.props.closePopup()}
            />
          }
          {
            isCheckStep===2 &&
            <CheckCustomerFailSecond
              UserDuplicate={UserDuplicate}
              getCheckFail2={()=>this.props.closePopup()}
            />
          }*/}

          {/* <CheckCustomerFail_2
            UserDuplicate={UserDuplicate}
          /> */}
        </div>
      </Bound>
    )
  }
}

const mapStateToProps = state => ({
  counterReducer: state.counterReducer,
  biometricReducer: state.biometricReducer
})

const mapDispatchFromProps = dispatch => ({
  closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
  removeStatusWaitingVerifyFinger: (ticketId, isNotCallApi = false) =>
    dispatch(removeStatusWaitingVerifyFinger(ticketId, isNotCallApi))
});

export default connect(mapStateToProps, mapDispatchFromProps, null, { forwardRef: true })(PopupCheckCustomer);