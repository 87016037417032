import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OverlayFullScreen from '../../controls/OverlayFullScreen'
import {
    WrapContentPage,
    WrapFooterPage,
    WrapPopupConsultantTeller,
} from './index.styles'
import ButtonControl from '../../controls/ButtonControl'

//imgs
import IcBank from '../../images/SVG/IcBank'
import IcUserMini from '../../images/SVG/IcUserMini'
import IcClosePopup from '../../images/SVG/IcClosePopup'
import IcSelected from '../../images/SVG/IcSelected'
import IcNoSelect from '../../images/SVG/IcNoSelect'
import IcCheckWhite from '../../images/SVG/IcCheckWhite'

//redux
import {
    sendStartAdvise,
    setIsShowPopupCounselor,
} from '../../../../actions/counselorAction'
import PopupEndCounselor from '../CounselorPage/PopupEndCounselor'
import GetTimeSuccess from '../../controls/GetTimeSuccess'
import CountDownTimeControl from '../../../Control/CountDownTimeControl'
import { adviseStatus } from '../../../../constant/keys'

export default function PopupConsultantTeller({ personalInfo, onClose, task }) {
    const dispatch = useDispatch()
    const {
        dataConnectDesktop,
        listServiceCounselor,
        detailsCurrentAdvise,
        detailsProcessingAdvise,
    } = useSelector((state) => ({
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        listServiceCounselor: state.counterReducer.listServiceCounselor,
        detailsCurrentAdvise: state.counselor.detailsCurrentAdvise,
        detailsProcessingAdvise: state.counselor.detailsProcessingAdvise,
    }))
    const [serviceSelect, setServiceSelect] = useState([listServiceCounselor[0]])
    const [textResult, setTextResult] = useState('')
    const [errTextResult, setErrTextResult] = useState('')
    const [isShowPopupEnd, setIsShowPopupEnd] = useState(false)
    const [isEndSuccess, setIsEndSuccess] = useState(false)

    useEffect(() => {
        if (textResult?.length >= 255) {
            setErrTextResult('Độ dài từ 1 đến 255 ký tự')
        }
    }, [textResult])

    const handleNext = () => {
        if (!textResult.length) {
            setErrTextResult('Vui lòng nhập kết quả bán')
            return
        }
        setIsShowPopupEnd(true)
    }

    const handleClosePopupReview = () => {
        dispatch(setIsShowPopupCounselor(false))
    }

    const handleSelectService = (obj) => {
        let newList = []
        if (
            serviceSelect?.filter((item) => item.AdviseType === obj?.AdviseType)
                ?.length > 0
        ) {
            newList = serviceSelect?.filter(
                (item) => item.AdviseType !== obj?.AdviseType
            )
        } else {
            newList = [...serviceSelect, obj]
        }
        setServiceSelect(newList)
    }

    useEffect(() => {
        if (!detailsCurrentAdvise?.Number) return
        if (detailsCurrentAdvise?.Number != task?.Number) return
        setTextResult(detailsCurrentAdvise?.Result)
        setServiceSelect(detailsCurrentAdvise?.ListService)
    }, [detailsCurrentAdvise])   

    return (
        <>
            (
            <OverlayFullScreen>
                <WrapPopupConsultantTeller>
                    {((detailsCurrentAdvise?.Number && detailsCurrentAdvise?.Number == task?.Number) ||
                        isEndSuccess ||
                        !listServiceCounselor?.length) && (
                            <IcClosePopup
                                className="ic-close"
                                onClick={handleClosePopupReview}
                            />
                        )}
                    <div className="title-page">
                        <div className="bold">Tư vấn khách hàng</div>
                        <div className="normal">
                            Thông tin vé & khách hàng đang được tư vấn
                        </div>
                    </div>
                    <WrapContentPage
                        isSuccess={(detailsCurrentAdvise?.Number && detailsCurrentAdvise?.Number == task?.Number) || isEndSuccess}
                    >
                        <div className="wrap-container">
                            <div className="time-advise">
                                <p className="time">Thời gian tư vấn</p>
                                <div className="wrap-countdown">
                                    {(detailsCurrentAdvise?.StatusAdvise ===
                                        adviseStatus.COMPLETED && detailsCurrentAdvise?.Number == task?.Number) ? (
                                        <GetTimeSuccess
                                            startTime={
                                                detailsCurrentAdvise?.StartAt
                                            }
                                            endTime={
                                                detailsCurrentAdvise?.CompleteAt
                                            }
                                            isSuccess={true}
                                            classname="countdown"
                                        />
                                    ) : (
                                        <CountDownTimeControl
                                            class="countdown"
                                            isCounselor={true}
                                            isEndSuccess={isEndSuccess}
                                            data={
                                                detailsProcessingAdvise?.StartAt
                                                    ? detailsProcessingAdvise?.StartAt
                                                    : undefined
                                            }
                                            ticketNumber={
                                                task?.Number ||
                                                detailsProcessingAdvise?.Number || detailsCurrentAdvise?.Number
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="wrap-list-info first">
                                <div className="title-content">
                                    Thông tin khách hàng
                                </div>
                                <div className="list-info">
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcUserMini />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                Mã vé
                                            </div>
                                            <div className="value-text">
                                                {task?.Number ||
                                                    detailsProcessingAdvise?.Number}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcUserMini />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                Tên khách hàng
                                            </div>
                                            <div className="value-text">
                                                {personalInfo?.nameCus ||
                                                    detailsProcessingAdvise?.Fullname}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcUserMini />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                Hạng khách hàng
                                            </div>
                                            <div className="value-text">
                                                {personalInfo?.segmentDesc ||
                                                    detailsProcessingAdvise?.Rank}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-info">
                                        <div className="wrap-icon">
                                            <IcBank />
                                        </div>
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                PGD/ Chi nhánh
                                            </div>
                                            <div className="value-text">
                                                {dataConnectDesktop?.OfficeName ||
                                                    '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap-list-info">
                                <div className="title-content">
                                    Dịch vụ tư vấn
                                </div>
                                <div className="list-service">
                                    {listServiceCounselor?.length > 0 ? (
                                        listServiceCounselor?.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="service-item"
                                                        onClick={() =>
                                                            handleSelectService(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        {serviceSelect?.filter(
                                                            (ser) =>
                                                                ser.AdviseType ===
                                                                item?.AdviseType
                                                        )?.length > 0 ? (
                                                            <IcSelected />
                                                        ) : (
                                                            <IcNoSelect />
                                                        )}{' '}
                                                        {item.Name}
                                                    </div>
                                                )
                                            }
                                        )
                                    ) : (
                                        <div className="note-no-list-service">
                                            * Không có danh sách dịch vụ tư vấn,
                                            vui lòng kiểm tra lại dữ liệu dịch
                                            vụ tư vấn trên hệ thống
                                        </div>
                                    )}
                                </div>
                                {serviceSelect?.length === 0 && (
                                    <div className="note-no-list-select">
                                        * Không có dịch vụ tư vấn được chọn, vui
                                        lòng chọn dịch vụ tư vấn
                                    </div>
                                )}
                            </div>
                            <div className="wrap-list-info">
                                <div className="title-content">
                                    Kết quả bán <span>*</span>
                                </div>
                                {(detailsCurrentAdvise?.Number && detailsCurrentAdvise?.Number == task?.Number) ||
                                    isEndSuccess ? (
                                    <div className="details-result">
                                        {textResult
                                            ?.split('\n')
                                            ?.map((text, index) => {
                                                return (
                                                    <p
                                                        className="text-result"
                                                        key={index}
                                                    >
                                                        {text}
                                                    </p>
                                                )
                                            })}
                                    </div>
                                ) : (
                                    <div className="wrap-input-result">
                                        <textarea
                                            className="input-result"
                                            placeholder="Nhập kết quả bán"
                                            onChange={(e) => {
                                                setTextResult(e.target.value)
                                                if (errTextResult) {
                                                    setErrTextResult('')
                                                }
                                            }}
                                            value={textResult}
                                            maxLength={255}
                                        />
                                    </div>
                                )}

                                <div className="err-text">{errTextResult}</div>
                            </div>
                        </div>
                    </WrapContentPage>
                    <WrapFooterPage>
                        <div className="wrap-btn">
                            {(detailsCurrentAdvise?.Number && detailsCurrentAdvise?.Number == task?.Number) || isEndSuccess ? (
                                <div className="end-success">
                                    <span>
                                        <IcCheckWhite />
                                    </span>
                                    Đã hoàn tất
                                </div>
                            ) : (
                                <ButtonControl
                                    text="Kết thúc"
                                    className="btn-next"
                                    onClick={handleNext}
                                    isDisable={
                                        !textResult ||
                                        serviceSelect?.length === 0
                                    }
                                />
                            )}
                        </div>
                    </WrapFooterPage>                    
                </WrapPopupConsultantTeller>
                {isShowPopupEnd && (
                    <PopupEndCounselor
                        onClose={() => setIsShowPopupEnd(false)}
                        setIsEndSuccess={() => setIsEndSuccess(true)}
                        serviceSelect={serviceSelect}
                        textResult={textResult}
                    />
                )}
            </OverlayFullScreen>
            )
        </>
    )
}
