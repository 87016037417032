import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//css
import { WrapGetNumberTicket, WrapScreenPage } from './index.styles'

//support

//component
import EnterPersonalInfo from './EnterPersonalInfo'
import SelectTransaction from './SelectTransaction'
import ConfirmInfomation from './ConfirmInfomation'
import IssueTicket from './IssueTicket'
import Portal from '../../controls/Portal'

//constant
import useStepStack from '../../hooks/useStepStack'
import {
    CONFIRM_INFOMATION,
    ENTER_PERSONAL_INFO,
    ISSUE_TICKET,
    SELECT_TRANSACTION,
} from '../../../../constant/keys'
import PopupEndService from './PopupEndService'
import OverlayFullScreen from '../../controls/OverlayFullScreen'
import PopupPrintFail from '../PopupPrintFail'
import IcClosePopup from '../../images/SVG/IcClosePopup'
import { clearDataGetTicket } from '../../../../actions/counterAction'

const initialStepStack = [ENTER_PERSONAL_INFO]

const GetNumberTicket = ({onClose}) => {
    const dispatch = useDispatch()
    const { personalInfo, listTransaction } = useSelector((state) => ({
        personalInfo: state.getNumber.personalInfo,
        listTransaction: state.getNumber.listTransaction,
    }))

    const [isShowPopupEnd, setIsShowPopupEnd] = useState(false)
    const [isExportFailed,setIsExportFailed] = useState(false)

    const { stepStack, pushStep, popStep, lastStep } = useStepStack(
        initialStepStack
    )

    const handleBack = () => {
        popStep()
    }

    const handleClosePopup = () => {
        dispatch(clearDataGetTicket())
        onClose()
    }

    const handleEnd = () => {}

    const renderScreenPage = () => {
        // Step 1
        if (lastStep === ENTER_PERSONAL_INFO) {
            const handleNext = () => {
                pushStep(SELECT_TRANSACTION)
            }
            return <EnterPersonalInfo onNext={handleNext} onBack={handleBack} />
        }
        // Step 2
        if (lastStep === SELECT_TRANSACTION) {
            const handleNext = () => {
                pushStep(CONFIRM_INFOMATION)
            }
            return <SelectTransaction onNext={handleNext} onBack={handleBack} />
        }
        // Step 3
        if (lastStep === CONFIRM_INFOMATION) {
            const handleNext = () => {
                pushStep(ISSUE_TICKET)
            }
            return (
                <ConfirmInfomation
                    personalInfo={personalInfo}
                    listTransaction={listTransaction}
                    onNext={handleNext}
                    onBack={handleBack}
                    setIsFail={()=>setIsExportFailed(true)}
                />
            )
        }
        // Step 4
        if (lastStep === ISSUE_TICKET) {
            return <IssueTicket />
        }
    }

    return (
        <OverlayFullScreen>
            {isExportFailed ? (
                <PopupPrintFail setIsFail={()=>setIsExportFailed(false)} onClose={handleClosePopup} />
            ) : (
                <WrapGetNumberTicket>
                    <IcClosePopup className="ic-close" onClick={handleClosePopup}/>
                    <WrapScreenPage>{renderScreenPage()}</WrapScreenPage>
                    {isShowPopupEnd && (
                        <Portal>
                            <PopupEndService
                                handleContinue={() => setIsShowPopupEnd(false)}
                                handleEnd={handleEnd}
                            />
                        </Portal>
                    )}
                </WrapGetNumberTicket>
            )}
        </OverlayFullScreen>
    )
}

export default GetNumberTicket
