import * as types from '../actions/types'
import { ACTIVE } from '../constant/keys'

export const initialState = {
    stepSelect: {
        step1: ACTIVE,
        step2: '',
        step3: '',
        step4: '',
    },
    personalInfo: {},
    listKeySelect: [],
    listTransaction: [],
    isCheckBoxAccNumber: true,
}

export default function getNumber(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_DATA_GET_TICKET:
            return initialState
        case types.RESET_GET_NUMBER:
            return initialState
        case types.SET_STEP_SELECT:
            const { step } = action
            return {
                ...state,
                stepSelect: step,
            }
        case types.SET_PERSONAL_INFO_GET_NUMBER:
            const { details } = action
            return {
                ...state,
                personalInfo: details,
            }
        case types.SET_CHECKBOX_ACC_NUMBER:
            const { isCheckBoxAccNumber } = action
            return {
                ...state,
                isCheckBoxAccNumber: isCheckBoxAccNumber,
            }
        case types.SET_LIST_TRANSACTION_GET_NUMBER:
            const { listTrans } = action
            return {
                ...state,
                listTransaction: listTrans,
            }
        case types.SET_LIST_KEY_SELECT:
            const { listKeys } = action
            return {
                ...state,
                listKeySelect: listKeys,
            }
        case types.RESET_LIST_KEY_SELECT:
            return {
                ...state,
                listKeySelect: [],
            }
        default:
            return state
    }
}
