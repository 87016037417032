import styled from 'styled-components'

const WrapPopupConfirmCounselor = styled.div`
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    width: 340px;
    min-height: 275px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 38px;
    align-items: center;
    .pop-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .bold {
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;
            color: #191919;
            span {
                font-weight: 700;
                text-transform: uppercase;
                color: #005993;
            }
        }
        .note {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: center;
            color: #f31919;
        }
    }
    .pop-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        .btn-counselor-cancel,
        .btn-counselor-confirm {
            width: 96px;
            height: 33px;
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: center;
            text-transform: uppercase;
        }
        .btn-counselor-cancel {
            color: #636060;
            background: #e6e6e6;
        }
        .btn-counselor-confirm{
            background: #005993;
            color: #ffffff;
        }
    }
`

export { WrapPopupConfirmCounselor }
