import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//css
import { WrapStepByStepGetNumber } from './index.styles'
//imgs

//constant
import { ACTIVE, SUCCESS } from '../../../../constant/keys';
import IcCheckWhite from '../../images/SVG/IcCheckWhite';



export default function StepByStepGetNumber() {

    const dispatch = useDispatch()

    const stepSelect = useSelector(state => state.getNumber.stepSelect)

    return (
        <>
            <WrapStepByStepGetNumber>
                <div className='wrap-step'>
                    {
                        stepSelect.step1 === ACTIVE ?
                            <div className='step active frist'>
                                <span className="icon">1</span>
                                <div className='step-text active'>Thông tin khách hàng</div>
                            </div>
                            :
                            <div className='step success frist'>
                                <span className="icon"><IcCheckWhite/></span>
                                <div className='step-text active'>Thông tin khách hàng</div>
                            </div>
                    }
                </div>
                <div className='wrap-step'>
                    {
                        stepSelect.step2 === ACTIVE ?
                            <div className='step active'>
                                <span className="icon">2</span>
                                <div className='step-text active'>Chọn giao dịch</div>
                            </div>
                            :
                            stepSelect.step2 === SUCCESS ?
                                <div className='step success'>
                                    <span className="icon"><IcCheckWhite/></span>
                                    <div className='step-text active'>Chọn giao dịch</div>
                                </div>
                                :
                                <div className='step'>
                                    <span className="icon">2</span>
                                    <div className='step-text'>Chọn giao dịch</div>
                                </div>
                    }
                </div>
                <div className='wrap-step'>
                    {
                        stepSelect.step3 === ACTIVE ?
                            <div className='step active'>
                                <span className="icon">3</span>
                                <div className='step-text active'>Xác nhận thông tin</div>

                            </div>
                            :
                            stepSelect.step3 === SUCCESS ?
                                <div className='step success'>
                                    <span className="icon"><IcCheckWhite/></span>
                                    <div className='step-text active'>Xác nhận thông tin</div>
                                </div>
                                :
                                <div className='step'>
                                    <span className="icon">3</span>
                                    <div className='step-text'>Xác nhận thông tin</div>

                                </div>
                    }
                </div>
                <div className='wrap-step'>
                    {
                        stepSelect.step4 === ACTIVE
                            ?
                            <div className='step active'>
                                <span className="icon">4</span>
                                <div className='step-text active'>Xuất vé</div>

                            </div>
                            :
                            <div className='step'>
                                <span className="icon">4</span>
                                <div className='step-text'>Xuất vé</div>
                            </div>
                    }
                </div>
            </WrapStepByStepGetNumber>
        </>

    )
}
