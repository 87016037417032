/**
 * Use to check condition before handle event accept in popup
 * @type {Object}
 */
const typesKeyDetailPopup = {
	// notice ticket have bio enroll not confirm yet when click complete ticket
	noticeHaveBioNotConfirmYet: 0,
	// notice ticket none have bio info when click complete ticket
	noticeNoneHaveBioInfos: 1,
	//  show form fill reason why not register bio data when click complete ticket
	fillReasonWhyNotRegisterBioData: 2,
	// When click accept on Popup confirm Logout ---> logout CounterWeb
	logOutCounterWeb: 3,
	// When click image finger in tab UserBiometric (Check showInfo types): 8 - 9
	fingerBioInfoResgister: 8,
	fingerBioInfoUpdate: 9,
	////////// Define info to show in flow Register or Update BioFaceID
	faceBioInfoRegister: 10,
	faceBioInfoUpdate: 11,
	// accept call miss call ticket
	acceptCallMissTiket: 12,
	// recall ticket in counter
	recallTicketInCounter: 13,
	///// Call PopupRegisterFinger FlowUpdate
	registerFingerFlowUpdate: 14,
	applyCifsForTicketAlreadyHaveCifs: 15,
	fillReasonNotAuth: 16,
	forceRegisterBiometric: 17,
	requestAuthCusBeforeCompleteTicket: 18,
	confirmRemoveBioData: 19,
	confirmSendSupervisor: 20,
	confirmSendSupervisorTeller: "confirmSendSupervisorTeller",
	addReasonNotFillFullData: 21,
	openFlowUpdateFace: 22,
	removeFingerFlowUpdate: 23,
	updateFaceSuccess: 24,
	searchCifsInFormAuthCustomer: "searchCifsInFormAuthCustomer",
	reCallTicketInFormAuthCustomer: "reCallTicketInFormAuthCustomer",
	moveTicketToOtherCounterInFormAuthCustomer: "moveTicketToOtherCounterInFormAuthCustomer",
	moveTicketToMissCallInFormAuthCustomer: "moveTicketToMissCallInFormAuthCustomer",
	addReasonDeleteBioData: "addReasonDeleteBioData",
	completeTicketCollector: "completeTicketCollector",
	fillReasonNRFingerBeforeSendSuperVisor: "fillReasonNRFingerBeforeSendSuperVisor",
	confirmStopCounter: "confirmStopCounter",
	updateBioIpay: "updateBioIpay"
}

export default typesKeyDetailPopup