export const ADMIN_ROLE = "ADMIN";
export const ADMIN_OFFICE_ROLE = "OFFADMIN"; // Quản trị chi nhánh
export const OFFINSUPERVISOR = "OFFINSUPERVISOR"; // Ban giám đốc
export const OFFEXSUPERVISOR = "OFFEXSUPERVISOR"; // Kiểm soát viên
export const INBIOCOLLECTOR = "INBIOCOLLECTOR"; // Đăng ký STH cho cán bộ
//vietin V2 start 30/01/2024
export const TELLER = "TELLER"; // Giao dịch viên
export const COUNSELOR = 'COUNSELOR' // Tư vấn viên
export const COORDINATOR = 'COORDINATOR' // Điều phối viên
//vietin V2 start 04/09/2024
export const COORLOBBY = 'COORLOBBY' // Vai trò điều phối
// list chức năng
export const MOVETICKET = "MOVETICKET" // Chuyển quầy
export const TAKETICKET = "TAKETICKET" // Lấy vé
export const ADVISETICKET = "ADVISETICKET" // Tư vấn

