import styled from 'styled-components'

const WrapContentCounselor = styled.div`
    box-sizing: border-box;
    width: 100%;
    /* min-height: 760px; */
    border-radius: 12px;
    background-color: #ffffff;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 16px 0px;
    .ic-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    .title-page {
        color: var(--Black, #191919);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        padding: 16px 20px;
        .normal {
            color: var(--Gray, #636060);
            font-size: 14px;
            font-weight: 500;
            line-height: 128%;
            margin-top: 2px;
            display: flex;
            align-items: center;
            gap: 5px;
            span {
                font-weight: 600;
                color: #005993;
            }
        }
    }
`
const WrapContentPage = styled.div`
    width: 100%;
    box-sizing: border-box;
    .wrap-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: overlay;
        overflow-x: hidden;
        .wrap-list-info {
            border-top: 1px solid #e7e6e6;
            padding: 16px 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
            /* &.first {
                margin-bottom: 10px;
            } */
            .title-content {
                color: var(--Gray, #636060);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.28px;
                margin-bottom: 12px;
                span {
                    color: #f31919;
                }
            }
            .countdown {
                font-size: 28px;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 1px;
                text-align: left;
                color: #191919;
            }
            .list-info {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 16px 16px;
                .block-info {
                    width: 100%;
                    height: 52px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: flex-start;
                    gap: 6px;
                    .wrap-icon {
                        width: 40px;
                        height: 40px;
                        flex-shrink: 0;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(
                            --Gradient-Light-Blue,
                            linear-gradient(225deg, #7ed3f7 0%, #3baddd 100%)
                        );
                        .symsbol {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .wrap-text {
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                        .key-text {
                            color: var(--Gray, #636060);
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            letter-spacing: -0.26px;
                        }
                        .key-value {
                            color: var(--Black, #191919);
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: -0.34px;
                        }
                    }
                }
            }
            .list-service {
                display: flex;
                align-items: center;
                flex-flow: wrap;
                gap: 26px;
                /*display: grid;
                grid-template-columns: repeat(5, auto);
                grid-gap: 20px 20px;*/
                .service-item {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: var(--Black, #191919);
                    font-family: 'SVN-Gilroy';
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: -0.02em;
                    pointer-events: ${props => props.isSuccess ? 'none' : 'unset'};
                }
                .note-no-list-service{
                    color: #f31919;
                }
            }
            .note-no-list-select {
                color: #f31919;
                margin-top: 12px;
            }
            .details-result {
                width: 100%;
                min-height: 140px;
                font-family: SVN-Gilroy;
                font-size: 17px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #191919;
            }
            .wrap-input-result {
                display: flex;
                width: 740px;
                height: 100px;
                align-items: center;
                flex-shrink: 0;
                border-radius: 6px;
                background: var(--Background, #f7f7f7);
                padding: 15px 13px;
                box-sizing: border-box;
                .input-result {
                    resize: none;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    font-family: SVN-Gilroy;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.34px;
                    text-align: left;
                    color: #191919;
                    background-color: inherit;
                    ::placeholder {
                        color: var(--Gray-2, #c4c4c4);
                    }
                }
            }
            .err-text {
                color: #f31919;
                font-family: SVN-Gilroy;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-align: left;
            }
        }
    }
`
const WrapFooterPage = styled.div`
    width: 100%;
    height: 84px;
    border-top: 1px solid var(--Gray-3, #e6e6e6);
    .wrap-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        box-sizing: border-box;
        height: 100%;
        .end-success {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 17px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #636060;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #005993;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .btn-next {
            display: flex;
            height: 44px;
            width: 150px;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background-color: #005993;
        }
    }
`

export { WrapContentCounselor, WrapContentPage, WrapFooterPage }
