import React from 'react'
//css
import { WrapFormTransactionReceipt } from './index.styles'

//imgs
import slide_ticket from '../../../../images/PNG/slide_ticket.png'
import LogoVietinbankTicket from '../../../../images/SVG/LogoVietinbankTicket'

const FormTransactionReceipt = ({ refForm, data }) => {

    return (
        <WrapFormTransactionReceipt ref={refForm}>
            <div className='content-header'>
                <LogoVietinbankTicket />
            </div>
            <div className='content-number'>
                <div className="content-number-transaction">Số vé của bạn</div>
                <div className="content-number-bill">{data?.NumTicket || "-"}</div>
            </div>
            <div className='content-desp'>
                <span className="content-desp-value">Giao dịch của bạn</span>
                <div className='content-desp-list'>
                    {
                        data?.ListService?.map((item, index) => ((
                            <span key={index} className="content-desp-list-service">{item?.Name || "-"}</span>
                        )))
                    }
                    <div className='content-bottom'>
                        Xin quý khách vui lòng đợi
                    </div>
                </div>
            </div>
            <img className='slide' src={slide_ticket} alt="slide" />
        </WrapFormTransactionReceipt>
    )
}

export default FormTransactionReceipt