import React, { Component } from 'react'
import { connect } from 'react-redux'
// import moment from "moment";

import * as typesAction from '../../actions/types'
//Image
import ic_cancel from '../../images/icon_cancel.svg'
import close from '../../images/ic_close_gray.svg'
import icLockGreen from '../../images/ic_lock_green.svg'
import icLockWhite from '../../images/ic_lock_white.svg'
import ic_Unlock from '../../images/ic_unlock.svg'
import icUnlockWhite from '../../images/ic_unlock_white.svg'
// import iconNotificationSuccess from "../../images/ic_counter_notification_success.svg"
// import ic_Eform from "../../images/ic_eForm_On.svg";
// import icEformWhite from "../../images/ic_eForm_white.svg";
import ic_History from '../../images/ic_history.svg'
import icHistoryWhite from '../../images/ic_history_white.svg'
// import ic_Delete_Biometric from "../../images/ic_delete_biometric.svg";
// import icDeleteBiometricWhite from "../../images/ic_delete_biometric_white.svg";
import ic_print_blue from '../../images/ic_print_blue.svg'
import ic_print_white from '../../images/ic_print_white.svg'
import iconNotificationClose from '../../images/ic_counter_notification_close.svg'

import { buildErrorContent } from '../../helper/tool'

//component
// import ButtonControll from "./PopupUserManage/Control/ButtonControll";
import ButtonConfirmControl from '../Control/ButtonConfirmControl'
import BtnControlHaveTooltip from './PopupUserManage/Control/BtnControlHaveTooltip'
import UserBiometric from './PopupUserManage/UserBiometric'
import UserCheckCIF from './PopupUserManage/UserCheckCIF'
import LoadingControl from '../Control/LoadingControl'
import ErrorGetTicket76 from '../../helper/ErrorGetTicket76'

// action
import {
    requestEnroll,
    getInfoCustomer,
    addBiometricExistFromCustomerInfos,
    requestNewEnrollIdToUpdateRejectBio,
    continueGetBio,
    requestUpDateBio,
    getCurrentTickets,
    addCustomerInfos,
    clearStatusPrinted,
    // getBioHistoryList
} from '../../actions/counterAction'
import { openPopup, closePopup } from '../../actions/popupBioAction'
import {
    completeBio,
    getCurrentBioEnroll,
    setStatusBiometricWithTicket,
    removeStatusWaitingBioData,
    removeDataBioOfTicketInStore,
} from '../../actions/biometricAction'
import { INBIOCOLLECTOR, TELLER } from '../../data/userRole'
import {
    completeBioCollector,
    removeStatusWaitingBioDataCollector,
    requestEnrollCollector,
    requestNewEnrollIdToUpdateRejectBioCollector,
    getCurrentBioEnrollCollector,
    getCustomerInfos,
    requestUpDateBioCollector,
    finishTransInTask,
    // getBioHistoryListCollector
} from '../../actions/biometricWithInBioCollectorAction'
import { clearError } from '../../actions/commonAction'

// import data
import statusBiometrics, {
    typeStatusBiometrics,
} from '../../data/statusBiometricWithTicket'
import {
    nameFingerPrint,
    FACE /* , listNameBiometric */,
} from '../../data/bioTypes'
import {
    /* REGISTER, */ CONFIRM,
    VALIDATE_DATA,
    AUTH_TRANS,
    ISHANG,
    REGISTER,
    REMOVE,
} from '../../actions/typeRequestBio'
import { popupBioTypes } from '../../data/PopupTypes'
import {
    COMPLETE_BIO_ERR,
    COMPLETE_BIO_COLLECTOR_ERR,
} from '../../actions/types'
import bioActionTypes from '../../data/bioActionTypes'
import { verifyFingerStatusDetail } from '../../data/verifyFingerStatusTypes'
// import typesKeyDetailPopup from "../../data/typesKeyDetailPopup";
import bioStatusTypes from '../../data/bioStatusTypes'
import fromChannels from '../../data/fromChannels'

// helper
import {
    openPopupShowReasonRejectBio,
    openPopupFillReasonWhyNotRegisterBio,
    openPopupConfirmSendSupervisor,
    openPopupNotifySendSuccess,
    openPopupConfirmSendSupervisorTeller,
    openPopupNotRegisterFingerButFace,
    showPopupPreventFinishTask,
} from '../../helper/actionOpenPopup'
import getFromChannel from '../../helper/getFromChannel'

import isDisableEventClick from '../../helper/isDisableEventWhenHaveSupervisor'
import isErrorWhenGetEnroll from '../../helper/isErrorWhenGetEnroll'

// styles
import { Bound } from './PopupUserManage.styles'
// import checkApplyBiometricForTicket from "../../helper/checkApplyBiometricForTicket";

const UNLOCK = 'Mở khóa'
const LOCK = 'Khóa'

const btnUnlockData = {
    title: UNLOCK,
    source: ic_Unlock,
    sourceOnHover: icUnlockWhite,
    alt: 'icon-unlock',
    bgColor: '#0fb11f',
    action: 'unlockBiometric',
}

const btnLockData = {
    title: LOCK,
    source: icLockGreen,
    sourceOnHover: icLockWhite,
    alt: 'icon-lock',
    bgColor: 'var(--vermillion)',
    action: 'lockBiometric',
}

const NotRegister = 1
const PrintAndHistoryBio = 2
// const ActionBio = 3;

const LOADING_FINGER = 1
const LOADING_FACE = 2

/////////// Check Enroll + Finger must have been to register
const labelError = {
    errorEmptyNameVi: 'Vui lòng nhập họ tên khách hàng có dấu',
    errorEmptyPhoneNum: 'Vui lòng nhập số điện thoại khách hàng',
    errorEmptyBioFace: 'Vui lòng hoàn tất đăng ký STH khuôn mặt',
    errorNotFillNoteDupFace: 'Vui lòng điền ghi chú trùng khuôn mặt',
    errorHaveFingerNotConfirmYet: 'Lấy mẫu vân tay chưa đủ trước khi gửi KSV',
    errorHaveFaceNotConfirmYet: 'Vui lòng hoàn tất đăng ký STH khuôn mặt',
    errorNotEnoughBioFinger:
        'Vui lòng đăng ký đủ 2 vân tay trạng thái hoạt động',
    errorTooManyBioFingerAuthTrans:
        'Chỉ được phép đăng ký 2 vân tay trạng thái hoạt động',
    errorNotEnoughBioFingerCollector: 'Vui lòng đăng kí đủ 10 vân tay',
    // flow delete
    errorFaceWasDeletedButFingerNotDelete:
        'Tồn tại Hình ảnh STH vân tay chưa được xóa. Vui lòng xóa STH vân tay trước khi chuyển phê duyệt',
    errorFaceWasClosedButFingerNotClose:
        'Tồn tại Hình ảnh STH vân tay chưa được đóng. Vui lòng đóng STH vân tay trước khi chuyển phê duyệt',
    errorFingerActivedButFaceNotActive:
        'Đề nghị kích hoạt lại hình ảnh STH khuôn mặt trước khi chuyển KSV phê duyệt',
    // errorTwoFingerActiveWasHang: "Vui lòng bỏ treo hoặc đóng các vân tay đang ở trạng thái treo trước khi chuyển phê duyệt",
    // errorFingerWasClosedButFaceNotClose: "Tồn tại Hình ảnh STH khuôn mặt chưa được Đóng. Bạn có muốn thực hiện tiếp GD?"
    errorNotMatchBioConfirmFinger_UpdateThread:
        'Số lượng vân tay ở trạng thái Hoạt động phải là 2 hoặc 0',
    errorNotMatchBioHangFinger_UpdateThread:
        'Số lượng vân tay ở trạng thái Treo phải là 2 hoặc 0',
    errorNotEnoughBioHangFinger_UpdateThread:
        'Bắt buộc phải có 2 vân tay ở trạng thái treo',
    errorNotMatchBioFinger_UpdateThread:
        'Chỉ được phép tồn tại 2 vân tay ở trạng thái Hoạt động hoặc 2 vân tay ở trạng thái Treo',
    errorNotEnoughBioFingerExcustInUpdate: 'Vui lòng đăng ký ít nhất 2 vân tay',
    errorChangeFaceDisableToActiveAndFingerDisable:
        'Bạn chưa kích hoạt lại hình ảnh STH vân tay của khách hàng. Bạn có muốn tiếp tục gửi KSV phê duyệt?',
}

class PopupUserManage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stepSettingsAction: NotRegister,
            step: 1,
            isLoadingTitle: {
                content: '',
                typeBio: null,
            },
            isRegistration: false,
            isPassCIF: false,
            dataCheckCIF: {}, ///// đợi server
            cif: '',
            lockAndUnlockBtnData: btnUnlockData,
            isLoading: false,
            ////////
            flowUpdate: false,
        }
    }
    changeStep = (step) => {
        this.setState({ step })
    }

    changeStepBiometric = () => {
        // this.props.openPopup(4, 2, this.props.dataGetTicket) ///// data and typePopup to show ///// popupNotRegister
    }

    isRegistration = async (isRegistration, data) => {
        ///// data is CustomerInfos
        const { counterReducer } = this.props
        let ishaveBioData

        if (counterReducer.customerInfo.BioInfos) {
            ishaveBioData = true
        }

        await this.setState({
            isRegistration,
            cif: data.CustomerNumber,
            dataCheckCIF: data,
            flowUpdate: ishaveBioData ? true : false,
        })
    }

    handleLockAndUnlock = () => {
        if (this.state.lockAndUnlockBtnData.title === UNLOCK) {
            this.setState({ lockAndUnlockBtnData: btnLockData })
        } else {
            this.setState({ lockAndUnlockBtnData: btnUnlockData })
        }
    }

    renderLoading = (isLoading) => {
        this.setState({ isLoading })
    }

    showPopupBiometric = (typeButtonControll) => {
        switch (true) {
            case typeButtonControll === 'showHistoryCustomer': {
                const ticketId = this.props.dataGetTicket.TicketID
                this.props.openPopup(popupBioTypes.HistoryCustomer, 2, {
                    ticketId,
                })
                break
            }
            // case typeButtonControll === "deleteBiometric":
            //   this.props.openPopup(3, 2, {
            //     content: "Xóa tất cả dữ liệu sinh trắc học?",
            //     contentDelete: "(*) Chức năng yêu cầu xác thực từ giám sát viên. Dữ liệu sau khi xóa sẽ không thể phục hồi",
            //     width: "274px",
            //   })
            //   break;
            // case typeButtonControll === "unlockBiometric":
            //   this.props.openPopup(3, 2, {
            //     content: "Mở khóa dữ liệu sinh trắc học?",
            //     contentDelete: "(*) Chức năng yêu cầu xác thực từ giám sát viên",
            //     width: "274px"
            //   })
            //   break;
            // case typeButtonControll === "lockBiometric":
            //   this.props.openPopup(3, 2, {
            //     content: "Khóa dữ liệu sinh trắc học?",
            //     contentDelete: "(*) Dữ liệu sau khi khóa sẽ không thể tùy chỉnh các thông tin sinh trắc học.",
            //     width: "274px",
            //   })
            //   break;
            case typeButtonControll === 'showPrintBio':
                this.handleShowPrintPage()
                break
            default:
                break
        }
    }

    enableStatusEditRejectBio = async () => {
        let {
            dataGetTicket,
            counterReducer /* , biometricReducer */,
        } = this.props
        let ticketID = dataGetTicket.TicketID
        const currentTicket = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketID
        )
        let enrollId = counterReducer.enrollId[ticketID]
        if (!enrollId) return

        this.props.clearStatusPrinted(ticketID)
        // debugger;

        this.props.setStatusBiometricWithTicket({
            TicketID: ticketID,
            PendingBioID: enrollId,
            PendingStatus: String(undefined),
        })

        if (currentTicket.bioInfosType === 'NOTREGISTER') {
            if (counterReducer.dataLogin.Roles.includes(TELLER)) {
                await this.props.requestEnroll(
                    ticketID,
                    currentTicket.CustomerInfos
                )
                isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                return
            }

            await this.props.requestEnrollCollector(
                currentTicket.CustomerInfos,
                ticketID
            )
            isErrorWhenGetEnroll(
                this.props.counterReducer,
                this.props.openPopup,
                this.props.clearError
            )
            return
        }

        if (counterReducer.dataLogin.Roles.includes(TELLER)) {
            this.props.requestNewEnrollIdToUpdateRejectBio(
                counterReducer.dataConnectDesktop.CounterID,
                enrollId,
                ticketID
            )
            return
        }
        this.props.requestNewEnrollIdToUpdateRejectBioCollector(
            counterReducer.dataConnectDesktop.CounterID,
            enrollId,
            ticketID
        )
    }

    getLabelErr = () => {
        let { dataGetTicket, counterReducer, biometricReducer } = this.props
        let ticketID = dataGetTicket.TicketID
        let enrollId = counterReducer.enrollId[ticketID]
        // debugger;
        if (!enrollId) return
        const userCurrent = counterReducer.listCurrentTicket.find(
            (user) => user.TicketID === ticketID
        )

        // if (enrollId) {
        const BioData = biometricReducer[userCurrent.TicketID]
        // const POPUP_LEVEL_2 = 2;
        let label = ''
        let widthPopup = '200px'
        let isNotEnoughConfirmBio = false
        // const b = BioData && !Object.keys(BioData).includes(FACE);
        // const errorFace = {
        //   haveConfirmError: false,
        //   notHaveConfirm: false
        // };
        const replacerBioFinger = (key, val) => (key !== FACE ? val : undefined)

        const bioFaceToString = !!BioData && JSON.stringify(BioData[FACE])
        const registerFieldBioFace =
            !!bioFaceToString && bioFaceToString.match(/"REGISTER":/g)
        const confirmFieldBioFace =
            !!bioFaceToString && bioFaceToString.match(/"CONFIRM":0/g)

        const bioFingerToString =
            !!BioData && JSON.stringify(BioData, replacerBioFinger)
        const registerFieldBioFinger =
            !!bioFingerToString && bioFingerToString.match(/"REGISTER":/g)
        const confirmFieldBioFinger =
            !!bioFingerToString && bioFingerToString.match(/"CONFIRM":0/g)

        switch (true) {
            case userCurrent.CustomerInfos &&
                !userCurrent.CustomerInfos.FullNameVi: {
                label = labelError.errorEmptyNameVi
                this.setState({
                    step: 1,
                })
                break
            }
            case userCurrent.CustomerInfos &&
                !userCurrent.CustomerInfos.MobileNumber: {
                label = labelError.errorEmptyPhoneNum
                this.setState({
                    step: 1,
                })
                break
            }
            case !BioData:
            case BioData && !Object.keys(BioData).includes(FACE):
            case BioData &&
                Object.keys(BioData).includes(FACE) &&
                ((Object.keys(BioData[FACE]).includes(CONFIRM) &&
                    BioData[FACE][CONFIRM] !== 0) ||
                    // (
                    //   !Object.keys(BioData[FACE]).includes(CONFIRM)
                    // ) ||
                    (!Object.keys(BioData[FACE]).includes(CONFIRM) &&
                        !(
                            BioData[FACE][VALIDATE_DATA] &&
                            BioData[FACE][VALIDATE_DATA].listId
                        ))): {
                label = labelError.errorEmptyBioFace
                this.setState({
                    step: 2,
                })
                break
            }
            case userCurrent.duplicateFaces &&
                userCurrent.duplicateFaces.length > 0 &&
                !userCurrent.tellerNoteDuplicateBio: {
                label = labelError.errorNotFillNoteDupFace
                this.setState({
                    step: 2,
                })
                break
            }

            case (!registerFieldBioFinger && confirmFieldBioFinger) ||
                (registerFieldBioFinger && !confirmFieldBioFinger) ||
                (registerFieldBioFinger &&
                    confirmFieldBioFinger &&
                    registerFieldBioFinger.length !==
                        confirmFieldBioFinger.length): {
                // debugger;
                label = labelError.errorHaveFingerNotConfirmYet
                this.setState({
                    step: 2,
                })
                break
            }
            case (!registerFieldBioFace && confirmFieldBioFace) ||
                (registerFieldBioFace && !confirmFieldBioFace) ||
                (registerFieldBioFace &&
                    confirmFieldBioFace &&
                    registerFieldBioFace.length !==
                        confirmFieldBioFace.length): {
                // debugger;
                label = labelError.errorHaveFaceNotConfirmYet
                this.setState({
                    step: 2,
                })
                break
            }
            case !!BioData: {
                const isCustomerInfosUpdateBio =
                    !!userCurrent.dataWasAuthen &&
                    !!Number(userCurrent.dataWasAuthen.BiometricActive)
                // debugger;
                // REGISTER THREAD
                if (!isCustomerInfosUpdateBio) {
                    // if(false){
                    const bioFingerIsConfirmFinger_RegisterThread = Object.keys(
                        BioData
                    ).filter((item) => {
                        const itemIsFinger = item !== FACE
                        // const haveValidatedData = !!BioData[item][VALIDATE_DATA];
                        const itemWasConfirmed = BioData[item][CONFIRM] === 0
                        const isConfirmFinger = BioData[item][AUTH_TRANS]
                        return (
                            itemIsFinger && itemWasConfirmed && isConfirmFinger
                        )
                    })

                    const lstBioFingerWasConfirmed = Object.keys(
                        BioData
                    ).filter((item) => {
                        const itemIsFinger = item !== FACE
                        const itemWasConfirmed = BioData[item][CONFIRM] === 0
                        return itemIsFinger && itemWasConfirmed
                    })

                    if (
                        (lstBioFingerWasConfirmed.length &&
                            bioFingerIsConfirmFinger_RegisterThread.length <
                                2) ||
                        (counterReducer.dataLogin.Roles.includes(
                            INBIOCOLLECTOR
                        ) &&
                            !lstBioFingerWasConfirmed.length)
                    ) {
                        label = labelError.errorNotEnoughBioFinger
                        this.setState({
                            step: 2,
                        })
                        break
                    }

                    if (bioFingerIsConfirmFinger_RegisterThread.length > 2) {
                        label = labelError.errorTooManyBioFingerAuthTrans
                        this.setState({
                            step: 2,
                        })
                        break
                    }

                    if (
                        counterReducer.dataLogin.Roles.includes(
                            INBIOCOLLECTOR
                        ) &&
                        lstBioFingerWasConfirmed.length !== 10
                    ) {
                        isNotEnoughConfirmBio = true
                    }
                    break
                }
                // debugger;
                // face was removed but finger is not
                const faceBio = BioData[FACE]
                if (!faceBio) break
                const faceBioIncludeValidatedData = Object.keys(
                    faceBio
                ).includes(VALIDATE_DATA)
                if (faceBioIncludeValidatedData) {
                    if (faceBio.bioActionType === 'REMOVE') {
                        const fingerNotRemove = Object.keys(BioData).find(
                            (item) =>
                                item !== FACE &&
                                BioData[item].bioActionType !== 'REMOVE'
                        )
                        if (!!fingerNotRemove) {
                            label =
                                labelError.errorFaceWasDeletedButFingerNotDelete
                            widthPopup = '300px'
                            break
                        }
                    }

                    // face was hang but finger is not
                    const faceIsHangCurrent = faceBio[VALIDATE_DATA][ISHANG]

                    const keyFaceBio = Object.keys(faceBio)
                    const fieldRegisterFace = [REGISTER, CONFIRM]
                    const isRegisterFace = fieldRegisterFace.every((item) =>
                        keyFaceBio.includes(item)
                    )
                    const fieldUpdateFace = [AUTH_TRANS, ISHANG]
                    const isUpdateFace = fieldUpdateFace.every((item) =>
                        keyFaceBio.includes(item)
                    )

                    // const faceIsHang = Object.keys(faceBio).includes(AUTH_TRANS)?
                    //   Object.keys(faceBio).includes(ISHANG)?
                    //     faceBio[ISHANG]
                    //     :
                    //     Object.keys(faceBio).includes(CONFIRM)?false:null
                    //   :
                    //   Object.keys(faceBio).includes(CONFIRM)?false:null;
                    const faceIsHang = isRegisterFace
                        ? false
                        : isUpdateFace
                        ? faceBio[ISHANG]
                        : null

                    const fingerAuthenticate = Object.keys(BioData).find(
                        (item) => {
                            const itemIsFinger = item !== FACE
                            const itemIsNotRemove =
                                BioData[item].bioActionType !== 'REMOVE'
                            if (!itemIsNotRemove) return false
                            const itemIsRegisterAndAuthenticate = Object.keys(
                                BioData[item]
                            ).includes(AUTH_TRANS)
                            const itemIsUpdateAndAuthenticate = Object.keys(
                                BioData[item]
                            ).includes(VALIDATE_DATA)

                            if (itemIsFinger && itemIsRegisterAndAuthenticate)
                                return BioData[item][AUTH_TRANS]
                            if (itemIsFinger && itemIsUpdateAndAuthenticate)
                                return BioData[item][VALIDATE_DATA][AUTH_TRANS]
                            return false
                        }
                    )

                    if (
                        !faceIsHangCurrent &&
                        faceIsHang &&
                        !!fingerAuthenticate
                    ) {
                        label = labelError.errorFaceWasClosedButFingerNotClose
                        widthPopup = '313px'
                        break
                    }
                    // debugger
                    if (
                        faceIsHangCurrent &&
                        (faceIsHang === null || faceIsHang) &&
                        !!fingerAuthenticate
                    ) {
                        label = labelError.errorFingerActivedButFaceNotActive
                        widthPopup = '313px'
                        break
                    }
                }

                // UPDATE THREAD
                const { CustomerType } = userCurrent
                const numOfFinger = Object.keys(BioData).filter(
                    (item) => item !== FACE
                )
                if (
                    counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                    numOfFinger.length < 2 &&
                    CustomerType === 'EXCUST'
                ) {
                    label = labelError.errorNotEnoughBioFingerExcustInUpdate
                    widthPopup = '260px'
                    this.setState({
                        step: 2,
                    })
                    break
                }
                // debugger;
                const bioFingerIsConfirmFingerAndNotHang = Object.keys(
                    BioData
                ).filter((item) => {
                    const itemIsFinger = item !== FACE
                    const itemIsNotRemove =
                        BioData[item].bioActionType !== 'REMOVE'
                    const haveValidatedData = !!BioData[item][VALIDATE_DATA]
                    const isConfirmFingerRegister =
                        Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                        BioData[item][AUTH_TRANS]
                    const isConfirmFingerUpdate =
                        !Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                        haveValidatedData &&
                        BioData[item][VALIDATE_DATA][AUTH_TRANS]

                    // const isConfirmFinger = Object.keys(BioData[item]).includes(AUTH_TRANS)?
                    //   BioData[item][AUTH_TRANS]
                    //   :
                    //   (haveValidatedData &&
                    //   BioData[item][VALIDATE_DATA][AUTH_TRANS]);
                    const isConfirmFinger =
                        isConfirmFingerRegister || isConfirmFingerUpdate
                    // const itemIsNotHang = isConfirmFingerUpdate?
                    //   !BioData[item][VALIDATE_DATA][ISHANG]:true;
                    const isConfirm = BioData[item][CONFIRM] === 0
                    const itemIsNotHang = isConfirm
                        ? true
                        : Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                          BioData[item][AUTH_TRANS]
                        ? !BioData[item][ISHANG]
                        : !BioData[item][VALIDATE_DATA][ISHANG]
                    return (
                        itemIsNotRemove &&
                        itemIsFinger &&
                        itemIsNotHang &&
                        isConfirmFinger
                    )
                })
                // debugger;
                const bioFingerIsHang = Object.keys(BioData).filter((item) => {
                    const itemIsFinger = item !== FACE
                    const itemIsNotRemove =
                        BioData[item].bioActionType !== 'REMOVE'
                    const haveValidatedData = !!BioData[item][VALIDATE_DATA]

                    const isConfirmFingerRegister =
                        Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                        BioData[item][AUTH_TRANS]
                    const isConfirmFingerUpdate =
                        !Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                        haveValidatedData &&
                        BioData[item][VALIDATE_DATA][AUTH_TRANS]

                    // const isConfirmFinger = Object.keys(BioData[item]).includes(AUTH_TRANS)?
                    //   BioData[item][AUTH_TRANS]
                    //   :
                    //   (haveValidatedData &&
                    //   BioData[item][VALIDATE_DATA][AUTH_TRANS]);

                    const isConfirmFinger =
                        isConfirmFingerRegister || isConfirmFingerUpdate

                    const isConfirm = BioData[item][CONFIRM] === 0
                    const itemIsHang = isConfirm
                        ? false
                        : Object.keys(BioData[item]).includes(AUTH_TRANS) &&
                          BioData[item][AUTH_TRANS]
                        ? BioData[item][ISHANG]
                        : BioData[item][VALIDATE_DATA][ISHANG]
                    return (
                        itemIsNotRemove &&
                        itemIsFinger &&
                        itemIsHang &&
                        isConfirmFinger
                    )
                })
                // debugger;
                //  the number of finger confirm and finger hang
                //  is bigger than 2
                if (
                    // (bioFingerIsConfirmFingerAndNotHang.length + bioFingerIsHang.length > 2) ||
                    bioFingerIsConfirmFingerAndNotHang.length >= 1 &&
                    bioFingerIsHang.length >= 1
                ) {
                    label = labelError.errorNotMatchBioFinger_UpdateThread
                    widthPopup = '260px'
                    this.setState({
                        step: 2,
                    })
                    break
                }

                //  confirm finger not exist and hang finger exist
                if (
                    bioFingerIsHang.length === 1 ||
                    bioFingerIsHang.length > 2
                ) {
                    label = labelError.errorNotMatchBioHangFinger_UpdateThread
                    widthPopup = '240px'
                    this.setState({
                        step: 2,
                    })
                    break
                }

                if (
                    bioFingerIsConfirmFingerAndNotHang.length === 1 ||
                    bioFingerIsConfirmFingerAndNotHang.length > 2
                ) {
                    label =
                        labelError.errorNotMatchBioConfirmFinger_UpdateThread
                    widthPopup = '220px'
                    this.setState({
                        step: 2,
                    })
                    break
                }

                const numOfFieldBioFinger = Object.keys(BioData).filter(
                    (item) => {
                        const itemIsFinger = item !== FACE
                        const itemIsDataNotRemove =
                            BioData[item].bioActionType !== 'REMOVE'
                        return itemIsFinger && itemIsDataNotRemove
                    }
                )

                if (
                    counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                    CustomerType === 'EXCUST' &&
                    numOfFieldBioFinger.length !== 10
                ) {
                    isNotEnoughConfirmBio = true
                }

                // if(!bioFingerIsConfirmFingerAndNotHang.length &&
                //   bioFingerIsHang.length === 1)
                if (faceBioIncludeValidatedData) {
                    const faceLastStatus = faceBio[VALIDATE_DATA][ISHANG]
                    const faceCurrentIncludesHang = Object.keys(
                        faceBio
                    ).includes(ISHANG)
                    const faceCurrentStatus = faceBio[ISHANG]
                    const haveBioFinger = Object.keys(BioData).some(
                        (item) =>
                            item !== FACE &&
                            BioData[item].bioActionType !== 'REMOVE'
                    )
                    if (
                        faceLastStatus &&
                        faceCurrentIncludesHang &&
                        !faceCurrentStatus &&
                        haveBioFinger &&
                        !bioFingerIsConfirmFingerAndNotHang.length &&
                        !bioFingerIsHang.length
                    ) {
                        label =
                            labelError.errorChangeFaceDisableToActiveAndFingerDisable
                        break
                    }
                }

                break
            }
            default:
                //label = "Lỗi không xác định";
                break
        }
        return {
            label,
            labelError,
            widthPopup,
            isNotEnoughConfirmBio,
        }
    }

    handleShowPrintPage = () => {
        let { dataGetTicket } = this.props
        let ticketId = dataGetTicket.TicketID
        const {
            label,
            // labelError,
            widthPopup,
            // isNotEnoughConfirmBio
        } = this.getLabelErr()

        if (
            label &&
            label !== labelError.errorChangeFaceDisableToActiveAndFingerDisable
        ) {
            const POPUP_LEVEL_2 = 2
            this.props.openPopup(
                popupBioTypes.PopupCounterFail,
                POPUP_LEVEL_2,
                {
                    icon: ic_cancel,
                    label:
                        'Không đủ điều kiện để in Phiếu đề nghị cho Khách hàng',
                    width: widthPopup,
                }
            )
            return
        }
        const { biometricReducer } = this.props
        const bioOfTicket = biometricReducer[ticketId]
        const isAllHaveDataRemove =
            !!bioOfTicket &&
            Object.keys(bioOfTicket).some(
                (item) => bioOfTicket[item].bioActionType === 'REMOVE'
            )
        if (isAllHaveDataRemove) {
            const POPUP_LEVEL_2 = 2
            this.props.openPopup(
                popupBioTypes.PopupCounterFail,
                POPUP_LEVEL_2,
                {
                    icon: ic_cancel,
                    label:
                        'Không hỗ trợ in phiếu khi xoá dữ liệu sinh trắc học',
                    width: widthPopup,
                }
            )
            return
        }

        this.props.openPopup(popupBioTypes.PopupPrintText, 2, { ticketId })
    }

    openPopupErrorNotPrintedYet = (ticketId) => {
        const { biometricReducer } = this.props
        const bioOfTicket = biometricReducer[ticketId]
        const isAllHaveDataRemove =
            !!bioOfTicket &&
            Object.keys(bioOfTicket).some(
                (item) => bioOfTicket[item].bioActionType === 'REMOVE'
            )
        if (isAllHaveDataRemove) {
            return false
        }

        const POPUP_LEVEL_2 = 2
        this.props.openPopup(popupBioTypes.PopupCounterFail, POPUP_LEVEL_2, {
            icon: ic_cancel,
            label: 'Vui lòng in biểu mẫu trước khi gửi KSV',
            width: '200px',
        })
        return true
    }

    completeBioKSV = async () => {
        let { dataGetTicket, counterReducer } = this.props
        let ticketID = dataGetTicket.TicketID
        let enrollId = counterReducer.enrollId[ticketID]
        // debugger;
        if (!enrollId) return
        const userCurrent = counterReducer.listCurrentTicket.find(
            (user) => user.TicketID === ticketID
        )

        const {
            label,
            labelError,
            widthPopup,
            isNotEnoughConfirmBio,
        } = this.getLabelErr()
        const POPUP_LEVEL_2 = 2
        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        if (label /*  && !this.state.ignoreCheckData */) {
            if (
                label ===
                labelError.errorChangeFaceDisableToActiveAndFingerDisable
            ) {
                if (!userCurrent.userWasPrinted) {
                    const isOpen = this.openPopupErrorNotPrintedYet(ticketID)
                    if (isOpen) return
                }

                if (isNotEnoughConfirmBio && !userIsTeller) {
                    openPopupConfirmSendSupervisor(
                        this,
                        {
                            dataTicket: userCurrent,
                            content:
                                'Bạn chưa đăng ký đủ 10 dấu vân tay. Bạn có muốn tiếp tục gửi KSV phê duyệt?',
                        },
                        'completeBioKSV - PopupUserManage.js'
                    )
                    return
                }
                openPopupConfirmSendSupervisorTeller(
                    this,
                    {
                        ticketId: userCurrent.TicketID,
                        content: label,
                    },
                    'completeBioKSV - PopupUserManage.js'
                )
                return
            }
            this.props.openPopup(
                popupBioTypes.PopupCounterFail,
                POPUP_LEVEL_2,
                {
                    icon: ic_cancel,
                    label,
                    width: widthPopup,
                }
            )
            return
        }

        if (counterReducer.dataLogin.Roles.includes(TELLER)) {
            const biometricOfCurrentTicket = this.props.biometricReducer[
                ticketID
            ]
            const {
                statusBiometric,
                dataWasAuthen,
                LatestHistoryType,
                reasonRefuseRegFinger,
            } = userCurrent
            const isNotHaveStatusSupervisor =
                String(statusBiometric) === typeStatusBiometrics.UNDEFINED

            if (!this.props.counterReducer.dataConnectDesktop.VirtualTablet){
            // kh khác SDB vào quẩy lần đầu
            // ko xóa face, ko đk vân tay vs chưa điền lý do
                const isMustUpdateBioFingerOrReasonRefuse =
                    isNotHaveStatusSupervisor &&
                    LatestHistoryType === REGISTER &&
                    getFromChannel(
                        biometricOfCurrentTicket[FACE][VALIDATE_DATA].fromChannel
                    ) !== fromChannels.SDB && // kênh đk face !== SDB
                    biometricOfCurrentTicket[FACE]?.bioActionType !== REMOVE &&
                    !reasonRefuseRegFinger &&
                    Object.keys(biometricOfCurrentTicket).length === 1
                    // && dataWasAuthen?.BiometricActive === bioStatusTypes.ACTIVE // có sth đã được phê duyệt
                    // && dataWasAuthen?.BiometricFace !== bioStatusTypes.DISABLE // có face đã được phê duyệt
                    // && dataWasAuthen?.BiometricFinger === bioStatusTypes.DISABLE // ko có finger đã được phê duyệt
                if (isMustUpdateBioFingerOrReasonRefuse) {
                    showPopupPreventFinishTask(this, userCurrent, 2)
                    return
                }

                const notRegisterFingerOrFillReason =
                    !userCurrent.notVerifyFingerReason &&
                    !userCurrent.notVerifyFingerReason_otherReason &&
                    this.isNotRegisterFingerBioButFace
                if (notRegisterFingerOrFillReason) {
                    this.props.openPopup(
                        popupBioTypes.PopupCounterFail,
                        POPUP_LEVEL_2,
                        {
                            icon: ic_cancel,
                            label:
                                'Bạn chưa đăng ký STH vân tay, vui lòng đăng ký STH vân tay hoặc nhập lý do không đăng ký',
                            width: '253px',
                        }
                    )
                    return
                }
            }

            if (!userCurrent.userWasPrinted) {
                const isOpen = this.openPopupErrorNotPrintedYet(ticketID)
                if (isOpen) return
            }
            // debugger;
            // return;
            await this.props.removeStatusWaitingBioData(ticketID)
            await this.props.completeBio(enrollId, ticketID)
            // debugger;
        } else {
            if (!userCurrent.userWasPrinted) {
                const isOpen = this.openPopupErrorNotPrintedYet(ticketID)
                if (isOpen) return
            }

            if (isNotEnoughConfirmBio) {
                openPopupConfirmSendSupervisor(
                    this,
                    {
                        dataTicket: userCurrent,
                    },
                    'completeBioKSV - PopupUserManage.js'
                )
                return
            }
            // debugger
            // return;
            await this.props.removeStatusWaitingBioDataCollector(ticketID)
            await this.props.completeBioCollector(
                counterReducer.enrollId[ticketID]
            )
        }
        if (this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]) {
            // switch(this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]){
            //   case 8: {
            const err = buildErrorContent(
                typesAction.COMPLETE_BIO_ERR,
                this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]
            )
            // debugger;
            const POPUP_LEVEL_2 = 2
            this.props.openPopup(
                popupBioTypes.PopupCounterFail,
                POPUP_LEVEL_2,
                { icon: ic_cancel, label: err, width: '270px' }
            )
            this.props.clearError(typesAction.COMPLETE_BIO_ERR)
            // break;
            // }

            // default:
            // break;
            // }
            return
        }
        if (
            !this.props.counterReducer.err ||
            (typeof this.props.counterReducer.err === 'object' &&
                !Object.keys(this.props.counterReducer.err).includes(
                    COMPLETE_BIO_COLLECTOR_ERR
                ) &&
                !Object.keys(this.props.counterReducer.err).includes(
                    COMPLETE_BIO_ERR
                ))
        ) {
            !userIsTeller &&
                (await this.props.getCurrentBioEnrollCollector(
                    counterReducer.dataConnectDesktop.CounterID,
                    counterReducer.enrollId[ticketID]
                ))
            // const POPUP_LEVEL_1 = 1;
            openPopupNotifySendSuccess(
                this,
                {},
                'completeBioKSV - PopupUserManage.js'
            )
            return
        }
    }

    async componentDidMount() {
        const { dataGetTicket } = this.props

        // const isTeller = this.props.counterReducer.dataLogin.Role === TELLER;

        // if(isTeller && dataGetTicket && dataGetTicket.CustomerInfos)
        //   this.props.getBioHistoryList(
        //     dataGetTicket.TicketID,
        //     dataGetTicket.CustomerInfos.CustomerNumber,
        //     10,
        //     1,
        //     // moment()
        //   )
        // if(!isTeller && dataGetTicket && dataGetTicket.CustomerInfos)
        //   this.props.getBioHistoryListCollector(
        //     dataGetTicket.TicketID,
        //     dataGetTicket.CustomerInfos.CustomerNumber,
        //     10,
        //     1,
        //     // moment()
        //   )

        let { biometricReducer, counterReducer } = this.props
        ///////// Have been CIF but hasn't been Biometric
        if (dataGetTicket) {
            if (dataGetTicket.CustomerInfos) {
                this.setState({
                    isRegistration: true,
                })
            }
        }
        ///////// Check Show Action PrintAndHistoryBio
        if (biometricReducer[dataGetTicket.TicketID]) {
            let checkBioRegister = /REGISTER/g.test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )
            let checkWaitting = /waitingConfirm/g.test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )
            if (checkBioRegister && !checkWaitting) {
                this.setState({
                    stepSettingsAction: PrintAndHistoryBio,
                })
            }
        }
        ///////// Check Collector ///////
        if (counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR)) {
            const checkCollector = counterReducer.listCurrentTicket.find(
                (item) => item.CustomerInfos
            )
            if (checkCollector) {
                this.setState({
                    isRegistration: true,
                })
            }
        }
        //// Auto next tab biometric
        if (this.props.dataGetTicket.autoNextBiometric)
            this.setState({
                step: 2,
            })

        /**
         * checkType: show title infoLoading
         */
        if (biometricReducer[dataGetTicket.TicketID]) {
            let checkBioRegister = /REGISTER/g.test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )
            let checkWaitting = /waitingConfirm/g.test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )
            switch (true) {
                case !checkWaitting:
                    this.setState({
                        isLoadingTitle: {
                            content: '',
                            typeBio: null,
                        },
                    })
                    break
                case !checkWaitting && checkBioRegister:
                    this.setState({
                        stepSettingsAction: PrintAndHistoryBio,
                    })
                    break
                case checkWaitting &&
                    biometricReducer[dataGetTicket.TicketID].waitingConfirm
                        .typeBio === 'FACE':
                    // console.log(biometricReducer[dataGetTicket.TicketID].waitingConfirm);
                    this.setState({
                        isLoadingTitle: {
                            content:
                                biometricReducer[dataGetTicket.TicketID]
                                    .waitingConfirm.typeBio,
                            typeBio: LOADING_FACE,
                        },
                    })
                    break
                case checkWaitting &&
                    biometricReducer[dataGetTicket.TicketID].waitingConfirm
                        .typeBio !== 'FACE':
                    // console.log(biometricReducer[dataGetTicket.TicketID].waitingConfirm);
                    this.setState({
                        isLoadingTitle: {
                            content:
                                biometricReducer[dataGetTicket.TicketID]
                                    .waitingConfirm.typeBio,
                            typeBio: LOADING_FINGER,
                        },
                    })
                    break
                default:
                    break
            }
        } else
            this.setState({
                stepSettingsAction: NotRegister,
            })
    }

    componentDidUpdate(prevProps, prevState) {
        let { biometricReducer, dataGetTicket } = this.props
        // if (JSON.stringify(prevProps.counterReducer.err) !== JSON.stringify(counterReducer.err)) {
        //   if (this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]) {
        //     let err = buildErrorContent(typesAction.COMPLETE_BIO_ERR, this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]);
        //     this.props.openPopup(9, 2, { icon: ic_cancel, label: err.data, width: '270px' })
        //     this.props.clearError(typesAction.COMPLETE_BIO_ERR)
        //     return;
        //   }
        // }

        /**
         * Check Show Action PrintAndHistoryBio
         */
        if (
            JSON.stringify(prevProps.biometricReducer) !==
            JSON.stringify(biometricReducer)
        ) {
            if (biometricReducer[dataGetTicket.TicketID]) {
                let checkBioRegister = /REGISTER/g.test(
                    JSON.stringify(biometricReducer[dataGetTicket.TicketID])
                )
                let checkWaitting = /waitingConfirm/g.test(
                    JSON.stringify(biometricReducer[dataGetTicket.TicketID])
                )
                switch (true) {
                    case !checkWaitting:
                        this.setState({
                            isLoadingTitle: {
                                content: '',
                                typeBio: null,
                            },
                        })
                        break
                    case !checkWaitting && checkBioRegister:
                        this.setState({
                            stepSettingsAction: PrintAndHistoryBio,
                        })
                        break
                    case checkWaitting &&
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeBio === 'FACE':
                        // console.log(biometricReducer[dataGetTicket.TicketID].waitingConfirm);
                        this.setState({
                            isLoadingTitle: {
                                content:
                                    biometricReducer[dataGetTicket.TicketID]
                                        .waitingConfirm.typeBio,
                                typeBio: LOADING_FACE,
                            },
                        })
                        break
                    case checkWaitting &&
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeBio !== 'FACE':
                        // console.log(biometricReducer[dataGetTicket.TicketID].waitingConfirm);
                        this.setState({
                            isLoadingTitle: {
                                content:
                                    biometricReducer[dataGetTicket.TicketID]
                                        .waitingConfirm.typeBio,
                                typeBio: LOADING_FINGER,
                            },
                        })
                        break
                    default:
                        break
                }
            } else
                this.setState({
                    stepSettingsAction: NotRegister,
                })
        }
        /**
         * Check showPopup Err
         */
        // if (JSON.stringify(prevProps.counterReducer.err) !== JSON.stringify(counterReducer.err)) {
        //   if (this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]) {
        //     let err = buildErrorContent(typesAction.COMPLETE_BIO_ERR, this.props.counterReducer.err[typesAction.COMPLETE_BIO_ERR]);
        //     this.props.openPopup(9, 2, { icon: ic_cancel, label: err.data, width: '270px' })
        //     this.props.clearError(typesAction.COMPLETE_BIO_ERR)
        //     // if (counterReducer.err === "") {
        //     //   const POPUP_LEVEL_1 = 1
        //     //   this.props.closePopup(POPUP_LEVEL_1)
        //     //   return;
        //     // }
        //     return;
        //   }
        // }
    }

    enableContinueFlowBio = () => {
        const { dataGetTicket } = this.props
        // const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === dataGetTicket.TicketID);
        this.props.continueGetBio(
            this.props.counterReducer.enrollId[dataGetTicket.TicketID],
            dataGetTicket.TicketID
        )
    }

    genTitleBtnEditRejectBio = (bioInfosType) => {
        const registerFlow = [
            bioActionTypes.REGISTER,
            bioActionTypes.NOTREGISTER,
        ]
        return registerFlow.includes(bioInfosType)
            ? 'Tiếp tục đăng ký'
            : 'tiếp tục cập nhật'
    }

    handleOpenPopupNotRegisterFingerButFace = (currentTicket) => {
        openPopupNotRegisterFingerButFace(
            this,
            {
                ticketId: currentTicket.TicketID,
            },
            'handleOpenPopupNotRegisterFingerButFace - PopupUserManager.js'
        )
    }

    handleOpenPopupNotRegister = (
        currentTicket,
        isHandleRejectFlow = false
    ) => {
        const { TicketID } = currentTicket

        if (
            !isHandleRejectFlow &&
            isDisableEventClick(TicketID, this.props.counterReducer, this)
        )
            return

        const isTicketWaitingBio = /waitingConfirm/.test(
            JSON.stringify(this.props.biometricReducer[TicketID])
        )
        if (isTicketWaitingBio) this.props.removeStatusWaitingBioData(TicketID)
        openPopupFillReasonWhyNotRegisterBio(
            this,
            { ...currentTicket, isHandleRejectFlow },
            'PopupUserManage.js'
        )
    }

    continueHandleAfterDataBioWasAccepted = async () => {
        // debugger;
        //  1.  get-current-tickets
        //  2.  clear enrollId  => step 4 was automatic clear old enrollId
        //  3.  clear bio data of ticket in store
        //  4.  get-cifs
        //    4.1 override cifs to ticket
        //    4.2 override `dataWasAuthen` of ticket
        //    4.2 override biometric of api to ticket
        //  5.  check biometric of customer
        //    IF customer have biometric THEN request-update-bio
        //    ELSE request-enroll

        //  get-current-tickets
        const { counterReducer, dataGetTicket } = this.props
        const { dataConnectDesktop, listCurrentTicket } = counterReducer
        const ticketId = dataGetTicket.TicketID
        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        const currentTicket = listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )
        if (!currentTicket) return
        if (!currentTicket.CustomerInfos) return
        userIsTeller &&
            this.props.getCurrentTickets(
                dataConnectDesktop.CounterID,
                dataConnectDesktop.OfficeID
            )
        !userIsTeller && (await this.props.finishTransInTask(ticketId))

        // clear bio data of ticket in store
        await this.props.removeDataBioOfTicketInStore(ticketId)

        //  get-cifs
        userIsTeller &&
            (await this.props.getInfoCustomer(
                dataConnectDesktop.CounterID,
                currentTicket.CustomerInfos.CustomerNumber
            ))
        !userIsTeller &&
            (await this.props.getInfoCustomerCollector(
                dataConnectDesktop.CounterID,
                currentTicket.CustomerInfos.CustomerNumber
            ))

        const { customerInfo } = this.props.counterReducer

        //  override cifs to ticket
        //  override `dataWasAuthen` of ticket
        const customerInfos = !!customerInfo.Cifs && customerInfo.Cifs[0]
        if (!customerInfos) return
        await this.props.addCustomerInfos(customerInfos, ticketId, false, [
            'updateTicket',
        ])

        const errorAddCusInfos = this.props.counterReducer.err[
            typesAction.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
        ]
        if (errorAddCusInfos && errorAddCusInfos.Code === 76) {
            const OPEN_POPUP_LEVEL_1 = 1
            const {
                CustomerNumber,
                CustomerName,
                TicketNumber,
                TellerName,
                OfficeName,
            } = errorAddCusInfos
            // data follow props in PopupAccept component
            const dataTranferFollowPopup = {
                image_noti: iconNotificationClose,
                title: (
                    <ErrorGetTicket76
                        CustomerNumber={CustomerNumber}
                        CustomerName={CustomerName}
                        TicketNumber={TicketNumber}
                        TellerName={TellerName}
                        OfficeName={OfficeName}
                    />
                ),
                widthPopup: '363px',
                titleConfirm: 'Xác nhận',
            }

            this.props.clearError(
                typesAction.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
            )

            this.props.openPopup(
                popupBioTypes.PopupAccect,
                OPEN_POPUP_LEVEL_1,
                dataTranferFollowPopup
            )
            return
        }

        const biometricOfCus = !!customerInfo.BioInfos && customerInfo.BioInfos
        if (biometricOfCus) {
            //  override biometric of api to ticket
            await this.props.addBiometricExistFromCustomerInfos(
                biometricOfCus,
                ticketId
            )

            // request update bio
            userIsTeller &&
                (await this.props.requestUpDateBio(
                    ticketId,
                    customerInfos.CustomerNumber
                ))
            !userIsTeller &&
                (await this.props.requestUpDateBioCollector(
                    ticketId,
                    customerInfos.CustomerNumber
                ))
        } else {
            //request enroll
            userIsTeller &&
                (await this.props.requestEnroll(ticketId, customerInfos))
            !userIsTeller &&
                (await this.props.requestEnrollCollector(
                    ticketId,
                    customerInfos
                ))
        }
        isErrorWhenGetEnroll(
            this.props.counterReducer,
            this.props.openPopup,
            this.props.clearError
        )
        // if(userIsTeller) return;

        // // user is Collector
        // await this.props.getCurrentBioEnrollCollector(
        //   dataConnectDesktop.CounterID
        // )
        // await checkApplyBiometricForTicket(this, mainTask.TicketID)

        // const { biometricReducer }=this.props;
        // const flowUpdate = true;
        // const validatedData =/validatedData/.test(JSON.stringify(biometricReducer[mainTask.TicketID]));

        // switch (true) {
        //   case mainTask.CustomerInfos:
        //     this.props.openPopup(7, 1, mainTask);
        //     break;
        //   case mainTask.CustomerInfos && validatedData:
        //     this.props.openPopup(7, 1, { ...mainTask, flowUpdate })
        //     break;
        //   case mainTask.CustomerInfos && !validatedData:
        //       this.props.openPopup(7, 1, mainTask)
        //       break;
        //   case !mainTask.CustomerInfos:
        //     this.props.openPopup(7, 1, mainTask)
        //     break;
        //   default:
        //     break;
        // }
    }

    openPopupRefuseRegFinger = (currentTicket) => {
        const {
            TicketID,
            dataWasAuthen: { fullNameVi, phoneNumber },
            CustomerInfos: { FullNameVi, MobileNumber },
        } = currentTicket

        if (isDisableEventClick(TicketID, this.props.counterReducer, this))
            return

        const biometricOfTicket = this.props.biometricReducer[TicketID]

        const haveUpdateFullNameVi = fullNameVi !== FullNameVi
        const haveUpdateMobileNumber = phoneNumber !== MobileNumber

        const haveUpdateFace = (() => {
            const bioFace = biometricOfTicket[FACE]
            const {
                isHang,
                bioActionType,
                CONFIRM,
                usedToAuthenticateTransaction,
                validatedData,
            } = bioFace
            if (isHang !== undefined && isHang !== validatedData.isHang)
                return true // thay đối treo
            if (bioActionType !== undefined && bioActionType === REMOVE)
                return true // xóa
            if (
                bioActionType === undefined &&
                CONFIRM !== undefined &&
                CONFIRM === 0
            )
                return true
            if (
                usedToAuthenticateTransaction !== undefined &&
                usedToAuthenticateTransaction !==
                    validatedData.usedToAuthenticateTransaction
            )
                return true
            return false
        })()

        const haveUpdate =
            haveUpdateFullNameVi || haveUpdateMobileNumber || haveUpdateFace

        this.props.openPopup(popupBioTypes.PopupRefuseRegFinger, 2, {
            currentTicket,
            haveUpdate,
        })
    }

    handleShowBtnFollowStatusBiometric = () => {
        const { counterReducer } = this.props

        const currentTicket = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === this.props.dataGetTicket.TicketID
        )
        if (!currentTicket) return null

        const {
            statusBiometric,
            statusCustomer,
            TicketID,
            CustomerInfos,
            bioInfosType,
            dataWasAuthen,
            LatestHistoryType,
        } = currentTicket

        const biometricOfCurrentTicket = this.props.biometricReducer[TicketID]
        // const { statusBiometric, noteRejectBio, TicketID } = this.props.dataGetTicket;
        const refuseGetBio = CustomerInfos && CustomerInfos.RefuseGetBio
        // const isHaveValidateData = /validatedData/.test(JSON.stringify(biometricOfCurrentTicket))
        // const tmp = String(statusBiometric);
        // const tmp2 = statusBiometrics.UNDEFINED
        const isNotHaveStatusSupervisor =
            !statusBiometric ||
            String(statusBiometric) === typeStatusBiometrics.UNDEFINED
        const isTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        const isNotRegisterBio = (this.isNotRegisterBio =
            CustomerInfos &&
            !Number(CustomerInfos.BiometricActive) &&
            !refuseGetBio /* && !isHaveValidateData */ &&
            isNotHaveStatusSupervisor &&
            isTeller)

        const isHaveValidateData =
            biometricOfCurrentTicket &&
            Object.keys(biometricOfCurrentTicket).find((item) =>
                Object.keys(biometricOfCurrentTicket[item]).includes(
                    VALIDATE_DATA
                )
            )
        const isNotHaveBio = CustomerInfos && !isHaveValidateData
        const isAlreadyFillReasonNotRegister = (this.isAlreadyFillReasonNotRegister =
            CustomerInfos &&
            !Number(CustomerInfos.BiometricActive) &&
            refuseGetBio /* && !isHaveValidateData */ &&
            isNotHaveStatusSupervisor)
        // const isAlreadyFillReasonNotRegisterFinger =
        //   CustomerInfos && !Number(CustomerInfos.BiometricActive) && CustomerInfos.ReasonNRFinger && isNotHaveStatusSupervisor;
        // debugger
        const bioHaveFaceRegisterButNotFinger =
            biometricOfCurrentTicket &&
            !Object.keys(biometricOfCurrentTicket).some(
                (item) =>
                    item !== FACE &&
                    (biometricOfCurrentTicket[item][CONFIRM] === 0 ||
                        Object.keys(biometricOfCurrentTicket[item]).includes(
                            VALIDATE_DATA
                        ))
            ) &&
            Object.keys(biometricOfCurrentTicket).includes(FACE) &&
            biometricOfCurrentTicket[FACE][CONFIRM] === 0 &&
            !Object.keys(biometricOfCurrentTicket[FACE]).includes(VALIDATE_DATA)

        // lần đầu đk sth và ko đk finger
        const isNotRegisterFingerBioButFace = (this.isNotRegisterFingerBioButFace =
            CustomerInfos &&
            // !Number(CustomerInfos.BiometricActive) &&
            bioHaveFaceRegisterButNotFinger &&
            // !refuseGetBio  &&
            isNotHaveStatusSupervisor &&
            isTeller)
        const NOT_SDB_AlreadyHaveFaceAndNotRegFinger =
            isTeller &&
            // && isNotHaveStatusSupervisor
            dataWasAuthen?.BiometricActive === bioStatusTypes.ACTIVE && // có sth đã được phê duyệt
            dataWasAuthen?.BiometricFace !== bioStatusTypes.DISABLE && // có face đã được phê duyệt
            dataWasAuthen?.BiometricFinger === bioStatusTypes.DISABLE && // ko có finger đã được phê duyệt
            !!biometricOfCurrentTicket &&
            getFromChannel(
                biometricOfCurrentTicket?.[FACE]?.[VALIDATE_DATA]?.fromChannel
            ) !== fromChannels.SDB && // kênh đk face !== SDB
            Object.keys(biometricOfCurrentTicket).length === 1 && // chỉ có face
            LatestHistoryType === REGISTER // lần trước đó là đăng ký sth
        // debugger;

        const styleFooter = {
            justifyContent: 'space-between',
            //// statusBiometric === typeStatusBiometrics.PENDING ||
            // (statusBiometric === typeStatusBiometrics.ACCEPT && isTeller) ||
            // statusBiometric === typeStatusBiometrics.REJECT ||
            // statusCustomer === "InprogressBio" ||
            // isAlreadyFillReasonNotRegister ||
            // isNotRegisterBio?
            // "space-between"
            // :
            // "flex-end"
        }
        // debugger
        switch (true) {
            // case statusBiometric === typeStatusBiometrics.PENDING:
            //   this.btnFooter = (
            //     <ButtonControll
            //       title="gửi in"
            //       source={ic_print_blue}
            //       sourceOnHover={ic_print_white}
            //       alt="print"
            //       showPopupBiometric={() => this.showPopupBiometric("showPrintBio")}
            //     />
            //   )
            //   break;
            case statusBiometric ===
                typeStatusBiometrics.ACCEPT /*  && isTeller */:
                this.btnFooter = (
                    <ButtonConfirmControl
                        onSubmit={this.continueHandleAfterDataBioWasAccepted}
                        //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}
                        bg_color="#005993"
                        width="148px"
                        titleConfirm="Tiếp tục thao tác"
                    />
                )
                break
            case statusBiometric === typeStatusBiometrics.REJECT:
                this.btnFooter = (
                    <div className="reject-wrap">
                        <ButtonConfirmControl
                            onSubmit={() =>
                                openPopupShowReasonRejectBio(
                                    this,
                                    { ticketId: TicketID },
                                    'PopupUserManager'
                                )
                            }
                            //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}
                            width="155px"
                            bg_color="var(--vermillion)"
                            titleConfirm="Nội dung từ chối"
                        />
                        <ButtonConfirmControl
                            onSubmit={this.enableStatusEditRejectBio}
                            //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}.
                            width="148px"
                            bg_color="#005993"
                            titleConfirm={this.genTitleBtnEditRejectBio(
                                bioInfosType
                            )}
                        />
                        {isNotHaveBio && isTeller && (
                            <div
                                className="content-reject"
                                onClick={() =>
                                    this.handleOpenPopupNotRegister(
                                        currentTicket,
                                        true
                                    )
                                }
                            >
                                Không đăng ký sinh trắc học
                            </div>
                        )}
                    </div>
                )
                break
            case statusCustomer === 'InprogressBio':
                this.btnFooter = (
                    <ButtonConfirmControl
                        onSubmit={this.enableContinueFlowBio}
                        //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}
                        bg_color="#005993"
                        titleConfirm="Tiếp tục"
                    />
                )
                break
            // case isAlreadyFillReasonNotRegisterFinger:
            //   this.btnFooter = (
            //     <ButtonConfirmControl
            //       onSubmit={() => openPopupShowReasonRejectBio(this, { ticketId: TicketID, type: "rejectRegisterFingerBio" }, "PopupUserManager")}
            //       //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}
            //       width="350px"
            //       bg_color="var(--vermillion)"
            //       titleConfirm="Nội dung từ chối đăng ký STH vân tay"
            //     />
            //   )
            //   break;
            case NOT_SDB_AlreadyHaveFaceAndNotRegFinger: {
                this.btnFooter = (
                    <div
                        className="content-reject"
                        onClick={() =>
                            this.openPopupRefuseRegFinger(currentTicket)
                        }
                    >
                        Không đăng ký STH vân tay
                    </div>
                )
                break
            }
            case isAlreadyFillReasonNotRegister:
                this.btnFooter = (
                    <ButtonConfirmControl
                        onSubmit={() =>
                            openPopupShowReasonRejectBio(
                                this,
                                {
                                    ticketId: TicketID,
                                    type: 'rejectRegisterBio',
                                },
                                'PopupUserManager'
                            )
                        }
                        //isDisabled={statusBiometric !== typeStatusBiometrics.REJECT}
                        width="334px"
                        bg_color="var(--vermillion)"
                        titleConfirm="Nội dung từ chối đăng ký STH"
                    />
                )
                break
            // case isNotRegisterFingerBioButFace:
            //   this.btnFooter = (
            //     <div
            //       className="content-reject"
            //       onClick={() => this.handleOpenPopupNotRegisterFingerButFace(currentTicket)}
            //     >
            //       Không đăng ký STH vân tay
            //     </div>
            //   )
            //   break;
            case !isNotRegisterFingerBioButFace && isNotRegisterBio:
                this.btnFooter = (
                    <div
                        className="content-reject"
                        onClick={() =>
                            this.handleOpenPopupNotRegister(currentTicket)
                        }
                    >
                        Không đăng ký STH
                    </div>
                )
                break
            default:
                this.btnFooter = null
                break
        }
        return {
            btnFooter: this.btnFooter,
            style: styleFooter,
        }
    }

    render() {
        const { counterReducer } = this.props
        let content = null
        if (this.state.step === 1) {
            // debugger;
            content = (
                <UserCheckCIF
                    isRegistration={this.isRegistration}
                    dataCheckCIF={this.state.dataCheckCIF}
                    renderLoading={this.renderLoading}
                    dataGetTicket={this.props.dataGetTicket}
                    flowUpdate={
                        this.state.flowUpdate === true
                            ? this.state.flowUpdate
                            : this.props.flowUpdate
                    }
                />
            )
        } else if (this.state.step === 2) {
            content = (
                <UserBiometric
                    showPopupBiometric={this.props.showPopupBiometric}
                    onShowRegisterFinger={this.props.onShowRegisterFinger}
                    id={this.state.cif}
                    dataGetTicket={this.props.dataGetTicket} ///// use for requestGetBioData
                    flowUpdate={
                        this.state.flowUpdate === true
                            ? this.state.flowUpdate
                            : this.props.flowUpdate
                    }
                />
            )
        }
        // debugger
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === this.props.dataGetTicket.TicketID
        )
        if (!currentTicket) return null

        ///// check Cif InbioCollector - Teller /////
        let contentCif = null
        switch (true) {
            case counterReducer.dataLogin.Roles.includes(TELLER):
                if (currentTicket && currentTicket.CustomerInfos) {
                    contentCif = currentTicket.CustomerInfos.CustomerNumber
                } else if (Object.keys(this.state.dataCheckCIF).length !== 0) {
                    contentCif = this.state.dataCheckCIF[0].CustomerNumber
                }
                break
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
                if (currentTicket && currentTicket.CustomerInfos)
                    contentCif = currentTicket.CustomerInfos.CustomerNumber
                break
            default:
                break
        }

        const {
            statusBiometric,
            // noteRejectBio,
            statusCustomer,
            TicketID,
            // CustomerInfos,
            // bioInfosType,
            CustomerType,
            VerifyFingerStatus,
            dataWasAuthen,
        } = currentTicket
        const { biometricReducer } = this.props
        const bioOfTicket = biometricReducer[TicketID]
        const cusOfTicketHaveFingerActive =
            !!dataWasAuthen &&
            !!dataWasAuthen.BiometricFinger &&
            // dataWasAuthen.BiometricFinger !== "0";
            dataWasAuthen.BiometricFinger === '1'
        const cusOfTiketHaveFingerAuthOrHang =
            !!bioOfTicket &&
            Object.keys(bioOfTicket).some(
                (item) =>
                    item !== FACE &&
                    !!bioOfTicket[item][VALIDATE_DATA] &&
                    bioOfTicket[item][VALIDATE_DATA][AUTH_TRANS]
            )
        // debugger;
        const genPopupBtnFooter = this.handleShowBtnFollowStatusBiometric()

        const isTeller = this.props.counterReducer.dataLogin.Roles.includes(
            TELLER
        )
        const bioRegUnComplete =
            !!bioOfTicket &&
            Object.keys(bioOfTicket).filter(
                (item) =>
                    item !== FACE &&
                    Object.keys(bioOfTicket[item]).includes(REGISTER) &&
                    (!Object.keys(bioOfTicket[item]).includes(CONFIRM) ||
                        bioOfTicket[item][CONFIRM] !== 0)
            )
        // debugger
        return (
            <Bound>
                <div className="popup-title">
                    <div className="popup-title__title">
                        <p className="p-title">Thông tin khách hàng</p>
                        <img
                            src={close}
                            alt="close"
                            onClick={() => {
                                if (this.props.cancelAction) {
                                    this.props.cancelAction()
                                } else if (this.props.onClosePopup)
                                    this.props.onClosePopup()
                            }}
                        />
                    </div>
                    {this.state.isRegistration ? (
                        <div className="popup-step">
                            <div className="step-container">
                                <div className="tab_content">
                                    <div
                                        className="step"
                                        style={{
                                            color:
                                                this.state.step === 1
                                                    ? '#222222'
                                                    : '#c8c8c8',
                                        }}
                                        onClick={() => this.changeStep(1)}
                                    >
                                        Thông tin CIF
                                    </div>
                                    <div
                                        className="step"
                                        style={{
                                            color:
                                                this.state.step === 2
                                                    ? '#222222'
                                                    : '#c8c8c8',
                                        }}
                                        onClick={() => this.changeStep(2)}
                                    >
                                        Sinh trắc học
                                    </div>
                                </div>
                                {String(statusBiometric) !==
                                    typeStatusBiometrics.UNDEFINED && (
                                    <div className="status-biometric">
                                        <div className="status-biometric__title">
                                            Trạng thái:
                                        </div>
                                        <div
                                            className="status-biometric__content"
                                            style={{
                                                color:
                                                    statusBiometrics[
                                                        String(statusBiometric)
                                                    ].color,
                                            }}
                                        >
                                            {
                                                statusBiometrics[
                                                    String(statusBiometric)
                                                ].text
                                            }
                                        </div>
                                    </div>
                                )}
                                <div className="cif">
                                    <span className="type">CIF: </span>
                                    <span className="id">{contentCif}</span>
                                </div>
                            </div>
                            <div className="mark-step">
                                <div
                                    className="step"
                                    style={{
                                        backgroundColor:
                                            this.state.step === 1
                                                ? '#005993'
                                                : 'transparent',
                                    }}
                                />
                                <div
                                    className="step"
                                    style={{
                                        backgroundColor:
                                            this.state.step === 2
                                                ? '#005993'
                                                : 'transparent',
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <p className="search-title">
                            Tìm kiếm thông tin khách hàng
                        </p>
                    )}
                </div>
                <div className="popup-container">{content}</div>
                {!!bioRegUnComplete && !!bioRegUnComplete.length && (
                    <div className="popup-list-un-complete-finger p-t-10">
                        Vân tay chưa hoàn tất đăng ký:
                        <span>
                            {bioRegUnComplete
                                .map((item) => nameFingerPrint[item].name)
                                .join(', ')}
                        </span>
                    </div>
                )}
                {isTeller && CustomerType === 'INCUST' && (
                    <div className="popup-disable-event p-t-10">
                        Không thể thao tác liên quan tới STH vì đây là khách
                        hàng nội bộ
                    </div>
                )}
                {isTeller &&
                    (cusOfTicketHaveFingerActive ||
                        cusOfTiketHaveFingerAuthOrHang) &&
                    verifyFingerStatusDetail[VerifyFingerStatus] && (
                        <div className="popup-detail-auth-cust p-t-10">
                            Xác thực khách hàng:
                            <span
                                style={{
                                    color:
                                        verifyFingerStatusDetail[
                                            VerifyFingerStatus
                                        ].color,
                                }}
                            >
                                {' ' +
                                    verifyFingerStatusDetail[VerifyFingerStatus]
                                        .detail}
                            </span>
                        </div>
                    )}
                {this.state.isLoading ? (
                    <div className="loading">
                        <LoadingControl loadingPage={false} size="24px" />
                        <p>Đang tải...</p>
                    </div>
                ) : this.state.isRegistration &&
                  !this.props.counterReducer.dataConnectDesktop
                      .VirtualTablet ? (
                    <div
                        className="popup-action"
                        style={genPopupBtnFooter.style}
                    >
                        <div className="btn-group">
                            {genPopupBtnFooter.btnFooter}
                            {this.isNotRegisterFingerBioButFace ? (
                                <div
                                    className="content-reject"
                                    onClick={() =>
                                        this.handleOpenPopupNotRegisterFingerButFace(
                                            currentTicket
                                        )
                                    }
                                >
                                    Không đăng ký STH vân tay
                                </div>
                            ) : null}
                            <BtnControlHaveTooltip
                                title="In biểu mẫu"
                                source={ic_print_blue}
                                sourceOnHover={ic_print_white}
                                isDisabled={
                                    !counterReducer.enrollId[TicketID] ||
                                    // String(statusBiometric) !== typeStatusBiometrics.UNDEFINED ||
                                    statusCustomer === 'InprogressBio'
                                }
                                alt="print"
                                showPopupBiometric={() =>
                                    this.showPopupBiometric('showPrintBio')
                                }
                            />
                            <BtnControlHaveTooltip
                                title="Lịch sử"
                                source={ic_History}
                                sourceOnHover={icHistoryWhite}
                                alt="icon-History"
                                showPopupBiometric={() =>
                                    this.showPopupBiometric(
                                        'showHistoryCustomer'
                                    )
                                }
                            />
                        </div>
                        {this.state.isLoadingTitle.typeBio === LOADING_FINGER &&
                        this.state.step === 2 ? (
                            <p className="title_show_loading">
                                <span style={{ display: 'block' }}>
                                    Đang đăng ký vân tay:
                                </span>
                                <span>
                                    {
                                        nameFingerPrint[
                                            this.state.isLoadingTitle.content &&
                                                this.state.isLoadingTitle
                                                    .content
                                        ].name
                                    }
                                </span>
                            </p>
                        ) : this.state.isLoadingTitle.typeBio ===
                              LOADING_FACE && this.state.step === 2 ? (
                            // true ?
                            <p className="title_show_loading">
                                Đang đăng ký khuôn mặt...
                            </p>
                        ) : null}
                        <ButtonConfirmControl
                            onSubmit={() => {
                                this.completeBioKSV()
                            }}
                            isDisabled={
                                !counterReducer.enrollId[TicketID] ||
                                String(statusBiometric) !==
                                    typeStatusBiometrics.UNDEFINED ||
                                statusCustomer === 'InprogressBio'
                            }
                            bg_color="#005993"
                            titleConfirm="gửi ksv"
                        />
                    </div>
                ) : (
                    <div
                        className="popup-action"
                        style={genPopupBtnFooter.style}
                    ></div>
                )}
            </Bound>
        )
    }

    async componentWillUnmount() {
        const ticketId = this.props.dataGetTicket.TicketID
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )

        const isTicketWaitingBio = /waitingConfirm/.test(
            JSON.stringify(this.props.biometricReducer[ticketId])
        )
        const isCollector = this.props.counterReducer.dataLogin.Roles.includes(
            INBIOCOLLECTOR
        )

        if (isTicketWaitingBio) {
            !isCollector &&
                (await this.props.removeStatusWaitingBioData(ticketId))
            isCollector &&
                (await this.props.removeStatusWaitingBioDataCollector(ticketId))
        }

        // await this.props.removeDataBioOfTicketInStore(ticketId);
        if (isCollector) {
            await this.props.getCurrentBioEnrollCollector(
                this.props.counterReducer.dataConnectDesktop.CounterID
            )
            !!currentTicket &&
                (await this.props.getInfoCustomerCollector(
                    this.props.counterReducer.dataConnectDesktop.CounterID,
                    currentTicket.CustomerInfos?.CustomerNumber,
                    0,
                    currentTicket.TicketID,
                    true
                ))
        }
        !isCollector &&
            (await this.props.getCurrentBioEnroll(
                this.props.counterReducer.dataConnectDesktop.CounterID,
                ticketId,
                currentTicket.CustomerInfos?.CustomerNumber
            ))
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({
    requestEnroll: (ticketId, cusInfo) =>
        dispatch(requestEnroll(ticketId, cusInfo)),
    requestNewEnrollIdToUpdateRejectBio: (counterId, enrollId, ticketId) =>
        dispatch(
            requestNewEnrollIdToUpdateRejectBio(counterId, enrollId, ticketId)
        ),
    requestUpDateBio: (ticketId, cusNumber) =>
        dispatch(requestUpDateBio(ticketId, cusNumber)),
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    closePopup: (level) => dispatch(closePopup(level)),
    removeStatusWaitingBioData: (ticketId) =>
        dispatch(removeStatusWaitingBioData(ticketId)),
    removeStatusWaitingBioDataCollector: (ticketId, justDispatch = false) =>
        dispatch(removeStatusWaitingBioDataCollector(ticketId, justDispatch)),
    completeBio: (enrollId, ticketId, forceComplete = true) =>
        dispatch(completeBio(enrollId, ticketId, forceComplete)),
    getInfoCustomer: (counterId, customerNumber, typeSearch = 0) =>
        dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
    addCustomerInfos: (
        customerInfos,
        ticketID,
        isChangeTicket = false,
        roles = ['TELLER']
    ) =>
        dispatch(
            addCustomerInfos(customerInfos, ticketID, isChangeTicket, roles)
        ),
    addBiometricExistFromCustomerInfos: (bioInfos, ticketId) =>
        dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
    clearError: (typeError) => dispatch(clearError(typeError)),
    getCurrentBioEnroll: (counterId, ticketId, customerNumber = undefined) =>
        dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
    continueGetBio: (enrollId, ticketId) =>
        dispatch(continueGetBio(enrollId, ticketId)),
    setStatusBiometricWithTicket: (data) =>
        dispatch(setStatusBiometricWithTicket(data)),
    getCurrentTickets: (CounterID, OfficeID) =>
        dispatch(getCurrentTickets(CounterID, OfficeID)),
    removeDataBioOfTicketInStore: (ticketId) =>
        dispatch(removeDataBioOfTicketInStore(ticketId)),
    // getBioHistoryList: (ticketId, cusNum, pageSize, pageNum, lestThanCreate) =>
    //   dispatch(getBioHistoryList(ticketId, cusNum, pageSize, pageNum, lestThanCreate)),
    ///// BioCollector
    requestEnrollCollector: (cifs, currentTicketId) =>
        dispatch(requestEnrollCollector(cifs, currentTicketId)),
    requestNewEnrollIdToUpdateRejectBioCollector: (
        counterId,
        enrollId,
        ticketId
    ) =>
        dispatch(
            requestNewEnrollIdToUpdateRejectBioCollector(
                counterId,
                enrollId,
                ticketId
            )
        ),
    completeBioCollector: (enrollId, forceComplete = true) =>
        dispatch(completeBioCollector(enrollId, forceComplete)),
    getCurrentBioEnrollCollector: (counterId, enrollId) =>
        dispatch(getCurrentBioEnrollCollector(counterId, enrollId)),
    getInfoCustomerCollector: (
        counterId,
        cifsNum,
        typeSearch = 0,
        ticketId = undefined,
        isApplyBiometric = false
    ) =>
        dispatch(
            getCustomerInfos(
                counterId,
                cifsNum,
                typeSearch,
                ticketId,
                isApplyBiometric
            )
        ),
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    clearStatusPrinted: (ticketId) => dispatch(clearStatusPrinted(ticketId)),
    finishTransInTask: (ticketId) => dispatch(finishTransInTask(ticketId)),
    // getBioHistoryListCollector: (ticketId, cusNum, pageSize, pageNum, lestThanCreate) =>
    //   dispatch(getBioHistoryListCollector(ticketId, cusNum, pageSize, pageNum, lestThanCreate)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PopupUserManage)
