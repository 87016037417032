import * as types from '../actions/types'
import { ACTIVE } from '../constant/keys'

export const initialState = {
    stepSelect: {
        step1: ACTIVE,
        step2: '',
    },
    personalInfo: {
        customerType: '',
        fullName: '',
        identifyCard: '',
        phoneNumber: '',
        email: '',
    },
    listTransaction: [],
}

export default function bookAppointment(state = initialState, action) {
    switch (action.type) {
        case types.CLEAR_DATA_GET_TICKET:
            return initialState
        case types.SET_STEP_SELECT_BOOKING:
            const { step } = action
            return {
                ...state,
                stepSelect: step,
            }
        case types.SET_PERSONAL_INFO:
            const { details } = action

            return {
                ...state,
                personalInfo: details,
            }
        case types.SET_LIST_TRANSACTION:
            const { listTrans } = action
            return {
                ...state,
                listTransaction: listTrans,
            }
        default:
            return state
    }
}
