import * as types from '../actions/types'
import * as bioTypes from '../data/bioTypes'
import * as typeRequestBios from '../actions/typeRequestBio'
import bioActionTypes from '../data/bioActionTypes'

import applyBioToReducerAndLocalStorage from '../helper/applyBioToReducerAndLocalStorage'
import getFromChannel from '../helper/getFromChannel'

export const initialState = {
    // [tiketId]: {
    //   [bioType]: {
    //     [typeRequestBio is REGISTER]: array \| string(bioType is FACE),
    //     [typeRequestBio is CONFIRM]: number,
    //     bioActionType: string, // from bioActionTypes in "../data/bioActionTypes"
    //     validatedData: {
    //        listId: array \| string,
    //        isHang: boolean,
    //        usedToAuthenticateTransaction: boolean
    //     },
    //     usedToAuthenticateTransaction: boolean
    //     isHang: boolean
    //   },
    //   waitingConfirm: {
    //     typeBio: type of biometric,
    //     typeRequestBio: type of request biometric
    //   },
    // },
    err: '',
}

export default function biometricReducer(state = initialState, action) {
    const tmpState = JSON.parse(JSON.stringify(state))
    switch (action.type) {
        case types.CLEAR_ERR: {
            const err = JSON.parse(JSON.stringify(state.err))
            delete err[action.typeErr]
            return {
                ...state,
                err,
            }
        }
        case types.REMOVE_BIO_DATA_OF_TICKET_IN_STORE: {
            const { ticketId } = action
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (biometricData) {
                delete biometricData[ticketId]
                if (Object.keys(biometricData).length > 0)
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
                else localStorage.removeItem('biometricData')
            }
            delete tmpState[ticketId]
            return tmpState
        }
        case types.REMOVE_BIO_IN_UPDATE_THREAD: {
            tmpState[action.ticketId][action.bioType].bioActionType =
                bioActionTypes.REMOVE
            tmpState[action.ticketId][action.bioType][
                typeRequestBios.REGISTER
            ] = tmpState[action.ticketId][action.bioType].validatedData.listId
            tmpState[action.ticketId][
                action.bioType
            ].usedToAuthenticateTransaction =
                tmpState[action.ticketId][
                    action.bioType
                ].validatedData.usedToAuthenticateTransaction
            tmpState[action.ticketId][action.bioType][
                typeRequestBios.CONFIRM
            ] = 0
            return tmpState
        }
        case types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD: {
            delete tmpState[action.ticketId][action.bioType].bioActionType
            delete tmpState[action.ticketId][action.bioType][
                typeRequestBios.REGISTER
            ]
            delete tmpState[action.ticketId][action.bioType]
                .usedToAuthenticateTransaction
            delete tmpState[action.ticketId][action.bioType][
                typeRequestBios.CONFIRM
            ]
            return tmpState
        }
        case types.COMPLETE_BIO:
        case types.REASON_NOT_REGISTER: {
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (
                biometricData &&
                Object.keys(biometricData).includes(action.ticketId)
            ) {
                delete biometricData[action.ticketId]
                if (Object.keys(biometricData).length > 0)
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
                else localStorage.removeItem('biometricData')
            }

            delete tmpState[action.ticketId]
            return tmpState
        }
        case types.ADD_BIOMETRIC_EXIST: {
            const bioInfos = action.bioInfos

            if (!bioInfos) return state

            const bioDatas = bioInfos.BioDatas
            if (!bioDatas) return state

            const objBiometric = {}
            bioDatas.forEach((element) => {
                if (element.BioActionType === 'REMOVE') return
                if (element.BioType === bioTypes.FACE) {
                    objBiometric[element.BioType] = {
                        validatedData: {
                            listId: element.EnrollData[0],
                            isHang: !element.IsActive,
                            usedToAuthenticateTransaction: element.IsConfirmFinger,
                            fromChannel: getFromChannel(element.FromChannel)
                        },
                    }
                    return
                }
                objBiometric[element.BioType] = {
                    validatedData: {
                        listId: element.EnrollData,
                        isHang: !element.IsActive,
                        usedToAuthenticateTransaction: element.IsConfirmFinger,
                        fromChannel: getFromChannel(element.FromChannel)
                    },
                }
            })

            const ticketIsExistBio = JSON.parse(
                JSON.stringify(state[action.ticketId] || null)
            )
            if (!!ticketIsExistBio) {
                const lstKeyObjBiometric = Object.keys(objBiometric)
                lstKeyObjBiometric.forEach((item) => {
                    objBiometric[item] = {
                        ...objBiometric[item],
                        ...ticketIsExistBio[item],
                        validatedData: {
                            ...objBiometric[item].validatedData,
                            ...(ticketIsExistBio[item]?.validatedData),
                        },
                    }
                })
                const lstItemNewRegister = Object.keys(ticketIsExistBio).filter(
                    (item) => !lstKeyObjBiometric.includes(item)
                )
                lstItemNewRegister.forEach((item) => {
                    objBiometric[item] = ticketIsExistBio[item]
                })
            }

            return {
                ...state,
                [action.ticketId]: {
                    ...objBiometric,
                },
            }
        }
        case types.COMPLETE_TICKET:
        case types.MOVE_TICKET_TO_MISS_CALL:
        case types.MOVE_TICKET_TO_OTHER_COUNTER: {
            //delete ticket in localStorage
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (biometricData) {
                delete biometricData[action.ticketId]
                if (Object.keys(biometricData).length > 0)
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
                else localStorage.removeItem('biometricData')
            }
            //delete ticket in store
            delete tmpState[action.ticketId]
            return tmpState
        }
        case types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET: {
            let ticketId = ''
            // get ticketId
            if (action.isChangeTicket) {
                ticketId = action.oldTicketId
            } else ticketId = action.ticketID

            //delete ticket in localStorage
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (biometricData) {
                delete biometricData[ticketId]
                if (Object.keys(biometricData).length > 0)
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
                else localStorage.removeItem('biometricData')
            }
            //delete ticket in store
            delete tmpState[ticketId]
            return tmpState
        }
        case types.SOCKET_REGISTER_FACE: {
            const { ticketId, bioType, typeRequestBio, bioId } = action.payload
            // save to localStorage
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (
                biometricData &&
                Object.keys(biometricData).includes(ticketId)
            ) {
                delete biometricData[ticketId].waitingConfirm
                if (Object.keys(biometricData[ticketId]).length === 0)
                    delete biometricData[ticketId]
                if (Object.keys(biometricData).length === 0)
                    localStorage.removeItem('biometricData')
                else
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
            }

            // change data store
            const isStateIncludeTicket = Object.keys(state).includes(ticketId)
            if (isStateIncludeTicket) {
                const isTicketIncludeBioType = Object.keys(
                    state[ticketId]
                ).includes(bioType)
                if (isTicketIncludeBioType) {
                    tmpState[ticketId][bioType] = {
                        ...tmpState[ticketId][bioType],
                        [typeRequestBio]: bioId,
                    }
                    return tmpState
                }

                tmpState[ticketId][bioType] = {
                    [typeRequestBio]: bioId,
                }
                return tmpState
            }
            return {
                ...state,
                [ticketId]: {
                    [bioType]: {
                        [typeRequestBio]: bioId,
                    },
                },
            }
        }
        case types.SOCKET_REGISTER_THUMB_LEFT:
        case types.SOCKET_REGISTER_INDEX_LEFT:
        case types.SOCKET_REGISTER_MIDDLE_LEFT:
        case types.SOCKET_REGISTER_RING_LEFT:
        case types.SOCKET_REGISTER_PINKY_LEFT:
        case types.SOCKET_REGISTER_THUMB_RIGHT:
        case types.SOCKET_REGISTER_INDEX_RIGHT:
        case types.SOCKET_REGISTER_MIDDLE_RIGHT:
        case types.SOCKET_REGISTER_RING_RIGHT:
        case types.SOCKET_REGISTER_PINKY_RIGHT: {
            const { ticketId, bioType, typeRequestBio, bioId } = action.payload
            // save to localStorage
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (
                biometricData &&
                Object.keys(biometricData).includes(ticketId)
            ) {
                delete biometricData[ticketId].waitingConfirm
                if (Object.keys(biometricData[ticketId]).length === 0)
                    delete biometricData[ticketId]
                if (Object.keys(biometricData).length === 0)
                    localStorage.removeItem('biometricData')
                else
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
            }

            // change data store
            const isStateIncludeTicket = Object.keys(state).includes(ticketId)
            if (isStateIncludeTicket) {
                const isTicketIncludeBioType = Object.keys(
                    state[ticketId]
                ).includes(bioType)
                const isBioTypeIncludeTypeRequest =
                    isTicketIncludeBioType &&
                    Object.keys(state[ticketId][bioType]).includes(
                        typeRequestBio
                    )
                if (isBioTypeIncludeTypeRequest) {
                    //register part 2
                    tmpState[ticketId][bioType][typeRequestBio].push(bioId)
                    return tmpState
                }
                //register part 1
                if (isTicketIncludeBioType) {
                    tmpState[ticketId][bioType] = {
                        ...tmpState[ticketId][bioType],
                        [typeRequestBio]: [bioId],
                    }
                    return tmpState
                }
                tmpState[ticketId][bioType] = {
                    [typeRequestBio]: [bioId],
                }

                return tmpState
            }
            return {
                ...state,
                [ticketId]: {
                    [bioType]: {
                        [typeRequestBio]: [bioId],
                    },
                },
            }
        }
        case types.SOCKET_CONFIRM_FACE:
        case types.SOCKET_CONFIRM_THUMB_LEFT:
        case types.SOCKET_CONFIRM_INDEX_LEFT:
        case types.SOCKET_CONFIRM_MIDDLE_LEFT:
        case types.SOCKET_CONFIRM_RING_LEFT:
        case types.SOCKET_CONFIRM_PINKY_LEFT:
        case types.SOCKET_CONFIRM_THUMB_RIGHT:
        case types.SOCKET_CONFIRM_INDEX_RIGHT:
        case types.SOCKET_CONFIRM_MIDDLE_RIGHT:
        case types.SOCKET_CONFIRM_RING_RIGHT:
        case types.SOCKET_CONFIRM_PINKY_RIGHT: {
            const { ticketId, bioType, typeRequestBio, code } = action.payload
            // save to localStorage
            const biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            if (
                biometricData &&
                Object.keys(biometricData).includes(ticketId)
            ) {
                delete biometricData[ticketId].waitingConfirm
                if (Object.keys(biometricData[ticketId]).length === 0)
                    delete biometricData[ticketId]
                if (Object.keys(biometricData).length === 0)
                    localStorage.removeItem('biometricData')
                else
                    localStorage.setItem(
                        'biometricData',
                        JSON.stringify(biometricData)
                    )
            }

            // change data store
            tmpState[ticketId][bioType][typeRequestBio] = code
            return tmpState
        }
        case types.REQUEST_REMOVE_BIO_COLLECTOR:
        case types.REQUEST_REMOVE_BIO_DATA: {
            const { ticketId, bioType, index } = action
            //update state
            if (bioType === bioTypes.FACE) {
                delete tmpState[ticketId][bioType][typeRequestBios.CONFIRM]
                delete tmpState[ticketId][bioType][typeRequestBios.REGISTER]

                delete tmpState[ticketId][bioType].bioActionType
                const isEmptyBioType =
                    Object.keys(tmpState[ticketId][bioType]).length === 0
                if (isEmptyBioType) delete tmpState[ticketId][bioType]
            } else {
                delete tmpState[ticketId][bioType]['CONFIRM']

                if (index === 0)
                    tmpState[ticketId][bioType][
                        typeRequestBios.REGISTER
                    ].shift()
                else tmpState[ticketId][bioType][typeRequestBios.REGISTER].pop()

                const isNoneRegister = tmpState[ticketId][bioType][
                    typeRequestBios.REGISTER
                ].every((item) => !item)
                if (isNoneRegister) {
                    delete tmpState[ticketId][bioType][typeRequestBios.REGISTER]
                    delete tmpState[ticketId][bioType].bioActionType
                    delete tmpState[ticketId][bioType].isHang
                }
                const isEmptyBioType =
                    Object.keys(tmpState[ticketId][bioType]).length === 0 ||
                    (
                        !tmpState[ticketId][bioType][typeRequestBios.REGISTER] &&
                        !Object.keys(tmpState[ticketId][bioType]).includes(typeRequestBios.VALIDATE_DATA)
                    )
                if (isEmptyBioType) delete tmpState[ticketId][bioType]

            }
            const isEmptyTicked = Object.keys(tmpState[ticketId]).length === 0
            if (isEmptyTicked) delete tmpState[ticketId]

            // save state to store
            return tmpState
        }
        case types.REQUEST_GET_BIO_DATA:
        case types.REQUEST_CONFIRM_BIO_DATA: {
            const { ticketId, bioType, typeRequest } = action
            // save localStorage
            let biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            const isRegister = typeRequest === typeRequestBios.REGISTER
            const isExistInStore =
                state[ticketId] && state[ticketId][typeRequestBios.REGISTER]
            const isHaveNoneRegister =
                isExistInStore &&
                state[ticketId][typeRequestBios.REGISTER].some((item) => !item)
            if (biometricData) {
                if (biometricData[ticketId])
                    biometricData[ticketId].waitingConfirm = {
                        typeBio: bioType,
                        typeRequestBio: typeRequest,
                        setToUndefined:
                            isRegister && isExistInStore && isHaveNoneRegister
                                ? true
                                : false,
                    }
                else
                    biometricData[ticketId] = {
                        waitingConfirm: {
                            typeBio: bioType,
                            typeRequestBio: typeRequest,
                            setToUndefined:
                                isRegister &&
                                    isExistInStore &&
                                    isHaveNoneRegister
                                    ? true
                                    : false,
                        },
                    }
            } else {
                biometricData = {
                    [ticketId]: {
                        waitingConfirm: {
                            typeBio: bioType,
                            typeRequestBio: typeRequest,
                            setToUndefined:
                                isRegister &&
                                    isExistInStore &&
                                    isHaveNoneRegister
                                    ? true
                                    : false,
                        },
                    },
                }
            }
            localStorage.setItem('biometricData', JSON.stringify(biometricData))

            // change store
            const isStateIncludeTicket = Object.keys(state).includes(ticketId)
            if (isStateIncludeTicket) {
                tmpState[ticketId]['waitingConfirm'] = {
                    typeBio: bioType,
                    typeRequestBio: typeRequest,
                }
                return {
                    ...tmpState,
                    err: '',
                }
            }
            return {
                ...state,
                [ticketId]: {
                    waitingConfirm: {
                        typeBio: bioType,
                        typeRequestBio: typeRequest,
                    },
                },
                err: '',
            }
        }
        case types.REQUEST_GET_BIO_DATA_COLLECTOR:
        case types.REQUEST_CONFIRM_BIO_DATA_COLLECTOR: {
            const { ticketId, bioType, typeRequest } = action
            // save localStorage
            let biometricData = JSON.parse(
                localStorage.getItem('biometricData')
            )
            const isRegister = typeRequest === typeRequestBios.REGISTER
            const isExistInStore =
                state[ticketId] && state[ticketId][typeRequestBios.REGISTER]
            const isHaveNoneRegister =
                isExistInStore &&
                state[ticketId][typeRequestBios.REGISTER].some((item) => !item)
            // console.log(isRegister, isExistInStore, isHaveNoneRegister)
            if (biometricData) {
                if (biometricData[ticketId])
                    biometricData[ticketId].waitingConfirm = {
                        typeBio: bioType,
                        typeRequestBio: typeRequest,
                        setToUndefined:
                            isRegister && isExistInStore && isHaveNoneRegister
                                ? true
                                : false,
                    }
                else
                    biometricData[ticketId] = {
                        waitingConfirm: {
                            typeBio: bioType,
                            typeRequestBio: typeRequest,
                            setToUndefined:
                                isRegister &&
                                    isExistInStore &&
                                    isHaveNoneRegister
                                    ? true
                                    : false,
                        },
                    }
            } else {
                biometricData = {
                    [ticketId]: {
                        waitingConfirm: {
                            typeBio: bioType,
                            typeRequestBio: typeRequest,
                            setToUndefined:
                                isRegister &&
                                    isExistInStore &&
                                    isHaveNoneRegister
                                    ? true
                                    : false,
                        },
                    },
                }
            }
            localStorage.setItem('biometricData', JSON.stringify(biometricData))

            // change store
            const isStateIncludeTicket = Object.keys(state).includes(ticketId)
            if (isStateIncludeTicket) {
                tmpState[ticketId]['waitingConfirm'] = {
                    typeBio: bioType,
                    typeRequestBio: typeRequest,
                }
                return {
                    ...tmpState,
                    err: '',
                }
            }
            return {
                ...state,
                [ticketId]: {
                    waitingConfirm: {
                        typeBio: bioType,
                        typeRequestBio: typeRequest,
                    },
                },
                err: '',
            }
        }
        case types.REQUEST_GET_BIO_DATA_ERR:
        case types.REQUEST_GET_BIO_DATA_COLLECTOR_ERR:
        case types.REQUEST_REMOVE_BIO_COLLECTOR_ERR:
        case types.REQUEST_REMOVE_BIO_DATA_ERR:
        case types.REMOVE_STATUS_WAITING_BIO_ERR:
            return {
                ...state,
                err: action.err,
            }
        case types.REQUEST_CONFIRM_BIO_DATA_COLLECTOR_ERR:
        case types.REQUEST_CONFIRM_BIO_DATA_ERR: {
            const { ticketId, bioType, typeRequest, code } = action.payload
            tmpState[ticketId][bioType][typeRequest] = code
            return tmpState
        }
        case types.REMOVE_STATUS_WAITING_BIO:
            if (
                Object.keys(state).includes(action.ticketId) &&
                Object.keys(state[action.ticketId]).includes('waitingConfirm')
            ) {
                delete tmpState[action.ticketId]['waitingConfirm']
                const biometricData = JSON.parse(
                    localStorage.getItem('biometricData')
                )
                if (
                    !!biometricData &&
                    Object.keys(biometricData).includes(action.ticketId)
                ) {
                    delete biometricData[action.ticketId]
                    if (!Object.keys(biometricData).length) {
                        localStorage.removeItem('biometricData')
                    } else
                        localStorage.setItem(
                            'biometricData',
                            JSON.stringify(biometricData)
                        )
                }
            }
            return tmpState
        case types.GET_CURRENT_BIO_ENROLL_COLLECTOR: {
            const { bioInfos, pendingBio } = action.payload
            const objBioInfos = {}
            // debugger;
            bioInfos.forEach((item) => {
                const bioDatas = item.BioDatas || []
                const ticketId = item.ID
                // not have data biometric exist
                if (bioDatas.length === 0) {
                    const biometricData = JSON.parse(
                        localStorage.getItem('biometricData')
                    )
                    let waitingConfirm = {}
                    if (
                        biometricData &&
                        biometricData[ticketId] &&
                        biometricData[ticketId].waitingConfirm
                    ) {
                        waitingConfirm = biometricData[ticketId].waitingConfirm
                    }
                    if (Object.keys(waitingConfirm).length > 0)
                        objBioInfos[ticketId] = {
                            waitingConfirm,
                        }
                    return
                }
                // have data biometric exist
                const tmpObj = applyBioToReducerAndLocalStorage(
                    ticketId,
                    bioDatas
                )
                // save to store
                objBioInfos[ticketId] = {
                    ...tmpObj,
                }
                return
            })
            pendingBio.forEach((item) => {
                const bioDatas = item.BioDatas || []
                const ticketId = item.ID
                // not have data biometric exist
                if (bioDatas.length === 0) {
                    const biometricData = JSON.parse(
                        localStorage.getItem('biometricData')
                    )
                    let waitingConfirm = {}
                    if (
                        biometricData &&
                        biometricData[ticketId] &&
                        biometricData[ticketId].waitingConfirm
                    ) {
                        waitingConfirm = biometricData[ticketId].waitingConfirm
                    }
                    if (Object.keys(waitingConfirm).length > 0)
                        objBioInfos[ticketId] = {
                            waitingConfirm,
                        }
                    return
                }
                // have data biometric exist
                const tmpObj = applyBioToReducerAndLocalStorage(
                    ticketId,
                    bioDatas
                )
                // save to store
                objBioInfos[ticketId] = {
                    ...tmpObj,
                }
                return
            })
            return {
                ...state,
                ...objBioInfos,
            }
        }
        case types.GET_CURRENT_BIO_ENROLL: {
            const { ticketId, bioDatas, statusBiometric } = action.payload

            // not have data biometric exist
            if (bioDatas.length === 0) {
                const biometricData = JSON.parse(
                    localStorage.getItem('biometricData')
                )
                let waitingConfirm = {}
                if (
                    biometricData &&
                    biometricData[ticketId] &&
                    biometricData[ticketId].waitingConfirm
                ) {
                    waitingConfirm = biometricData[ticketId].waitingConfirm
                }
                return Object.keys(waitingConfirm).length > 0
                    ? {
                        ...state,
                        [ticketId]: {
                            waitingConfirm,
                        },
                        err: '',
                    }
                    : {
                        ...state,
                        err: '',
                    }
            }
            const tmpObj = applyBioToReducerAndLocalStorage(
                ticketId,
                bioDatas,
                statusBiometric
            )
            return {
                ...state,
                [ticketId]: {
                    ...state[ticketId],
                    ...tmpObj,
                },
                err: '',
            }
        }
        case types.GET_CURRENT_BIO_ENROLL_COLLECTOR_ERR:
        case types.GET_CURRENT_BIO_ENROLL_ERR:

            return {
                ...state,
                err: action.err,
            }
        case types.RESET_ITEM_BIO_DATA_CONFIRM: {
            const { ticketId, bioType } = action
            const isStateIncludeTicket = tmpState[ticketId]
            const isTicketIncludeBioType =
                isStateIncludeTicket && isStateIncludeTicket[bioType]
            const isBioTypeInCludeConfirm =
                isTicketIncludeBioType &&
                Object.keys(tmpState[ticketId][bioType]).includes(
                    typeRequestBios.CONFIRM
                )
            if (isBioTypeInCludeConfirm) {
                delete tmpState[ticketId][bioType][typeRequestBios.CONFIRM]
                if (
                    Object.keys(tmpState[ticketId][bioType]).includes(
                        'usedToAuthenticateTransaction'
                    )
                )
                    delete tmpState[ticketId][bioType]
                        .usedToAuthenticateTransaction
                if (!Object.keys(tmpState[ticketId][bioType]).length)
                    delete tmpState[ticketId][bioType]
                return tmpState
            }
            return state
        }
        case types.EDIT_FINGER_INFO_IN_REGISTER_THREAD:
        case types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR: {
            tmpState[action.ticketId][
                action.bioType
            ].usedToAuthenticateTransaction = action.isAuthenticate
            tmpState[action.ticketId][
                action.bioType
            ].isHang = action.isAuthenticate ? false : true
            return tmpState
        }
        case types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR:
        case types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR:
        case types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR: {
            const err = JSON.parse(JSON.stringify(state.err))
            return {
                ...state,
                err: {
                    ...err,
                    ...action.err,
                },
            }
        }
        case types.EDIT_FINGER_INFO_IN_UPDATE_THREAD: {
            tmpState[action.ticketId][
                action.bioType
            ].usedToAuthenticateTransaction = action.isAuthenticate
            // tmpState[action.ticketId][action.bioType].validatedData.isHang = action.isHang;
            tmpState[action.ticketId][action.bioType].isHang = action.isHang

            return tmpState
        }
        default:
            return state
    }
}
