import { popupBioTypes } from "../data/PopupTypes"

// import helper
import checkApplyBiometricForTicket from "./checkApplyBiometricForTicket";

// data
import { FACE } from "../data/bioTypes"
import { VALIDATE_DATA, AUTH_TRANS, REGISTER } from "../actions/typeRequestBio";
import verifyFingerStatusTypes from "../data/verifyFingerStatusTypes";
import fromChannels from '../data/fromChannels'
import { typeStatusBiometrics } from "../data/statusBiometricWithTicket";

// image
import ic_warning_big from '../images/ic_warning_big.svg'
import typesKeyDetailPopup from "../data/typesKeyDetailPopup";

const autoOpenPopupWhenGetTicket = async (_this, ticketId, get_this) => {
  // clear all popup
  const POPUP_LEVEL_1 = 1;
  _this.props.closePopup(POPUP_LEVEL_1);

  const currentTicket = _this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === ticketId);
  if (!currentTicket) return;

  const { CustomerInfos } = currentTicket;
  if (!CustomerInfos) return;  

  await checkApplyBiometricForTicket(_this, ticketId);

  const isVirtualTablet = _this.props.counterReducer.dataConnectDesktop.VirtualTablet
  if(isVirtualTablet) return;

  if (currentTicket.VerifyFingerStatus !== verifyFingerStatusTypes.NV) return;
  // debugger;
  const biometricOfTicket = _this.props.biometricReducer[ticketId];

  const wrapConditionOpenPopup = CustomerInfos.BiometricFace + CustomerInfos.BiometricFinger;
  const cifs = CustomerInfos.CustomerNumber;
  // const isHaveBiometric = !!Number(CustomerInfos.BiometricActive)
  const isHaveBiometric = !!biometricOfTicket
  const isHaveBiometrictFinger = /.1/.test(wrapConditionOpenPopup) ||
    (!!biometricOfTicket &&
      !!Object.keys(biometricOfTicket).find(item =>
        item !== FACE &&
        Object.keys(biometricOfTicket[item]).includes(VALIDATE_DATA) &&
        biometricOfTicket[item][VALIDATE_DATA][AUTH_TRANS]
      ));
  const isHaveBiometrictFingerClosed = (!!biometricOfTicket &&
    !!Object.keys(biometricOfTicket).find(item =>
      item !== FACE &&
      Object.keys(biometricOfTicket[item]).includes(VALIDATE_DATA) &&
      !biometricOfTicket[item][VALIDATE_DATA][AUTH_TRANS]
    ));
  const isHaveBiometrictFaceAndNoneFinger = /10/.test(wrapConditionOpenPopup) ||
    (!!biometricOfTicket &&
      !!Object.keys(biometricOfTicket).find(item =>
        item === FACE && Object.keys(biometricOfTicket[item]).includes(VALIDATE_DATA)
      ));
  const isCusFilledReasonNotRegisterBio = CustomerInfos.RefuseGetBio
  switch (true) {
    case isHaveBiometric && isHaveBiometrictFinger: {
      const POPUP_LEVEL_1 = 1;
      _this.props.openPopup(
        popupBioTypes.CheckCustomerActions,
        POPUP_LEVEL_1,
        { ticketId }
      )
      break;
    }
    case isHaveBiometric && isHaveBiometrictFingerClosed: {
      break;
    }
    case isHaveBiometric && isHaveBiometrictFaceAndNoneFinger: {
      const currentTicket = _this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === ticketId);
      const { LatestHistoryType, statusBiometric } = currentTicket
      if(LatestHistoryType !== REGISTER) break; // ko phải lần đầu vào quầy
      if(String(statusBiometric) !== typeStatusBiometrics.UNDEFINED) break; // ko ở trạng thái gửi ksv
      const bioFace = biometricOfTicket[FACE]
      const { fromChannel } = bioFace[VALIDATE_DATA]
      if (['', fromChannels.SDB].includes(fromChannel))
        break;
      _this.props.openPopup(
        popupBioTypes.PopupAccect,
        POPUP_LEVEL_1,
        {
          key: typesKeyDetailPopup.updateBioIpay,
          image_noti: ic_warning_big,
          title: "Khách hàng đăng ký STH tại kênh ngoài quầy. Vui lòng đăng ký bổ sung sinh trắc học vân tay hoặc nhập lý do không đăng ký bổ sung tại quầy",
          widthPopup: "367px",
          btnWidth: "129px",
          titleConfirm: "đăng ký ngay",
          dataTicket: { ...currentTicket }
        }
      )
      break;
    }
    case cifs && !isHaveBiometric && !isCusFilledReasonNotRegisterBio:

      _this.props.openPopup(
        popupBioTypes.PopupUserManage,
        POPUP_LEVEL_1,
        {
          ...currentTicket,
        }
      )
      break;
    default:
      break;
  }
}

export default autoOpenPopupWhenGetTicket