import React from 'react'
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
import { WrapPopupOffCounselor } from './index.styles'
import ButtonControl from '../../../controls/ButtonControl'
import IcNotiRed from '../../../images/SVG/IcNotiRed'
import { useSelector } from 'react-redux'

export default function PopupOffCounselor({ onClose, onNext }) {

	const { isShowContentCounselorPage} = useSelector((state) => ({
		isShowContentCounselorPage: state.counselor.isShowContentCounselorPage,
    }))
    return (
        <OverlayFullScreen>
            <WrapPopupOffCounselor>
                <div className="pop-content">
                    <IcNotiRed />
                    <div className="bold">Bạn còn khách hàng trong quầy.</div>
                    <div className="note">
                        (*) Vui lòng phục vụ khách hàng.
                    </div>
                </div>
                <div className="pop-btn-container">
					{
						isShowContentCounselorPage ?
						<ButtonControl
							text="xác nhận"
							className="btn-counselor-confirm"
							onClick={onClose}
						/>
						:
						<>
							<ButtonControl
								text="hủy bỏ"
								className="btn-counselor-cancel"
								onClick={onClose}
							/>
							<ButtonControl
								text="xác nhận"
								className="btn-counselor-confirm"
								onClick={onNext}
							/>
						</>
					}
                    
                </div>
            </WrapPopupOffCounselor>
        </OverlayFullScreen>
    )
}
