import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { connect } from 'react-redux'

import * as typesAction from '../../../actions/types'

//Image
// import ic_close_noti from "../../../images/ic_counter_notification_close.svg";
import ic_cancel from '../../../images/icon_cancel.svg'
//data
import { branch } from '../../../data/mockData'
import { COORDINATOR, COUNSELOR, TELLER, COORLOBBY, ADVISETICKET, MOVETICKET, TAKETICKET, OFFEXSUPERVISOR } from '../../../data/userRole'
// import { transaction } from "../../../data/data";
// import * as ticketStatuses from "../../../data/ticketStatuses";

// import action
import {
    startOrStopCounter,
    getCurrentTickets,
} from '../../../actions/counterAction'

// import type
import * as counterStates from '../../../data/counterStates'
import { openPopup } from '../../../actions/popupBioAction'

// helper
import getDataAdministrativeUnitsByCode, {
    fieldAdministrativeUnits,
} from '../../../helper/getDataAdministrativeUnitsByCode'
import { buildErrorContent } from '../../../helper/tool'
import { clearError } from '../../../actions/commonAction'
import isStopCounter from '../../../helper/handleStopCounter'
import IcGetTicket from '../../ComponentV2/images/SVG/IcGetTicket'
import IcGetTicketOnline from '../../ComponentV2/images/SVG/IcGetTicketOnline'
import IcGetTicketOffline from '../../ComponentV2/images/SVG/IcGetTicketOffline'
import {
    POPUP_CLIENT_CONSULTANT,
    POPUP_GET_TICKET_NUMBER,
    POPUP_GET_TICKET_NUMBER_ONLINE,
} from '../../../constant/keys'
import Portal from '../../ComponentV2/controls/Portal'
import GetNumberTicket from '../../ComponentV2/screens/GetNumberTicket'
import GetNumberTicketOnline from '../../ComponentV2/screens/GetNumberTicketOnline'
import PopupOffCounselor from '../../ComponentV2/screens/CounselorPage/PopupOffCounselor'

const Bound = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    font-family: 'SVN-Gilroy';
    font-size: 20px;
    font-weight: bold;
    justify-content: space-between;
    padding: 17px 0 28px 0;
    .branch {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 24px;
        .branch-name {
            color: #005993;
            margin-bottom: 10px;
        }
        .branch-address {
            font-size: 14px;
            font-weight: 500;
        }
        .address {
            max-width: 450px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .transaction-status {
        display: flex;
        flex-direction: row;
        padding-right: 24px;
        gap: 5px;
        .system-status {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 80px 0 0;
            .active-status {
                margin-bottom: 10px;
            }
            .time-wrapper {
                display: flex;
                flex-direction: row;
                .time-title {
                    font-size: 14px;
                    font-weight: bold;
                }
                .time {
                    width: 55px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: end;
                }
            }
        }
        .get-ticket {
            display: flex;
            gap: 4px;
            .btn-get-ticket {
                box-sizing: border-box;
                cursor: pointer;
                display: inline-flex;
                padding: 4px 13px 4px 4px;
                align-items: center;
                gap: 5px;
                border-radius: 17px;
                background: var(--Addition, #1477ec);
                height: 32px;
                color: var(--White, #fff);
                font-family: SVN-Gilroy;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                &.disabled{
                    pointer-events: none;
                    background: rgb(233, 233, 233);
                    color: rgba(34, 34, 34, 0.25);
                }
            }
        }
        .button-active {
            font-size: 12px;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 36px;
            text-transform: uppercase;
            border-radius: 18px;
            color: #ffffff;
            cursor: pointer;
            .status {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0 6px;
                line-height: 0;
            }
            &.refresh {
                margin: 10px 0 0 0;
            }
        }
        .button-active2 {
            width: 110px;
            height: 36px;
            margin: 10px 0 0 0;
        }
    }
`
class Header extends Component {
    state = {
        // isActive: this.props.isActive,
        time: '',
        branchName: branch.name,
        branchAddress: branch.address,
        counterName: '',
        counterTransaction: '',
        typeShowPopGetTicket: '',
        isShowPopupEndCounselor: false,
    }
    componentDidMount() {
        this.getTimeNow = setInterval(() => {
            this.setState({ time: moment().format('HH:mm:ss') })
        }, 1000)
        let counterName = ''
        const { dataLogin } = this.props.counterReducer
        let counterTransaction = dataLogin.Roles.includes(TELLER)
            ? dataLogin.ServAllService
                ? 'Tất cả nghiệp vụ'
                : `Nghiệp vụ thực hiện (${(
                    '0' + dataLogin.ServiceList.length
                ).slice(-2)})`
            : 'Đăng ký STH cho cán bộ VietinBank'

        this.setState({ counterName, counterTransaction })
    }

    componentWillUnmount() {
        clearInterval(this.getTimeNow)
    }

    toggleCounterStatus = () => {
        const { counterState } = this.props.counterReducer

        if (!isStopCounter(this, this.props.counterReducer)) return

        const { dataConnectDesktop } = this.props.counterReducer

        if (counterState === counterStates.STOP_COUNTER) {
            this.props.startOrStopCounter(
                dataConnectDesktop.CounterID,
                counterStates.OPEN_COUNTER
            )
            return
        }
        this.props.startOrStopCounter(
            dataConnectDesktop.CounterID,
            counterStates.STOP_COUNTER
        )
    }

    handleClickOffCounselor = () => {
        const { counterState } = this.props.counterReducer

        const { dataConnectDesktop } = this.props.counterReducer

        if (counterState === counterStates.STOP_COUNTER) {
            this.props.startOrStopCounter(
                dataConnectDesktop.CounterID,
                counterStates.OPEN_COUNTER
            )
            return
        }

        this.setState({ isShowPopupEndCounselor: true })
    }

    handleConfirmOffCounselor = () => {
        const { counterState } = this.props.counterReducer

        const { dataConnectDesktop } = this.props.counterReducer

        this.props.startOrStopCounter(
            dataConnectDesktop.CounterID,
            counterStates.STOP_COUNTER,
            () => this.setState({ isShowPopupEndCounselor: false })
        )
    }

    render() {
        const {
            dataLogin,
            dataConnectDesktop,
            counterState,
            // listMissCalled,
            // listMoveTo
        } = this.props.counterReducer
        const wardName = getDataAdministrativeUnitsByCode(
            fieldAdministrativeUnits.ward,
            dataLogin.OfficeWard,
            'name'
        )
        const districtName = getDataAdministrativeUnitsByCode(
            fieldAdministrativeUnits.district,
            dataLogin.OfficeDistrict,
            'name'
        )
        const cityName = getDataAdministrativeUnitsByCode(
            fieldAdministrativeUnits.city,
            dataLogin.OfficeProvince,
            'name'
        )

        return (
            <Bound>
                <div className="branch">
                    <p className="branch-name">
                        {dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COUNSELOR) || dataLogin.Roles.includes(COORLOBBY)
                            ? dataLogin.OfficeName
                            : dataConnectDesktop.OfficeName ||
                            dataLogin.OfficeName ||
                            ''}
                    </p>
                    <p className="branch-address address">
                        {dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COUNSELOR) || dataLogin.Roles.includes(COORLOBBY)
                            ? [
                                dataLogin.OfficeAddress,
                                wardName,
                                districtName,
                                cityName,
                            ].join(', ')
                            : !!dataLogin.OfficeWard &&
                                !!dataLogin.OfficeDistrict &&
                                !!dataLogin.OfficeProvince
                                ? [
                                    dataLogin.OfficeAddress,
                                    wardName,
                                    districtName,
                                    cityName,
                                ].join(', ')
                                : ''}
                    </p>
                </div>
                <div className="branch">
                    <p className="branch-name">{`Quầy số ${dataConnectDesktop.CounterNum}`}</p>
                    {
                        dataLogin.Roles.includes(OFFEXSUPERVISOR) ?
                            <p className="branch-address">Kiểm soát viên</p>
                            : ((dataLogin.Roles.includes(ADVISETICKET) && dataLogin.Roles.includes(TAKETICKET)) || (dataLogin.Roles.includes(ADVISETICKET) && dataLogin.Roles.includes(MOVETICKET)) || (dataLogin.Roles.includes(TAKETICKET) && dataLogin.Roles.includes(MOVETICKET))) ?
                                <p className="branch-address">Điều phối sảnh</p>
                                : (dataLogin.Roles.includes(COUNSELOR) || dataLogin.Roles.includes(ADVISETICKET)) ?
                                    <p className="branch-address">Tư vấn viên</p>
                                    : (dataLogin.Roles.includes(COORDINATOR) || dataLogin.Roles.includes(TAKETICKET)) ?
                                        <p className="branch-address">Điều phối viên</p>
                                        : dataLogin.Roles.includes(MOVETICKET) ?
                                            <p className="branch-address">Điều phối sảnh</p>
                                            :
                                            <p className="branch-address">
                                                {this.state.counterTransaction}
                                            </p>
                    }
                </div>
                <div className="transaction-status">
                    <div className="system-status">
                        <p
                            className="active-status"
                            style={{
                                color:
                                    counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0
                                        ? '#0fb11f'
                                        : '#f31919',
                            }}>
                            {dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COORDINATOR) || dataLogin.Roles.includes(TAKETICKET) || dataLogin.Roles.includes(MOVETICKET)
                                ? counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0
                                    ? 'Đang giao dịch'
                                    : 'Ngừng phục vụ'
                                : dataLogin.Roles.includes(COUNSELOR) || (dataLogin.Roles.includes(ADVISETICKET) && !dataLogin.Roles.includes(TAKETICKET) && !dataLogin.Roles.includes(MOVETICKET))
                                    ? counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0
                                        ? 'Đang tư vấn'
                                        : 'Ngừng tư vấn'
                                    : ''}
                        </p>
                        <div className="time-wrapper">
                            <div className="time-title">
                                Thời gian hệ thống:
                            </div>
                            <div className="time">{this.state.time}</div>
                        </div>
                    </div>
                    {(dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COORDINATOR) || dataLogin.Roles.includes(TAKETICKET)) &&
                        (counterState === counterStates.OPEN_COUNTER ||
                            this.props.data.length > 0) && (
                            <div className="get-ticket">
                                <div
                                    className="btn-get-ticket"
                                    onClick={() =>
                                        this.setState({
                                            typeShowPopGetTicket: POPUP_GET_TICKET_NUMBER,
                                        })
                                    }
                                >
                                    <IcGetTicket />
                                    lấy số xếp hàng
                                </div>
                                <div
                                    className="btn-get-ticket"
                                    onClick={() =>
                                        this.setState({
                                            typeShowPopGetTicket: POPUP_GET_TICKET_NUMBER_ONLINE,
                                        })
                                    }
                                >
                                    <IcGetTicketOnline />
                                    lấy số kh online
                                </div>
                            </div>
                        )}
                    <div>
                        {(dataLogin.Roles.includes(TELLER) || dataLogin.Roles.includes(COORDINATOR) || dataLogin.Roles.includes(TAKETICKET) || dataLogin.Roles.includes(MOVETICKET)) && (
                            <React.Fragment>
                                <div
                                    className="button-active"
                                    style={{
                                        backgroundColor:
                                            counterState ===
                                                counterStates.OPEN_COUNTER ||
                                                this.props.data.length > 0
                                                ? '#f31919'
                                                : '#0fb11f',
                                    }}
                                    onClick={this.toggleCounterStatus}
                                >
                                    <div className="status">
                                        <div>
                                            {counterState ===
                                                counterStates.OPEN_COUNTER ||
                                                this.props.data.length > 0
                                                ? 'ngừng gd'
                                                : 'bắt đầu gd'}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="button-active refresh"
                                    style={{
                                        backgroundColor: 'var(--peacock-blue)',
                                    }}
                                    onClick={() => window.location.reload()}
                                >
                                    Refresh
                                </div>
                            </React.Fragment>
                        )}
                        {
                            (dataLogin.Roles.includes(COUNSELOR) || (dataLogin.Roles.includes(ADVISETICKET) && !dataLogin.Roles.includes(TAKETICKET) && !dataLogin.Roles.includes(MOVETICKET))) && (
                                <React.Fragment>
                                    <div
                                        className="button-active"
                                        style={{
                                            backgroundColor:
                                                counterState ===
                                                    counterStates.OPEN_COUNTER ||
                                                    this.props.data.length > 0
                                                    ? '#f31919'
                                                    : '#0fb11f',
                                        }}
                                        onClick={this.handleClickOffCounselor}
                                    >
                                        <div className="status">
                                            <div>
                                                {counterState ===
                                                    counterStates.OPEN_COUNTER ||
                                                    this.props.data.length > 0
                                                    ? 'ngừng tư vấn'
                                                    : 'bắt đầu tư vấn'}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="button-active refresh"
                                        style={{
                                            backgroundColor: 'var(--peacock-blue)',
                                        }}
                                        onClick={() => window.location.reload()}
                                    >
                                        Refresh
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
                {this.state.typeShowPopGetTicket ===
                    POPUP_GET_TICKET_NUMBER && (
                        <Portal>
                            <GetNumberTicket
                                onClose={() =>
                                    this.setState({ typeShowPopGetTicket: '' })
                                }
                            />
                        </Portal>
                    )}
                {this.state.typeShowPopGetTicket ===
                    POPUP_GET_TICKET_NUMBER_ONLINE && (
                        <Portal>
                            <GetNumberTicketOnline
                                onClose={() =>
                                    this.setState({ typeShowPopGetTicket: '' })
                                }
                            />
                        </Portal>
                    )}
                {this.state.isShowPopupEndCounselor && (
                    <Portal>
                        <PopupOffCounselor
                            onClose={() =>
                                this.setState({
                                    isShowPopupEndCounselor: false,
                                })
                            }
                            onNext={this.handleConfirmOffCounselor}
                        />
                    </Portal>
                )}
            </Bound>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.counterReducer !== this.props.counterReducer) {
            if (
                this.props.counterReducer.err[
                typesAction.CHANGE_STATE_COUNTER_ERR
                ] === 5
            ) {
                let err = buildErrorContent(
                    typesAction.CHANGE_STATE_COUNTER_ERR,
                    this.props.counterReducer.err[
                    typesAction.CHANGE_STATE_COUNTER_ERR
                    ]
                )
                this.props.openPopup(9, 1, { icon: ic_cancel, label: err })
                this.props.clearError(typesAction.CHANGE_STATE_COUNTER_ERR)
                return
            }
        }
    }
}
const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
})

const mapDispatchToProps = (dispatch) => ({
    startOrStopCounter: (counterId, stateCounter, callbackSuccess) =>
        dispatch(startOrStopCounter(counterId, stateCounter, callbackSuccess)),
    getCurrentTickets: (counterId, officeId) =>
        dispatch(getCurrentTickets(counterId, officeId)),
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    clearError: (typeErr) => dispatch(clearError(typeErr)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
