import React from 'react'

export default function IcNoSelect(props) {
    return (
        <svg
            {...props}
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width={16}
                height={16}
                rx="3.5"
                fill="#F7F7F7"
            />
            <rect
                x="0.5"
                y="0.5"
                width={16}
                height={16}
                rx="3.5"
                stroke="#E6E6E6"
            />
        </svg>
    )
}
