import moment from 'moment'
import * as types from '../actions/types'

export const initialState = {
    isShowContentCounselorPage: false,
    listCounselorSuccess: [],
    dataTicketCounselor: {},
    detailsCurrentAdvise: {},
    isEndAdviseTeller: [],
    isCancelPopup: false,
    isShowPopupCounselor: false,
    detailsProcessingAdvise: {},
    isTellerCompleteTicker: false,
    isShowPopupRequireCompleteAdvise: false,
}

export default function counselor(state = initialState, action) {
    switch (action.type) {
        case types.COMPLETE_TICKET:
            return {
                ...initialState,
                isTellerCompleteTicker: true,
                isEndAdviseTeller: state.isEndAdviseTeller,
                dataTicketCounselor: state.dataTicketCounselor
            }
        case types.GET_PROCESSING_ADVISE:
            let { detailsProcessingAdvise } = action
            return {
                ...state,
                detailsProcessingAdvise: detailsProcessingAdvise,
            }
        case types.SET_IS_SHOW_POPUP_COUNSELOR:
            let { isShowPopup } = action
            return {
                ...state,
                isShowPopupCounselor: isShowPopup,
            }
        case types.SET_IS_SHOW_POPUP_REQUIRE_COMPLETE_ADVISE:
            let { isShowPopupRequire } = action
            return {
                ...state,
                isShowPopupRequireCompleteAdvise: isShowPopupRequire,
            }
        case types.START_ADVISE:
            let { ticketIDStart } = action
            return {
                ...state,
                isEndAdviseTeller: [
                    ...state.isEndAdviseTeller,
                    {
                        ticketId: ticketIDStart,
                        isEnd: false,
                        startAdvise: moment().format(),
                        endAdvise: ''
                    },
                ],
            }
        case types.END_ADVISE:
            let { ticketID } = action
            let findTask = state.isEndAdviseTeller?.filter(task => task.ticketId === ticketID)?.[0]
            let newFindTask = { ...findTask, isEnd: true, endAdvise: moment().format() }
            let newListEndAdviseTeller = state.isEndAdviseTeller?.filter(
                (item) => item.ticketId !== ticketID
            )
            return {
                ...state,
                isEndAdviseTeller: [
                    ...newListEndAdviseTeller,
                    newFindTask
                ],
            }
        case types.CANCEL_POPUP_COUNSELOR:
            return {
                ...state,
                isCancelPopup: true,
            }
        case types.CLEAR_DATA_COUNSELOR:
            return {
                ...state,
                isShowContentCounselorPage: false,
                dataTicketCounselor: {},
                detailsCurrentAdvise: {},
                isCancelPopup: false,
                isShowPopupCounselor: false,
                detailsProcessingAdvise: {},
                isTellerCompleteTicker: false,
            }
        case types.GET_CURRENT_ADVISE:
            let { detailsCurrentAdvise } = action
            return {
                ...state,
                detailsCurrentAdvise: detailsCurrentAdvise,
            }
        case types.SET_DATA_TICKET_COUNSELOR:
            let { dataTicketCounselor } = action
            return {
                ...state,
                dataTicketCounselor: dataTicketCounselor,
            }
        case types.SET_SHOW_COUNSELOR_PAGE:
            let { isShow } = action
            return {
                ...state,
                isShowContentCounselorPage: isShow,
            }
        case types.SET_LIST_COUNSELOR_SUCCESS:
            let { ticketCode } = action
            return {
                ...state,
                listCounselorSuccess: [
                    ...state.listCounselorSuccess,
                    ticketCode,
                ],
            }
        default:
            return state
    }
}
