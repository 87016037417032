import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';


// import image
import icClose from "../../../images/ic_close_gray.svg";
import ic_biometric_register from '../../../images/ic_biometric_register.svg';
import ic_biometric_delete from '../../../images/ic_biometric_delete.svg';
import on_form from '../../../images/on_form.svg';
import fingerprint_err from '../../../images/fingerprint_err.svg';
import active_face from '../../../images/active_face.svg';
import hang_finger from '../../../images/hang_finger.svg';
import ic_closed_finger from '../../../images/ic_closed_finger.svg';

// import { apiGetBioFile, apiFingerHistory } from '../../../../actions/supervisorAction/biometricAction';
import { apiGetBioFile, apiGetBioFingerImage } from '../../../actions/biometricAction';
import { apiGetBioFileCollector, apiGetBioFingerImageCollector } from '../../../actions/biometricWithInBioCollectorAction';
import { FACE, REGISTER, DELETE, BioInfosType, customType, ACTIVE, ISACTIVE, HANG } from '../../../data/infosType';
import { TELLER } from '../../../data/userRole';
import PopupNote from './PopupNote';

// helper
import getFromChannel from '../../../helper/getFromChannel';
import getChannelName from '../../../helper/getChannelName';

const StyledPopupShowFingerInfoCustomer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: ${props => props.isRegister ? "383px" : "272px"}; */
  /* height: 248px; */
  background: #ffffff;
  padding: 16px 20px;
  z-index: 999;
  box-shadow: 0px 0px 10px 0px #0000008a;
  .popup-title {
    font-family: "SVN-Gilroy";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    color: #000000;
    padding-top: 15px;
  }

  .channel {
    margin-top: 5px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
    color: #717171;
  }

  & > img {
    position: absolute;
    top: 10px;
    right: 10px;

    :hover {
      cursor: pointer;
    }
  }

  .popup-finger-detail {
    display: flex;
    align-items: center;
    margin-top: 11px;
    font-family: "SVN-Gilroy";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    position: relative;
    display: ${props => props.matchData && 'none'};
    img {
      width: 18px;
      height: 18px;
      margin-right: 6px;
      &:last-child{
        margin-right: ${props => (props.ic_zoom === BioInfosType[DELETE].dlFinger) ? 0 : 'none'};
        position: ${props => (props.ic_zoom === BioInfosType[DELETE].dlFinger) ? 'absolute' : 'none'};;
        right: -10.42px;
        cursor: pointer;
      }
    }
    .img_hover{
      width: 16px;
      height: 16px;
      position: absolute;
      right: 1px;
      cursor: pointer;
      &:hover {
        p{
          display: block;
          visibility: visible;
          opacity: 1;
          right: 16px;
          top: 19px;
        }
      }
      p{
        visibility: hidden;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        width: 60px;
        height: 20px;
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-left: -60px;
        /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
        opacity: 0;
        transition: opacity 1s;
      }
    }
  }

  .popup-main {
    display: flex;
    justify-content: space-between;
    margin-top: 19px;

    & h3 {
      margin-bottom: 7px;
      font-family: "SVN-Gilroy";
      font-size: 11px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.26;
      color: var(--peacock-blue);
      text-transform: uppercase;
    }

    .content_bottom{
      /* padding: 11px 21.42px 17px 16px; */
      /* display: flex;
      justify-content: space-between; */
      margin-top: 8px;
      p{
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #979797;
      }
      .content_confirmFinger{
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        h3{
          font-family: "SVN-Gilroy";
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 130%;
          text-transform: none;
        }
      }
    }
    /*& img {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      border: solid 0.5px var(--pinkish-grey);
      object-fit: cover;
      padding: 10px;
    }*/

    &-registration{
      .image-registration{
        display: flex;
        justify-content: space-evenly;
        .c_image {
          position: relative;
          width: calc(84px + 1px + 9.5px*2);
          height: calc(84px + 1px + 9.5px*2);
          border-radius: 5px;
          border: solid 0.5px var(--pinkish-grey);
          object-fit: cover;
          padding: 9.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .c_image:not(:first-child) {
          margin-left: 8px;
        }
        img{
          width: ${props => props.imageLoadError ? '84px  ' : '45px'};
          height: ${props => props.imageLoadError ? '84px ' : '40px'};
        }
        p.text-error-finger{
          font-family: "SVN-Gilroy";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 130%;
          color: #979797;
          text-align: center;
          margin-top: 13px;
        }
      }
      /* img:not(:first-child) {
          margin-left: 8px;
      } */
    }

    &-authentication{
      margin-left: 35px;
    }
  }

  .popup-footer {
    font-family: "SVN-Gilroy";
    margin: 8px auto;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.3;
    color: #979797;
  }
  .block_radio{
    display: flex;
    justify-content:start;
    align-items: center;
    margin: 20px 0 13px 0;
    &:last-child{
      margin: 0;
    }
    label{
      .text_label{
        opacity: 0.25;
      }
    }
    .radio-field {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-family: "SVN-Gilroy";
      font-size: 14px;
      line-height: 128%;
      color: #222222;
      ${'' /* opacity: 0.25; */}
      input[type="radio"]{
        appearance: none;
        outline: none;
        width: 16px;
        height: 16px;
        position:relative;
        background: #fff;
        border-radius: 50%;
        border: 0.75px solid #D6D6D6;
        margin-right: 5px;
        border-radius: 8px;

        ::before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #fff;
          display: none;
        }

        &:checked::before {
          background: #D71249;
          border-radius: 8px;
          opacity: 0.25;
          display: block;
        }
      }
    }
  }
`;

class PopupShowFingerInfoCustomer extends Component {
  state = {
    typeCloseActive: this.props.data && this.props.data.IsConfirmFingerCurrent,
    typeHang: this.props.data && this.props.data.IsActiveCurrent,
    isShowPopupNoteLv2: false,
    imageLoadError: true
  }

  onshowPopupNote = () => {
    // this.props.onshowPopupNote()
    this.setState({
      isShowPopupNoteLv2: true
    });
  }

  onClose = () => {
    if (this.props.type === 'fingerHistory') {
      this.props.onclose()
    } else {
      this.props.onClosePopup()
    }
  }
  onClosePopupNoteLv2 = () => {
    this.setState({
      isShowPopupNoteLv2: false
    })
  }

  addDefaultSrc(e) {
    if (this.state.imageLoadError) {
      this.setState({
        imageLoadError: false
      })
    }
    e.target.src = fingerprint_err
  }

  render() {
    const { data } = this.props
    const { typeCloseActive, typeHang } = this.state
    let ic_biometric, colorBio, contentBio, elmConfirmFinger, clConfirmFinger

    // switch (data && data.BioActionType) {
    //   case REGISTER:
    //     ic_biometric = ic_biometric_register
    //     colorBio = '#222222'
    //     contentBio = BioInfosType[REGISTER].content
    //     break;
    //   case UPDATE:
    //     ic_biometric = ic_biometric_update
    //     colorBio = '#1477EC'
    //     contentBio = BioInfosType[UPDATE].content
    //     break
    //   case DELETE:
    //     ic_biometric = ic_biometric_delete
    //     colorBio = '#F31919'
    //     contentBio = BioInfosType[DELETE].content
    //     break
    //   default:
    //     ic_biometric = ic_biometric_register
    //     colorBio = '#222222'
    //     contentBio = BioInfosType[REGISTER].content
    //     break;
    // }

    switch (data && data.BioActionFinger) {
      case BioInfosType[REGISTER].content:
        ic_biometric = ic_biometric_register
        colorBio = '#222222'
        contentBio = BioInfosType[REGISTER].content
        break;
      case customType[ACTIVE].vlFinger:
        ic_biometric = active_face
        colorBio = customType[ACTIVE].color
        contentBio = customType[ACTIVE].vlFinger
        break
      case customType[ISACTIVE].vlFinger:
        ic_biometric = ic_closed_finger
        colorBio = customType[ISACTIVE].color
        contentBio = customType[ISACTIVE].vlFinger
        break
      case BioInfosType[DELETE].dlFinger:
        ic_biometric = ic_biometric_delete
        colorBio = BioInfosType[DELETE].color
        contentBio = BioInfosType[DELETE].dlFinger
        break
      default:
        ic_biometric = hang_finger
        colorBio = customType[HANG].color
        contentBio = customType[HANG].vlFinger
        break;
    }

    let index = 0
    if (typeHang) {
      // debugger
      if (typeCloseActive) {
        // debugger
        elmConfirmFinger = customType[ACTIVE].label
        clConfirmFinger = customType[ACTIVE].color
      } else {
        // debugger
        elmConfirmFinger = customType[ISACTIVE].label
        clConfirmFinger = customType[ISACTIVE].color
      }
    } else {
      index = 1
      if (typeCloseActive === false) {
        if (index === 1) {
          elmConfirmFinger = customType[ISACTIVE].label
          clConfirmFinger = customType[ISACTIVE].clHang
        }
      } else {
        elmConfirmFinger = customType[HANG].label
        clConfirmFinger = customType[HANG].clHang
      }
    }
    const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
    const apiGetFinger = isTeller ? apiGetBioFingerImage : apiGetBioFingerImageCollector
    const apiGetFile = isTeller ? apiGetBioFile : apiGetBioFileCollector
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 99999
        }}>
        <StyledPopupShowFingerInfoCustomer matchData={data.MatchData} imageLoadError={this.state.imageLoadError} ic_zoom={data.BioActionFinger} removeNote={data.RemoveNote}>
          <div className="popup-title">
            {
              data && data.BioType !== FACE && data.BioType
            }
          </div>
          <div className="channel">Kênh tác nghiệp: {getChannelName(getFromChannel(data.FromChannel))}</div>
          <img src={icClose} alt="close" onClick={this.onClose} />
          <div className="popup-finger-detail" style={{ color: colorBio }}>
            <img src={ic_biometric} alt="biometric" />
            {contentBio}
            {
              data.BioActionFinger === BioInfosType[DELETE].dlFinger &&
              <div className='img_hover'>
                <img src={on_form} alt='on_form' onClick={this.onshowPopupNote} className='on_form' />
                <p>Lý do</p>
              </div>
            }
          </div>
          <div className="popup-main">
            <div className="popup-main-registration">
              {
                (this.props.data.IsTextFingerHistory !== 'FingerHistory' && data.EnrollData !== null) && <h3>Dấu vân tay</h3>
              }
              <div className="image-registration">
                {
                  data && data.EnrollData && data.EnrollData.map((img, index) =>
                    <div className='c_image' key={index}>
                      <img src={data.IsTextFingerHistory === 'FingerHistory' ? apiGetFinger(img) : apiGetFile(img)} alt="finger" key={index}
                        onError={(e) => this.addDefaultSrc(e)}
                      />
                      {
                        !this.state.imageLoadError && <p className='text-error-finger'>Dữ liệu không tồn tại</p>
                      }
                    </div>
                  )
                }
              </div>
              {
                //((data.BioActionType !== REGISTER && data.BioActionType !== undefined) && this.props.data.IsTextFingerHistory !== 'FingerHistory') ?
                this.props.data.IsTextFingerHistory !== 'FingerHistory' ?
                  <div className='content_bottom'>
                    <p>{(data.BioActionType === REGISTER && data.BioActionType !== undefined) || (data.MatchData !== undefined && data.MatchData.matchData) ? 'Trạng thái vân tay' : 'Trạng thái vân tay trước cập nhật'}</p>
                    <div className='content_confirmFinger'>
                      <h3 style={{ color: clConfirmFinger }}>{elmConfirmFinger}</h3>
                      {/* <img src = {on_form} alt = 'on_form' onClick = {this.onshowPopupNote}/> */}
                    </div>
                  </div> : null
              }
            </div>
            {/* {
              data.BioActionType === REGISTER ?
                <div className="popup-main-authentication">
                  <h3>Xác thực</h3>
                  <img src={apiGetBioFile(data.ConfirmData)} alt="auth" />
                </div>
              : null
            } */}
          </div>
          {/* <div className="popup-footer">
            {`Cập nhật gần nhất: ${formatDateTimeToSpecificFormat(data && data.CreateAt, formatDateTime.HHmmDDMMYY)}`}
          </div> */}
          {/* <div className="block_radio">
            <label className="radio-field">
              <input 
                id="type-search"
                type="radio" 
                name="type-search" 
                value= {true}
                checked={this.state.typeSearch === true}
                onChange={() => this.setState({typeSearch: true})}
                disabled ={true}
              />
              <div className='text_label'>Hoạt động</div>
            </label>
            <label className="radio-field">
              <input 
                id="type-search"
                type="radio" 
                name="type-search" 
                value= {false}
                checked={this.state.typeSearch === false}
                onChange={() => this.setState({typeSearch: false})}
                disabled = {true}
              />
              <div className='text_label'>Đóng</div>
            </label>
          </div>
          <div className="block_radio">
            <label className="radio-field">
              <input 
                id="type-face"
                type="radio" 
                name="type-face" 
                value= {false}
                // checked={this.state.typeFace === false}
                checked={this.state.typeSearch === false ? this.state.typeFace === true : this.state.typeFace === false}
                onChange={() => this.setState({typeFace: false})}
                disabled ={true}
              />
              <div className='text_label'>Treo hình ảnh vân tay</div>
            </label>
          </div> */}
        </StyledPopupShowFingerInfoCustomer>
        {
          this.state.isShowPopupNoteLv2 &&
          <PopupNote
            data={this.props.data}
            title='Nội dung xóa'
            placeholder='Nội dung xóa'
            onClosePopupNoteLv2={this.onClosePopupNoteLv2}
            type='noteBiometricInfo'
          />
        }
      </div>
    )
  }
}

/**
 * props = {
 *  onClosePopup*: function,
 *  fingerDetail*: string, //type of finger - type of hand
 *  imgRegister*: array(string), // array of link finger image registration
 *  imgAuthenticate: string, //link finger image authentication
 *  lastUpdate*: string, //datetime last update
 * }
 */

export default connect(state => ({ counterReducer: state.counterReducer }))(PopupShowFingerInfoCustomer);
