import Axios from 'axios'
import * as types from './types'
import { host } from '../host'
import { addLoading, removeLoading } from './commonAction'
import * as typeCodeResponses from './typeCodeResponses'
import catchErrAction from '../helper/catchErrAction'

const apiSendStartAdvise = host + 'counter/advise-start'
const apiSendEndAdvise = host + 'counter/advise-end'
const apiGetCurrentAdvise = host + 'counter/advise-review'
const apiCancelAppoimentAdvise = host + 'counter/appointment-online/cancel'
const apiGetProcessingAdvise = host + 'counter/get-current-advise'

export const setIsShowPopupRequireCompleteAdvise = (isShowPopupRequire) => ({
    type: types.SET_IS_SHOW_POPUP_REQUIRE_COMPLETE_ADVISE,
    isShowPopupRequire,
})

export const clearDataCounselor = () => ({
    type: types.CLEAR_DATA_COUNSELOR,
})

export const setIsShowPopupCounselor = (isShowPopup) => ({
    type: types.SET_IS_SHOW_POPUP_COUNSELOR,
    isShowPopup,
})

export const cancelPopupCounselor = () => ({
    type: types.CANCEL_POPUP_COUNSELOR,
})

export const setShowContentCounselorPage = (isShow) => ({
    type: types.SET_SHOW_COUNSELOR_PAGE,
    isShow,
})

export const setListCounselorSuccess = (ticketCode) => ({
    type: types.SET_LIST_COUNSELOR_SUCCESS,
    ticketCode,
})

export const setDataTicketCounselor = (dataTicketCounselor) => ({
    type: types.SET_DATA_TICKET_COUNSELOR,
    dataTicketCounselor,
})

export const sendStartAdvise = (
    officeId,
    counterId,
    ticketID,
    numberTicket,
    from,
    callbackSuccess
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        OfficeID: officeId,
        CounterID: counterId,
        TicketID: ticketID,
        Number: numberTicket,
        From: from,
    })

    try {
        const res = await Axios.post(apiSendStartAdvise, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.START_ADVISE,
                ticketIDStart: ticketID
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.START_ADVISE_ERR,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.START_ADVISE_ERR)
    }
}

export const sendEndAdvise = (
    officeId,
    counterId,
    ticketID,
    numberTicket,
    listService,
    adviseResult,
    from,
    callbackSuccess
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        OfficeID: officeId,
        CounterID: counterId,
        TicketID: ticketID,
        Number: numberTicket,
        ListService: listService,
        AdviseResult: adviseResult,
        From: from,
    })

    try {
        const res = await Axios.post(apiSendEndAdvise, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.END_ADVISE,
                ticketID
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.END_ADVISE_ERR,
                err: data
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.END_ADVISE_ERR)
    }
}

export const getCurrentAdvise = (
    officeId,
    counterId,
    ticketID,
    from,
    callbackSuccess
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        OfficeID: officeId,
        CounterID: counterId,
        TicketID: ticketID,
        From: from,
    })

    try {
        const res = await Axios.post(apiGetCurrentAdvise, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_CURRENT_ADVISE,
                detailsCurrentAdvise: data.Result || {},
            })
            callbackSuccess && callbackSuccess()
        } else {
            dispatch({
                type: types.GET_CURRENT_ADVISE_ERR,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_CURRENT_ADVISE_ERR)
    }
}

export const cancelAppointment = (officeId, counterId, appoitmentID) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        AtOfficeID: officeId,
        CounterID: counterId,
        AppoitmentID: appoitmentID,
    })

    try {
        const res = await Axios.post(apiCancelAppoimentAdvise, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.CANCEL_APPOINMENT_ONLINE,
            })
        } else {
            dispatch({
                type: types.CANCEL_APPOINMENT_ONLINE_ERR,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.CANCEL_APPOINMENT_ONLINE_ERR)
    }
}
//lấy thông tin case đang tư vấn dở khi reload nếu có
export const getProcessingAdvise = (officeId, counterId) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        OfficeID: officeId,
        CounterID: counterId,
    })

    try {
        const res = await Axios.post(apiGetProcessingAdvise, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_PROCESSING_ADVISE,
                detailsProcessingAdvise: data.Result || {}
            })
        } else {
            dispatch({
                type: types.GET_PROCESSING_ADVISE_ERR,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_PROCESSING_ADVISE_ERR)
    }
}
