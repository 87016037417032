import React from 'react'
//css
import { WrapPopupEndService } from './index.style'
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
import ButtonControl from '../../../controls/ButtonControl'


const PopupEndService = ({ handleContinue, handleEnd }) => {
    return (
        <OverlayFullScreen>
            <WrapPopupEndService>
                <div className='wrap-content'>
                    {/* <IcQuestionBlue /> */}
                    <div className='title'>Xác nhận kết thúc dịch vụ “Xuất số xếp hàng”</div>
                    <div className='desc'>(*) Thông tin & Kết quả đăng ký sẽ không được lưu lại</div>
                </div>
                <div className='wrap-btn'>
                    <ButtonControl
                        text='Tiếp tục'
                        className='btn-continue'
                        onClick={handleContinue}
                    />
                    <ButtonControl
                        text='Kết thúc'
                        className='btn-end'
                        onClick={handleEnd}
                    />
                </div>

            </WrapPopupEndService>
        </OverlayFullScreen>
    )
}

export default PopupEndService