import styled from "styled-components";

const InputWrapper = styled.div`
    margin-bottom: 20px;
    height: ${props => props.heightInput || "60px"};
    width: ${props => props.widthInput || "348px"};
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield
    }
    &.err-wrap-input-login{
        margin-bottom: 40px;
    }
    &.diabled{
        .title-input{
            color: #C4C4C4;
        }
        .wrap-input{
            input{
                pointer-events: none;
                background: #E6E6E6;
                color: #C4C4C4;
            }
        }
    }
    .title-input{
        color: var(--Gray, #636060);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.28px;
        margin-bottom: 4px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;
        .ic-checkbox{
            cursor: pointer;
        }
        .title{
            padding-top: 1px;
            .title-notice{
                color: #F31919;
                margin-left: 3px;
            }
        }
    }
    
    .wrap-input{
        height: 40px;
        position: relative;
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        /* input[type=number] {
            -moz-appearance: textfield;
        } */
        input{
            font-family: 'SVN-Gilroy';
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            height: 100%;
            width: 100%;
            padding: 11px 12px;
            box-sizing: border-box;
            border-radius: 8px;
            background: #F6F7F9;
            color: #191919;;
            outline: none;
            border: 1px solid #FFFFFF;
            
            &:disabled {
                background: #6C6F75;
                color: #CBCBCB;
            }
            &:focus{
                outline: none;
                border: 1px solid #CBCBCB;
            }
            &::placeholder{
                color: #C4C4C4;
            }
        }
        & > .error-input{
            border: 1px solid #F50100 !important;
        }
    }
    .error{
        color: #F50100;
        font-family: 'SVN-Gilroy';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 123.077% */
        letter-spacing: -0.13px;
        padding: 4px 0 0 0px;
        float: left;
    }
`

export { InputWrapper };