import * as types from "./types"


/**
 * [description]
 * @param  {
 * {
 * type: typeNotice.passTicket, //typeNotice from "../data/data"
 * name: string, //name of teller move ticket
 * counterNum: number, //number of counter move ticket
 * content: string, //note for reason move ticket
 * time: Datetime, //time when ticket was moved in
 * } \| {
 * type: typeNotice.beyondTime, //typeNotice from "../data/data"
 * ticketNum: number //number of ticket was moved in
 * time: Datetime, //time when ticket was moved in
 * }
 * } data [description]
 * @return {[type]}      [description]
 */
export const addNewNotify = data => dispatch => {
	dispatch({
		type: types.ADD_NEW_NOTIFY,
		data
	})
}

export const setStatusSeen = () => dispatch => {
	dispatch({
		type: types.SET_STATUS_SEEN
	})
}