import React from 'react'
import { connect } from 'react-redux'


// style
import StyleFaceUpdateFlow from "./StyleFaceUpdateFlow"

// image
import icClose from "../../../../images/ic_close_gray.svg";
import imgUserGrayBg from '../../../../images/img_user_gray_bg.svg'
// import imgUserGrayBg from '../../../../images/img_user_gray_bg_3_4.svg'
import icRefresh from "../../../../images/Icons_Dashboard_Refresh.svg";


// component
import LoadingGetImage from './LoadingGetImage';
import ButtonConfirmControl from '../../../Control/ButtonConfirmControl';

// data
import { FACE } from "../../../../data/bioTypes";
import { REGISTER, CONFIRM } from "../../../../actions/typeRequestBio";
import { popupBioTypes } from "../../../../data/PopupTypes";
import typesKeyDetailPopup from '../../../../data/typesKeyDetailPopup';
import { TELLER } from '../../../../data/userRole';

// action
import { 
	apiGetBioFile,
	requestGetBioData,
	requestRemoveBioData,
	requestConfirmBioData,
	removeStatusWaitingBioData,
	editStatusFingerInRegisterThread
} from "../../../../actions/biometricAction";
import {
	requestUpDateBio
} from "../../../../actions/counterAction";
import { openPopup } from "../../../../actions/popupBioAction";
import { 
	requestUpDateBioCollector, requestGetBioDataCollector, removeStatusWaitingBioDataCollector, apiGetBioFileCollector, requestConfirmBioDataCollector, removeBioDataCollector 
} from '../../../../actions/biometricWithInBioCollectorAction';

// helper
import isDisableEventClick from '../../../../helper/isDisableEventWhenHaveSupervisor';
import { openPopupDeleteBioRegistered } from '../../../../helper/actionOpenPopup';
import isErrorWhenGetEnroll from '../../../../helper/isErrorWhenGetEnroll';
import { clearError } from '../../../../actions/commonAction';
import LoadingControl from '../../../Control/LoadingControl';
 

class FaceUpdateFlow extends React.Component {
	state = {
		popupContent: null,
		popupFooter: null,
		closingGetBio: ''
	}

	handleChangeBioFace = () => {
		const { ticketId } = this.props;
		const enrollId = this.props.counterReducer.enrollId[ticketId];
		const bioId = this.props.biometricReducer[ticketId][FACE][REGISTER];
		const userIsTeller = 
			this.props.counterReducer.dataLogin.Roles.includes(TELLER);

		userIsTeller && this.props.requestRemoveBioData(
			enrollId,
			FACE,
			bioId,
			ticketId
		);

		!userIsTeller && this.props.removeBioDataCollector(
			enrollId,
			FACE,
			bioId,
			ticketId
		)
		this.handleRegisterBioFace();
	}

	handleConfirmBioFace = () => {
		const { ticketId, counterReducer } = this.props;
		const enrollId = this.props.counterReducer.enrollId[ticketId];
		const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);

		userIsTeller && this.props.requestConfirmBioData(
			enrollId,
			FACE,
			ticketId
		)

		!userIsTeller && this.props.requestConfirmBioDataCollector(
			enrollId,
			FACE,
			ticketId
		)
	}

	handleRegisterBioFace = async () => {
		const { ticketId } = this.props;
		const enrollId = this.props.counterReducer.enrollId[ticketId];
		const userIsTeller = 
			this.props.counterReducer.dataLogin.Roles.includes(TELLER);
		if(!enrollId){
			const currentTicket = 
				this.props.counterReducer.listCurrentTicket.find(item => 
					item.TicketID === ticketId
				)
			if(!currentTicket) return;
			if(!currentTicket.CustomerInfos) return;
			
			userIsTeller && 
				await this.props.requestUpDateBio(
					ticketId,
					currentTicket.CustomerInfos.CustomerNumber
				)

			!userIsTeller && 
				await this.props.requestUpDateBioCollector(
					ticketId,
					currentTicket.CustomerInfos.CustomerNumber
				)
			
			const isError = isErrorWhenGetEnroll(
                this.props.counterReducer,
                this.props.openPopup,
                this.props.clearError
            )
            if (isError) return
		}
		const dataRequest = {
			CounterID: this.props.counterReducer.dataConnectDesktop.CounterID,
			TicketID: ticketId,
			EnrollID: this.props.counterReducer.enrollId[ticketId],
			BioType: FACE
		}
		userIsTeller && 
			this.props.requestGetBioData(dataRequest)
		!userIsTeller && 
			await this.props.requestGetBioDataCollector(
				{
					CounterID: this.props.counterReducer.dataConnectDesktop.CounterID,
					EnrollID: this.props.counterReducer.enrollId[ticketId],
					BioType: FACE
				},
				ticketId
			);
	}

	handleRenderContentAndFooter = async () => {
		const { 
			ticketId,
			biometricReducer,
			counterReducer
		} = this.props;
		const biometricOfTicket = 
			this.biometricOfTicket = 
				biometricReducer[ticketId];
		const faceOfTicket = 
			this.faceOfTicket = 
				!!biometricReducer[ticketId] && 
					biometricReducer[ticketId][FACE];
		if(!faceOfTicket) return;
		// debugger;
		const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);
		// 	IF this.isTicketWaitingFace AND current face have confirm THEN:
		// 		show popup success lv3 
		// 		show popup face, btn "Xác nhận" and popup dulicate face in one frame in lv2
		//		RETURN
		//	ELSE IF current face have confirm THEN:
		// 		show popup face, btn "Xác nhận" and popup dulicate face in one frame in lv2 
		//		RETURN
		//	ELSE:
		//		nothing
		const faceOfTicketwasConfirmed = !!faceOfTicket &&
			Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM);
		const faceOfTicketwasConfirmedFailed = faceOfTicketwasConfirmed &&
			biometricReducer[ticketId][FACE][CONFIRM] !== 0;
		const faceOfTicketwasConfirmedSuccess = faceOfTicketwasConfirmed &&
			biometricReducer[ticketId][FACE][CONFIRM] === 0;
		
		switch(true){
			case this.isTicketWaitingFace && faceOfTicketwasConfirmedSuccess: {
				// const POPUP_LEVEL_3 = 3;
				const POPUP_LEVEL_2 = 2;
				this.props.openPopup(
					popupBioTypes.PopupNotify,
					POPUP_LEVEL_2,
					{
						key: typesKeyDetailPopup.updateFaceSuccess,
						ticketId,
						titlePopup: "Cập nhật khuôn mặt",
						detailNotify: "Cập nhật khuôn mặt thành công", 
						isSuccess: true
					}
				)
				// this.props.editStatusFingerInRegisterThread(ticketId, FACE, true)
				// this.props.openPopup(
				// 	popupBioTypes.PopupMixResultConfirmFace,
				// 	POPUP_LEVEL_2,
				// 	{ticketId}
				// )
				return;
			}
			case faceOfTicketwasConfirmedSuccess: {
				const POPUP_LEVEL_2 = 2;
				this.props.openPopup(
					popupBioTypes.PopupMixResultConfirmFace,
					POPUP_LEVEL_2,
					{ticketId}
				)
				return;
			}
			default:
				break;
		}



		const isTicketWaitingFace = 
			this.isTicketWaitingFace = 
				!!biometricOfTicket.waitingConfirm && 
					biometricOfTicket.waitingConfirm.typeBio === FACE;
		

		switch(true){
			case faceOfTicketwasConfirmedFailed: 
			{	
				this.setState({ closingGetBio: '' })
				// show popup alert confirm error lv3
				const POPUP_LEVEL_3 = 3;
				this.props.openPopup(
					popupBioTypes.PopupNotify,
					POPUP_LEVEL_3,
					{
						titlePopup: "Cập nhật khuôn mặt",
						detailNotify: "Khuôn mặt xác thực không trùng khớp. Bạn vui lòng thử lại.", 
						titleBtn: "Thử lại"
					}
				)

				// render change
				const linkGetFace = userIsTeller?apiGetBioFile:apiGetBioFileCollector;
				const popupContent = <img src={linkGetFace(faceOfTicket[REGISTER])} alt="face-img" />;
				const popupFooter = <ButtonConfirmControl 
					onSubmit={this.handleChangeBioFace}
					bg_color="var(--peacock-blue)"
					titleConfirm="Thay đổi"
				/>
				this.setState({
					popupContent,
					popupFooter
				})
				break;
			}
			case isTicketWaitingFace:
			{
				await this.setState({ isVisibleFinishBtn: false });
				
				
				const popupContent = (
					<div className="loading">
                        <LoadingGetImage />
                    </div>
                )
				const popupFooter = this.state.closingGetBio?
					<div className="closing-get-bio">{this.state.closingGetBio}</div>
					:
					<div className="btn-finish-waiting"></div>
				this.setState({
					popupContent,
					popupFooter
				}, () => {
					setTimeout(() => {
						this.setState({ isVisibleFinishBtn: true })
					}, 1500)
				})
				break;
			}
			case !!faceOfTicket[REGISTER]:
			{
				this.setState({ closingGetBio: '' })
				const linkGetFace = userIsTeller?apiGetBioFile:apiGetBioFileCollector;
				const popupContent = <img src={linkGetFace(faceOfTicket[REGISTER])} alt="face-img" />;
				const popupFooter = (
					<div className="popup__footer__confirm">
						<div className="btn" onClick={this.clearDataFace}>
							<img 
								src={icRefresh} 
								alt="refresh"
							/> 
							<div className="btn__title re-get">Lấy lại</div>
						</div>
						<ButtonConfirmControl 
							onSubmit={this.handleConfirmBioFace}
							bg_color="var(--peacock-blue)"
							titleConfirm="Xác thực"
						/>
					</div>
				)
				this.setState({
					popupContent,
					popupFooter
				})
				break;
			}
			case !faceOfTicket[REGISTER]:
			{
				this.setState({ closingGetBio: '' })
				const popupContent = <img src={imgUserGrayBg} alt="user-default" />;
				const popupFooter = <ButtonConfirmControl 
					onSubmit={this.handleRegisterBioFace}
					bg_color="var(--peacock-blue)"
					titleConfirm="Đăng ký"
				/>;
				this.setState({
					popupContent,
					popupFooter
				})
				break;
			}
			default:
				break;
		}
	}

	handleClosePopup = () => {
		const { counterReducer } = this.props;

		if(this.isTicketWaitingFace){
			const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);
			userIsTeller && this.props.removeStatusWaitingBioData(this.props.ticketId);
			!userIsTeller && this.props.removeStatusWaitingBioDataCollector(this.props.ticketId);
		}
		this.props.closePopup();
	}

	clearDataFace = () => {
		const { ticketId, biometricReducer, counterReducer } = this.props;
	
		if(isDisableEventClick(ticketId, counterReducer, this)) return;
		
		// const ticketId = ticketId;
		const faceId = biometricReducer[ticketId][FACE][REGISTER];
		const enrollId = counterReducer.enrollId[ticketId];
		// this.props.requestRemoveBioData(enrollId, bioType, bioId, ticketId)
		// const isRecall = false;
		// this.props.SubmitRemoveFaceID();
		const data = {
			ticketId,
			faceId,
			enrollId,
			bioType: FACE
		}
		openPopupDeleteBioRegistered(this, data, "clearDataFace - FaceUpdateFlow.js")
		// debugger;
	}

	render() {
		return (
			<StyleFaceUpdateFlow>
				<div className="popup__header">
					<div className="title">Cập nhật khuôn mặt</div>
					<img 
						src={icClose} 
						alt="close-popup"
						onClick={this.handleClosePopup}
					/>
				</div>
				<div className="popup__content">
					{this.state.popupContent}
				</div>
				<div className="popup__footer">
					{this.state.popupFooter}
				</div>
			</StyleFaceUpdateFlow>
		)
	}

	componentDidUpdate(prevProps, prevState) {
		const isDiffBio = prevProps.biometricReducer !== this.props.biometricReducer;
		const isDiffClosingGetBio = prevState.closingGetBio !== this.state.closingGetBio;
		if (isDiffBio || isDiffClosingGetBio)
			this.handleRenderContentAndFooter()
		
		const isDiffStatusFinishBtn = this.state.isVisibleFinishBtn !== prevState.isVisibleFinishBtn;
		const { isVisibleFinishBtn } = this.state;
		if (isDiffStatusFinishBtn && isVisibleFinishBtn && this.isTicketWaitingFace) {
			const { ticketId } = this.props
			const userIsTeller =
				this.props.counterReducer.dataLogin.Roles.includes(TELLER)
			const removeStatusWaitingBioData = userIsTeller
                ? this.props.removeStatusWaitingBioData
                : this.props.removeStatusWaitingBioDataCollector
			const handleClosingGetBio = (ticketId) => {
                this.setState(
                    {
                        closingGetBio: (
                            <>
                                <LoadingControl
                                    size="25px"
                                    loadingPage={false}
                                />
                                Vui lòng chờ...
                            </>
                        ),
                    },
                    () => {
                        setTimeout(() => {
                            removeStatusWaitingBioData(ticketId)
                        }, 5000)
                    }
                )
            }
			const popupFooter = this.state.closingGetBio?
				<div className="closing-get-bio">{this.state.closingGetBio}</div>
				:
				<div 
					className="btn-finish-waiting"
					onClick={() => handleClosingGetBio(ticketId)}
				>Kết thúc</div>
			this.setState({ popupFooter })
		}
	}
	

	componentDidMount() {
		this.handleRenderContentAndFooter();
	}
	
}

const mapStateToProps = state => ({
	counterReducer: state.counterReducer,
	biometricReducer: state.biometricReducer
})

const mapDispatchToProps = dispatch => ({
	requestConfirmBioData: (enrollId, bioType, ticketId) => 
		dispatch(requestConfirmBioData(enrollId, bioType, ticketId)),
	requestGetBioData: (dataRequest) => 
		dispatch(requestGetBioData(dataRequest)),
	requestRemoveBioData: (enrollId, bioType, bioId, ticketId, index = undefined) =>
		dispatch(requestRemoveBioData(enrollId, bioType, bioId, ticketId, index)),
	removeStatusWaitingBioData: (ticketId, justDispatch=false) => 
		dispatch(removeStatusWaitingBioData(ticketId, justDispatch)),
	requestUpDateBio: (ticketId, cusNumber) => 
		dispatch(requestUpDateBio(ticketId, cusNumber)),
	openPopup: (typePopup, levelPopup, data) => 
		dispatch(openPopup(typePopup, levelPopup, data)),
	removeBioDataCollector: (enrollId, bioType, bioId, ticketId, index = undefined) =>
		dispatch(removeBioDataCollector(enrollId, bioType, bioId, ticketId, index)),
	requestUpDateBioCollector: (ticketId, cusNumber) => 
		dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
	requestGetBioDataCollector: (dataRequest, ticketId) => 
		dispatch(requestGetBioDataCollector(dataRequest, ticketId)),
	removeStatusWaitingBioDataCollector: (ticketId, justDispatch = false) => 
		dispatch(removeStatusWaitingBioDataCollector(ticketId, justDispatch)),
	requestConfirmBioDataCollector: (enrollId, bioType, ticketId) => 
		dispatch(requestConfirmBioDataCollector(enrollId, bioType, ticketId)),
	editStatusFingerInRegisterThread: (ticketId, bioType, isAuthenticate) =>
		dispatch(editStatusFingerInRegisterThread(ticketId, bioType, isAuthenticate)),
	clearError: (typeError) => dispatch(clearError(typeError))

})

export default connect(mapStateToProps, mapDispatchToProps)(FaceUpdateFlow)