import Axios from 'axios'

// data
import * as types from './types'
import { host } from '../host'


export const resetGetNumber = () => ({
    type: types.RESET_GET_NUMBER,
})

export const setStepSelect = (step) => ({
    type: types.SET_STEP_SELECT,
    step,
})

export const setListTransaction = (listTrans) => ({
    type: types.SET_LIST_TRANSACTION_GET_NUMBER,
    listTrans,
})

export const setPersonalInfoGetNumber = (details) => ({
    type: types.SET_PERSONAL_INFO_GET_NUMBER,
    details,
})

export const setIsCheckBoxAccNumber = (isCheckBoxAccNumber) => ({
    type: types.SET_CHECKBOX_ACC_NUMBER,
    isCheckBoxAccNumber,
})

export const setListKeySelect = (listKeys) => ({
    type: types.SET_LIST_KEY_SELECT,
    listKeys,
})

export const resetListKeySelect = () => ({
    type: types.RESET_LIST_KEY_SELECT
})