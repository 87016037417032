import React from 'react'
//css
import { WrapPopupEndService } from './index.style'
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
import ButtonControl from '../../../controls/ButtonControl'
import IcPopupQuestion from '../../../images/SVG/IcPopupQuestion'
import { EARLY } from '../SearchCustomer'


const PopupWarningTime = ({ handleBack, handleTakeTicket, status }) => {
    return (
        <OverlayFullScreen>
            <WrapPopupEndService>
                <div className='wrap-content'>
                    <IcPopupQuestion />
                    <div className='title'>{status === EARLY ? 'Vé hiện tại chưa tới giờ hẹn. Bạn vui lòng xác nhận muốn xuất vé' : 'Vé hiện tại đã quá giờ hẹn. Bạn vui lòng xác nhận muốn xuất vé'}</div>
                    <div className='desc'>*Vé được xuất sẽ được chuyển sang vé thường</div>
                </div>
                <div className='wrap-btn'>
                    <ButtonControl
                        text='Quay lại'
                        className='btn-back'
                        onClick={handleBack}
                    />
                    <ButtonControl
                        text='Xuất vé'
                        className='btn-take-ticket'
                        onClick={handleTakeTicket}
                    />
                </div>
            </WrapPopupEndService>
        </OverlayFullScreen>
    )
}

export default PopupWarningTime