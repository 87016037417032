import styled from "styled-components";

const Bound = styled.div`
  position: relative;
  transform: translate(0,0) translate(0,100px);
  margin: 20px auto;
  width: 1100px;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: "SVN-Gilroy";
  color: #000000;
  overflow: hidden;
  z-index: 9;
  box-shadow: 0 0 20px #7d7d7d;
  @keyframes fadeInPopupUse {
    0%   { top:40%; }
    100% { top:50%; }
  }
  .popup-title {
    background-color: #ffffff;
    z-index: 99;
    &__title {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px 0;
      position: relative;

      .p-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
      }
      img {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }
    .search-title {
      padding: 9px 0 12px 40px;
      color: #005993;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #005993;
    }
    .popup-step {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 14px;
      color: #222222;
      background-color: #ffffff;
      margin-top: 14px;
      .step-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 28px;
        padding: 0 20px;
        .tab_content{
          display: flex;
          align-items: center;
          flex-direction:row;
          .step {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            line-height: 1.2;
            cursor: pointer;
          }
        }
        .status-biometric {
          display: flex;
          font-family: SVN-Gilroy;
          font-size: 14px;
          font-weight: 500;

          &__title {
            color: #005993;
            margin-right: 5px;
          }

          &__content {
            color: rgb(200, 200, 200);
          }
        }
        .cif {
          top: 58px;
          right: 32px;
          font-family: SVN-Gilroy;
          font-size: 14px;
          font-weight: 500;
          .type {
            color: #005993;
          }
          .id {
            color: #222222;
          }
        }
      }
      .mark-step {
        padding: 0 20px;
        display: flex;
        height: 3px;
        flex-direction: row;
        background-color: #efefef;
        .step {
          display: flex;
          width: 140px;
        }
      }
    }
  }
  .popup-container {
    min-height: 400px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .popup-list-un-complete-finger {
    /* padding: 0 10px; */
    color: var(--black);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background: #ffffff;
    span {
      margin-left: 5px;
      color: #979797;
      font-weight: normal;
    }
  }

  .popup-disable-event {
    color: var(--vermillion);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    background: #ffffff;
  }

  .popup-detail-auth-cust {
    padding-top: 10px;
    font-size:14px;
    text-align: center; 
    font-weight: 500;
    background: #ffffff;
  }

  .popup-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff;
    padding: 20px 20px 24px 20px;
    .reject-wrap {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 5px;
      }
    }
    .content-reject {
      text-decoration: underline;
      white-space: nowrap;
      color: rgb(243, 25, 25);
      :hover {
        cursor: pointer;
      }
    }
    
    .block_PrintAndHistoryBio{
      display: flex;
    }
    .p-registration {
      font-size: 14px;
      font-weight: 500;
      color: #f31919;
      border-bottom: solid 0.5px #f31919;
      cursor: pointer;
    }
    img {
      margin-right: 16px;
    }

    .btn-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      & > * {
        margin-right: 5px;
      }
    }
    .title_show_loading {
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #979797;
      margin: 0 40px 0 0;
    }
  }
  .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 30px 40px;
    p {
      font-family: "SVN-Gilroy";
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      margin-left: 5px;
      line-height: 18px;
    }
  }
`;

export {
    Bound
}