import React, { useState } from 'react'
//css
import { WrapItemTransaction } from './index.styles'
//img
import IcArrowRightWhite from '../../../../images/SVG/IcArrowRightWhite'
import IcCheckBoxActive from '../../../../images/SVG/IcCheckBoxActive'
import IcCheckBox from '../../../../images/SVG/IcCheckBox'

const ItemTransaction = ({ data, listKeySelect, handleSelected }) => {

    const [isExpand, setIsExpand] = useState(false)

    return (
        <WrapItemTransaction isExpand={isExpand}>
            <div className='wrap-title' onClick={() => setIsExpand(pre => !pre)}>
                <div className='name'>{data?.Name}</div>
                <IcArrowRightWhite className='ic-arrow' />
            </div>
            {
                isExpand && (
                    <div className='wrap-block-transaction'>
                        {
                            data?.Children?.map((item, i) => {
                                let itemSelect = listKeySelect.filter(value => value === item?.ServiceType)
                                return (
                                    <div className='block-transaction' key={i} onClick={() => handleSelected(item)}>
                                        <div className='wrap-check-box' >
                                            {
                                                item?.ServiceType === (itemSelect[0]) ?
                                                    <IcCheckBoxActive className='check-box' />
                                                    :
                                                    <IcCheckBox className='check-box' />
                                            }
                                        </div>
                                        <div className='text'>{item?.Name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </WrapItemTransaction>
    )
}

export default ItemTransaction