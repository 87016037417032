import React, { Component } from 'react'
import styled from "styled-components";
import { connect } from 'react-redux';

import icCloseWhite from "../../../images/ic_close_white.svg";
import ic_delete from '../../../images/Icons_Delete.svg';
import ic_update from '../../../images/Icons_Edit.svg';
import icRefreshRed from "../../../images/ic_refresh_red.svg";
import icRefreshBlue from "../../../images/Icons_Dashboard_Refresh.svg";
import iconNotificationFailed from "../../../images/ic_counter_notification_close.svg";
import ic_cancel from '../../../images/icon_cancel.svg'


import typesKeyDetailPopup from '../../../data/typesKeyDetailPopup';
import { popupBioTypes } from '../../../data/PopupTypes';
import { FACE } from '../../../data/bioTypes';
import {
  REGISTER,
  CONFIRM,
  AUTH_TRANS,
  VALIDATE_DATA,
  ISHANG,
  LIST_ID,
  REMOVE
} from '../../../actions/typeRequestBio';
import { TELLER } from "../../../data/userRole";
import bioActionTypes from "../../../data/bioActionTypes";
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket';
import { EDIT_FINGER_INFO_IN_REGISTER_THREAD } from '../../../actions/types';
import { errorData } from '../../../data/errorData';

// component
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';

// action
import {
  apiGetBioFile,
  apiGetBioFaceImage,
  updateFingerInfoInRegisterThread,
  updateFingerInfoInUpdateThread,
  updateRevertRemoveBio
} from '../../../actions/biometricAction';
import {
  requestUpDateBio
} from "../../../actions/counterAction";
import {
  apiGetBioFileCollector,
  apiGetBioFaceImageCollector,
  requestUpDateBioCollector,
  updateRevertRemoveBioCollector,
  setAuthenticateFingerCollectorUpdateThread
} from "../../../actions/biometricWithInBioCollectorAction";
import { openPopup } from '../../../actions/popupBioAction';
import { clearError } from '../../../actions/commonAction';

// hepler
import { buildErrorContent } from '../../../helper/tool';
import isDisableEventClick from '../../../helper/isDisableEventWhenHaveSupervisor';
import { openPopupAddReasonDeleteBioData, openPopupNotifyErrorLv3 } from '../../../helper/actionOpenPopup';
import isErrorWhenGetEnroll from '../../../helper/isErrorWhenGetEnroll';
import getChannelName from '../../../helper/getChannelName';


const StylePopupShowFaceImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  z-index: 100;
  /* width: 300px; */
  box-shadow: 0 0 15px #29292980;
  .blockImage {
    height: 500px;
    position: relative;
    & > img {
      display: block;
      /* width: 300px; */
      /* max-height: 300px; */
      height: 100%;
      /* height: 376px; */
      /* object-fit:cover; */
      object-fit:contain;
      margin: 0 auto;
    }
    .channel {
      position: absolute;
      top: 0;
      width: 100%;
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 130%;
      color: #FFFFFF;
      box-sizing: border-box;
      padding: 10px;
      background: rgba(0,0,0,0.4);
    }
  }
  .popup-header {
    :hover {
      cursor: move;
    }

    display: flex;
    justify-content: space-between;
    background: var(--black);
    color: #ffffff;
    padding: 11px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;

    img:hover {
      cursor: pointer;
    }
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .btn {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    & > * {
      font-size: 14px;
    }

    &__title {
      line-height: 0;
    }

    .delete {
      color: var(--vermillion);
    }

    .re-get {
      color: var(--peacock-blue);
    }
  }

`;

const StyleFaceFlowUpdate = styled.div`
  width:calc(100% - 40px);
  background-color: white;
  font-family: "SVN-Gilroy";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #222222;
  padding: 16px 20px;
  .block_radioBtn{
    display: flex;
    justify-content:start;
    align-items: center;
    margin: 0 0 17px 0;
    
  .checkBox2{
    margin: 0 20px 0 0;
      .radio-field {
      display: flex;
      align-items: center;
      position: relative;
      input[type="checkbox"] {
          position: absolute;
          height: 0;
          width: 0;
          opacity:0;
      }        
      .checkmark {
          height: 16px;
          width: 16px;
          border: solid 0.8px #c8c8c8;
          background: #ffffff;
          border-radius:50%;
          position: relative;
      }
      .checkmark:after {
          content: "";
          position: absolute;
          display: none;
      }
      input[type="checkbox"]:checked ~ .checkmark:after {
          display: block;
      }
      .checkmark::after {
        width: 70%;
        height: 70%;
        background-color: #d71249;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .text_label {
          margin: 0 0 0 4px;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
      }
      }
    }
  }
  .box2 {
        margin: 13px 0 0 0;
    }
    .Block_action{
        margin: 26px 0 0 0;
        display: flex;
        align-items: center;
        justify-content:space-between;
        & > *:not(:last-child){
          margin-right: 5px;
        }

        .div_bio {
          display: flex;

          & > *:not(:last-child){
            margin-right: 5px;
          }
        }
        img{
            cursor: pointer;
            &:last-child {
                margin: 0 0 0 12px;
            }
        }
    }

    .checkBox{
        .radio-field {
        display: flex;
        align-items: center;
        position: relative;
        input[type="checkbox"] {
            position: absolute;
            height: 0;
            width: 0;
            opacity:0;
        }        
        .checkmark {
            height: 16px;
            width: 16px;
            border: solid 0.8px #c8c8c8;
            background: #ffffff;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        input[type="checkbox"]:checked ~ .checkmark:after {
            display: block;
        }
        .checkmark::after {
            left: 5.5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid #d71249;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .text_label {
            margin: 0 0 0 4px;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
        }
        }
      }
`;

const StyleDeleteBio = styled.div`
  padding-bottom: 18px;
  border-radius: 0 0 5px 5px;
  background: #ffffff;

  .detail {
    /* margin-top: 10px; */
    /* padding: 0 39px; */
    padding: 10px 7px 0;
    color: #F31919;
    font-family: "SVN-Gilroy";
    font-size: 14px;
    line-height: 128%;
    font-weight: 500;
    text-align: center;
  }

  .btn-group {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    
    & > *:hover {
      cursor: pointer;
    }

    .btn-fallback {
      display: flex;
      align-items: center;

      & > div {
        font-family: "SVN-Gilroy";
        font-size: 13px;
        line-height: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #F31919;

      }
    }
  }
`;

const BIO_INFO_REGISTER = typesKeyDetailPopup.faceBioInfoRegister;
const BIO_INFO_UPDATE = typesKeyDetailPopup.faceBioInfoUpdate;
const DELETE_BIO_UPDATE = "DELETE_BIO_UPDATE"
const DELETE_BIO_UPDATE_ACCEPT = "DELETE_BIO_UPDATE_ACCEPT"
const DELETE_BIO_UPDATE_PENDING = "DELETE_BIO_UPDATE_PENDING"
const DELETE_BIO_UPDATE_REJECT = "DELETE_BIO_UPDATE_REJECT"

const statusDeteleBioUpdate = [
  DELETE_BIO_UPDATE,
  DELETE_BIO_UPDATE_ACCEPT,
  DELETE_BIO_UPDATE_PENDING,
  DELETE_BIO_UPDATE_REJECT
]

const textUpdateRemove = {
  [DELETE_BIO_UPDATE]: "Khuôn mặt đang ở trạng thái xoá và đang chờ chuyển KSV phê duyệt",
  [DELETE_BIO_UPDATE_ACCEPT]: "Khuôn mặt đã được phê duyệt xoá bởi KSV",
  [DELETE_BIO_UPDATE_PENDING]: "Khuôn mặt đang chờ KSV phê duyệt",
  [DELETE_BIO_UPDATE_REJECT]: "Khuôn mặt đã bị từ chối xoá bởi KSV"
}

class PopupShowFaceImage extends Component {

  state = {
    step: this.props.dataBio.typeDetail,
    confirmFaceTransaction: false,
    onCloseTransaction: false,
    facePrintHanging: false,
    imglink: this.props.sourceImg,
    fromChannel: undefined
  }

  confirmFaceTransaction = (numberCheck) => {
    const { dataBio, counterReducer } = this.props;
    if (isDisableEventClick(dataBio.dataGetTicket.TicketID, counterReducer, this)) return;


    //// 0: flowRegister, 1 - 2: flowUpdate 
    if (numberCheck === 2)
      this.setState({
        // confirmFaceTransaction: false,
        confirmFaceTransaction: true,
        facePrintHanging: true,
        onCloseTransaction: true
      })
    else if (numberCheck === 1)
      this.setState({
        confirmFaceTransaction: true,
        facePrintHanging: false,
        onCloseTransaction: false
      })

    else if (numberCheck === 0)
      this.setState({
        confirmFaceTransaction: !this.state.confirmFaceTransaction
      });
  }

  facePrintHanging = () => {
    this.setState({
      facePrintHanging: !this.state.facePrintHanging
    })
  }

  popupConfirmDeleteBio = () => {
    const {
      dataBio,
      counterReducer,
      biometricReducer
    } = this.props;

    const ticketId = dataBio.dataGetTicket.TicketID

    if (isDisableEventClick(ticketId, counterReducer, this)) return;

    // no have bio finger
    // have finger deleted
    const bioOfCus = biometricReducer[ticketId];
    // const haveBioFinger = Object.keys(bioOfCus).some(
    //   (item) => item !== FACE
    // );
    const haveAllFingerDeleted = Object.keys(bioOfCus)
      .filter(item => item !== FACE)
      .every(
        (item) =>
          bioOfCus[item].bioActionType === REMOVE
      )

    if (!haveAllFingerDeleted) {
      this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
        icon: ic_cancel,
        label:
          'Đề nghị xoá dấu vân tay của khách hàng trước khi xoá sinh trắc học khuôn mặt',
        width: '226px',
      })
      return
    }

    this.props.openPopup(
      popupBioTypes.PopupConfirm,
      3,
      {
        width: "287px",
        content: "Xóa dữ liệu sinh trắc học - khuôn mặt?",
        contentDelete: "(*) Yêu cầu xoá phải được KSV phê duyệt. Dữ liệu sau khi xoá sẽ không thể phục hồi",
        key: typesKeyDetailPopup.removeFingerFlowUpdate,
        data: {
          ticketId,
          typeBio: FACE
        }
      }
    )
  }

  popupConfirmUpdateBio = () => {
    const { dataBio, counterReducer, biometricReducer } = this.props
    const ticketId = dataBio.dataGetTicket.TicketID

    if (isDisableEventClick(ticketId, counterReducer, this)) return

    // no have bio finger
    // have finger deleted
    const bioOfCus = biometricReducer[ticketId];

    // const haveAllFingerDeleted = Object.keys(bioOfCus)
    // .filter(item => item !== FACE)
    // .every(
    //     (item) => 
    //       bioOfCus[item].bioActionType === REMOVE
    // )

    // if (!haveAllFingerDeleted) {
    //     this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
    //         icon: ic_cancel,
    //         label:
    //             'Đề nghị xoá dấu vân tay của khách hàng trước khi lấy lại sinh trắc học khuôn mặt',
    //         width: '226px',
    //     })
    //     return
    // }

    const haveFingerRegisterNew = Object.keys(bioOfCus)
      .filter(item => item !== FACE)
      .find(item => !Object.keys(bioOfCus[item]).includes(VALIDATE_DATA))

    if (haveFingerRegisterNew) {
      // debugger;
      this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
        icon: ic_cancel,
        label: (
          <>
            <div>Bạn Vui lòng xóa ngón tay đang lấy mẫu</div>
            <div>chưa được phê duyệt trước khi</div>
            <div>lấy lại hình ảnh khuôn mặt</div>
          </>
        ),
        width: '340px',
      })
      return;
    }

    this.props.openPopup(
      popupBioTypes.PopupConfirm,
      3,
      {
        key: typesKeyDetailPopup.openFlowUpdateFace,
        width: "274px",
        content: "Cập nhật dữ liệu sinh trắc học",
        contentDelete: "(*) Dữ liệu sẽ được thay đổi sau khi được KSV phê duyệt",
        ticketId
      }
    )
  }

  handleUpdateTrans = async () => {
    const { dataBio, counterReducer } = this.props;
    if (isDisableEventClick(dataBio.dataGetTicket.TicketID, counterReducer, this)) return;


    // const { onCloseTransaction } = this.state;
    // if (onCloseTransaction) {

    //   // await this.props.faceUpdateTrans(dataBio.dataGetTicket.TicketID, FACE)  
    //   await this.props.onClosePopup();
    //   return;
    // }
    // else {
    const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === dataBio.dataGetTicket.TicketID);
    if (!currentTicket) return;
    if (!currentTicket.CustomerInfos) return;

    const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);

    const enrollId = this.props.counterReducer.enrollId[currentTicket.TicketID];
    if (!enrollId) {
      userIsTeller && await this.props.requestUpDateBio(
        currentTicket.TicketID,
        currentTicket.CustomerInfos.CustomerNumber
      )

      !userIsTeller && await this.props.requestUpDateBioCollector(
        currentTicket.TicketID,
        currentTicket.CustomerInfos.CustomerNumber
      )

      const isError = isErrorWhenGetEnroll(
        this.props.counterReducer,
        this.props.openPopup,
        this.props.clearError
      )
      if (isError) return
    }
    // debugger;
    userIsTeller && await this.props.faceUpdateTrans(
      dataBio.dataGetTicket.TicketID,
      this.props.counterReducer.enrollId[currentTicket.TicketID],
      FACE,
      // this.state.confirmFaceTransaction, 
      true,
      this.state.facePrintHanging
    )
    !userIsTeller && await this.props.setAuthenticateFingerCollectorUpdateThread(
      this.props.counterReducer.enrollId[currentTicket.TicketID],
      dataBio.dataGetTicket.TicketID,
      FACE,
      true,
      this.state.facePrintHanging
    )
    // }
    const { err } = this.props.biometricReducer;
    // debugger;
    if (err &&
      typeof (err) === "object" &&
      Object.keys(err).includes(EDIT_FINGER_INFO_IN_REGISTER_THREAD)) {
      switch (true) {
        case Object.keys(errorData[EDIT_FINGER_INFO_IN_REGISTER_THREAD]).includes(String(err[EDIT_FINGER_INFO_IN_REGISTER_THREAD])):
          openPopupNotifyErrorLv3(
            this,
            {
              image_noti: iconNotificationFailed,
              title: buildErrorContent(EDIT_FINGER_INFO_IN_REGISTER_THREAD, err[EDIT_FINGER_INFO_IN_REGISTER_THREAD]),
              widthPopup: "266px",
              titleConfirm: "Xác nhận"
            },
            "handleUpdateTrans - PopupShowFaceImage.js"
          )
          break;
        default:
          openPopupNotifyErrorLv3(
            this,
            {
              image_noti: iconNotificationFailed,
              title: err[EDIT_FINGER_INFO_IN_REGISTER_THREAD],
              widthPopup: "180px",
              titleConfirm: "Xác nhận"
            },
            "handleUpdateTrans - PopupShowFaceImage.js"
          )
          break;
      }
      this.props.clearError(EDIT_FINGER_INFO_IN_REGISTER_THREAD)
    }
    await this.props.onClosePopup();
  }

  handleShowUI = () => {
    const {
      dataBio,
      biometricReducer,
      counterReducer
    } = this.props;
    // const typeBio = FACE;
    const ticketId = dataBio.dataGetTicket.TicketID;

    const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);

    const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId);

    const isTicketHaveFaceBio = isTicketHaveBio &&
      Object.keys(biometricReducer[ticketId]).includes(FACE);
    // check FACE was delete
    const isTicketHaveFaceBioDelete = isTicketHaveFaceBio &&
      biometricReducer[ticketId][FACE].bioActionType === bioActionTypes.REMOVE;
    const isTicketHaveFaceConfirm = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM);
    // check CONFIRM FACE success
    const isTicketHaveFaceConfirmSuccess = isTicketHaveFaceConfirm &&
      biometricReducer[ticketId][FACE][CONFIRM] === 0;
    // check validatedData FACE
    const isTicketHaveFaceValidatedData = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes("validatedData");

    switch (true) {
      case isTicketHaveFaceBioDelete: {
        const currentTicket = counterReducer.listCurrentTicket.find(item =>
          item.TicketID === ticketId
        )
        if (!currentTicket) break;
        const { statusBiometric } = currentTicket;
        let step = DELETE_BIO_UPDATE;

        switch (true) {
          case statusBiometric === typeStatusBiometrics.PENDING:
            step = DELETE_BIO_UPDATE_PENDING;
            break;
          case statusBiometric === typeStatusBiometrics.REJECT:
            step = DELETE_BIO_UPDATE_REJECT;
            break;
          case statusBiometric === typeStatusBiometrics.ACCEPT:
            step = DELETE_BIO_UPDATE_ACCEPT;
            break;
          default:
            break;
        }

        const imgFace = biometricReducer[ticketId][FACE][REGISTER];
        const fromChannel = biometricReducer[ticketId][FACE].fromChannel;
        this.setState({
          step,
          imglink: userIsTeller ?
            apiGetBioFaceImage(imgFace)
            :
            apiGetBioFaceImageCollector(imgFace),
          fromChannel
        })
        break;
      }
      case isTicketHaveFaceConfirmSuccess: {
        const imgFace = biometricReducer[ticketId][FACE][REGISTER];
        const fromChannel = biometricReducer[ticketId][FACE].fromChannel;
        this.setState({
          step: BIO_INFO_REGISTER,
          imglink: userIsTeller ?
            apiGetBioFile(imgFace)
            :
            apiGetBioFileCollector(imgFace),
          fromChannel
        })
        break;
      }
      case isTicketHaveFaceValidatedData: {
        const thisFaceBio = biometricReducer[ticketId][FACE];
        const thisFaceWasUpdated = Object.keys(thisFaceBio).includes(AUTH_TRANS);
        const validatedData = thisFaceBio[VALIDATE_DATA];
        const confirmFaceTransaction =
          thisFaceWasUpdated ?
            thisFaceBio[AUTH_TRANS] : validatedData[AUTH_TRANS];
        const facePrintHanging =
          thisFaceWasUpdated ?
            thisFaceBio[ISHANG] : validatedData[ISHANG];
        // debugger
        const imgFace = validatedData[LIST_ID];
        const fromChannel = validatedData.fromChannel;
        this.setState({
          step: BIO_INFO_UPDATE,
          imglink: userIsTeller ?
            apiGetBioFaceImage(imgFace)
            :
            apiGetBioFaceImageCollector(imgFace),
          confirmFaceTransaction,
          // confirmFaceTransaction: !validatedData[ISHANG],
          facePrintHanging: confirmFaceTransaction ? facePrintHanging : false,
          fromChannel
        })
        break;
      }
      default:
        break;
    }
  }

  handleShowNoteDelete = () => {
    const { dataBio, counterReducer } = this.props;
    if (isDisableEventClick(dataBio.dataGetTicket.TicketID, counterReducer, this)) return;
    openPopupAddReasonDeleteBioData(
      this,
      {
        ticketId: dataBio.dataGetTicket.TicketID,
        bioType: FACE
      },
      "handleShowNoteDelete - PopupShowFaceImage.js"
    )
  }

  handleFallBack = async () => {
    const {
      dataBio,
      counterReducer
    } = this.props;
    // const typeBio = FACE;
    const ticketId = dataBio.dataGetTicket.TicketID;
    if (isDisableEventClick(ticketId, counterReducer, this)) return;

    const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER);
    const enrollId = counterReducer.enrollId[ticketId];
    if (!enrollId) {
      const currentTicket = counterReducer.listCurrentTicket.find(item =>
        item.TicketID === ticketId
      )
      if (!currentTicket) return;
      if (!currentTicket.CustomerInfos) return;
      userIsTeller &&
        await this.props.requestUpDateBio(
          ticketId,
          currentTicket.CustomerInfos.CustomerNumber
        )

      !userIsTeller &&
        await this.props.requestUpDateBioCollector(
          ticketId,
          currentTicket.CustomerInfos.CustomerNumber
        )

      const isError = isErrorWhenGetEnroll(
        this.props.counterReducer,
        this.props.openPopup,
        this.props.clearError
      )
      if (isError) return
    }

    userIsTeller &&
      this.props.updateRevertRemoveBio(
        this.props.counterReducer.enrollId[ticketId],
        FACE
      )
    !userIsTeller &&
      this.props.updateRevertRemoveBioCollector(
        this.props.counterReducer.enrollId[ticketId],
        FACE
      )
  }
  
  render() {
    const {
      step,
      // confirmFaceTransaction, 
      // onCloseTransaction, 
      facePrintHanging
    } = this.state;
    return (
      <StylePopupShowFaceImage ref={ref => this.popupShowFaceImage = ref}>
        <div className="popup-header">
          <div className="title">Hình ảnh khuôn mặt</div>
          <img src={icCloseWhite} alt="close" onClick={this.props.onClosePopup} />
        </div>
        <div className="blockImage"
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          <img src={this.state.imglink} alt="face" />
          <div className="channel">Kênh tác nghiệp: {getChannelName(this.state.fromChannel)}</div>

          {
            (step === BIO_INFO_UPDATE && !this.props.counterReducer.dataConnectDesktop.VirtualTablet) &&
            <StyleFaceFlowUpdate>
              <div className="block_radioBtn">
                <div className="checkBox2">
                  <label className="radio-field">
                    <input
                      type="checkbox"
                      name="content"
                      value='Xác thực giao dịch'
                      checked={
                        // confirmFaceTransaction
                        !facePrintHanging
                      }
                      onChange={() => this.confirmFaceTransaction(1)}
                    />
                    <div className="checkmark"></div>
                    <div className='text_label'>Hoạt động</div>
                  </label>
                </div>
                <div className="checkBox2">
                  <label className="radio-field">
                    <input
                      type="checkbox"
                      name="content"
                      value='Xác thực giao dịch'
                      checked={
                        // !confirmFaceTransaction
                        facePrintHanging
                      }
                      onChange={() => this.confirmFaceTransaction(2)}
                    />
                    <div className="checkmark"></div>
                    <div className='text_label'>Đóng</div>
                  </label>
                </div>
              </div>

              <div className="Block_action">
                <div className="div_bio">
                  <div className="btn m-r-5" onClick={() => { this.popupConfirmDeleteBio() }}>
                    <img src={ic_delete} alt="img_finger" />
                    <div className="btn__title delete">Xóa</div>
                  </div>
                  {/* <div></div> */}
                  <div className="btn" onClick={() => { this.popupConfirmUpdateBio() }}>
                    <img src={icRefreshBlue} alt="img_finger" />
                    <div className="btn__title re-get">Lấy lại</div>
                  </div>
                </div>
                <ButtonConfirmControl
                  width="100px"
                  onSubmit={() => { this.handleUpdateTrans() }}
                  titleConfirm="Xác nhận"
                />
              </div>
            </StyleFaceFlowUpdate>
          }
          {
            statusDeteleBioUpdate.includes(step) &&
            <StyleDeleteBio>
              <div className="detail">{textUpdateRemove[step]}</div>
              <div className="btn-group">
                <img
                  src={ic_update}
                  alt="img_finger"
                  onClick={this.handleShowNoteDelete}
                />
                <div
                  className="btn-fallback"
                  onClick={this.handleFallBack}
                >
                  <img src={icRefreshRed} alt="fallback" />
                  <div>Hoàn tác</div>
                </div>
                <ButtonConfirmControl
                  width="100px"
                  onSubmit={this.props.onClosePopup}
                  titleConfirm="Xác nhận"
                />
              </div>
            </StyleDeleteBio>
          }
        </div>
      </StylePopupShowFaceImage>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.biometricReducer) !==
      JSON.stringify(this.props.biometricReducer))
      this.handleShowUI()
  }


  componentDidMount() {
    this.dragElement(this.popupShowFaceImage, ".popup-header")
    this.handleShowUI()
  }


  dragElement = (elmnt, headerClass) => {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const header = elmnt.querySelector(headerClass)
    if (header) {
      /* if present, the header is where you move the DIV from:*/
      header.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    const that = this;
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      const tmpTop = (elmnt.offsetTop - pos2);
      const tmpLeft = (elmnt.offsetLeft - pos1);
      const { scrollHeight, scrollWidth, clientHeight, clientWidth } = that.popupShowFaceImage;
      const { innerHeight, innerWidth } = window;
      if (tmpTop < scrollHeight / 2 && pos2 > 0) {
        elmnt.style.top = scrollHeight / 2 + "px";
      }
      else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
        elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
      }
      else {
        elmnt.style.top = tmpTop + "px";
      }

      if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
        elmnt.style.left = scrollWidth / 2 + "px";
      }
      else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
        elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
      }
      else {
        elmnt.style.left = tmpLeft + "px";
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }
}

const mapStateToProps = state => ({
  biometricReducer: state.biometricReducer,
  counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
  openPopup: (type, levelPopup, data) =>
    dispatch(openPopup(type, levelPopup, data)),
  faceAuthTrans: (ticketId, enrollId, bioType, isAuthenticate) =>
    dispatch(updateFingerInfoInRegisterThread(ticketId, enrollId, bioType, isAuthenticate)),
  faceUpdateTrans: (ticketId, enrollId, bioType, isAuthenticate, isHang) =>
    dispatch(updateFingerInfoInUpdateThread(ticketId, enrollId, bioType, isAuthenticate, isHang)),
  requestUpDateBio: (ticketId, cusNumber) =>
    dispatch(requestUpDateBio(ticketId, cusNumber)),
  updateRevertRemoveBio: (enrollId, bioType) =>
    dispatch(updateRevertRemoveBio(enrollId, bioType)),
  clearError: (typeErr) =>
    dispatch(clearError(typeErr)),
  // COLLECTOR
  requestUpDateBioCollector: (ticketId, cusNumber) =>
    dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
  updateRevertRemoveBioCollector: (enrollId, bioType) =>
    dispatch(updateRevertRemoveBioCollector(enrollId, bioType)),
  setAuthenticateFingerCollectorUpdateThread: (enrollId, ticketId, bioType, isAuthenticate, isHang) =>
    dispatch(setAuthenticateFingerCollectorUpdateThread(enrollId, ticketId, bioType, isAuthenticate, isHang))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupShowFaceImage)


/**
 * props = {
 *  onClosePopup: function, // handle event close popup
 *  sourceImg: string, //link soure of image
 * }
 */
