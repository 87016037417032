import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import ic_success from '../../../images/ic_counter_notification_success.svg'
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';

// action
import { closePopup } from '../../../actions/popupBioAction'

const Bound = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction:column;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .block_success{
    margin: 36px 0 0 0;
    .ic_success{
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
  .content{
    margin: 20px 0 0 0;
    width: 202px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    color: #222222;
    text-align: center;
  }
  .btn {
    margin: 108px 0 24px 0;
  }
`

 class CheckCustomerSuccess extends Component {
  render() {
    return (
      <Bound>
        <div className="block_success">
          <img src={ic_success} alt="ic_success" />
        </div>
        <div className="content">
          Vân tay xác thực trùng với hình ảnh lưu trữ
        </div>
        <div className="btn">
          <ButtonConfirmControl
            bg_color="#005993"
            titleConfirm='Xác nhận'
            onSubmit={() => this.props.closePopup(1)}
          />
        </div>
      </Bound>
    )
  }
}

export default connect(
  null,
  dispatch => ({
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup))
  }) 
)(CheckCustomerSuccess)
