import React from 'react'

export default function IcNondata(props) {
    return (
        <svg
            {...props}
            width={166}
            height={166}
            viewBox="0 0 166 166"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={83} cy={83} r={83} fill="#E6E6E6" />
            <path
                d="M122.26 82.2196L121.567 81.2663C120.353 79.7929 118.923 78.6229 117.277 77.7563C115.067 76.4996 112.553 75.8496 109.953 75.8496H56.0034C53.4034 75.8496 50.9334 76.4996 48.68 77.7563C46.99 78.6663 45.4734 79.9229 44.2167 81.4829C41.7467 84.6463 40.5767 88.5463 40.9667 92.4463L42.57 112.683C43.1334 118.793 43.87 126.333 57.6067 126.333H108.393C122.13 126.333 122.823 118.793 123.43 112.64L125.033 92.4896C125.423 88.8496 124.47 85.2096 122.26 82.2196ZM93.3567 106.14H72.6C70.91 106.14 69.5667 104.753 69.5667 103.106C69.5667 101.46 70.91 100.073 72.6 100.073H93.3567C95.0467 100.073 96.39 101.46 96.39 103.106C96.39 104.796 95.0467 106.14 93.3567 106.14Z"
                fill="white"
            />
            <path
                d="M120.285 71.1227C120.299 71.5072 119.887 71.7644 119.543 71.5933C116.589 70.1269 113.384 69.3937 109.996 69.3937H56.0032C52.6148 69.3937 49.2999 70.1637 46.3956 71.6026C46.0549 71.7714 45.6465 71.5287 45.6465 71.1485V59.8603C45.6465 44.3903 50.3698 39.667 65.8398 39.667H70.9532C77.1498 39.667 79.0998 41.6603 81.6132 44.9103L86.8132 51.8437C87.8965 53.317 87.9398 53.4037 89.8465 53.4037H100.16C114.799 53.4037 119.799 57.6418 120.285 71.1227Z"
                fill="white"
            />
        </svg>
    )
}
