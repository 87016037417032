import React from 'react'
import { WrapPopupPrintFail } from './index.styles'
import ButtonControl from '../../controls/ButtonControl'
import IcPopFail from '../../images/SVG/IcPopFail'

export default function PopupPrintFail({setIsFail, onClose}) {
    const handleCancel = () => {
        setIsFail()
    }

    const handleConfirm = () => {
        onClose()
    }


    return (
        <WrapPopupPrintFail>
            <div className="block-content">
                <IcPopFail/>
                <div className='bold'>Mã đặt lịch đã được xuất</div>
                <div className='red'>(*) Bạn vui lòng đặt vé mới</div>
            </div>
            <div className="block-btn">
                <ButtonControl
                    text="Hủy bỏ"
                    className="btn-print-cancel"
                    onClick={handleCancel}
                />
                <ButtonControl
                    text="Xác nhận"
                    className="btn-print-confirm"
                    onClick={handleConfirm}
                />
            </div>
        </WrapPopupPrintFail>
    )
}
