import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//css
import {
    WrapEnterPersonalInfo,
    WrapContentPage,
    WrapFooterPage,
} from './index.styles'
import InputControl from '../../../controls/InputControl'
import ButtonControl from '../../../controls/ButtonControl'
import StepByStepGetNumber from '../../StepByStepGetNumber'

//redux
import {
    setIsCheckBoxAccNumber,
    setPersonalInfoGetNumber,
    setStepSelect,
} from '../../../../../actions/getNumberAction'

//img

//ultils
import { checkValidation, getStrValidation } from '../../../ultils/validation'
import {
    ACTIVE,
    BUSINES_CUSTOMER,
    INDIVIDUAL_CUSTOMER,
    SUCCESS,
} from '../../../../../constant/keys'
import IcChecked from '../../../images/SVG/IcChecked'
import IcUnCheck from '../../../images/SVG/IcUnCheck'
import { getInfoCustomer } from '../../../../../actions/counterAction'
import trimStartAndStripStr from '../../../ultils/trimStartAndStripStr'
import removeAccent from '../../../ultils/removeAccent'

const EnterPersonalInfo = ({ onNext }) => {
    const dispatch = useDispatch()
    //---
    const [isSubmit, setIsSubmit] = useState(false)

    //---
    const { personalInfo, dataConnectDesktop, isCheckBoxAccNumber } = useSelector((state) => ({
        personalInfo: state.getNumber.personalInfo,
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        isCheckBoxAccNumber: state.getNumber.isCheckBoxAccNumber
    }))
    //---
    const [data, setData] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            customerType: personalInfo.customerType || INDIVIDUAL_CUSTOMER,
            fullName: personalInfo.fullName || '',
            identifyCard: personalInfo.identifyCard || '',
            bussinessRegister: personalInfo.bussinessRegister || '',
            phoneNumber: personalInfo.phoneNumber || '',
            email: personalInfo.email || '',
            accNumber: personalInfo.accNumber || ''
        }
    )

    const [error, setError] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            fullName: '',
            identifyCard: '',
            bussinessRegister: '',
            phoneNumber: '',
            email: '',
            accNumber: ''
        }
    )

    useEffect(() => {
        if (data.customerType === INDIVIDUAL_CUSTOMER) {
            if (data.identifyCard && !error.identifyCard) {
                setIsSubmit(true)
            } else {
                setIsSubmit(false)
            }
        } else {
            if (isCheckBoxAccNumber) {
                if (data.accNumber && !error.accNumber) {
                    setIsSubmit(true)
                } else {
                    setIsSubmit(false)
                }
            } else {
                if (data.bussinessRegister && !error.bussinessRegister) {
                    setIsSubmit(true)
                } else {
                    setIsSubmit(false)
                }
            }
        }
    }, [data, error, isCheckBoxAccNumber])

    const _handleSetValueSelect = (val) => {
        setData({ customerType: val })
    }

    const handleValidationFullName = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ fullName: getStrValidation.errorEmpty })
            callback(getStrValidation.errorEmpty)
            return
        }
        if (checkValidation.limitRange(value, 2, 50)) {
            setError({ fullName: getStrValidation.limitRange(2, 50) })
            callback(getStrValidation.limitRange(2, 50))
            return
        }
        if (checkValidation.errorFullname(value)) {
            setError({ fullName: getStrValidation.errorFullname })
            callback(getStrValidation.errorFullname)
            return
        }
        setError({ fullName: '' })
    }

    const handleValidationIdentifyCard = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ identifyCard: getStrValidation.errorEmpty })
            callback(getStrValidation.errorEmpty)
            return
        }
        if (!checkValidation.errorCharacterSpecial(value)) {
            setError({ identifyCard: getStrValidation.errorCharacterSpecial })
            callback(getStrValidation.errorCharacterSpecial)
            return
        }
        if (checkValidation.limitRange(value, 8, 12)) {
            setError({ identifyCard: getStrValidation.limitRange(8, 12) })
            callback(getStrValidation.limitRange(8, 12))
            return
        }
        setError({ identifyCard: '' })
    }

    const handleValidationBusinessRegister = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ bussinessRegister: getStrValidation.errorEmpty })
            callback(getStrValidation.errorEmpty)
            return
        }
        if (!checkValidation.errorAllowSomethingCharacterSpecial(value)) {
            setError({ bussinessRegister: getStrValidation.errorAllowSomethingCharacterSpecial })
            callback(getStrValidation.errorAllowSomethingCharacterSpecial)
            return
        }
        setError({ bussinessRegister: '' })
    }

    const handleValidationAccNumber = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ accNumber: getStrValidation.errorEmpty })
            callback(getStrValidation.errorEmpty)
            return
        }
        setError({ accNumber: "" })
    }

    const handleValidationPhoneNumber = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ phoneNumber: getStrValidation.errorEmpty })
            callback(getStrValidation.errorEmpty)
            return
        }
        if (value.charAt(0) !== '0') {
            setError({ phoneNumber: 'SĐT bắt đầu bằng số 0' })
            callback('SĐT bắt đầu bằng số 0')
            return
        }
        if (checkValidation.errorNumber(value)) {
            setError({ phoneNumber: getStrValidation.errorNumber })
            callback(getStrValidation.errorNumber)
            return
        }
        if (checkValidation.limitRange(value, 10, 11)) {
            setError({ phoneNumber: getStrValidation.limitRange(10, 11) })
            callback(getStrValidation.limitRange(10, 11))
            return
        }

        setError({ phoneNumber: '' })
    }
    const handleValidationEmail = (value, callback) => {
        if (checkValidation.errorEmpty(value)) {
            setError({ email: '' })
            return
        }
        if (checkValidation.limitRange(value, 5, 50)) {
            setError({ email: getStrValidation.limitRange(5, 50) })
            callback(getStrValidation.limitRange(5, 50))
            return
        }
        if (checkValidation.errorEmail(value)) {
            setError({ email: getStrValidation.errorEmail })
            callback(getStrValidation.errorEmail)
            return
        }
        setError({ email: '' })
    }

    const handleValidation = () => {
        // handleValidationFullName(data.fullName, () => {})
        if (data.customerType === INDIVIDUAL_CUSTOMER) {
            handleValidationIdentifyCard(data.identifyCard, () => { })
        } else {
            if (isCheckBoxAccNumber) {
                handleValidationAccNumber(data.accNumber, () => { })
            } else {
                handleValidationBusinessRegister(data.bussinessRegister, () => { })
            }
        }
        // handleValidationPhoneNumber(data.phoneNumber, () => {})
        // handleValidationEmail(data.email, () => {})
    }

    const handleCheckBox = () => {
        dispatch(setIsCheckBoxAccNumber(!isCheckBoxAccNumber))
        if (!isCheckBoxAccNumber) {
            setError({ bussinessRegister: "" })
            setData({ bussinessRegister: "" })
            return
        } else {
            setError({ accNumber: "" })
            setData({ accNumber: "" })
            return
        }
    }

    const handleNext = () => {
        if (!isSubmit) {
            handleValidation()
            return
        }

        const callbackSuccess = (cifs) => {
            dispatch(
                setPersonalInfoGetNumber({
                    customerType: data.customerType,
                    fullName: cifs ? cifs?.Name : '',
                    identifyCard: cifs ? data.identifyCard : '',
                    phoneNumber: cifs ? cifs?.MobileNumber : '',
                    email: cifs ? cifs?.Email : '',
                    // bussinessRegister: cifs ? '' : data.bussinessRegister,
                    bussinessRegister: data.bussinessRegister,
                    accNumber: data.accNumber
                })
            )
            dispatch(
                setStepSelect({
                    step1: SUCCESS,
                    step2: ACTIVE,
                    step3: '',
                    step4: '',
                })
            )
            setTimeout(() => {
                onNext()
            }, 200)
        }

        if (data.customerType === INDIVIDUAL_CUSTOMER) {
            dispatch(
                getInfoCustomer(
                    dataConnectDesktop.CounterID,
                    data.identifyCard,
                    1,
                    callbackSuccess
                )
            )   
        } else {
            if (data.bussinessRegister) {
                dispatch(
                    getInfoCustomer(
                        dataConnectDesktop.CounterID,
                        data.bussinessRegister,
                        1,
                        callbackSuccess
                    )
                )
            } else {
                dispatch(
                    getInfoCustomer(
                        dataConnectDesktop.CounterID,
                        data.accNumber,
                        5,
                        callbackSuccess
                    )
                )
            }
        }
    }

    const handleKeyPress = (event) => {
        const valueText = event.target.value
        // if (valueText.length >= event.target.maxLength) {
        //   event.preventDefault()
        // }
        if (
            event.key === 'e' ||
            event.key === 'E' ||
            event.key === '+' ||
            event.key === '-' ||
            event.key === '.'
        ) {
            event.preventDefault()
        }
    }

    const handleChangeAccNumber = (e) => {
        const values = e.target.value
        const strNumber = values.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setData({
            accNumber: strNumber.replace(/,/g, ""),
        });
    }

    return (
        <WrapEnterPersonalInfo>
            <div className="title-page">
                <div className="bold">Lấy số xếp hàng</div>
                <div className="normal">Lấy số cho khách hàng vào quầy</div>
            </div>
            <StepByStepGetNumber />
            <WrapContentPage>
                <div className="wrap-container">
                    <div className="wrap-type-customer">
                        <div className="title-select">
                            Loại khách hàng <span>*</span>
                        </div>
                        <div className="content-select">
                            <div
                                className="radio"
                                onClick={() => {
                                    _handleSetValueSelect(INDIVIDUAL_CUSTOMER)
                                    setData({ bussinessRegister: '' })
                                    setError({ bussinessRegister: '' })
                                }}
                            >
                                {data?.customerType === INDIVIDUAL_CUSTOMER ? (
                                    <IcChecked />
                                ) : (
                                    <IcUnCheck />
                                )}
                                Khách hàng cá nhân
                            </div>
                            <div
                                className="radio"
                                onClick={() => {
                                    _handleSetValueSelect(BUSINES_CUSTOMER)
                                    setData({ identifyCard: '' })
                                    setError({ identifyCard: '' })
                                }}
                            >
                                {data?.customerType === BUSINES_CUSTOMER ? (
                                    <IcChecked />
                                ) : (
                                    <IcUnCheck />
                                )}
                                Khách hàng doanh nghiệp
                            </div>
                        </div>
                    </div>
                    {/* <InputControl
                        className="form-input"
                        title={'Họ và tên'}
                        placeholder={'Nhập họ và tên'}
                        isRequired={true}
                        value={data.fullName}
                        error={error.fullName}
                        onChange={(e) => {
                            setData({ fullName: e.target.value })
                        }}
                        handleValidationInput={handleValidationFullName}
                    /> */}
                    {data.customerType === INDIVIDUAL_CUSTOMER && (
                        <InputControl
                            // onKeyPress={handleKeyPress}
                            className="form-input"
                            title={'GTTT (CCCD/CMND/Passport)'}
                            placeholder={'Nhập GTTT (CCCD/CMND/Passport)'}
                            isRequired={true}
                            value={data.identifyCard}
                            error={error.identifyCard}
                            onChange={(e) => {
                                setData({ identifyCard: trimStartAndStripStr(removeAccent(e.target.value)) })
                            }}
                            handleValidationInput={handleValidationIdentifyCard}
                            maxLength={12}
                            minLength={8}
                        />
                    )}
                    {data.customerType !== INDIVIDUAL_CUSTOMER && (
                        <>
                            <InputControl
                                //onKeyPress={handleKeyPress}
                                handleCheckBox={handleCheckBox}
                                isCheckBox={isCheckBoxAccNumber}
                                isShowCheckBox={true}
                                isDisabled={!isCheckBoxAccNumber}
                                className="form-input"
                                title={'Số tài khoản'}
                                placeholder={'Nhập số tài khoản'}
                                isRequired={true}
                                value={data.accNumber}
                                error={error.accNumber}
                                maxLength={20}
                                onChange={(e) => { handleChangeAccNumber(e) }}
                                handleValidationInput={handleValidationAccNumber}
                            />
                            <InputControl
                                // onKeyPress={handleKeyPress}
                                handleCheckBox={handleCheckBox}
                                isCheckBox={!isCheckBoxAccNumber}
                                isShowCheckBox={true}
                                isDisabled={isCheckBoxAccNumber}
                                className="form-input"
                                title={'Số đăng ký kinh doanh'}
                                placeholder={'Nhập số đăng ký kinh doanh'}
                                isRequired={true}
                                value={data.bussinessRegister}
                                error={error.bussinessRegister}
                                onChange={(e) => {
                                    setData({ bussinessRegister: trimStartAndStripStr(removeAccent(e.target.value)) })
                                }}
                                handleValidationInput={
                                    handleValidationBusinessRegister
                                }
                                maxLength={20}
                                keyInput="DKKD"
                            />
                        </>
                    )}
                    {/* <InputControl
                        // type="number"
                        onKeyPress={handleKeyPress}
                        className="form-input"
                        title={'Số điện thoại'}
                        placeholder={'Nhập số điện thoại'}
                        isRequired={true}
                        value={data.phoneNumber}
                        error={error.phoneNumber}
                        onChange={(e) => {
                            setData({ phoneNumber: e.target.value })
                        }}
                        handleValidationInput={handleValidationPhoneNumber}
                        maxLength={11}
                    /> */}
                    {/* <InputControl
                        className="form-input"
                        title={'Email'}
                        placeholder={'Nhập email'}
                        value={data.email}
                        error={error.email}
                        onChange={(e) => {
                            setData({ email: e.target.value })
                        }}
                        handleValidationInput={handleValidationEmail}
                    /> */}
                </div>
            </WrapContentPage>
            <WrapFooterPage>
                <div className="wrap-btn">
                    <ButtonControl
                        text="Tiếp theo"
                        className="btn-next"
                        // isDisable={isDisabled}
                        onClick={handleNext}
                    />
                </div>
            </WrapFooterPage>
        </WrapEnterPersonalInfo>
    )
}

export default EnterPersonalInfo
