// action
// import { resetStore } from "../actions/counterAction";
import { alertLogout, removeLoading } from "../actions/commonAction";
import { catchErrConnection } from "../actions/commonAction";

// data
import * as typesStatusCode from "../data/typesStatusCode";
import * as typesAction from "../actions/types"
import { errorData } from "../data/errorData";

const mergeErrorData = (msg, code) => `${msg} - Code ${code}`;

const addAction = (typeErrAction, code) => ({
    type: typeErrAction,
    err: {
        [typeErrAction]: code
    }
})

/**
 * [description]
 * @param  { object } err             [description]
 * @param  { function } dispatch        [description]
 * @param  { string } typeErrAction [description]
 * @param  { boolean=true } isCheckInvalid [description]
 * @return { void }
 */
const catchErrAction = (err, dispatch, typeErrAction, isCheckInvalid = true) => {
    console.log(typeErrAction)
    dispatch(removeLoading());
    if (err.response && err.response.status === typesStatusCode.CODE_FORBIDDEN) {
        dispatch(alertLogout());
        return;
    }
    if (err.response && err.response.status === typesStatusCode.CODE_BAD_REQUEST) {
        const { Code, Message } = err.response.data;
        switch (typeErrAction) {
            case typesAction.VERIFY_FINGER_ERR:
            case typesAction.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR:
            case typesAction.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR:
                if (Object.keys(errorData[typeErrAction]).includes(String(Code)))
                    dispatch(addAction(typeErrAction, Code));
                else
                    dispatch(addAction(typeErrAction, mergeErrorData(Message, Code)));
                return;
            case typesAction.REQUEST_ENROLL_ERR:
            case typesAction.REQUEST_UPDATE_BIO_ERR:
            case typesAction.REQUEST_ENROLL_COLLECTOR_ERR:
                if (Code !== 30) break;
                dispatch({
                    type: typeErrAction,
                    err: {
                        [typeErrAction]: err.response.data
                    }
                });
                return;
            default:
                break;
        }
    }
    if (isCheckInvalid){
        if (err.response && err.response.status === typesStatusCode.CODE_BAD_REQUEST) {
            if (err.response.data.Code === 76 || (typeErrAction === typesAction.CALL_TICKET_ERR && err.response.data.Code === 4)) {
                dispatch({
                    type: typeErrAction,
                    err: {
                        [typeErrAction]: err.response.data
                    }
                });
                return;
            }
            dispatch({
                type: typeErrAction,
                err: {
                    [typeErrAction]: err.response.data.Code
                }
            });
            return;
        }
    }
    dispatch(catchErrConnection())
}

export default catchErrAction;