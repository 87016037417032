import styled from 'styled-components'

const WrapPopupProcessingCounselor = styled.div`
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    width: 340px;
    min-height: 275px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .wrap-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        .title {
            font-family: 'SVN-Gilroy';
            font-size: 20px;
            font-weight: 700;
            line-height: 25.6px;
            text-align: center;
            color: #191919;
        }
        .desc{
            font-family: 'SVN-Gilroy';
            font-size: 17px;
            font-weight: 500;
            line-height: 21.76px;
            text-align: center;
            color: #191919;
        }
    }
    .note {
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 17.92px;
        text-align: center;
        color: #F31919;
        }
`

export { WrapPopupProcessingCounselor }
