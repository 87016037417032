import styled from 'styled-components';

const Bound = styled.div`
    background-color: #fafafa;
    margin: 20px 0px 0px 0px;
    .head-table, .body-table{
      display: grid;
      grid-template-columns:  10% 20% 14% 30% 10% 8% 8% ;
      grid-template-rows: max-content;
      grid-auto-flow: row;
      grid-row-gap: 4px;
    }
    .scroll{
      overflow-y: auto;
      max-height: 220px;
      height:${props => props.height}px;
      width: ${props => props.width};
      ::-webkit-scrollbar {
        width: 5px;
        background: rgba(120,120,120,0.2);
      }
      ::-webkit-scrollbar-thumb {
        background: gray;
      }
    }
    .tooltip {
        position: relative;
        &:hover {
          .tooltiptext{
            visibility: visible;
            opacity:1;
          }
        }
        .tooltiptext{
          visibility: hidden;
          position: absolute;
          width: 150px;
          background-color: #555;
          color: #fff;
          text-align: center;
          padding: 5px 0;
          border-radius: 6px;
          z-index: 1;
          opacity: 0;
          transition: .2s;
          right: 120%;
          top: -8px;
          display:flex;
          justify-content: center;
          align-items: center;
        }
        .move{
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            width: 16px;
            height: 22px;
            background-color: #0fb11f;
        }
    }

    .try{
        clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
        width: 28px;
        height: 19px;
        background-color: #005993;
        cursor: pointer;
    }
    .icon-moveTo{
      display: flex;
      align-items: center;
    }
    .name-ticky{
      grid-template-columns: 20px ${props => props.names === 'SỐ VÉ HÀNG ĐỢI' ? '54px' : '47px'} auto;
      .ticky{
        grid-column-start: 2;
        margin-left: 12px;
        margin-right: 25px;
      }
    }

    .customer-name, .customer-type{
      grid-template-columns: auto 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .customer-name > span, .customer-type > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .customer-name > span {
      max-width: 25ch;
    }

    .customer-type > span {
      max-width: 17ch;
    }

    .transaction{
      grid-template-columns: auto 20px;
      .text{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .time{
      justify-content: center;
    }
    .button-tranfer{

    }
    .item{
      height:44px;
      background:#ffffff;
      display: grid;
      align-items: center;
      font-family: SVN-Gilroy;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.7;
      color: #000000;
    }
    
    .item.transaction {
      position: relative;
      .transaction-tooltip {
        font-family: "SVN-Gilroy";
        visibility: hidden;
        box-sizing: border-box;
        position: absolute;
        bottom: 100%;
        background: #555;
        padding: 5px;
        border-radius: 7px;
        color: #ffffff;
        z-index: 1;
      }

      .transaction-tooltip::after {
        content: " ";
        position: absolute;
        top: 100%; 
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }

      &:hover .transaction-tooltip {
        visibility: visible;
      }
    }

    .font-head{
      font-weight: bold;
      line-height: inherit;
      color: ${props => props.type === "MISSCALL_QUEUE" ? "var(--lipstick)" : "#005993"};
      font-family: SVN-Gilroy;
      font-size: 11px;
      display: flex;
      align-items: center;
      height: 40px;
    }
    .ticky-block{
      border-radius: 5px 0 0 5px;
      grid-template-columns: 12px 35px auto;
      .ticky-style{
        grid-column-start: 2;
      }
    }
    .button-icon{
      border-radius: 0 5px 5px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-right: 12px;
      &.disabled{
        pointer-events: none;
      }
    }
    .special{
      grid-template-columns: 80px auto;
      .checkbox{
        display: flex;
        align-items: center;
        span{
          margin-left: 10px;
        }
      }
    }

    .transaction-tooltip {
      font-family: "SVN-Gilroy";
      visibility: hidden;
      box-sizing: border-box;
      position: absolute;
      background: #555;
      padding: 5px;
      border-radius: 7px;
      color: #ffffff;
      z-index: 1;
    }

    .transaction-tooltip::after {
      content: " ";
      position: absolute;
      top: 100%; 
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
`


export {
    Bound
}