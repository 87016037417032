import React, { Component } from 'react'
import styled from "styled-components"
import { connect } from 'react-redux'


// action
import { openPopup, closePopup } from '../../actions/popupBioAction';
import { addReasonNotVerifyFingerButFace, reasonNotRegisterFingerButFace } from "../../actions/counterAction";

// images
import ic_close from "../../images/ic_close_gray.svg";

// data
import {
    listReasonNotRegisterFingerID
} from "../../data/data";
// import typesKeyDetailPopup from '../../data/typesKeyDetailPopup';


const Bound = styled.div`
  background: #fafafa;
  /* width: 400px; */
  max-height: auto;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 25px 20px;
  font-family: "SVN-Gilroy";
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  z-index: 999;
  box-shadow: 0 0 20px #00000070;
  & > figure {
    width: 100%;
    text-align: right;
    .ic_close {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title,
  .title2 {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
    &.title2 {
      margin: 27px 0 0 0;
    }
  }

  .btn-submit {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .warning {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: var(--vermillion);
    }
    button {
      bottom: 24px;
      right: 40px;
      border: none;
      outline: none;
      width: 100px;
      height: 36px;
      border-radius: 18px;
      background-color: #005993;
      text-transform: uppercase;
      font-family: SVN-Gilroy;
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
`;

const ReasonNotRegister = styled.div`
  .select-reason {
    margin-top: 16px;
    .radio-field,
    .radio-field2 {
      display: flex;
      align-items: center;
      margin-top: 17px;
      position: relative;
      input[type="radio"] {
        position: absolute;
        height: 0;
        width: 0;
        display: none;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0px;
        height: 16px;
        width: 16px;
        border: solid 0.8px #c8c8c8;
        background: #ffffff;
        border-radius: 50%;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input[type="radio"]:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark::after {
        /* left: 3.9px;
        top: 0.3px;
        width: 6px;
        height: 9px;
        border: solid #d71249;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); */
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: #d71249;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      div {
        margin-left: 26px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #222222;
      }
    }
  }
  .content-reason {
    margin-top: 16px;
    .title {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    textarea {
      font-family: "SVN-Gilroy";
      margin-top: 1px;
      resize: none;
      outline: none;
      width: 375px;
      height: 46px;
      border: solid 0.5px #c8c8c8;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 10px;
      line-height: 1.2;
      color: #222222;
    }
    textarea:disabled {
      background: #efefef;
      color: #2222228a;
    }
    textarea::placeholder {
      color: #222222;
      opacity: 0.25;
    }
  }
`;

class PopupNotRegisterFingerButFace extends Component {
    constructor(props) {
        super(props);
        this.state = {
          enableTextAreaFaceID: false,
          enableTextAreaFingerID: false,
          dataFaceID: {
            content: "",
            otherReason: ""
          },
          dataFingerID: {
            content: "",
            otherReason: ""
          },
          cannotConfirm: false
        };
    }

    checkToOpenTextAreaFaceID = () => {
        const lastRadio = document.querySelector(".radio-field:last-child input");
        const content = document.querySelector(
          'input[name="contentFaceID"]:checked'
        ).value;
        this.setState({
          cannotConfirm: false,
          enableTextAreaFaceID: lastRadio.checked,
          dataFaceID: {
            ...this.state.dataFaceID, 
            content, 
            // otherReason: "" 
          }
        });
    };

    otherReasonFaceID = e => {
        let value = e.target.value;
        this.setState({
          cannotConfirm: false,
          dataFaceID: { ...this.state.dataFaceID, otherReason: value }
        });
    };
    

    handleEventSubmit = async () => {
        const {
            content: contentFaceID,
            otherReason: otherReasonFaceID
        } = this.state.dataFaceID

        const isEmptyContentReasonFace = !contentFaceID.trim();
        const isEmptyContentOtherReasonFace = !otherReasonFaceID.trim();
        
        const errorEmptyReason = isEmptyContentReasonFace /* || isEmptyContentReasonFinger */;
        const errorEmptyOtherReason = isEmptyContentOtherReasonFace /* ||
        isEmptyContentOtherReasonFinger; */

        if (errorEmptyReason) {
            this.setState({ 
                cannotConfirm: true,
                errorEmptyReason: "Vui lòng nhập lý do",
                errorEmptyOtherReason: ""
            });
            return;
        }
    
        if(errorEmptyOtherReason){
            this.setState({ 
                cannotConfirm: true,
                errorEmptyReason: "",
                errorEmptyOtherReason: "Vui lòng nhập nội dung"
            });
            return;
        }
        // debugger
        const { 
          ticketId, 
          // key 
        } = this.props.data;
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => 
            item.TicketID === ticketId
        )
        
        if(!currentTicket) return;
        if(!currentTicket.CustomerInfos) return;

        const { dataFaceID } = this.state;
        
        const dataNotRegister = {
            ticketId,
            cusNum: currentTicket.CustomerInfos.CustomerNumber,
            reasonText: dataFaceID.content,
            otherReasonText: dataFaceID.otherReason
        }
        this.props.addReasonNotVerifyFingerButFace(
            dataNotRegister.ticketId,
            dataNotRegister.cusNum,
            dataNotRegister.reasonText,
            dataNotRegister.otherReasonText
        );
        this.props.onClosePopup();
        // if(key === typesKeyDetailPopup.fillReasonNRFingerBeforeSendSuperVisor){
        //     this.props.closePopup(1);
        // }

        // this.props.reasonNotRegisterFingerButFace(
        //     dataNotRegister.ticketId,
        //     dataNotRegister.reasonText,
        //     dataNotRegister.otherReasonText
        // )
        // this.props.onClosePopup();
    }

    render() {
        return (
            <Bound>
                <figure>
                    <img
                        onClick={() => {
                            this.props.onClosePopup();
                        }}
                        className="ic_close"
                        src={ic_close}
                        alt="ic_close"
                    />
                </figure>
                <div className="header">
                    <p className="title">Khách hàng không đăng ký sinh trắc học</p>
                </div>
                <ReasonNotRegister>
                    <div className="select-reason">
                        {listReasonNotRegisterFingerID.map((reason, key) => (
                        <label className="radio-field" key={key}>
                            <input
                                onClick={this.checkToOpenTextAreaFaceID}
                                type="radio"
                                name="contentFaceID"
                                value={reason}
                                checked={this.state.dataFaceID.content === reason}
                            />
                            <span className="checkmark" />
                            <div>{reason}</div>
                        </label>
                        ))}
                    </div>
                    <div className="content-reason">
                        <div className="title">Chi tiết lý do không đăng ký</div>
                        <textarea
                            onChange={e => this.otherReasonFaceID(e)}
                            placeholder="Nhập nội dung...không quá 200 ký tự"
                            maxLength="200"
                            value={this.state.dataFaceID.otherReason}
                            //disabled={!this.state.enableTextAreaFaceID}
                        />
                    </div>
                </ReasonNotRegister>
                <div className="btn-submit">
                  <p className="warning">
                      {
                      this.state.cannotConfirm ?
                          this.state.errorEmptyReason || this.state.errorEmptyOtherReason
                          :
                          null
                      }
                  </p>
                  <button onClick={this.handleEventSubmit}>Xác nhận</button>
                </div>
            </Bound>
        )
    }

    componentDidMount() {
        const { ticketId } = this.props.data;
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => 
            item.TicketID === ticketId
        )
        
        if(!currentTicket) return;
        if(!currentTicket.CustomerInfos) return;
        // debugger;
        const { 
            notVerifyFingerReason, 
            notVerifyFingerReason_otherReason 
        } = currentTicket;
        if(!notVerifyFingerReason || !notVerifyFingerReason_otherReason) return;

        this.setState({
            dataFaceID: {
                content: notVerifyFingerReason,
                otherReason: notVerifyFingerReason_otherReason
            }
        })

    }
    
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    openPopup: (type, levelPopup, data) => 
        dispatch(openPopup(type, levelPopup, data)),
    closePopup: (levelPopup) => 
        dispatch(closePopup(levelPopup)),
    reasonNotRegisterFingerButFace: (ticketId, reasonText, otherReasonText) =>
        dispatch(reasonNotRegisterFingerButFace(ticketId, reasonText, otherReasonText)),
    addReasonNotVerifyFingerButFace: (ticketId, cusNum, reasonText, otherReasonText) =>
        dispatch(addReasonNotVerifyFingerButFace(ticketId, cusNum, reasonText, otherReasonText)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupNotRegisterFingerButFace);
