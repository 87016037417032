import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//css
import {
    WrapSearchCustomer,
    WrapContentPage,
    WrapFooterPage,
} from './index.styles'
import { ACTIVE, APPONLINE, SUCCESS } from '../../../../../constant/keys'
import StepByStepBookingOnline from '../../StepByStepBookingOnline'

//imgs
import IcUserMini from '../../../images/SVG/IcUserMini'
import IcIdentifyMini from '../../../images/SVG/IcIndentifyMini'
import IcPhoneMini from '../../../images/SVG/IcPhoneMini'
import IcBank from '../../../images/SVG/IcBank'
import IcSearchWhite from '../../../images/SVG/IcSearchWhite'
import IcSearchGray from '../../../images/SVG/IcSearchGray'

//redux
import { setStepSelectBooking } from '../../../../../actions/bookAppointment'
import ButtonControl from '../../../controls/ButtonControl'
import {
    apiGetIcon,
    cancelTakeTicket,
    getInfoAppointmentOnline,
    getTicketQueueNumber,
    startTakeTicket,
} from '../../../../../actions/counterAction'
import Portal from '../../../controls/Portal'
import PopupWarningTime from '../PopupWarningTime'
import trimStartAndStripStr from '../../../ultils/trimStartAndStripStr'
import removeAccent from '../../../ultils/removeAccent'

export const EARLY = 'EARLY'
export const LATE = 'LATE'
const NORMAL = 'NORMAL'

const handleStatusTime = (time, timeEarly, timeLate) => {
    const duration = moment.duration(moment().diff(time))
    let mins = duration.asMinutes()
    if (moment().isBefore(moment(time)) && mins * -1 > timeEarly) {
        return EARLY
    }
    if (!moment().isBefore(moment(time)) && mins > timeLate) {
        return LATE
    }
    return NORMAL
}

const SearchCustomer = ({ onNext, setIsFail }) => {
    const dispatch = useDispatch()
    const {
        infoAppointmentOnline,
        dataConnectDesktop,
        appointmentNumber,
        appointmentId,
        timeEarly,
        timeLate,
    } = useSelector((state) => ({
        infoAppointmentOnline: state.counterReducer.infoAppointmentOnline,
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        appointmentNumber: state.counterReducer.appointmentNumber,
        appointmentId: state.counterReducer.appointmentId,
        timeEarly: state.counterReducer.timeEarly,
        timeLate: state.counterReducer.timeLate,
    }))

    const [code, setCode] = useState('')
    const [searchError, setSearchError] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [isShowPop, setIsShowPop] = useState('')

    const callbackSuccess = (id, number) => {
        //nếu tìm thấy mã mới thì cancel mã cũ đi
        if (appointmentId && appointmentId !== id) {
            dispatch(
                cancelTakeTicket(
                    dataConnectDesktop.OfficeID,
                    dataConnectDesktop.CounterID,
                    appointmentId
                )
            )
        }
        dispatch(
            startTakeTicket(
                dataConnectDesktop.OfficeID,
                dataConnectDesktop.CounterID,
                id,
                number
            )
        )
    }

    const callbackError = (data) => {
        switch (data.Code) {
            case 1:
                setSearchError('Mã đặt lịch đã được xuất, vui lòng thử lại.')
                break;
            case 42:
                setSearchError('Vé hiện tại đã quá hạn.')
                break;
            case 43:
                setSearchError('Mã đặt lịch đã được xuất, vui lòng thử lại.')
                break;
            case 44:
                setSearchError('Mã đặt lịch nằm ở chi nhánh/PGD khác. Vui lòng xem lại địa điểm trên phiếu đặt lịch hẹn.')
                break;
            case 45:
                setSearchError('Vé hiện tại chưa tới thời gian đặt lịch.')
                break;
            case 46:
                setSearchError('Không tìm thấy mã đặt lịch')
                break;
            case 47:
                setSearchError(`Mã đặt lịch ${data?.Result?.ApppointmentOnline} đang được tư vấn tại quầy ${data?.Result?.CounterName} của tư vấn viên ${data?.Result?.AdvisorName}`)
                break;
            default:
                break;
        }

        //if (code === 43) {
        //    setSearchError('Mã đặt lịch không tồn tại, vui lòng thử lại.')
        //} else if (code === 44) {
        //    setSearchError(
        //        'Mã đặt lịch nằm ở chi nhánh/PGD khác. Vui lòng xem lại địa điểm trên phiếu đặt lịch hẹn.'
        //    )
        //} else if (code === 42) {
        //    setSearchError('Vé hiện tại đã quá hạn.')
        //} else if (code === 45) {
        //    setSearchError('Vé hiện tại chưa tới thời gian đặt lịch.')
        //} else if (code === 46) {
        //    setSearchError('Không tìm thấy mã đặt lịch')
        //}
        appointmentId &&
            dispatch(
                cancelTakeTicket(
                    dataConnectDesktop.OfficeID,
                    dataConnectDesktop.CounterID,
                    appointmentId
                )
            )
    }

    const callbackInvalidRequest = () => {
        setSearchError('Yêu cầu gửi đi không hợp lệ: Mã lỗi 400')
        appointmentId &&
            dispatch(
                cancelTakeTicket(
                    dataConnectDesktop.OfficeID,
                    dataConnectDesktop.CounterID,
                    appointmentId
                )
            )
    }

    const handleSearch = () => {
        if (infoAppointmentOnline?.ApppointmentOnline === code) return
        if (!code) {
            setSearchError('Vui lòng nhập mã đặt lịch')
            appointmentId &&
                dispatch(
                    cancelTakeTicket(
                        dataConnectDesktop.OfficeID,
                        dataConnectDesktop.CounterID,
                        appointmentId
                    )
                )
        }
        dispatch(
            getInfoAppointmentOnline(
                dataConnectDesktop.OfficeID,
                dataConnectDesktop.CounterID,
                code,
                callbackSuccess,
                callbackError,
                callbackInvalidRequest
            )
        )
    }

    useEffect(() => {
        if (!appointmentNumber) return
        setCode(appointmentNumber)
        dispatch(
            getInfoAppointmentOnline(
                dataConnectDesktop.OfficeID,
                dataConnectDesktop.CounterID,
                appointmentNumber,
                callbackSuccess,
                callbackError,
                callbackInvalidRequest
            )
        )
    }, [appointmentNumber])

    const handleSubmit = () => {
        let status = handleStatusTime(
            infoAppointmentOnline?.AppointmentTime,
            timeEarly,
            timeLate
        )
        if (status === EARLY) {
            setIsShowPop(EARLY)
            return
        }
        if (status === LATE) {
            setIsShowPop(LATE)
            return
        }
        if (status === NORMAL) {
            handleNext()
            return
        }
    }

    const handleNext = () => {
        const callbackSuccess = () => {
            dispatch(
                setStepSelectBooking({
                    step1: SUCCESS,
                    step2: ACTIVE,
                })
            )
            setTimeout(() => {
                onNext()
            }, 200)
        }
        const callbackError = () => {
            setIsFail()
        }

        let arrServiceKey = infoAppointmentOnline?.ListService?.map(
            (item) => item.ServiceType
        )

        dispatch(
            getTicketQueueNumber(
                '',
                dataConnectDesktop.OfficeID,
                infoAppointmentOnline?.CustType,
                infoAppointmentOnline?.Fullname,
                infoAppointmentOnline?.Nid,
                '',
                infoAppointmentOnline?.PhoneNumber,
                infoAppointmentOnline?.Email,
                arrServiceKey,
                infoAppointmentOnline?.ApppointmentOnline,
                APPONLINE,
                callbackSuccess,
                callbackError
            )
        )
    }

    useEffect(() => {
        if (
            infoAppointmentOnline?.Fullname &&
            infoAppointmentOnline?.PhoneNumber &&
            (infoAppointmentOnline?.Nid ||
                infoAppointmentOnline?.BusinessLicenseNumber) &&
            infoAppointmentOnline?.IssueDate &&
            infoAppointmentOnline?.PlaceOfIssue &&
            infoAppointmentOnline?.Office &&
            infoAppointmentOnline?.AppointmentTime &&
            infoAppointmentOnline?.ListService?.length > 0
        ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [infoAppointmentOnline])

    return (
        <WrapSearchCustomer>
            <div className="title-page">
                <div className="bold">Lấy số khách hàng online</div>
                <div className="normal">
                    Thông tin vé & khách hàng đang được tư vấn
                </div>
            </div>
            <StepByStepBookingOnline />
            <div className="wrap-input-search">
                <div className="ticket-code">
                    Mã đặt lịch<span>*</span>
                </div>
                <div className="input-search">
                    <IcSearchGray />
                    <input
                        className="input-ticket-code"
                        placeholder="Nhập mã đặt lịch"
                        maxLength={10}
                        onChange={(e) => {
                            setCode(trimStartAndStripStr(removeAccent(e.target.value)))
                            setSearchError('')
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        value={code}
                    />
                    <div className="btn-search" onClick={handleSearch}>
                        <IcSearchWhite />
                    </div>
                </div>
                <div className="search-error">{searchError}</div>
            </div>
            {infoAppointmentOnline?.ApppointmentOnline && (
                <WrapContentPage>
                    <div className="wrap-container">
                        <div className="wrap-list-info">
                            <div className="title-content">
                                Thông tin số xếp hàng cho mã đặt lịch:{' '}
                                {infoAppointmentOnline?.ApppointmentOnline}
                            </div>
                            <div className="list-info">
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcUserMini />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            Tên khách hàng
                                        </div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.Fullname ||
                                                '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcUserMini />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            Hạng khách hàng
                                        </div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.Rank || '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcPhoneMini />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            Số điện thoại
                                        </div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.PhoneNumber ||
                                                '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcIdentifyMini />
                                    </div>
                                    {infoAppointmentOnline?.BusinessLicenseNumber ? (
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                Đăng ký kinh doanh
                                            </div>
                                            <div className="value-text">
                                                {
                                                    infoAppointmentOnline?.BusinessLicenseNumber
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="wrap-text">
                                            <div className="key-text">
                                                CMND/CCCD/HC
                                            </div>
                                            <div className="value-text">
                                                {infoAppointmentOnline?.Nid ||
                                                    '-'}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcIdentifyMini />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">Ngày cấp</div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.IssueDate ||
                                                '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcIdentifyMini />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">Nơi cấp</div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.PlaceOfIssue ||
                                                '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcBank />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            PGD/ Chi nhánh
                                        </div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.Office ||
                                                '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="block-info">
                                    <div className="wrap-icon">
                                        <IcBank />
                                    </div>
                                    <div className="wrap-text">
                                        <div className="key-text">
                                            Thời gian hẹn
                                        </div>
                                        <div className="value-text">
                                            {infoAppointmentOnline?.AppointmentTime
                                                ? moment(
                                                    infoAppointmentOnline?.AppointmentTime
                                                ).format('HH:mm - DD/MM/YYYY')
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-list-info">
                            <div className="title-content transaction">
                                Giao dịch
                            </div>
                            <div className="list-info">
                                {infoAppointmentOnline?.ListService?.map(
                                    (item, index) => (
                                        <div key={index} className="block-info">
                                            {/*<div className="wrap-icon">
                                                <img
                                                    src={apiGetIcon(
                                                        item?.ID,
                                                        24
                                                    )}
                                                    alt="service"
                                                />
                                            </div>*/}
                                            <div className="wrap-text">
                                                <div className="key-text">
                                                    Giao dịch {index + 1}
                                                </div>
                                                <div className="value-text">
                                                    {item?.Name || '-'}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </WrapContentPage>
            )}
            <WrapFooterPage>
                <div className="wrap-btn">
                    <ButtonControl
                        text="Xuất vé"
                        className="btn-next"
                        onClick={handleSubmit}
                        isDisable={isDisabled}
                    />
                </div>
            </WrapFooterPage>
            {isShowPop && (
                <Portal>
                    <PopupWarningTime
                        status={isShowPop}
                        handleBack={() => setIsShowPop('')}
                        handleTakeTicket={handleNext}
                    />
                </Portal>
            )}
        </WrapSearchCustomer>
    )
}

export default SearchCustomer
