import React from 'react'

export default function IcPopFail(props) {
    return (
        <svg
            {...props}
            width={52}
            height={52}
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={26} cy={26} r={26} fill="#F31919" />
            <path
                d="M29 38C29 39.6569 27.6569 41 26 41C24.3431 41 23 39.6569 23 38C23 36.3431 24.3431 35 26 35C27.6569 35 29 36.3431 29 38Z"
                fill="white"
            />
            <path
                d="M23 14C23 12.3431 24.3431 11 26 11C27.6569 11 29 12.3431 29 14V30C29 31.6569 27.6569 33 26 33C24.3431 33 23 31.6569 23 30V14Z"
                fill="white"
            />
        </svg>
    )
}
