import Axios from "axios";

import * as types from "./types";
import { host } from '../host'

export const clearError = (typeErr) => dispatch => {
	dispatch({
		type: types.CLEAR_ERR,
		typeErr
	})
}

export const catchErrConnection = () => ({
	type: types.ERROR_CONNECTION,
	err: {
		[types.ERROR_CONNECTION]: 408
	}
})

export const addLoading = () => ({
	type: types.RUN_LOADING
})

export const removeLoading = () => ({
	type: types.REMOVE_LOADING
})

export const alertLogout = () => ({
	type: types.ALERT_LOGOUT
})

// export const showErrConnection = () => ({
// 	type: types.ERR_CONNECTION
// })

// export const removeErrorConnection = () => ({
// 	type: types.REMOVE_ERR_CONNECTION
// })


export const alertConnectingSocket = () => ({
	type: types.CONNECTTING_SOCKET
})

export const alertFailedToConnectSocket = () => ({
	type: types.FAILED_TO_CONNECT_SOCKET
})

export const alertSuccessToConnectSocket = () => ({
	type: types.SUCCESS_TO_CONNECT_SOCKET
})

export const setStatusDevice = (status) => ({
	type: types.SET_STATUS_DEVICE,
	status
})

export const getClientName = (clientName) => ({
	type: types.GET_CLIENT_NAME,
	clientName
})

const apiGetClientName = process.env.NODE_ENV !== 'production' ?
	host + 'dns/host/clientName'
	:
	"https://coredns.vietinbank.vn/dns/host/clientName"

export const getNameOfClient = () => async dispatch => {
	Axios.defaults.withCredentials = false;
	delete Axios.defaults.headers.common["CSRF-TOKEN"];
	delete Axios.defaults.headers.common.office;

	if (process.env.NODE_ENV === 'development') {
		dispatch(getClientName('univer'))
		return;
	}

	try {
		const resPc = await Axios.get(apiGetClientName);
		console.log("clientName: ", resPc.data)
		if (typeof (resPc.data) !== 'string' || !resPc.data) {
			dispatch(getClientName("EMPTY"))
			return;
		}

		dispatch(getClientName(resPc.data))
	}
	catch (err) {
		process.env.REACT_APP_BUILD_TYPE === "wee" && dispatch(getClientName('224.233.63.6'))
		console.dir(err)
	}
}