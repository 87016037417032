import React from 'react'
//css
import { WrapPopupRequireCompleteAdvise } from './index.styles'
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
//img
import IcPopConfirmCounselor from '../../../images/SVG/IcPopConfirmCounselor'
//control
import ButtonControl from '../../../controls/ButtonControl'

const PopupRequireCompleteAdvise = ({ onClose }) => {
    return (
        <OverlayFullScreen>
            <WrapPopupRequireCompleteAdvise>
                <IcPopConfirmCounselor />
                <div className="wrap-title">
                    <div className="title">
                        Số vé của khách hàng đang được gọi vào quầy giao dịch.
                    </div>
                    <div className="desc">
                        Vui lòng kết thúc quá trình tư vấn.
                    </div>
                </div>
                <div className='wrap-btn'>
                    <ButtonControl
                        text="Đã hiểu"
                        className="btn-done"
                        onClick={onClose}
                    />
                </div>
            </WrapPopupRequireCompleteAdvise>
        </OverlayFullScreen>
    )
}

export default PopupRequireCompleteAdvise