import * as bioTypes from '../data/bioTypes'
import * as typeRequestBios from '../actions/typeRequestBio'
import getFromChannel from './getFromChannel'

const applyBioToReducerAndLocalStorage = (
    ticketId,
    bioDatas,
    statusBiometric = null
) => {
    // have data biometric exist
    const objBiometric = {}
    bioDatas.map((bioData) => {
        if (bioData.EnrollData) {
            //FACE
            if (bioData.BioType === bioTypes.FACE) {
                objBiometric[bioData.BioType] = {
                    [typeRequestBios.REGISTER]: bioData.EnrollData[0],
                    fromChannel: getFromChannel(bioData.FromChannel)
                }
            }
            // FINGER
            else {
                // debugger;
                objBiometric[bioData.BioType] = {
                    [typeRequestBios.REGISTER]: bioData.EnrollData,
                    fromChannel: getFromChannel(bioData.FromChannel)
                }
            }
        }
        if (bioData.IsComfirmed) {
            if (!Object.keys(objBiometric).includes(bioData.BioType)) {
                objBiometric[bioData.BioType] = {}
            }
            if (bioData.EnrollData)
                objBiometric[bioData.BioType][typeRequestBios.CONFIRM] = 0
            if (bioData.BioActionType)
                objBiometric[bioData.BioType].bioActionType =
                    bioData.BioActionType
            objBiometric[bioData.BioType].usedToAuthenticateTransaction =
                bioData.IsConfirmFinger
            if (bioData.IsConfirmFinger /* && !bioData.IsActive */)
                objBiometric[bioData.BioType].isHang = !bioData.IsActive
            objBiometric[bioData.BioType].fromChannel = getFromChannel(bioData.FromChannel)
        }
        return true
    })

    // map with localStorage
    const biometricData = JSON.parse(localStorage.getItem('biometricData'))
    let waitingConfirm = {}
    if (biometricData && biometricData[ticketId]) {
        const tmpWaitingConfirm = biometricData[ticketId].waitingConfirm
        if (tmpWaitingConfirm) {
            const ticketExist = objBiometric[ticketId]
            const bioTypeExist =
                ticketExist && ticketExist[tmpWaitingConfirm.typeBio]
            const typeRequestBioExist =
                bioTypeExist &&
                ticketExist[tmpWaitingConfirm.typeBio][
                tmpWaitingConfirm.typeRequestBio
                ]
            if (!typeRequestBioExist)
                waitingConfirm = biometricData[ticketId].waitingConfirm
        }
    }
    const tmpObj = {
        ...objBiometric,
    }
    if (Object.keys(waitingConfirm).length > 0)
        tmpObj.waitingConfirm = waitingConfirm

    return tmpObj
}

export default applyBioToReducerAndLocalStorage
