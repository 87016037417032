import React from 'react'

const IcMoveGreen = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1429 7.5L5 12L5 3L10.1429 7.5Z" fill="#0FB11F" />
        </svg>
    )
}

export default IcMoveGreen