import React from 'react'

const IcArrowRightWhite = (props) => {
    return (
        <svg {...props} width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 6L0.88889 11.5V0.5L7 6Z" fill="white" />
        </svg>
    )
}

export default IcArrowRightWhite