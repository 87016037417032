import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WrapPopupConfirmCounselor } from './index.styles'

//control
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
import ButtonControl from '../../../controls/ButtonControl'

//action
import { sendStartAdvise, setDataTicketCounselor, setIsShowPopupCounselor, setShowContentCounselorPage } from '../../../../../actions/counselorAction'

//constants
import { APPONLINE, QUEUETICKET } from '../../../../../constant/keys'

//imgs
import IcPopConfirmCounselor from '../../../images/SVG/IcPopConfirmCounselor'



export default function PopupConfirmCounselor({ onClose, dataTicket, isShowPopup }) {
    const dispatch = useDispatch()
    const {
        dataConnectDesktop
    } = useSelector((state) => ({
        dataConnectDesktop: state.counterReducer.dataConnectDesktop
    }))
    // console.log(dataTicket)

    const handleToCounselorPage = () => {
        dispatch(setDataTicketCounselor(dataTicket))
        const callbackSuccess = () => {
            if (isShowPopup) {
                dispatch(setIsShowPopupCounselor(true))
                onClose()
            } else {
                dispatch(setShowContentCounselorPage(true))
            }
        }
        dispatch(sendStartAdvise(dataConnectDesktop?.OfficeID, dataConnectDesktop?.CounterID, dataTicket?.TicketID, String(dataTicket?.Number), dataTicket?.From ? dataTicket?.From : QUEUETICKET, callbackSuccess))
    }

    return (
        <OverlayFullScreen>
            <WrapPopupConfirmCounselor>                
                <div className="pop-content">
                    <IcPopConfirmCounselor />
                    <div className="bold">
                        Bắt đầu tư vấn khách hàng mã vé{' '}
                        <span>“{dataTicket?.Number} - {dataTicket?.Fullname ? dataTicket?.Fullname : dataTicket?.CustomerInfos?.FullNameVi ? dataTicket?.CustomerInfos?.FullNameVi : dataTicket?.CustomerInfos?.Name ? dataTicket?.CustomerInfos?.Name : "KH vãng lai"}”</span>
                    </div>
                    <div className="note">
                        (*) Thời gian tư vấn sẽ được đếm sau khi bạn bắt đầu tư
                        vấn
                    </div>
                </div>
                <div className="pop-btn-container">
                    <ButtonControl
                        text="hủy bỏ"
                        className="btn-counselor-cancel"
                        onClick={onClose}
                    />
                    <ButtonControl
                        text="bắt đầu"
                        className="btn-counselor-confirm"
                        onClick={handleToCounselorPage}
                    />
                </div>
            </WrapPopupConfirmCounselor>
        </OverlayFullScreen>
    )
}
