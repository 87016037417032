import React, { useState, useEffect, useRef } from 'react'

// styles
import { WrapPopupStyles } from '../WrapPopupStyles'
import { WrapDuplicateBio } from './index.styles'

// image
import icCloseGray from '../../../../images/ic_close_gray.svg'

// component
import CustomerInfos from './CustomerInfos'
import CustomerInfoDupFinger from './CustomerInfoDupFinger'

// constant
import { dataFingerPrint } from '../../../../data/infosType'
import { FACE } from '../../../../data/bioTypes'
import { TELLER } from '../../../../data/userRole'

// api
import { apiGetBioFaceImage, apiGetBioFile, apiGetBioFingerImage } from '../../../../actions/biometricAction'
import { apiGetBioFaceImageCollector, apiGetBioFileCollector, apiGetBioFingerImageCollector } from '../../../../actions/biometricWithInBioCollectorAction'

// helper
import useIsRole from '../../../../helper/hooks/useIsRole'
import getFromChannel from '../../../../helper/getFromChannel'

// hooks
import useMoveElement from '../../../../helper/hooks/useMoveElement'

const BEFORE_CUST_TAB = "BEFORE_CUST_TAB"
const LAST_CUST_TAB = "LAST_CUST_TAB"

const DuplicateBio = ({
    data,
    queryCode,
    handleClose
}) => {
    const isTeller = useIsRole(TELLER)

    const DuplicateBioRef = useRef(null)

    const [activeTab, setActiveTab] = useState(BEFORE_CUST_TAB);
    const [currentCust, setCurrentCust] = useState({});
    const [listLastCust, setListLastCust] = useState([]);

    useMoveElement(DuplicateBioRef, ".wrap-header > .title")

    const apiGetFile = isTeller ? apiGetBioFile : apiGetBioFileCollector
    const apiGetFace = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector
    const apiGetFinger = isTeller ? apiGetBioFingerImage : apiGetBioFingerImageCollector

    const _executeCustomerInfos = (custInfos, isBeforeCust) => {
        const {
            BioDatas,
            CurrentBioData,
            CustomerInfos,
            TellerInfos,
            FromChannel,
            SupervisorInfos,
            RequestAt,
            AcceptedAt,
            BranchCode,
            OfficeName,
            BioType: BioTypeDuplicate
        } = custInfos || {}
        const {
            FullNameVi,
            CustomerNumber,
            Name,
            CustomerType,
            IdNumber,
            IdIssueDate,
            IdIssuePlace,
            MobileNumber,
            Address
        } = CustomerInfos || {}

        const faceBioInBioDatas = BioDatas?.find(item => item.BioType === FACE)
        const faceBioInCurrentBioData = CurrentBioData?.find(item => item.BioType === FACE)

        const bioDataDuplInBioDatas = BioTypeDuplicate !== FACE
            ? BioDatas?.find(item => item.BioType === BioTypeDuplicate)
            : null
        const bioDataDuplInCurrentBioData = BioTypeDuplicate !== FACE
            ? CurrentBioData?.find(item => item.BioType === BioTypeDuplicate)
            : null

        const nameOfBioDupl = BioTypeDuplicate !== FACE && dataFingerPrint[BioTypeDuplicate].label

        return {
            infos: {
                FullNameVi,
                CustomerNumber,
                Name,
                CustomerType,
                IdNumber,
                IdIssueDate,
                IdIssuePlace,
                MobileNumber,
                Address,
            },
            bioInfos: {
                faceImg: faceBioInBioDatas
                    ? apiGetFile(faceBioInBioDatas.EnrollData[0])
                    : apiGetFace(faceBioInCurrentBioData.EnrollData[0]),
                fingerImgs: bioDataDuplInBioDatas
                    ? bioDataDuplInBioDatas?.EnrollData.map(item => apiGetFile(item))
                    : bioDataDuplInCurrentBioData?.EnrollData.map(item => apiGetFinger(item)),
                fingerName: nameOfBioDupl,
                faceChanelExecute: BioTypeDuplicate === FACE
                    ? isBeforeCust
                        ? FromChannel
                        : faceBioInBioDatas
                            ? getFromChannel(faceBioInBioDatas.FromChannel)
                            : getFromChannel(faceBioInCurrentBioData.FromChannel)
                    : bioDataDuplInBioDatas
                        ? getFromChannel(bioDataDuplInBioDatas.FromChannel)
                        : getFromChannel(bioDataDuplInCurrentBioData.FromChannel),
                fingerChanelExecute: bioDataDuplInBioDatas
                    ? getFromChannel(bioDataDuplInBioDatas?.FromChannel)
                    : getFromChannel(bioDataDuplInCurrentBioData?.FromChannel)
            },
            TellerInfos,
            SupervisorInfos,
            RequestAt,
            acceptAt: !!SupervisorInfos && AcceptedAt,
            BranchCode,
            OfficeName
        }
    }

    useEffect(() => {
        if (!data) return;
        const { CurrentCustomerInfos, DuplicatedCustomersInfos } = data
        const currentCust = _executeCustomerInfos(CurrentCustomerInfos)
        setCurrentCust(currentCust)
        const listLastCust = DuplicatedCustomersInfos.map(item => _executeCustomerInfos(item, true))
        setListLastCust(listLastCust)
    }, [data]);

    const isDupFace = /^FACE/.test(data?.CodeName)

    const title = (() => {
        switch (true) {
            case isDupFace:
                return "Thông tin trùng STH khuôn mặt"
            case /^FING/.test(data?.CodeName):
                return "Thông tin trùng STH vân tay"
            default:
                return 'Thông tin trùng STH'
        }
    })()

    return (
        <WrapPopupStyles>
            <WrapDuplicateBio isDupFace={isDupFace} ref={DuplicateBioRef}>
                <div className="wrap-header">
                    <div className="title">{title}</div>
                    <img className="ic-close" src={icCloseGray} alt="close" onClick={handleClose} />
                    <div className="wrap-tab">
                        <div className="wrap-inner-tab">
                            <label htmlFor="before-cust-tab">
                                <input
                                    type="radio"
                                    id="before-cust-tab"
                                    value={BEFORE_CUST_TAB}
                                    name="tab"
                                    defaultChecked={true}
                                    onClick={() => setActiveTab(BEFORE_CUST_TAB)}
                                />
                                <span>Khách hàng đăng ký trước</span>
                            </label>
                            <label htmlFor="last-cust-tab">
                                <input
                                    type="radio"
                                    id="last-cust-tab"
                                    value={LAST_CUST_TAB}
                                    name="tab"
                                    onClick={() => setActiveTab(LAST_CUST_TAB)}
                                />
                                <span>Khách hàng đăng ký sau</span>
                            </label>
                        </div>
                        <div className="query-code"><span>Mã truy vấn:</span> {data.CodeName}</div>
                    </div>
                </div>
                <div className="wrap-content">
                    {
                        activeTab === BEFORE_CUST_TAB &&
                        listLastCust.map((item) => (
                            isDupFace
                            ? <CustomerInfos key={item.RequestAt} dupInfos={item} />
                            : <CustomerInfoDupFinger key={item.RequestAt} dupInfos={item} />
                        ))
                    }
                    {
                        activeTab === LAST_CUST_TAB &&
                        (
                            isDupFace
                            ? <CustomerInfos dupInfos={currentCust} />
                            : <CustomerInfoDupFinger dupInfos={currentCust} />
                        )
                    }

                </div>
            </WrapDuplicateBio>
        </WrapPopupStyles>
    )
}

export default DuplicateBio
