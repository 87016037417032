import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'

// image
import icFingerRed from "../../../images/img-finger-red.svg";
import btn_Recall_green from '../../../images/btn_Recall_green.svg'
import icPrintBlue from "../../../images/ic_print_blue.svg";

// component;
import ButtonControl from '../../MainPage/Mainscreen/Task/ButtonControl';

// action
import { openPopup, closePopup } from "../../../actions/popupBioAction";
import { getInfoCustomer, addBiometricExistFromCustomerInfos} from "../../../actions/counterAction";
import { getCurrentBioEnroll } from "../../../actions/biometricAction";

// data
import { popupBioTypes } from "../../../data/PopupTypes"
// import { TELLER } from "../../../data/userRole"

// helper
import checkApplyBiometricForTicket from "../../../helper/checkApplyBiometricForTicket";

const Bound = styled.div`
	width: 100%;
	text-align: center;

	& > img {
		margin-top: 46px;
	}

	.detail {
		margin-top: 13px;
		font-family: "SVN-Gilroy";
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
	}

	.note {
		margin-top: 13px;
		margin-bottom: 38px;
		font-family: "SVN-Gilroy";
		font-size: 14px;
		line-height: 128%;
		color: var(--peacock-blue);
	}
	.footer {
		width: 100%;
		background: #fff;
		padding: 16px 0 20px;
		position: relative;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;

		.print {
			position: absolute;
		    top: 50%;
		    left: 20px;
		    transform: translate(0, -50%);
		    display: none;
		}

		
	}
`;

class AlertFingerHanging extends Component {

	handleSubmit = async () => {
		const currentTicket = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === this.props.ticketId)
		if(currentTicket.CustomerType === "INCUST"){
			this.props.closePopup(1);
			return;
		}
		await checkApplyBiometricForTicket(this, currentTicket.TicketID)
		const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
  		if(isVirtualTablet) return;
		const POPUP_LEVEL_1 = 1;
		this.props.openPopup(
			popupBioTypes.PopupUserManage, 
			POPUP_LEVEL_1, 
			{
				...currentTicket, 
				flowUpdate: true,  
				autoNextBiometric: true
			}
		)
	}

	render() {
		// const isTeller = this.props.counterReducer.dataLogin.Role === TELLER;
		const currentTicket = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === this.props.ticketId)
		if(!currentTicket) return null;
		return (
			<Bound>
				<img src={icFingerRed} alt="finger" />
				<div className="detail">
					<div>Vân tay khách hàng đang ở trạng thái</div>
					<div>"Treo"</div>
				</div>
				<div className="note">
					{
						currentTicket.CustomerType === "INCUST"?
						<>
							<div>Khách hàng là cán bộ ngân hàng.</div> 
							<div>Vui lòng liên hệ cán bộ điện toán kích hoạt vân tay</div>
							<div>để xác thực khách hàng</div>
						</>
						:
						<>
							<div> Vui lòng kích hoạt vân tay để</div>
							<div> xác thực khách hàng </div>
						</>
					}
				</div>
				<div className="footer">
					<img className="print" src={icPrintBlue} alt="print" />
					<ButtonControl
	                    bgColor="#0FB11F"
	                    width={currentTicket.CustomerType === "INCUST"?"100px":"200px"}
	                    height='36px'
	                    padding='0px'
	                    mr_img='0px'
	                    icon={btn_Recall_green}
	                    content={currentTicket.CustomerType === "INCUST"?"Xác nhận":"Đăng ký/ cập nhật STH"}
	                    onSubmit={this.handleSubmit} 
	                />
				</div>
			</Bound>
		)
	}
}

const mapStateToProps = state => ({
	counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
	closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
	openPopup: (typePopup, levelPopup, data) => dispatch(openPopup(typePopup, levelPopup, data)),
	getInfoCustomer: (counterId, customerNumber, typeSearch=0) => dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
	addBiometricExistFromCustomerInfos: (bioInfos, ticketId) => dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
	getCurrentBioEnroll: (counterId, ticketId, customerNumber=undefined) => dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertFingerHanging);