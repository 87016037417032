import React from 'react'

export default function IcSelected(props) {
    return (
        <svg
            {...props}
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={17} height={17} rx={4} fill="#005993" />
            <path
                d="M5 8.57895L7.21053 10.7895L12 6"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
