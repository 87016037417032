import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//css
import { WrapStepByStepBookingOnline } from './index.styles'
//imgs

//constant
import { ACTIVE } from '../../../../constant/keys';
import IcCheckWhite from '../../images/SVG/IcCheckWhite';



export default function StepByStepBookingOnline() {

    const dispatch = useDispatch()

    const stepSelect = useSelector(state => state.bookAppointment.stepSelect)

    return (
        <>
            <WrapStepByStepBookingOnline>
                <div className='wrap-step'>
                    {
                        stepSelect.step1 === ACTIVE ?
                            <div className='step active frist'>
                                <span className="icon">1</span>
                                <div className='step-text active'>Tìm kiếm khách hàng</div>

                            </div>
                            :
                            <div className='step frist'>
                                <span className="icon"><IcCheckWhite/></span>
                                <div className='step-text success'>Tìm kiếm khách hàng</div>
                            </div>
                    }
                </div>                
                <div className='wrap-step'>
                    {
                        stepSelect.step2 === ACTIVE
                            ?
                            <div className='step active'>
                                <span className="icon">2</span>
                                <div className='step-text active'>Xuất vé</div>

                            </div>
                            :
                            <div className='step'>
                                <span className="icon">2</span>
                                <div className='step-text'>Xuất vé</div>
                            </div>
                    }
                </div>
            </WrapStepByStepBookingOnline>
        </>

    )
}
