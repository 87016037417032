import styled from 'styled-components'

const WrapIssueTicket = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .title-page {
        color: var(--Black, #191919);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        padding: 16px 20px;
        .normal {
            color: var(--Gray, #636060);
            font-size: 14px;
            font-weight: 500;
            line-height: 128%;
            margin-top: 2px;
        }
    }
`
const WrapContentPage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--Background, #f7f7f7);
    .wrap-title-content {
        display: none;
        /* background-color: #FFFFFF; */
    }
    .wrap-container {
        max-height: 470px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        overflow-y: overlay;
        overflow-x: hidden;
    }
`

const WrapFooterPage = styled.div`
   display: flex;
    flex-direction: column;
    width: 100%;
    height: 84px;
    border-top: 1px solid var(--Gray-3, #E6E6E6);
    .wrap-btn {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .btn-download {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--Black, #191919);
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.3px;
            cursor: pointer;
        }
        .btn-next {
            display: flex;
            height: 44px;
            width: 150px;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background-color: #005993;
        }
    }
`

export { WrapIssueTicket, WrapContentPage, WrapFooterPage }
