import React from 'react'

const IcCheckBox = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width={17} height={17} rx="3.5" fill="#F7F7F7" />
            <rect x="0.5" y="0.5" width={17} height={17} rx="3.5" stroke="#E6E6E6" />
        </svg>
    )
}

export default IcCheckBox