import React from 'react'

const IcCheckBlueMini = (props) => {
    return (
        <svg {...props} width={19} height={19} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="9.5" r="9.5" fill="#005993" />
            <path d="M5.58838 9.77938L8.5222 12.7132L13.4119 7.8235" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCheckBlueMini