import styled from "styled-components";


const WrapGetNumberTicket = styled.div`
    width: 740px;
    min-height: 601px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    .ic-close{
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }
`

const WrapScreenPage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;   
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--White, #FFF);
`
export { WrapGetNumberTicket, WrapScreenPage }