
const hostConfigs = {
    dev: 'http://localhost:8080/api/',
    // dev: 'https://counter.wee.vn/api/',
    ppe: 'https://prekioskcounters.vietinbank.vn/api/',
    uat: 'https://uatkioskcounter.vietinbank.vn/api/',
    product: 'https://sdbcounter.vietinbank.vn/api/',
    // wee: 'https://vtb-counter.wee.vn/api/'
    wee: 'https://vtb1-counter.wee.vn/api/'
}

const webSocketUrlConfigs = {
    dev: 'ws://localhost:8999',
    // dev: 'wss://counter.wee.vn/ws/connection/websocket',
    ppe: 'wss://prekioskcounters.vietinbank.vn/ws/connection/websocket',
    uat: 'wss://uatkioskcounter.vietinbank.vn/ws/connection/websocket',
    product: 'wss://sdbcounter.vietinbank.vn/ws/connection/websocket',
    // wee: 'wss://vtb-counter.wee.vn/ws/connection/websocket'
    wee: 'wss://vtb1-counter.wee.vn/ws/connection/websocket'
}

const host = process.env.REACT_APP_BUILD_TYPE
    ? hostConfigs[process.env.REACT_APP_BUILD_TYPE]
    : hostConfigs.dev
const webSocketUrl = process.env.REACT_APP_BUILD_TYPE
    ? webSocketUrlConfigs[process.env.REACT_APP_BUILD_TYPE]
    : webSocketUrlConfigs.dev

console.log("Build PPE: 17/09/2024 14:30")

export { host, webSocketUrl }
