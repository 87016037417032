import React from 'react'

const LogoVietinbankTicket = (props) => {
    return (
        <svg {...props} width={108} height={27} viewBox="0 0 108 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.9888 19.6054V17.4517H29.4968V15.1484H26.7764V17.4517H25.417V19.6054H26.7764V23.5733C26.7764 24.8321 27.8146 26.6992 30.0648 26.6992L30.9869 26.6699L30.9907 24.2657C30.8927 24.2745 30.8341 24.2779 30.8124 24.2779C30.0317 24.2779 29.4968 23.6361 29.4968 22.9601V19.6054H30.9888Z" fill="#005993" />
            <path d="M24.908 22.9175H17.9364C17.9829 23.9274 18.879 24.9452 20.1409 24.9452C20.7992 24.9452 21.6215 24.6026 22.0718 23.7983H24.6634C24.3738 24.646 23.7078 25.517 22.942 26.0826C22.1274 26.6806 21.1764 26.9749 20.1736 26.9749C18.8306 26.9749 17.6842 26.4979 16.7351 25.5444C15.7803 24.5958 15.3296 23.3027 15.3296 22.0724C15.3296 20.8197 15.7696 19.6062 16.7027 18.6416C17.6465 17.6607 18.8619 17.1601 20.1542 17.1601C21.4374 17.1601 22.6787 17.6607 23.6118 18.6416C24.5232 19.6062 25.0192 20.8346 24.9788 22.1897C24.9724 22.3998 24.9449 22.7245 24.908 22.9175ZM22.2993 21.0664C22.1133 20.0169 21.1898 19.2594 20.1409 19.2594C19.1438 19.2594 18.1753 19.9568 17.9829 21.0664H22.2993Z" fill="#005993" />
            <path d="M11.5684 19.8963C11.5684 18.4338 12.7651 17.4468 14.0171 17.4468H14.2849V26.6997H11.5752L11.5684 19.8963Z" fill="#005993" />
            <path d="M32.0879 19.8963C32.0879 18.4338 33.2846 17.4468 34.5366 17.4468H34.8044V26.6997H32.0951L32.0879 19.8963Z" fill="#005993" />
            <path d="M41.9813 26.6973V21.1048C41.9813 20.3773 41.4189 19.6183 40.4988 19.6183C39.3619 19.6183 38.95 20.5323 38.95 21.1497V26.6973H36.187V17.4661H38.7633V18.4486H38.8037C39.3025 17.7718 40.2607 17.1544 41.4041 17.1544C43.4672 17.1544 44.7439 18.846 44.7439 20.4657V26.6973H41.9813Z" fill="#005993" />
            <path d="M64.1409 17.4575V18.4395H64.1089C63.8525 18.0067 63.4676 17.6767 62.9544 17.4495C62.4949 17.2443 61.9928 17.1411 61.4476 17.1411C60.1061 17.1411 59 17.6253 58.1291 18.5941C57.2901 19.5297 56.8706 20.6873 56.8706 22.0668C56.8706 23.4676 57.2821 24.6331 58.105 25.5638C58.9596 26.5211 60.0744 27 61.4476 27C61.9977 27 62.503 26.8995 62.9624 26.6997C63.497 26.4671 63.8791 26.1291 64.1089 25.6852H64.1409V26.6833H66.8662V17.4575H64.1409ZM63.4779 23.7462C63.0546 24.195 62.5224 24.4196 61.88 24.4196C61.2586 24.4196 60.7309 24.1923 60.2977 23.7378C59.8748 23.289 59.6633 22.7428 59.6633 22.0991C59.6633 21.4284 59.8668 20.8685 60.2741 20.4193C60.6921 19.9545 61.222 19.7216 61.8644 19.7216C62.5117 19.7216 63.047 19.9435 63.4699 20.3869C63.8932 20.8308 64.1047 21.3903 64.1047 22.0668C64.1047 22.7371 63.8959 23.2974 63.4779 23.7462Z" fill="#005993" />
            <path fillRule="evenodd" clipRule="evenodd" d="M94.0401 7.6275H100.366V13.1934C102.72 13.5516 104.946 14.3053 106.972 15.3798C107.632 13.9874 108 12.4298 108 10.7873C108 4.82895 103.163 0 97.2028 0C91.2424 0 86.4121 4.82895 86.4121 10.7873C86.4121 12.4264 86.7779 13.981 87.434 15.373C89.4636 14.2984 91.6859 13.5482 94.0401 13.19V7.6275Z" fill="#005993" />
            <path fillRule="evenodd" clipRule="evenodd" d="M97.203 21.5518C100.978 21.5518 104.3 19.615 106.231 16.6817C103.535 15.2554 100.457 14.448 97.1927 14.448C93.9355 14.448 90.8643 15.2524 88.1748 16.6752C90.1031 19.612 93.4246 21.5518 97.203 21.5518Z" fill="#D71249" />
            <path d="M74.0389 26.6973V21.1048C74.0389 20.3773 73.4769 19.6183 72.5568 19.6183C71.4195 19.6183 71.0077 20.5323 71.0077 21.1497V26.6973H68.2446V17.4661H70.8206V18.4486H70.8613C71.3601 17.7718 72.3187 17.1544 73.4617 17.1544C75.5252 17.1544 76.8015 18.846 76.8015 20.4657V26.6973H74.0389Z" fill="#005993" />
            <path d="M6.09488 26.6936C6.79745 26.6936 7.75491 26.3133 8.20144 25.1599C9.35016 22.195 12.85 13.3239 12.85 13.3239H10.0504L6.71706 21.8094L3.38672 13.3239H0L5.25287 26.6936H6.09488Z" fill="#005993" />
            <path d="M53.9235 19.5244C55.7058 18.5538 55.5972 16.8999 55.4143 15.9859C55.1861 14.844 53.9719 13.3221 51.234 13.3385H46.1309V26.6974H51.3266C54.6821 26.6974 56.2152 24.7504 56.1725 22.6466C56.1535 21.7086 55.8864 20.1384 53.9235 19.5244ZM49.2452 15.8093H51.2546C52.1004 15.8093 52.7172 16.4415 52.7172 17.1941C52.7172 18.0426 51.9617 18.5823 51.2546 18.5793H49.2452V15.8093ZM51.7925 24.227H49.2318V21.0501H51.7426C52.8841 21.0501 53.3627 22.0093 53.3627 22.5841C53.3627 23.5852 52.5896 24.227 51.7925 24.227Z" fill="#005993" />
            <path d="M83.5245 21.5598L87.4329 17.4548H83.9696L81.0012 20.7467L81.0084 15.7423C81.0084 14.2798 79.8117 13.2932 78.5593 13.2932H78.1787V26.6833H81.0012V22.5293L83.7989 26.6833H87.4329L83.5245 21.5598Z" fill="#005993" />
        </svg>
    )
}

export default LogoVietinbankTicket