import styled from "styled-components";

const WrapItemTransaction = styled.div`
    display: flex;
    flex-direction: column;
    .wrap-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 42px;
        padding: 12px 12px 12px 8px;
        border-radius: 4px;
        background: #005993;
        box-shadow: 0px 2px 13px 0px #00000014;
        cursor: pointer;
        box-sizing: border-box;
        .name{
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 700;
            line-height: 17.92px;
            text-align: left;
            color: #FFFFFF;
        }
        .ic-arrow{
            cursor: pointer;
            transform: ${props => props.isExpand ? 'rotate(90deg)' : 'rotate(0deg)'};
        }
    }
    .wrap-block-transaction{
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-top: 4px;
        .block-transaction{
            width: 100%;
            border-radius: 4px;
            padding: 12px 8px;
            box-sizing: border-box;
            box-shadow: 0px 2px 13px 0px #00000014;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            .text{
                font-family: 'SVN-Gilroy';
                font-size: 14px;
                font-weight: 500;
                line-height: 17.92px;
                text-align: left;
                color: #191919;
                padding-top: 3px;
            }
            .wrap-check-box{
                height: 18px;
                width: 18px;
                cursor: pointer;
            }
        }
    }

`
export { WrapItemTransaction }