export const FACE = "FACE";

export const THUMB_LEFT = "THUMB_LEFT"; //// ngón cái
export const INDEX_LEFT = "INDEX_LEFT" //// ngón trỏ
export const MIDDLE_LEFT = "MIDDLE_LEFT" /// ngón giữa
export const RING_LEFT = "RING_LEFT" //// ngón áp út (đeo nhẫn)
export const PINKY_LEFT = "PINKY_LEFT" /// ngón út

export const THUMB_RIGHT = "THUMB_RIGHT"
export const INDEX_RIGHT = "INDEX_RIGHT"
export const MIDDLE_RIGHT = "MIDDLE_RIGHT"
export const RING_RIGHT = "RING_RIGHT"
export const PINKY_RIGHT = "PINKY_RIGHT"

export const bioTypes = {
   FACE,
   THUMB_LEFT,
   INDEX_LEFT,
   MIDDLE_LEFT,
   RING_LEFT,
   PINKY_LEFT,
   THUMB_RIGHT,
   INDEX_RIGHT,
   MIDDLE_RIGHT,
   RING_RIGHT,
   PINKY_RIGHT
}

export const listNameBiometric = ["FACE", "THUMB_LEFT", "INDEX_LEFT", "MIDDLE_LEFT", "RING_LEFT", "PINKY_LEFT", "THUMB_RIGHT", "INDEX_RIGHT", "MIDDLE_RIGHT", "RING_RIGHT", "PINKY_RIGHT"]

export const nameFingerPrint = {
   "FACE":{
      name: "Khuôn mặt",
      nameInVerify: "khuôn mặt"
   },
   "THUMB_LEFT": {
      name: "Ngón cái - bàn tay trái",
      nameInVerify: "ngón cái - trái",
      nameNormal: "Ngón cái"
   },
   "INDEX_LEFT": {
      name: "Ngón trỏ - bàn tay trái",
      nameInVerify: "ngón trỏ - trái",
      nameNormal: "Ngón trỏ"
   },
   "MIDDLE_LEFT": {
      name: "Ngón giữa - bàn tay trái",
      nameInVerify: "ngón giữa - trái",
      nameNormal: "Ngón giữa"
   },
   "RING_LEFT": {
      name: "Ngón áp út - bàn tay trái",
      nameInVerify: "ngón áp út - trái",
      nameNormal: "Ngón áp út"
   },
   "PINKY_LEFT": {
      name: "Ngón út - bàn tay trái",
      nameInVerify: "ngón út - trái",
      nameNormal: "Ngón út"
   },
   "THUMB_RIGHT": {
      name: "Ngón cái - bàn tay phải",
      nameInVerify: "ngón cái - phải",
      nameNormal: "Ngón cái"
   },
   "INDEX_RIGHT": {
      name: "Ngón trỏ - bàn tay phải",
      nameInVerify: "ngón trỏ - phải",
      nameNormal: "Ngón trỏ"
   },
   "MIDDLE_RIGHT": {
      name: "Ngón giữa - bàn tay phải",
      nameInVerify: "ngón giữa - phải",
      nameNormal: "Ngón giữa"
   },
   "RING_RIGHT": {
      name: "Ngón áp út - bàn tay phải",
      nameInVerify: "ngón áp út - phải",
      nameNormal: "Ngón áp út"
   },
   "PINKY_RIGHT": {
      name: "Ngón út - bàn tay phải",
      nameInVerify: "ngón út - phải",
      nameNormal: "Ngón út"
   },
}