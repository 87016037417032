import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
///// images
import img_user from "../../../../images/img_user.svg";
import ic_search_white from "../../../../images/ic_search_white.svg";
import ic_KHVL from "../../../../images/ic_subAvatar.svg";
import ic_ticket_success from "../../../../images/ic_ticket_success.svg";
import ic_right_arrow from "../../../../images/ic_right_arrow.svg";
import ic_finger_query from "../../../../images/ic_finger_query.svg";
import ic_warning from "../../../../images/ic_warning.svg";

// action
import { openPopup } from '../../../../actions/popupBioAction';
import {
  // createFakeTicket,
  createFakeListCurrentTicket,
  completeFakeTicket,
  apiGetBioFileCollector,
  setEnrollId,
  getCurrentBioEnrollCollector,
  completeBioCollector,
  getCustomerInfos,
  apiGetBioFaceImageCollector,
  finishTransInTask
} from '../../../../actions/biometricWithInBioCollectorAction';
import {
  addBiometricExistFromCustomerInfos
} from "../../../../actions/counterAction"
import { movePendingBioToPendingStack } from '../../../../actions/inBioCollector/listDataSentSupervisorAction'

// component
import ButtonControl from './ButtonControl';
import ButtonUserInfoControl from './ButtonUserInfoControl';
import StatusSocket from '../../../Control/StatusSocket';

// đata
import statusBiometrics, { typeStatusBiometrics } from "../../../../data/statusBiometricWithTicket"
import * as bioTypes from "../../../../data/bioTypes";
import { REGISTER, CONFIRM, VALIDATE_DATA, REMOVE, LIST_ID } from '../../../../actions/typeRequestBio';
import { popupBioTypes } from '../../../../data/PopupTypes';
import typesKeyDetailPopup from '../../../../data/typesKeyDetailPopup';

// helper
import { openPopupSearchCif } from "../../../../helper/actionOpenPopup";
import checkApplyBiometricForTicket from "../../../../helper/checkApplyBiometricForTicket";
import FaceDuplicate from './FaceDuplicate';



const Bound = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    transition: 0.5s all ease-in-out;
    .tab-container {
        display: flex;
        align-items: center;
        position: relative;
        .customer {
            display: flex;
            text-align: center;
            flex-basis: 240px;
            height: 30px;
            line-height: 30px;
            border-radius: 10px 10px 0 0;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.19;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;
            background: #efefef;
            z-index: 1;
            color: #d6d6d6;
            &:nth-child(2) {
                margin-left: -20px;
            }
        }

        .tab-active {
            background: #fff;
            z-index: 2;
            color: #222222;
        }

        .socket-status {
            position: absolute;
            right: 20px;
            bottom: 0;
            background: #fff;
            display: flex;
            align-items: center;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            padding: 5px;
            border-radius: 5px 5px 0 0;
            font-size: 12px;
            color: #005993;
            font-weight: 500;
        }
    }
    .detail-inform-customer {
        border-radius: 0 20px 20px 20px;
        padding: 24px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        display: flex;
        flex-direction: row;
        position: relative;
        box-sizing: border-box;

        .task-control-button {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: fit-content;
            position: absolute;
            top: 40px;
            left: 20px;
            z-index: 99;
            display: block;
        }
        .avatar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            /* align-items: center; */
            margin-right: 49px;
            margin-left: 20px;
            width: 200px;
            .div_avatrUser {
                height: 200px;
                position: relative;
                margin: 0 auto;
                .img_user {
                    height: 100%;
                    object-fit: contain;
                    border-radius: 6px;
                }
                .div_ic_subAvatar {
                    position: absolute;
                    right: 10px;
                    bottom: 8px;
                }
                .div_ic_finger {
                    position: absolute;
                    bottom: 8px;
                    right: 48px;
                }
            }
            .notice-duplicate-data {
                margin: 14px auto 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                    padding-left: 7px;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 110%;
                    color: #F31919;
                }
            }
        }
        .task-information {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            .task-info-item {
                display: flex;
                flex-direction: column;
                .title {
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 1.26;
                    color: #005993;
                    margin-bottom: 12px;
                }

                .content {
                    & * {
                        font-family: 'SVN-Gilroy';
                        font-size: 14px;
                        line-height: 128%;
                    }
                    table.info-cus {
                        border-collapse: collapse;
                        width: 100%;
                        margin-bottom: 24px;
                        .info-title {
                            margin-right: 5px;
                            color: #979797;
                            white-space: nowrap;
                        }

                        td {
                            padding-bottom: 12px;
                            vertical-align: initial;
                        }
                    }
                    .row {
                        display: flex;
                        &:not(:last-child) {
                            margin-bottom: 12px;
                        }
                        &:last-child {
                            margin-bottom: 24px;
                        }
                        .wrap {
                            & > * {
                                font-family: 'SVN-Gilroy';
                                font-size: 14px;
                                line-height: 128%;
                            }

                            .info-title {
                                flex-shrink: 0;
                                margin-right: 5px;
                                color: #979797;
                            }
                            &:first-child {
                                width: 22ch;
                                margin-right: 2rem;
                            }
                            &:last-child {
                                max-width: 36ch;
                                margin-right: 2rem;
                            }
                        }

                        .long-wrap {
                            width: auto !important;
                            max-width: 60ch;
                        }
                    }
                }

                .status {
                    font-family: 'SVN-Gilroy';
                    font-size: 14px;
                    line-height: 15px;
                    font-weight: bold;
                }
            }
        }
        .button-contact {
            display: flex;
            flex-direction: column;
        }
    }
    .loading {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 15px;
        right: 25px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        font-weight: 500;
        color: #000000;
        .title_loading {
            margin: 0 0 0 7px;
        }
    }
`

const TASK_1 = 0;
const TASK_2 = 1;


class InformTransactionContainerForCollector extends Component {
  state = {
    mainTask: TASK_1,
    avatarCustomer: img_user
  }

  handleShowPopup = async () => {
    const ticket = this.props.counterReducer.listCurrentTicket[this.state.mainTask]
    if (!ticket) return;
    openPopupSearchCif(this, { ticketId: ticket.TicketID }, "handleShowPopup - InformTransactionContainerForCollector.js");
  }

  handleFinishTask = () => {
    const currentTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    if (!currentTask) return;
    const { statusBiometric } = currentTask;
    // if (String(statusBiometric) === typeStatusBiometrics.ACCEPT) {
    //   this.props.completeFakeTicket(currentTask.TicketID);
    //   return;
    // }

    this.props.openPopup(
      popupBioTypes.PopupConfirm,
      1,
      {
        content: `Bạn có muốn kết thúc giao dịch?`,
        width: "264px",
        dataTicket: currentTask,
        key: typesKeyDetailPopup.completeTicketCollector
      }
    )

  }

  handleMoveToQueue = () => {
    const currentTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    if (!currentTask) return;
    console.log(currentTask)

    this.props.movePendingBioToPendingStack(currentTask.TicketID, this.handleMoveToQueueSuccess)
  }

  handleMoveToQueueSuccess = () => {
    const currentTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    if (!currentTask) return;
    this.props.completeFakeTicket(currentTask.TicketID);
  }

  hanleOpenPopupQueries = () => {
    this.props.openPopup(popupBioTypes.PopupQueries, 1, {});
  }

  checkLoadSourceUserImg = () => {
    const { counterReducer } = this.props
    const task = counterReducer.listCurrentTicket[this.state.mainTask];
    if (!task) {
      console.log(
        `Task is undefined: ${this.state.mainTask}`,
        JSON.stringify(counterReducer.listCurrentTicket)
      )
      return this.state.avatarCustomer;
    }

    const { CustomerInfos } = task;
    if (!CustomerInfos) {
      console.log(`Can't get CustomerInfos: `, JSON.stringify(task))
      return this.state.avatarCustomer;
    }

    const { biometricReducer } = this.props;
    const { statusBiometric } = task;

    const biometricOfTicket = biometricReducer[task.TicketID];
    if (!biometricOfTicket) {
      console.log(
        `Can't found biometric: ${task.TicketID}`,
        JSON.stringify(biometricReducer)
      )
      return this.state.avatarCustomer;
    }

    const faceOfTicket = biometricOfTicket[bioTypes.FACE];
    if (!faceOfTicket) {
      console.log("Can't found face of data: ", JSON.stringify(biometricReducer))
      return this.state.avatarCustomer;
    }
    const faceStatus = faceOfTicket.bioActionType;
    const faceRegister = faceOfTicket[REGISTER];
    const faceConfirm = faceOfTicket[CONFIRM];
    const faceAlreadyAuth = faceOfTicket[VALIDATE_DATA];
    if (faceStatus === REMOVE && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
      return this.state.avatarCustomer;
    if (!!faceRegister && faceConfirm === 0 && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
      return apiGetBioFileCollector(faceRegister);
    if (faceAlreadyAuth)
      return apiGetBioFaceImageCollector(faceAlreadyAuth[LIST_ID]);

    console.log('Undefined reason: ');
    console.log('mainTask: ', this.state.mainTask)
    console.log('counterReducer: ', JSON.stringify(counterReducer))
    console.log('biometricReducer: ', JSON.stringify(biometricReducer))
    return this.state.avatarCustomer;
  }

  moveToTask = (taskNum) => {
    this.setState({
      mainTask: taskNum
    }, this.checkRenderTaskUI)
  }

  checkRenderTaskUI = () => {
    const { mainTask } = this.state;

    if (mainTask === TASK_1) {
      // const task1 = this.task1;
      this.task2.classList.remove("tab-active");
      this.task1.classList.add("tab-active");
      // debugger;
    } else {
      // const task2 = this.task2;
      this.task1.classList.remove("tab-active");
      this.task2.classList.add("tab-active");
      // debugger
    }
  }

  genTitletask = (taskIndex, lstCurrentTicket) => {
    const currentTask = lstCurrentTicket[taskIndex];
    const { cusName } = this.genNameAndPhoneOfTask(currentTask);
    return currentTask &&
      currentTask.CustomerInfos ?
      // (currentTask.dataWasAuthen && currentTask.dataWasAuthen.fullNameVi) || 
      // currentTask.CustomerInfos.Name
      cusName
      :
      "Chưa có khách hàng"
  }

  handleOpenPopupInfoUser = () => {
    // const ticket = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    // checkApplyBiometricForTicket(this, ticket.TicketID);
    // this.props.openPopup(7, 1, ticket);
    this.openPopupUserManager()
  }

  isDisableBtnFinish = () => {
    const ticket = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    if (!ticket) return true;
    const enrollId = this.props.counterReducer.enrollId[ticket.TicketID]
    if (!enrollId) return false;
    if (enrollId && ticket.statusBiometric === typeStatusBiometrics.PENDING) return true;
    return false;
  }

  openPopupUserManager = async () => {
    const mainTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    if (!mainTask) return;

    await checkApplyBiometricForTicket(this, mainTask.TicketID)

    const { biometricReducer } = this.props;
    const flowUpdate = true;
    const validatedData = /validatedData/.test(JSON.stringify(biometricReducer[mainTask.TicketID]));

    switch (true) {
      case mainTask.CustomerInfos:
        this.props.openPopup(7, 1, mainTask);
        break;
      case mainTask.CustomerInfos && validatedData:
        this.props.openPopup(7, 1, { ...mainTask, flowUpdate })
        break;
      case mainTask.CustomerInfos && !validatedData:
        this.props.openPopup(7, 1, mainTask)
        break;
      case !mainTask.CustomerInfos:
        this.props.openPopup(7, 1, mainTask)
        break;
      default:
        break;
    }
  }

  checkStatusTask = () => {
    const statusTask = { ...statusBiometrics[typeStatusBiometrics.UNDEFINED] }
    const { listCurrentTicket } = this.props.counterReducer;
    const currentTask = listCurrentTicket[this.state.mainTask];
    if (!currentTask) return statusTask;
    if (currentTask && !currentTask.CustomerInfos) return statusTask;
    const { statusBiometric } = currentTask;
    const isHaveBio =
      currentTask.CustomerInfos.BiometricFace === "1" ||
      currentTask.CustomerInfos.BiometricFinger === "1" ||
      currentTask.CustomerInfos.BiometricActive === "1";
    if (isHaveBio || statusBiometric === typeStatusBiometrics.ACCEPT) {
      statusTask.text = "Đã đăng ký"
      statusTask.color = statusBiometrics[typeStatusBiometrics.ACCEPT].color;
    }
    return statusTask
  }

  handleCheckReOpenPopupUserManager = () => {


  }

  genNameAndPhoneOfTask = (mainTask) => {
    // let { mainTask } = this.state;
    if (!mainTask)
      return {
        cusName: "",
        cusPhone: ""
      }
    const {
      statusBiometric,
      dataWasAuthen,
      CustomerInfos
    } = mainTask;
    // debugger;
    if (!CustomerInfos)
      return {
        cusName: "",
        cusPhone: ""
      }
    if (String(statusBiometric) === typeStatusBiometrics.ACCEPT)
      return {
        cusName: CustomerInfos.FullNameVi || CustomerInfos.Name || "",
        cusPhone: CustomerInfos.MobileNumber || ""
      }
    if (!!dataWasAuthen)
      return {
        cusName: dataWasAuthen.fullNameVi || CustomerInfos.Name || "",
        cusPhone: dataWasAuthen.phoneNumber || CustomerInfos.MobileNumber || ""
      }
    return {
      cusName: "",
      cusPhone: ""
    }
  }

  render() {

    const { listCurrentTicket } = this.props.counterReducer;
    const currentTask = listCurrentTicket[this.state.mainTask];
    const isCurrentTaskHaveCust = !!currentTask && !!currentTask.CustomerInfos;
    const { cusName, cusPhone } = this.genNameAndPhoneOfTask(currentTask);

    const task1Name = this.genTitletask(TASK_1, listCurrentTicket)
    const task2Name = this.genTitletask(TASK_2, listCurrentTicket)

    let ic_subType = null;
    ic_subType = ic_KHVL
    const statusInTask = this.checkStatusTask();
    const existCustomerNumbers = (() => {
      if (!isCurrentTaskHaveCust) return []
      const mainTaskCustNum = currentTask.CustomerInfos.CustomerNumber
      const existCustomerNumbers = currentTask.CustomerInfos.ExistCustomerNumbers || []
      return existCustomerNumbers.filter(custNum => custNum !== mainTaskCustNum)
    })()
    return (
      <Bound>
        <div className="tab-container">
          <div
            className="customer tab-active"
            ref={ref => this.task1 = ref}
            onClick={() => this.moveToTask(TASK_1)}
          >
            {task1Name}
          </div>
          <div
            className="customer"
            ref={ref => this.task2 = ref}
            onClick={() => this.moveToTask(TASK_2)}
          >
            {task2Name}
          </div>
          <div className="socket-status"><StatusSocket /></div>
        </div>
        <div className="detail-inform-customer">
          <div className="avatar">
            <div className="div_avatrUser">
              <img
                className="img_user"
                src={this.checkLoadSourceUserImg()}
                alt="img_user"
              />
              {
                isCurrentTaskHaveCust &&
                (
                  <div
                    className="div_ic_subAvatar"
                    onClick={this.handleOpenPopupInfoUser}
                  >
                    <ButtonUserInfoControl image={ic_subType} />
                  </div>
                )
              }
            </div>
            {
              !!existCustomerNumbers.length &&
              <FaceDuplicate />
            }
          </div>
          <div className="task-information">
            {
              isCurrentTaskHaveCust &&
              <React.Fragment>
                <div className="task-info-item">
                  <p className="title">Thông tin khách hàng</p>
                  <div className="content">

                    <table className="info-cus">
                      <tbody>
                        <tr>
                          <td><span className="info-title">Họ tên KH:</span>{cusName}</td>
                          <td><span className="info-title">Giới tính:</span>{currentTask.CustomerInfos.Gender}</td>
                        </tr>
                        <tr>
                          <td><span className="info-title">CMND/CCCD/HC:</span>{currentTask.CustomerInfos.IdNumber}</td>
                          <td><span className="info-title">Hạng khách hàng:</span>{currentTask.CustomerInfos.SegmentDesc}</td>
                        </tr>
                        <tr>
                          <td ><span className="info-title">Ngày cấp:</span>{currentTask.CustomerInfos.IdIssueDate}</td>
                          <td><span className="info-title">SĐT di động:</span>{cusPhone}</td>
                        </tr>
                        <tr>
                          <td><span className="info-title">Nơi cấp:</span>{currentTask.CustomerInfos.IdIssuePlace}</td>
                          <td><span className="info-title">Email:</span>{currentTask.CustomerInfos.Email}</td>
                        </tr>
                        <tr>
                          <td colSpan="2"><span className="info-title">Địa chỉ:</span>{currentTask.CustomerInfos.Address}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="task-info-item">
                  <p className="title">trạng thái dữ liệu STH</p>
                  {
                    !!currentTask && String(currentTask.statusBiometric) !== typeStatusBiometrics.UNDEFINED ?
                      <div className="status" style={{ color: statusBiometrics[String(currentTask.statusBiometric)].colorInTaskCollector }}>{statusBiometrics[String(currentTask.statusBiometric)].textInTask}</div>
                      :
                      <div className="status" style={{ color: statusInTask.color }}>{statusInTask.text}</div>
                  }
                </div>
              </React.Fragment>
            }
          </div>

          <div className="button-contact">
            {
              currentTask && currentTask.CustomerInfos ?
                <ButtonControl
                  margin="0 0 0 6px"
                  width="156px"
                  icon={ic_ticket_success}
                  isDisable={this.isDisableBtnFinish()}
                  bgColor="#0fb11f"
                  content="Kết thúc giao dịch"
                  onSubmit={this.handleFinishTask}
                />
                :
                <ButtonControl
                  margin="0 0 0 6px"
                  icon={ic_search_white}
                  bgColor="#d71249"
                  width="156px"
                  content="tìm kiếm KH"
                  onSubmit={() => {
                    this.handleShowPopup()
                  }}
                />
            }
            {
              !!currentTask &&
              [typeStatusBiometrics.PENDING, typeStatusBiometrics.REJECT].includes(currentTask.statusBiometric) &&
              <ButtonControl
                margin="10px 0 0 6px"
                width="156px"
                icon={ic_right_arrow}
                bgColor="#005993"
                content="chuyển hàng chờ"
                onSubmit={this.handleMoveToQueue}
              />
            }
            <ButtonControl
              margin="10px 0 0 6px"
              icon={ic_finger_query}
              bgColor="#005993"
              content="màn hình vấn tin"
              onSubmit={this.hanleOpenPopupQueries}
              width="156px"
            />
          </div>
        </div>
      </Bound>
    )
  }

  async componentDidUpdate(prevProps, prevState) {
    {
      const mainTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
      const preveMainTask = prevProps.counterReducer.listCurrentTicket[prevState.mainTask];
      // if (
      //   mainTask && preveMainTask &&
      //   this.state.mainTask === prevState.mainTask &&
      //   this.props.counterReducer.listCurrentTicket[this.state.mainTask].CustomerInfos === null &&
      //   prevProps.counterReducer.listCurrentTicket[prevState.mainTask].CustomerInfos !== null) {
      //   this.setState({ mainTask: TASK_1 })
      //   this.moveToTask(TASK_1)
      // }
      if (mainTask && preveMainTask &&
        this.state.mainTask === prevState.mainTask &&
        mainTask.TicketID !== preveMainTask.TicketID) {
        await this.props.getCurrentBioEnrollCollector(
          this.props.counterReducer.dataConnectDesktop.CounterID,
        )
        checkApplyBiometricForTicket(this, mainTask.TicketID)
      }
    }

    {

      const preTask2 = prevProps.counterReducer.listCurrentTicket[TASK_2]
      const task2 = this.props.counterReducer.listCurrentTicket[TASK_2]
      if (preTask2 && task2) {
        if (!preTask2.CustomerInfos && task2.CustomerInfos) {
          this.setState({ mainTask: TASK_2 })
          this.moveToTask(TASK_2)
          await checkApplyBiometricForTicket(this, task2.TicketID)
        }

        if (preTask2.CustomerInfos && !task2.CustomerInfos) {
          this.setState({ mainTask: TASK_1 })
          this.moveToTask(TASK_1)
        }
      }
      const preTask1 = prevProps.counterReducer.listCurrentTicket[TASK_1]
      const task1 = this.props.counterReducer.listCurrentTicket[TASK_1]
      if (preTask1 && task1) {
        if (!preTask1.CustomerInfos && task1.CustomerInfos) {
          this.setState({ mainTask: TASK_1 })
          this.moveToTask(TASK_1)
          await checkApplyBiometricForTicket(this, task2.TicketID)
        }

        // if (preTask1.CustomerInfos && !task1.CustomerInfos) {
        //   this.setState({ mainTask: TASK_1 })
        //   this.moveToTask(TASK_1)
        // }
      }
    }


    {
      const mainTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
      if (!mainTask) return;
      const oldEnrollId = prevProps.counterReducer.enrollId[mainTask.TicketID];
      if (!oldEnrollId) return;
      const newEnrollId = this.props.counterReducer.enrollId[mainTask.TicketID];
      if (!newEnrollId) return;
      if (oldEnrollId !== newEnrollId) {
        await this.props.getCurrentBioEnrollCollector(
          this.props.counterReducer.dataConnectDesktop.CounterID,
        )
        this.openPopupUserManager()
      }
    }


  }


  async componentDidMount() {

    await this.props.createFakeListCurrentTicket();

    await this.props.getCurrentBioEnrollCollector(
      this.props.counterReducer.dataConnectDesktop.CounterID,
    )
    // const mainTask = this.props.counterReducer.listCurrentTicket[this.state.mainTask];
    // if (mainTask)
    //   checkApplyBiometricForTicket(this, mainTask.TicketID)
    const task1 = this.props.counterReducer.listCurrentTicket[TASK_1]
    if (task1?.CustomerInfos) {
      await checkApplyBiometricForTicket(this, task1.TicketID)
    }

    const task2 = this.props.counterReducer.listCurrentTicket[TASK_2]
    if (task2?.CustomerInfos) {
      await checkApplyBiometricForTicket(this, task2.TicketID)
    }
  }
}



const mapStateToProps = state => ({
  counterReducer: state.counterReducer,
  biometricReducer: state.biometricReducer
})

const mapDispatchToProps = dispatch => ({
  openPopup: (typePopup, levelPopup, data) => dispatch(openPopup(typePopup, levelPopup, data)),
  createFakeListCurrentTicket: () => dispatch(createFakeListCurrentTicket()),
  // createFakeTicket: () => dispatch(createFakeTicket()),
  completeFakeTicket: (ticketID) => dispatch(completeFakeTicket(ticketID)),
  finishTransInTask: (ticketID) => dispatch(finishTransInTask(ticketID)),
  completeBioCollector: (enrollId, forceComplete = true) => dispatch(completeBioCollector(enrollId, forceComplete)),
  getCurrentBioEnrollCollector: (counterId) => dispatch(getCurrentBioEnrollCollector(counterId)),
  setEnrollIdCollector: (enrollId) => dispatch(setEnrollId(enrollId)),
  getCustomerInfosCollector: (counterId, cifsNum, typeSearch = 0, ticketId, isApplyBiometric = false) =>
    dispatch(getCustomerInfos(counterId, cifsNum, typeSearch, ticketId, isApplyBiometric)),
  addBiometricExistFromCustomerInfos: (bioInfos, ticketId) => dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
  movePendingBioToPendingStack: (id, callbackSuccess) => dispatch(movePendingBioToPendingStack(id, callbackSuccess))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InformTransactionContainerForCollector)