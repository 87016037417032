import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// Image
import ic_refresh from '../../../images/Icons_Dashboard_Refresh.svg'
// import ic_refresh from '../../../images/ic_refresh_blue.svg';
// import ic_warning from '../../../images/ic_warning_grey.svg';
import ic_cancel from '../../../images/icon_cancel.svg'
import ic_success_blue from '../../../images/ic_success_blue.svg'
// Data
import { priorType } from '../../../data/data'
import {
    updateBioInfo,
    requestEnroll,
    requestUpDateBio,
} from '../../../actions/counterAction'
import {
    requestEnrollCollector,
    updateCusInfosCollector,
    requestUpDateBioCollector,
} from '../../../actions/biometricWithInBioCollectorAction'
import { INBIOCOLLECTOR, TELLER } from '../../../data/userRole'
// import { typeStatusBiometrics } from "../../../data/statusBiometricWithTicket";

// helper
import isDisableEventClick from '../../../helper/isDisableEventWhenHaveSupervisor'

// action
import { openPopup } from '../../../actions/popupBioAction'
import isErrorWhenGetEnroll from '../../../helper/isErrorWhenGetEnroll'
import { clearError } from '../../../actions/commonAction'

const BoundStyle = styled.div`
    width: 100%;
    background-color: #fafafa;
    font-family: 'SVN-Gilroy';
    font-size: 20px;
    font-weight: bold;
    color: #222222;
    .title-info {
        font-size: 11px;
        color: #005993;
        text-transform: uppercase;
        margin-bottom: 14px;
    }
    .block_typeCustomer {
        display: flex;
        margin: 0 0 30px 0;
        /* /////  CheckBox ///// */
        .checkBox {
            &:last-child {
                margin: 0 0 0 40px;
            }
            .radio-field {
                display: flex;
                align-items: center;
                position: relative;
                input[type='radio'] {
                    position: absolute;
                    height: 0;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                }
                .checkmark {
                    height: 16px;
                    width: 16px;
                    border: solid 0.8px #c8c8c8;
                    background: #ffffff;
                    border-radius: 50%;
                }
                .checkmark:after {
                    content: '';
                    position: absolute;
                    display: none;
                }
                input[type='radio']:checked ~ .checkmark:after {
                    display: block;
                }
                .checkmark::after {
                    /* left: 5.5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid #d71249;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg); */
                    top: 5px;
                    left: 5px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #d71249;
                }
                .text_label {
                    margin: 0 0 0 4px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        .info-name {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 14px;
        }
        .info-wrapper {
            /* padding-bottom: 10px; */
            .title-info-wrapper {
                font-family: 'SVN-Gilroy';
                font-size: 14px;
                font-weight: 500;
                color: #979797;
                margin-right: 5px;
            }
            .detail-input-wrap {
                display: inline-block;
            }
            .input-phone {
                width: 128px;
            }
            .input-phone::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        /* .detail-input {
        display: flex;
        align-items: center;
      } */

        tr > td:last-child {
            padding-left: 10px;
        }

        tr > td:first-child {
            padding-left: 0;
        }

        tr > td.info-wrapper {
            padding-bottom: 10px;
        }
    }
    .customer-info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        /* margin-bottom: 24px; */
        .left-info {
            display: flex;
            flex-direction: column;
            width: 40%;
            max-width: 19ch;
        }
        .right-info {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            width: auto;
            max-width: 30ch;
        }
        .info-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 14px;
            .title-info-wrapper {
                font-family: 'SVN-Gilroy';
                font-size: 14px;
                font-weight: 500;
                color: #979797;
                margin-right: 5px;
            }
            .refresh-wraper {
                /* width: 100px; */
                height: 15px;
                flex: 1;
                max-width: 17ch;
                display: flex;
                flex-direction: row;
                align-items: center;
                .input-phone {
                    width: 128px;
                }
                .input-phone::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                }
            }
        }
    }
    .info {
        font-size: 14px;
        font-weight: 500;
        /* padding-bottom: 14px;  */
        .address {
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            color: #979797;
            margin-right: 5px;
        }
    }
    .ic_fresh {
        margin: 0 0 0 6px;
    }
    img {
        margin: 0 0 0 6px;
    }
    .search-info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 28px;
        .input-name {
            width: 159px;
        }
    }
    .label_errName {
        height: 20px;
        color: #f31919;
        font-family: 'SVN-Gilroy';
        font-size: 12px;
        font-weight: 500;
        /* margin: 0 0 0 10px; */
        display: flex;
        align-items: center;
    }
    .label_errPhone {
        color: #f31919;
        font-family: 'SVN-Gilroy';
        font-size: 12px;
        font-weight: 500;
        /* position: absolute; */
        /* top: 200%; */
        display: flex;
        align-items: center;
    }
    .warning-info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        p {
            font-family: SVN-Gilroy;
            font-size: 12px;
            font-weight: 500;
            color: #979797;
            margin-left: 4px;
        }
        img {
            margin: 0;
        }
    }
    input {
        width: 128px;
        height: 16px;
        border-radius: 4px;
        border: solid 0.5px #c8c8c8;
        padding: 6px;
    }
`
const PHONE_TYPE = 'phone'
const NAME_TYPE = 'name'

// const BANK_CUSTOMER = 1;
// const INTERNAL_CUSTOMER = 2;

class UserInfomation extends Component {
    state = {
        mainTicket: this.props.data,
        dataPrior: priorType,
        priorType: '',
        bank_customer: false,
        internal_customer: false,
        step: {
            phone: 1,
            name: 1,
        },
        tmpPhone: null,
        nameCustomer: '',
        tmpNameCustomer: null,
        errLabelPhone: '',
        errLabelName: '',
    }
    changeStep = (type) => {
        // stop event when status
        const { dataGetTicket } = this.props
        // const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === dataGetTicket.TicketID);
        // if(String(currentTicket.statusBiometric) !== typeStatusBiometrics.UNDEFINED) return;
        if (
            isDisableEventClick(
                dataGetTicket.TicketID,
                this.props.counterReducer,
                this
            )
        )
            return
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === dataGetTicket.TicketID
        )
        if (!currentTicket) return
        // debugger;
        if (type === PHONE_TYPE) {
            this.setState({
                tmpPhone: currentTicket.CustomerInfos.MobileNumber,
            })
            this.resetStatusEmptyField(NAME_TYPE)
        } else if (type === NAME_TYPE) {
            this.setState({
                tmpNameCustomer: currentTicket.CustomerInfos.FullNameVi,
            })
            this.resetStatusEmptyField(PHONE_TYPE)
        }
        this.setState({
            step: {
                ...this.state.step,
                [type]: this.state.step[type] === 1 ? 2 : 1,
                [type === PHONE_TYPE ? NAME_TYPE : PHONE_TYPE]: 1,
            },
        })
    }

    isEmptyField = (type, dataPhone, dataName) => {
        // PHONE_TYPE
        if (
            type === PHONE_TYPE &&
            (!dataPhone ||
                dataPhone.trim() === '' ||
                /[a-zA-Z]+/g.test(dataPhone.trim()))
        ) {
            this.setState({ errLabelPhone: 'Không hợp lệ!' })
            return true
        }
        // if(type === PHONE_TYPE && /\D+/g.test(dataPhone.trim())){
        //   this.setState({ errLabelPhone: "Vui lòng nhập ký tự là số!" })
        //   return true;
        // }

        // NAME_TYPE
        if (type === NAME_TYPE && (!dataName || dataName.trim() === '')) {
            this.setState({ errLabelName: 'Không hợp lệ!' })
            return true
        }

        return false
    }

    resetStatusEmptyField = (type) => {
        // PHONE_TYPE
        if (type === PHONE_TYPE) {
            this.setState({ errLabelPhone: '' })
            return
        }
        // NAME_TYPE
        this.setState({ errLabelName: '' })
    }

    saveData = async (type) => {
        // this.resetStatusEmptyField(type)

        const { tmpPhone, tmpNameCustomer } = this.state
        const { counterReducer, dataGetTicket } = this.props
        const userCheckListCurrent = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === dataGetTicket.TicketID
        )

        if (!userCheckListCurrent) return
        if (!userCheckListCurrent.CustomerInfos) return
        const {
            BiometricFace,
            BiometricFinger,
            BiometricActive,
        } = userCheckListCurrent.CustomerInfos
        // if (tmpPhone === "" || tmpNameCustomer === "") {
        //   if (tmpPhone === "")
        //     this.setState({
        //       errLabelPhone: "Vui lòng không bỏ trống sdt!"
        //     })

        //   if (tmpNameCustomer === "")
        //     this.setState({
        //       errLabelName: "Vui lòng không bỏ trống tên!"
        //     })
        //   return;
        // }
        // debugger;
        const isEmptyField = this.isEmptyField(type, tmpPhone, tmpNameCustomer)
        if (isEmptyField) return
        ////////// CheckPermission
        switch (true) {
            case counterReducer.dataLogin.Roles.includes(TELLER) &&
                !Number(BiometricFace) &&
                !Number(BiometricFinger) &&
                !Number(BiometricActive):
                // !this.props.flowUpdate:
                if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                    await this.props.requestEnroll(
                        dataGetTicket.TicketID,
                        userCheckListCurrent.CustomerInfos
                    )
                    const isError = isErrorWhenGetEnroll(
                        this.props.counterReducer,
                        this.props.openPopup,
                        this.props.clearError
                    )
                    if (isError) return
                }
                break
            case counterReducer.dataLogin.Roles.includes(TELLER) &&
                !!(
                    Number(BiometricFace) ||
                    Number(BiometricFinger) ||
                    Number(BiometricActive)
                ):
                // this.props.flowUpdate:
                if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                    await this.props.requestUpDateBio(
                        dataGetTicket.TicketID,
                        userCheckListCurrent.CustomerInfos.CustomerNumber
                    )
                    const isError = isErrorWhenGetEnroll(
                        this.props.counterReducer,
                        this.props.openPopup,
                        this.props.clearError
                    )
                    if (isError) return
                }

                break
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                !Number(BiometricFace) &&
                !Number(BiometricFinger) &&
                !Number(BiometricActive):
                if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                    await this.props.requestEnrollCollector(
                        userCheckListCurrent.CustomerInfos.CustomerNumber,
                        dataGetTicket.TicketID
                    )
                    const isError = isErrorWhenGetEnroll(
                        this.props.counterReducer,
                        this.props.openPopup,
                        this.props.clearError
                    )
                    if (isError) return
                }
                break
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                !!(
                    Number(BiometricFace) ||
                    Number(BiometricFinger) ||
                    Number(BiometricActive)
                ):
                if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                    await this.props.requestUpDateBioCollector(
                        dataGetTicket.TicketID,
                        userCheckListCurrent.CustomerInfos.CustomerNumber
                    )

                    const isError = isErrorWhenGetEnroll(
                        this.props.counterReducer,
                        this.props.openPopup,
                        this.props.clearError
                    )
                    if (isError) return
                }
                break
            default:
                break
        }

        switch (true) {
            case counterReducer.dataLogin.Roles.includes(TELLER):
                if (type === PHONE_TYPE) {
                    this.props.updateBioInfo(
                        this.props.counterReducer.enrollId[
                            dataGetTicket.TicketID
                        ],
                        null,
                        this.state.tmpPhone,
                        dataGetTicket.TicketID
                    )
                    this.setState({
                        mainTicket: {
                            ...this.state.mainTicket,
                            phoneNumber: this.state.tmpPhone,
                        },
                    })
                } else if (type === NAME_TYPE) {
                    this.props.updateBioInfo(
                        this.props.counterReducer.enrollId[
                            dataGetTicket.TicketID
                        ],
                        this.state.tmpNameCustomer,
                        null,
                        dataGetTicket.TicketID
                    )
                    this.setState({
                        nameCustomer: this.state.tmpNameCustomer,
                    })
                }
                break
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR): {
                // const
                if (type === PHONE_TYPE) {
                    this.props.updateCusInfosCollector(
                        this.props.counterReducer.enrollId[
                            dataGetTicket.TicketID
                        ],
                        dataGetTicket.TicketID,
                        null,
                        this.state.tmpPhone
                    )
                    this.setState({
                        mainTicket: {
                            ...this.state.mainTicket,
                            phoneNumber: this.state.tmpPhone,
                        },
                    })
                } else if (type === NAME_TYPE) {
                    this.props.updateCusInfosCollector(
                        this.props.counterReducer.enrollId[
                            dataGetTicket.TicketID
                        ],
                        dataGetTicket.TicketID,
                        this.state.tmpNameCustomer,
                        null
                    )
                    this.setState({
                        nameCustomer: this.state.tmpNameCustomer,
                    })
                }
                break
            }
            default:
                break
        }

        this.changeStep(type)
    }

    cancelSaveData = (type) => {
        this.resetStatusEmptyField(type)
        this.changeStep(type)
        if (type === PHONE_TYPE)
            this.setState({
                errLabelPhone: '',
            })
        if (type === NAME_TYPE)
            this.setState({
                errLabelName: '',
            })
    }

    handleChangePhone = (e) => {
        this.setState({
            tmpPhone: e.target.value,
            errLabelPhone: '',
        })
    }
    handleChangeName = (e) => {
        this.setState({
            tmpNameCustomer: e.target.value,
            errLabelName: '',
        })
    }

    // typeUser = (typeUser) => {
    //   if (typeUser === BANK_CUSTOMER) {
    //     this.setState({
    //       bank_customer: !this.state.bank_customer
    //     });
    //   } else this.setState({
    //     internal_customer: !this.state.internal_customer
    //   })
    // }

    componentDidUpdate(prevProps, prevState) {
        // let { counterReducer } = this.props;
        // if (prevProps.counterReducer.customerInfo !== counterReducer.customerInfo) {
        //   debugger;
        //   this.setState({
        //     mainTicket: counterReducer.customerInfo.Cifs[0],
        //   });
        // }
    }

    // checkRadio = () => {
    //   let typeUser = document.querySelector('input[name="content"]:checked').value;
    //   this.setState({
    //     typeUser
    //   });
    // }
    render() {
        let mainTicket
        let listCurrentTicket = this.props.counterReducer.listCurrentTicket
        let dataGetTicket = this.props.dataGetTicket
        let dataTest = listCurrentTicket.find(
            (item) => item.TicketID === dataGetTicket.TicketID
        )
        if (dataTest.CustomerInfos) {
            mainTicket = dataTest.CustomerInfos
        }
        // console.log(this.props.flowUpdate);

        return (
            <BoundStyle>
                {/* <div className='CIF-content' style={{ margin: "10px 0 25px 0", textTransform: "uppercase" }}>
          <p className='p-status-title'>{mainTicket.Name}</p>
        </div> */}

                {Object.keys(dataTest).includes('CustomerType') ? (
                    <>
                        <p className="title-info">Loại khách hàng</p>
                        <div className="block_typeCustomer">
                            <div className="checkBox">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="KH nội bộ"
                                        checked={
                                            dataTest.CustomerType === 'INCUST'
                                        }
                                        onChange={() => {}}
                                        // onChange={() => { this.typeUser(BANK_CUSTOMER) }}
                                    />
                                    <div
                                        className="checkmark"
                                        style={{
                                            backgroundColor:
                                                dataTest.CustomerType !==
                                                    'INCUST' && '#efefef',
                                        }}
                                    ></div>
                                    <div
                                        className="text_label"
                                        style={{
                                            color:
                                                dataTest.CustomerType !==
                                                    'INCUST' && '#d6d6d6',
                                        }}
                                    >
                                        Cán bộ ngân hàng
                                    </div>
                                </label>
                            </div>
                            <div className="checkBox">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="KH nội bộ"
                                        checked={
                                            dataTest.CustomerType === 'EXCUST'
                                        }
                                        onChange={() => {}}
                                        // onChange={this.state.internal_customer ? () => { this.typeUser(INTERNAL_CUSTOMER) } : () => { }}
                                    />
                                    <div
                                        className="checkmark"
                                        style={{
                                            backgroundColor:
                                                dataTest.CustomerType !==
                                                    'EXCUST' && '#efefef',
                                        }}
                                    />
                                    <div
                                        className="text_label"
                                        style={{
                                            color:
                                                dataTest.CustomerType !==
                                                    'EXCUST' && '#d6d6d6',
                                        }}
                                    >
                                        Khách hàng
                                    </div>
                                </label>
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="CIF-content"
                        style={{
                            margin: '10px 0 25px 0',
                            textTransform: 'uppercase',
                        }}
                    >
                        <p className="p-status-title">{mainTicket.Name}</p>
                    </div>
                )}
                <p className="title-info">Thông tin khách hàng</p>
                <table>
                    <tbody>
                        <tr className="info-wrapper">
                            <td colSpan="2" className="info-name">
                                <span className="title-info-wrapper">
                                    Họ tên KH:
                                </span>
                                <strong>{mainTicket.Name}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Giới tính:
                                </span>
                                <span className="info">
                                    {mainTicket.Gender}
                                </span>
                            </td>
                            <td
                                className="info-wrapper"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    className="title-info-wrapper"
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        width: '15ch',
                                    }}
                                >
                                    Họ tên KH (có dấu)
                                    <span
                                        style={{ color: 'var(--vermillion)' }}
                                    >
                                        *
                                    </span>
                                    :
                                </span>
                                {this.state.step[NAME_TYPE] === 1 ? (
                                    <>
                                        <span className="info">
                                            {this.state.nameCustomer
                                                ? this.state.nameCustomer
                                                : mainTicket.FullNameVi}
                                        </span>
                                        {!this.props.counterReducer
                                            .dataConnectDesktop
                                            .VirtualTablet && (
                                            <img
                                                className="ic_fresh"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    this.changeStep(NAME_TYPE)
                                                }
                                                src={ic_refresh}
                                                alt="icon-refresh"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <input
                                            className="input-name"
                                            type="text"
                                            name=""
                                            value={
                                                this.state.tmpNameCustomer ||
                                                this.state.tmpNameCustomer ===
                                                    ''
                                                    ? this.state.tmpNameCustomer
                                                    : mainTicket.FullNameVi
                                            }
                                            onKeyPress={(e) => {
                                                // debugger;
                                                // e.preventDefault();a
                                                return e.charCode === 13
                                                    ? this.saveData(NAME_TYPE)
                                                    : false
                                            }}
                                            onKeyDown={(e) => {
                                                return e.which === 9
                                                    ? this.saveData(NAME_TYPE)
                                                    : false
                                            }}
                                            onChange={(e) =>
                                                this.handleChangeName(e)
                                            }
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.saveData(NAME_TYPE)
                                            }
                                            src={ic_success_blue}
                                            alt="icon-success-blue"
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.cancelSaveData(NAME_TYPE)
                                            }
                                            src={ic_cancel}
                                            alt="icon-cancel"
                                        />
                                    </>
                                )}
                                {!!this.state.errLabelName && (
                                    <p className="label_errName">
                                        {this.state.errLabelName}
                                    </p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="info-wrapper">
                                <span className="title-info-wrapper">
                                    CMND/CCCD/HC:
                                </span>
                                <span className="info">
                                    {mainTicket.IdNumber ||
                                        mainTicket.IDNum ||
                                        mainTicket.IDNumber}
                                </span>
                            </td>
                            <td className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Hạng khách hàng:
                                </span>
                                <span className="info">
                                    {mainTicket.SegmentDesc}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Ngày cấp:
                                </span>
                                <span className="info">
                                    {mainTicket.IdIssueDate}
                                </span>
                            </td>
                            <td
                                className="info-wrapper"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    className="title-info-wrapper"
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                    }}
                                >
                                    Số điện thoại di động
                                    <span
                                        style={{ color: 'var(--vermillion)' }}
                                    >
                                        *
                                    </span>
                                    :
                                </span>
                                {this.state.step[PHONE_TYPE] === 1 ? (
                                    <React.Fragment>
                                        <span className="info">
                                            {mainTicket.MobileNumber}
                                        </span>
                                        {!this.props.counterReducer
                                            .dataConnectDesktop
                                            .VirtualTablet && (
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    this.changeStep(PHONE_TYPE)
                                                }
                                                src={ic_refresh}
                                                alt="icon-refresh"
                                            />
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <input
                                            className="input-phone"
                                            type="number"
                                            name=""
                                            value={
                                                this.state.tmpPhone ||
                                                this.state.tmpPhone === ''
                                                    ? this.state.tmpPhone
                                                    : mainTicket.MobileNumber
                                            }
                                            maxLength="10"
                                            onKeyPress={(e) => {
                                                // debugger;
                                                // e.preventDefault();
                                                return e.charCode === 13
                                                    ? this.saveData(PHONE_TYPE)
                                                    : false
                                            }}
                                            onKeyDown={(e) => {
                                                // debugger;
                                                return e.which === 9
                                                    ? this.saveData(PHONE_TYPE)
                                                    : false
                                            }}
                                            onChange={(e) =>
                                                this.handleChangePhone(e)
                                            }
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.saveData(PHONE_TYPE)
                                            }
                                            src={ic_success_blue}
                                            alt="icon-success-blue"
                                        />
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                this.cancelSaveData(PHONE_TYPE)
                                            }
                                            src={ic_cancel}
                                            alt="icon-cancel"
                                        />
                                    </React.Fragment>
                                )}
                                {!!this.state.errLabelPhone && (
                                    <p className="label_errPhone">
                                        {this.state.errLabelPhone}
                                    </p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Nơi cấp:
                                </span>
                                <span className="info">
                                    {mainTicket.IdIssuePlace}
                                </span>
                            </td>
                            <td colSpan="2" className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Email:
                                </span>
                                <span className="info">{mainTicket.Email}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="info-wrapper">
                                <span className="title-info-wrapper">
                                    Địa chỉ:
                                </span>
                                <span className="info">
                                    {mainTicket.Address}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* <div className='info-wrapper' style={{marginBottom: "14px"}}>
          <div className='info' style={{maxWidth: "82ch"}}><span className="address">Họ tên KH:</span><strong>{mainTicket.Name}</strong></div>
        </div>
        <div className='customer-info-wrapper'>
          <div className='left-info'>
            <div className='info-wrapper'>
              <div className='title-info-wrapper'>Giới tính:</div>
              <div className='info'>{mainTicket.Gender}</div>
            </div>
          </div>
          <div className='right-info'>
            <div className='info-wrapper' style={{ position: "relative" }}>
              <div 
                className='title-info-wrapper'
                style={{position:"relative", display: "inline-block", width: "15ch"}}
              >
                Họ tên KH (có dấu)<span style={{color: "var(--vermillion)"}}>*</span>:
              </div>
              <div className='refresh-wraper'>
                {
                  this.state.step[NAME_TYPE] === 1 ?
                    <React.Fragment>
                      <div className='info'>
                        {
                          this.state.nameCustomer ?
                            <p>{this.state.nameCustomer}</p>
                            :
                            <p>{mainTicket.FullNameVi}</p>
                        }
                      </div>
                      <img className='ic_fresh' style={{ cursor: 'pointer' }} onClick={() => this.changeStep(NAME_TYPE)} src={ic_refresh} alt='icon-refresh' />
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <input 
                        className='input-name' 
                        type="text" 
                        name="" 
                        value={
                          this.state.tmpNameCustomer || 
                          this.state.tmpNameCustomer === '' ? 
                          this.state.tmpNameCustomer 
                          : 
                          mainTicket.FullNameVi
                        }
                        onKeyPress={e => {
                          // debugger;
                          // e.preventDefault();a
                          return e.charCode === 13?
                          this.saveData(NAME_TYPE)
                          :
                          false
                        }}
                        onChange={(e) => this.handleChangeName(e)} 
                      />
                      <img style={{ cursor: 'pointer' }} onClick={() => this.saveData(NAME_TYPE)} src={ic_success_blue} alt='icon-success-blue' />
                      <img style={{ cursor: 'pointer' }} onClick={() => this.cancelSaveData(NAME_TYPE)} src={ic_cancel} alt='icon-cancel' />
                    </React.Fragment>
                }
              </div>
            </div>
            {
              !!this.state.errLabelName &&
              <p className="label_errName">{this.state.errLabelName}</p>
            }
          </div>
        </div>
        <div className='customer-info-wrapper'>
          <div className='left-info'>
          <div className='info-wrapper'>
            <div className='title-info-wrapper'>CMND/CCCD/HC:</div>
              <div className='info'>{mainTicket.IdNumber || mainTicket.IDNum || mainTicket.IDNumber}</div>
            </div>
          </div>
          <div className='right-info'>
            <div className='info-wrapper'>
              <div className='title-info-wrapper'>Hạng khách hàng:</div>
              <div className='info'>{mainTicket.SegmentDesc}</div>
            </div>
          </div>
        </div>
        <div className='customer-info-wrapper'>
          <div className='left-info'>
            <div className='info-wrapper'>
              <div className='title-info-wrapper'>Ngày cấp:</div>
              <div className='info'>{mainTicket.IdIssueDate}</div>
            </div>
          </div>
          <div className='right-info'>
            <div className='info-wrapper'
              style={{ position: "relative" }}
            >
              <div 
                className='title-info-wrapper'
                style={{position:"relative", display: "inline-block"}}
              >
              Số điện thoại di động
              <span 
                style={{
                  color: "var(--vermillion)"
                }}
              >*</span>:
              </div>
              <div className='refresh-wraper'>
                {
                  (this.state.step[PHONE_TYPE] === 1) ?
                    <React.Fragment>
                      <div className='info'>{mainTicket.MobileNumber}</div>
                      <img style={{ cursor: 'pointer' }} onClick={() => this.changeStep(PHONE_TYPE)} src={ic_refresh} alt='icon-refresh' />
                    </React.Fragment> :
                    <React.Fragment>
                      <input 
                        className='input-phone' 
                        type="number" 
                        name="" 
                        value={
                          this.state.tmpPhone || 
                          this.state.tmpPhone === '' ? 
                          this.state.tmpPhone 
                          : 
                          mainTicket.MobileNumber
                        }
                        maxLength="10"
                        onKeyPress={e => {
                          // debugger;
                          // e.preventDefault();
                          return e.charCode === 13?
                          this.saveData(PHONE_TYPE)
                          :
                          false
                        }}
                        onChange={(e) => this.handleChangePhone(e)} />
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.saveData(PHONE_TYPE)}
                        src={ic_success_blue} alt='icon-success-blue' />
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.cancelSaveData(PHONE_TYPE)}
                        src={ic_cancel} alt='icon-cancel' />
                    </React.Fragment>
                }
              </div>
            </div>
            {
              !!this.state.errLabelPhone &&
              <p className="label_errPhone">{this.state.errLabelPhone}</p>
            }
          </div>
        </div>
        <div className='customer-info-wrapper'>
          <div className='left-info'>
            <div className='info-wrapper'>
              <div className='title-info-wrapper'>Nơi cấp:</div>
              <div className='info'>{mainTicket.IdIssuePlace}</div>
            </div>
            
          </div>
          <div className='right-info'>
            <div className='info-wrapper'>
              <div className='title-info-wrapper'>Email:</div>
              <div className='info'>{mainTicket.Email}</div>
            </div>
          </div>
        </div>
        
        <div className='info-wrapper'>
          <div className='info' style={{maxWidth: "82ch"}}><span className="address">Địa chỉ:</span> {mainTicket.Address}</div>
        </div> */}
            </BoundStyle>
        )
    }

    componentDidMount() {
        const listCurrentTicket = this.props.counterReducer.listCurrentTicket
        const dataGetTicket = this.props.dataGetTicket
        const currentTicket = listCurrentTicket.find(
            (item) => item.TicketID === dataGetTicket.TicketID
        )
        if (currentTicket.CustomerInfos) {
            const mainTicket = currentTicket.CustomerInfos
            this.setState({
                tmpPhone: mainTicket.MobileNumber,
                tmpNameCustomer: mainTicket.FullNameVi,
            })
        }
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (typePopup, levelPopup, data) =>
        dispatch(openPopup(typePopup, levelPopup, data)),
    updateBioInfo: (enrollId, newFullNameVi, newMobile, ticketId) =>
        dispatch(updateBioInfo(enrollId, newFullNameVi, newMobile, ticketId)),
    requestEnroll: (ticketId, cusInfo) =>
        dispatch(requestEnroll(ticketId, cusInfo)),
    //// flowUpdate
    requestUpDateBio: (ticketId, cusNumber) =>
        dispatch(requestUpDateBio(ticketId, cusNumber)),
    /////////// BioCollector
    requestEnrollCollector: (cifs, currentTicketId) =>
        dispatch(requestEnrollCollector(cifs, currentTicketId)),
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    updateCusInfosCollector: (enrollId, ticketId, newFullNameVi, newMobile) =>
        dispatch(
            updateCusInfosCollector(
                enrollId,
                ticketId,
                newFullNameVi,
                newMobile
            )
        ),
    clearError: (typeError) => dispatch(clearError(typeError)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(UserInfomation)
