import React from 'react'
import { useEffect, useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
//
import OnlineTime from './index.styles'
dayjs.extend(duration)

const initialState = {
    hours: '00',
    minutes: '00',
    seconds: '00',
}

const GetTimeSuccess = ({ startTime, endTime, isSuccess, classname, ticketId }) => {
    const timer = useRef()
    // const startOnlineTime = useSelector((state) => state.monitor.startOnlineTime);
    const [timeOnline, setTimeOnline] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            ...initialState,
        }
    )

    useEffect(() => {
        if (!isSuccess) {
            setTimeOnline({
                ...initialState,
            })
        }
    }, [isSuccess])

    const handleTime = () => {
        const now = dayjs(endTime)
        const diff = Math.abs(now.diff(dayjs(startTime)))
        const duration = dayjs.duration(diff)
        const hours = Math.floor(duration.hours()).toString().padStart(2, '0')
        const minutes = Math.floor(duration.minutes())
            .toString()
            .padStart(2, '0')
        const seconds = Math.floor(duration.seconds())
            .toString()
            .padStart(2, '0')

        setTimeOnline({
            hours,
            minutes,
            seconds,
        })
    }

    useEffect(() => {
        // console.log(startTime,endTime)
        if (!isSuccess) return
        handleTime()
    }, [isSuccess,ticketId])

    // useEffect(() => {
    // 	if (!startTime) return;
    // 	timer.current = setTimeout(handleTime, 1000);
    // 	return () => {
    // 		clearTimeout(timer.current);
    // 	};
    // }, [startTime, timeOnline]);

    const { hours, minutes, seconds } = timeOnline

    return (
        <OnlineTime.Wrap className={classname}>
            <OnlineTime.Time>
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            </OnlineTime.Time>
        </OnlineTime.Wrap>
    )
}
export default GetTimeSuccess
