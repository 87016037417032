import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { popupBioTypes } from '../../../data/PopupTypes'
import { openPopup, closePopup } from '../../../actions/popupBioAction'

import RegisterFingerIDControl from '../../Control/RegisterFingerIDControl'
import PopupShowFaceImage from '../PopupRegisterFingerID/PopupShowFaceImage'
import PopupHistoryCustomer from '../PopupHistoryDetailV2/PopupHistoryCustomer'
import PopupConfirm from '../PopupUserManage/PopupConfirm'
import PopupUserManage from '../PopupUserManage'
import PopupSearchCifs from '../PopupSearchCifs'
import PoupNotRegister from '../PoupNotRegister'
import PopupCheckCustomer from '../PopupCheckCustomer/PopupCheckCustomer'
import PopupCouterFail from '../PopupCouterFail'
import PopupAccept from '../PopupAccept'
import typesKeyDetailPopup from '../../../data/typesKeyDetailPopup'
import PopupHistoryFinger from '../PopupHistoryFinger'
import PopupPassTicket from '../PopupPassTicket'
import PopupCouterLogin from '../PopupCouterLogin'
import PopupCouterSuccess from '../PopupCouterSuccess'
import PopupShowNotice from '../PopupShowNotice'
import PopupShowReasonRejectBio from '../PopupShowReasonRejectBio'
import PopupBioFingerTransaction from '../PopupUserManage/PopupBioFingerTransaction'
import PopupFillReasonCusNotAuth from '../PopupCheckCustomer/PopupFillReasonCusNotAuth'
import PopupShowErrorExistedDataBiometric from '../PopupShowErrorExistedDataBiometric'
import PopupWaitingEvalueateQualityService from '../PopupWaitingEvalueateQualityService'
import FaceUpdateFlow from '../PopupUserManage/FaceUpdateFlow'
import PopupNotify from '../../Control/PopupNotify'
import PopupMixResultConfirmFace from '../PopupMixResultConfirmFace'
import PopupPrintV2 from '../PopupPrintV2'
import PopupNotRegisterFingerButFace from '../PopupNotRegisterFingerButFace'
import PopupRefuseRegFinger from '../PopupRefuseRegFinger'
import ErrorGetTicket76 from '../../../helper/ErrorGetTicket76'
import PopupControlTablet from '../PopupControlTablet'
import Queries from '../Queries'
import PopupFillClientName from '../PopupFillClientName'
import PopupShowFace from '../Queries/GTTTQueries/BioInfos/PopupShowFace'
import PopupShowFinger from '../Queries/GTTTQueries/BioInfos/PopupShowFinger'
import QueryResults from '../Queries/QueryByBioImage/QueryResults'
import PopupDetailBiometric from '../../MainPage/Mainscreen/ListWaitingCollector/PopupDetailBiometric'
import GTTTQueries from '../Queries/GTTTQueries'
import PopupShowCustDetailsBl from '../../MainPage/Mainscreen/ListWaitingCollector/PopupShowCustDetails/indexBl'
import QueryFingerResults from '../Queries/QueryByBioImage/QueryFingerResults'

// helper
import { buildErrorContent } from '../../../helper/tool'
import autoOpenPopupWhenGetTicket from '../../../helper/autoOpenPopupWhenGetTicket'
import checkApplyBiometricForTicket from '../../../helper/checkApplyBiometricForTicket'
import isDisableEventWithCORPCust from '../../../helper/isDisableEventWithCORPCust'
import {
    openPopupAddReasonNotFillFullData,
    openPopupNotifySendSuccess,
    openPopupEvalueateQualityService,
    // openPopupConfirmSendSupervisor,
    openPopupAddReasonDeleteBioData,
    showPopupPreventFinishTask,
} from '../../../helper/actionOpenPopup'
import isErrorWhenGetEnroll from '../../../helper/isErrorWhenGetEnroll'
import getFromChannel from '../../../helper/getFromChannel'

// data
import {
    CALL_TICKET_ERR,
    COMPLETE_TICKET_ERR,
    COMPLETE_BIO_COLLECTOR_ERR,
    COMPLETE_BIO_ERR,
    MOVE_TICKET_TO_MISS_CALL_ERR,
    ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR,
} from '../../../actions/types'
import { FACE } from '../../../data/bioTypes'
import {
    REGISTER,
    REMOVE,
    VALIDATE_DATA,
} from '../../../actions/typeRequestBio'
import { TELLER } from '../../../data/userRole'
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket'
import * as counterStates from '../../../data/counterStates'
import bioStatusTypes from '../../../data/bioStatusTypes'
import fromChannels from '../../../data/fromChannels'

// action
import {
    completeTicket,
    tellerLogoutCounter,
    disconnectDesktop,
    getInfoCustomer,
    addCustomerInfos,
    addBiometricExistFromCustomerInfos,
    addInprogressBioExistFromCustomerInfo,
    moveToMissCall,
    callTicket,
    recallTicket,
    requestUpDateBio,
    getAllTicketWaiting,
    clearCustomerInfosInStore,
    startOrStopCounter,
} from '../../../actions/counterAction'
import { clearError } from '../../../actions/commonAction'
import {
    getCurrentBioEnroll,
    requestRemoveBioData,
    resetConfirmBioData,
    removeDuplicateFace,
    updateRemoveBio,
    removeDataBioOfTicketInStore,
    removeStatusWaitingBioData,
    completeBio,
} from '../../../actions/biometricAction'
import {
    removeBioDataCollector,
    completeBioCollector,
    updateBiometricNoteCollector,
    removeStatusWaitingBioDataCollector,
    getCurrentBioEnrollCollector,
    requestUpDateBioCollector,
    updateRevertRemoveBioCollector,
    updateRemoveBioCollector,
    finishTransInTask,
} from '../../../actions/biometricWithInBioCollectorAction'

// image
import iconNotificationClose from '../../../images/ic_counter_notification_close.svg'
import ic_cancel from '../../../images/icon_cancel.svg'
import ic_warning_big from '../../../images/ic_warning_big.svg'

// import iconNotificationSuccess from "../../../images/ic_counter_notification_success.svg"

const BoundPopupLv1 = styled.div`
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: ${(props) =>
        (props.nonOverlay && 'transparent') || 'rgba(0, 0, 0, 0.6)'};
`
const BoundPopupLv2 = styled.div`
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
`

const BoundPopupLv3 = styled.div`
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.haveOverlay ? 'rgba(0, 0, 0, 0.6)' : 'transparent'};
`

class PopupContainerRedux extends Component {
    state = {
        nonOverlay: false,
        contentLv1: null,
        contentLv2: null,
        contentLv3: null,
        /////////////////
        isOnOffShowNotice: false,
        haveOverlay: false,
    }

    isMouseEnterPopup = false

    handleAcceptActionLv3 = async (data) => {
        const { dataLv3 } = this.props.popupBioManagerReducer

        switch (dataLv3.key) {
            case typesKeyDetailPopup.addReasonDeleteBioData: {
                const { ticketId, bioType } = dataLv3
                const { note } = data
                const currentTicket = this.props.counterReducer.listCurrentTicket.find(
                    (item) => item.TicketID === ticketId
                )
                if (!currentTicket) return
                if (!currentTicket?.CustomerInfos) return
                const userIsTeller = this.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                const enrollId = this.props.counterReducer.enrollId[ticketId]
                if (!enrollId) {
                    userIsTeller &&
                        (await this.props.requestUpDateBio(
                            ticketId,
                            currentTicket?.CustomerInfos?.CustomerNumber
                        ))
                    !userIsTeller &&
                        (await this.props.requestUpDateBioCollector(
                            ticketId,
                            currentTicket?.CustomerInfos?.CustomerNumber
                        ))
                    const isError = isErrorWhenGetEnroll(
                        this.props.counterReducer,
                        this.props.openPopup,
                        this.props.clearError
                    )
                    if (isError) return
                }
                userIsTeller &&
                    (await this.props.updateRemoveBio(
                        this.props.counterReducer.enrollId[ticketId],
                        bioType,
                        note
                    ))
                !userIsTeller &&
                    (await this.props.updateRemoveBioCollector(
                        this.props.counterReducer.enrollId[ticketId],
                        bioType,
                        note
                    ))
                this.props.closePopup(3)
                break
            }
            case typesKeyDetailPopup.removeFingerFlowUpdate: {
                const { ticketId, typeBio } = dataLv3.data
                openPopupAddReasonDeleteBioData(
                    this,
                    {
                        ticketId,
                        bioType: typeBio,
                    },
                    'handleAcceptActionLv3 - PopupContainerRedux.js'
                )
                break
            }
            case typesKeyDetailPopup.registerFingerFlowUpdate: {
                const OPEN_POPUP_LEVEL_2 = 2
                const data = { ...dataLv3.data /* , posLeft: "48%" */ }
                this.props.closePopup(3)
                // this.props.closePopup(2)
                this.props.openPopup(
                    popupBioTypes.RegisterFingerIDControl,
                    OPEN_POPUP_LEVEL_2,
                    data
                )
                break
            }
            case typesKeyDetailPopup.openFlowUpdateFace: {
                const OPEN_POPUP_LEVEL_2 = 2
                this.props.closePopup(3)

                const dataTranferToPopup = {
                    ticketId: dataLv3.ticketId,
                }
                this.props.openPopup(
                    popupBioTypes.PopupFaceUpdateFlow,
                    OPEN_POPUP_LEVEL_2,
                    dataTranferToPopup
                )
                break
            }

            default:
                break
        }
    }

    // handleCheckOpenPopupWhenCallTicket = async (ticketId) => {
    //   // debugger;
    //   // clear all popup
    //   const POPUP_LEVEL_1 = 1;
    //   this.props.closePopup(POPUP_LEVEL_1);

    //   const curentTicket = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === ticketId);
    //   if(!curentTicket) return;

    //   const { CustomerInfos, TicketID } = curentTicket;
    //   if(!CustomerInfos) return;

    //   const wrapConditionOpenPopup = CustomerInfos.BiometricFace + CustomerInfos.BiometricFinger;
    //   const cifs = CustomerInfos.CustomerNumber;
    //   const isHaveBiometrictFinger = /.1/.test(wrapConditionOpenPopup);
    //   // const isHaveBiometrictFinger = true;
    //   const isHaveBiometrictFaceAndNoneFinger = /10/.test(wrapConditionOpenPopup);
    //   const isNotHaveBiometric = /00/.test(wrapConditionOpenPopup);
    //   switch (true) {
    //     case isHaveBiometrictFinger: {
    //       // case true: {
    //       // console.log("have finger")
    //       // debugger;
    //       const POPUP_LEVEL_1 = 1;
    //       this.props.openPopup(
    //         popupBioTypes.CheckCustomerActions,
    //         POPUP_LEVEL_1,
    //         { ticketId: TicketID }
    //       )
    //       break;
    //     }
    //     case isHaveBiometrictFaceAndNoneFinger:
    //     case cifs && isNotHaveBiometric:
    //       // console.log("have face")
    //       // console.log("no have bio")
    //       await this.props.openPopup(7, 1, curentTicket)
    //       break;
    //     default:
    //       break;
    //   }
    // }

    handleAcceptAction = async () => {
        const { dataLv1 } = this.props.popupBioManagerReducer
        switch (dataLv1.key) {
            case typesKeyDetailPopup.completeTicketCollector: {
                this.props.finishTransInTask(dataLv1.dataTicket.TicketID)
                this.props.closePopup(1)
                break
            }
            case 'completeTicket': {
                // debugger
                // ==============================
                const ticketId = dataLv1.dataTicket.TicketID
                const currentTicket = this.props.counterReducer.listCurrentTicket.find(
                    (item) => item.TicketID === ticketId
                )
                // debugger;
                const isCORPCust = isDisableEventWithCORPCust(
                    ticketId,
                    this.props.counterReducer
                )
                if (
                    !isCORPCust &&
                    [
                        typeStatusBiometrics.UNDEFINED,
                        typeStatusBiometrics.REJECT,
                    ].includes(String(currentTicket?.statusBiometric))
                ) {
                    const isTicketHaveCustomerInfos = !!currentTicket?.CustomerInfos
                    // const isTicketHaveEnrollId = !!this.props.counterReducer.enrollId[ticketId];\

                    const isCustomerHaveBio =
                        (isTicketHaveCustomerInfos &&
                            /validatedData/.test(
                                JSON.stringify(
                                    this.props.biometricReducer[ticketId]
                                )
                            )) ||
                        Number(currentTicket?.CustomerInfos?.BiometricActive) ||
                        Number(currentTicket?.CustomerInfos?.BiometricFace) ||
                        Number(currentTicket?.CustomerInfos?.BiometricFinger)
                    const isFilledReasonNotRegisterBio =
                        currentTicket?.CustomerInfos &&
                        currentTicket?.CustomerInfos?.RefuseGetBio

                    //nếu ko có tablet thật thì return
                    if (
                        !this.props.counterReducer.dataConnectDesktop
                            .VirtualTablet
                    ) {
                        if (
                            isTicketHaveCustomerInfos /* && !isTicketHaveEnrollId */ &&
                            !isCustomerHaveBio &&
                            !isFilledReasonNotRegisterBio
                        ) {
                            const POPUP_LEVEL_1 = 1
                            const dataTranferToPopup = {
                                key: typesKeyDetailPopup.forceRegisterBiometric,
                                image_noti: iconNotificationClose,
                                title:
                                    'Vui lòng hoàn tất đăng ký sinh trắc học',
                                widthPopup: '190px',
                                titleConfirm: 'Xác nhận',
                                ticket: currentTicket,
                            }
                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                POPUP_LEVEL_1,
                                dataTranferToPopup
                            )
                            return
                        }
                    }
                }

                const biometricOfCurrentTicket = this.props.biometricReducer[
                    ticketId
                ]
                const {
                    statusBiometric,
                    dataWasAuthen,
                    LatestHistoryType,
                    reasonRefuseRegFinger,
                } = currentTicket
                if(!this.props.counterReducer.dataConnectDesktop.VirtualTablet){
                  const isNotHaveStatusSupervisor =
                      String(statusBiometric) === typeStatusBiometrics.UNDEFINED
                  const isMustUpdateBioFingerOrReasonRefuse =
                      isNotHaveStatusSupervisor &&
                      LatestHistoryType === REGISTER &&
                      getFromChannel(
                          biometricOfCurrentTicket[FACE][VALIDATE_DATA]
                              .fromChannel
                      ) !== fromChannels.SDB && // kênh đk face !== SDB
                      biometricOfCurrentTicket[FACE]?.bioActionType !== REMOVE &&
                      !reasonRefuseRegFinger &&
                      Object.keys(biometricOfCurrentTicket).length === 1
                  // && dataWasAuthen?.BiometricActive === bioStatusTypes.ACTIVE // có sth đã được phê duyệt
                  // && dataWasAuthen?.BiometricFace !== bioStatusTypes.DISABLE // có face đã được phê duyệt
                  // && dataWasAuthen?.BiometricFinger === bioStatusTypes.DISABLE // ko có finger đã được phê duyệt
                  if (!isCORPCust && isMustUpdateBioFingerOrReasonRefuse) {
                      showPopupPreventFinishTask(this, currentTicket)
                      return
                  }
                }
                // return;
                // ==============================
                await this.props.completeTicket(
                    this.props.counterReducer.dataConnectDesktop.CounterID,
                    dataLv1.dataTicket.TicketID,
                    false
                )

                if (
                    Object.keys(this.props.counterReducer.err).includes(
                        COMPLETE_TICKET_ERR
                    )
                ) {
                    switch (
                        this.props.counterReducer.err[COMPLETE_TICKET_ERR]
                    ) {
                        case 30: {
                            showPopupPreventFinishTask(this, currentTicket)
                            break
                        }
                        case 21: {
                            const POPUP_LEVEL_1 = 1
                            await checkApplyBiometricForTicket(
                                this,
                                dataLv1.dataTicket.TicketID
                            )
                            this.props.openPopup(
                                popupBioTypes.CheckCustomerActions,
                                POPUP_LEVEL_1,
                                {
                                    ticketId: dataLv1.dataTicket.TicketID,
                                    isAuthenBeforeCompleteTicket: true,
                                    isFingerHanging: true,
                                }
                            )
                            break
                        }
                        case 16: {
                            await checkApplyBiometricForTicket(
                                this,
                                dataLv1.dataTicket.TicketID
                            )
                            const POPUP_LEVEL_1 = 1
                            const dataTranferToPopup = {
                                key:
                                    typesKeyDetailPopup.requestAuthCusBeforeCompleteTicket,
                                image_noti: iconNotificationClose,
                                title:
                                    'Vui lòng xác thực khách hàng trước khi kết thúc giao dịch',
                                widthPopup: '190px',
                                titleConfirm: 'Xác nhận',
                                ticket: dataLv1.dataTicket,
                            }
                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                POPUP_LEVEL_1,
                                dataTranferToPopup
                            )

                            break
                        }
                        case 9: {
                            const { counterReducer } = this.props
                            const ticket = dataLv1.dataTicket
                            const currentTicket = counterReducer.listCurrentTicket.find(
                                (item) => item.TicketID === ticket.TicketID
                            )
                            const isUpdate =
                                currentTicket?.CustomerInfos &&
                                (Number(
                                    currentTicket?.CustomerInfos?.BiometricActive
                                ) ||
                                    Number(
                                        currentTicket?.CustomerInfos?.BiometricFace
                                    ) ||
                                    Number(
                                        currentTicket?.CustomerInfos?.BiometricFinger
                                    ))
                            const isFilledReasonNotRegister =
                                currentTicket?.CustomerInfos &&
                                currentTicket?.CustomerInfos?.RefuseGetBio
                            // debugger;
                            if (isUpdate || isFilledReasonNotRegister) {
                                // UPDATE FLOW
                                const dataConfigPopup = {
                                    key:
                                        typesKeyDetailPopup.noticeHaveBioNotConfirmYet,
                                    // content: "Vui lòng hoàn tất luồng sinh trắc học trước khi hoàn tất giao dịch?",
                                    // content: "Luồng sinh trắc học đang mở, bạn có muốn kết thúc giao dịch",
                                    content:
                                        'STH của khách hàng đang được tác nghiệp, Bạn có muốn hoàn tất giao dịch?',
                                    contentDelete:
                                        '(*) Dữ liệu vừa xử lý sẽ không được lưu lại',
                                    width: '389px',
                                    fontSizeNote: '12px',
                                    btnLeft: {
                                        title: 'Huỷ bỏ',
                                        bgColor: 'var(--peacock-blue)',
                                        fontSize: '13px',
                                    },
                                    btnRight: {
                                        title: 'Hoàn tất GD',
                                        // title: "Xác nhận",
                                        bgColor: 'var(--lipstick)',
                                        fontSize: '13px',
                                    },
                                    dataTicket: dataLv1.dataTicket,
                                }
                                this.props.openPopup(
                                    popupBioTypes.PopupConfirm,
                                    1,
                                    dataConfigPopup
                                )
                                break
                            }
                            //ko có tablet thật thì return
                            if (
                                !this.props.counterReducer.dataConnectDesktop
                                    .VirtualTablet
                            ) {
                                // REGISTER FLOW
                                const POPUP_LEVEL_1 = 1
                                const dataTranferToPopup = {
                                    key:
                                        typesKeyDetailPopup.forceRegisterBiometric,
                                    image_noti: iconNotificationClose,
                                    title:
                                        'Vui lòng hoàn tất đăng ký sinh trắc học',
                                    widthPopup: '190px',
                                    titleConfirm: 'Xác nhận',
                                    ticket,
                                }
                                this.props.openPopup(
                                    popupBioTypes.PopupAccect,
                                    POPUP_LEVEL_1,
                                    dataTranferToPopup
                                )
                                break
                            }
                        }
                        case 13:
                        case 15:
                        case 17: {
                            const POPUP_LEVEL_1 = 1
                            const dataTranferToPopup = {
                                image_noti: iconNotificationClose,
                                title: buildErrorContent(
                                    COMPLETE_TICKET_ERR,
                                    this.props.counterReducer.err[
                                        COMPLETE_TICKET_ERR
                                    ]
                                ),
                                widthPopup: '190px',
                                titleConfirm: 'Xác nhận',
                            }

                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                POPUP_LEVEL_1,
                                dataTranferToPopup
                            )
                            break
                        }
                        default: {
                            const POPUP_LEVEL_1 = 1
                            const dataTranferToPopup = {
                                image_noti: iconNotificationClose,
                                title: buildErrorContent(
                                    COMPLETE_TICKET_ERR,
                                    100
                                ),
                                widthPopup: '190px',
                                titleConfirm: 'Xác nhận',
                            }
                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                POPUP_LEVEL_1,
                                dataTranferToPopup
                            )
                            break
                        }
                    }
                    this.props.clearError(COMPLETE_TICKET_ERR)
                    break
                }
                // debugger
                openPopupEvalueateQualityService(
                    this,
                    { ticketId },
                    'handleAcceptAction - PopupContainerRedux.js'
                )

                // this.handleClosePopupLv1()
                break
            }
            case typesKeyDetailPopup.noticeHaveBioNotConfirmYet:
                await this.props.completeTicket(
                    this.props.counterReducer.dataConnectDesktop.CounterID,
                    dataLv1.dataTicket.TicketID,
                    true
                )
                openPopupEvalueateQualityService(
                    this,
                    { ticketId: dataLv1.dataTicket.TicketID },
                    'handleAcceptAction - PopupContainerRedux.js'
                )
                // this.handleClosePopupLv1()
                break
            // case typesKeyDetailPopup.fillReasonWhyNotRegisterBioData:
            //   this.handleClosePopupLv1()
            //   // this.props.openPopup(
            //   //   popupBioTypes.PopupConfirm,
            //   //   1,
            //   //   {
            //   //     // content: `Khách hàng có ${Nzero(dataLv1.dataTicket.ListService.length)} giao dịch cần thực hiện, bạn có muốn kết thúc giao dịch ?`,
            //   //     content: `Bạn có muốn kết thúc giao dịch?`,
            //   //     width: "264px",
            //   //     dataTicket: dataLv1.dataTicket,
            //   //     key: 'completeTicket'
            //   //   }
            //   // )
            //   break;
            case typesKeyDetailPopup.noticeNoneHaveBioInfos: {
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const popupBiometric = popupBioTypes.PopupUserManage
                const dataGetTicket = {
                    ...dataLv1.dataTicket,
                    autoNextBiometric: true,
                }
                this.props.closePopup(1)
                this.props.openPopup(popupBiometric, 1, dataGetTicket)
                break
            }
            case 'moveTomissCall':
            case typesKeyDetailPopup.moveTicketToMissCallInFormAuthCustomer: {
                await this.props.closePopup(1)
                const { dataConnectDesktop } = this.props.counterReducer
                await this.props.moveToMissCall(
                    dataLv1.counterID,
                    dataLv1.ticketID
                )
                const errorMoveToMissCall = this.props.counterReducer.err[
                    MOVE_TICKET_TO_MISS_CALL_ERR
                ]
                if (errorMoveToMissCall) {
                    switch (errorMoveToMissCall) {
                        case 13: {
                            const OPEN_POPUP_LEVEL_1 = 1

                            // data follow props in PopupAccept component
                            const dataTranferFollowPopup = {
                                image_noti: iconNotificationClose,
                                title: buildErrorContent(
                                    MOVE_TICKET_TO_MISS_CALL_ERR,
                                    errorMoveToMissCall
                                ),
                                widthPopup: '381px',
                                titleConfirm: 'Xác nhận',
                            }

                            this.props.clearError(MOVE_TICKET_TO_MISS_CALL_ERR)

                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                OPEN_POPUP_LEVEL_1,
                                dataTranferFollowPopup
                            )
                            break
                        }
                        default:
                            break
                    }
                    break
                }
                await this.props.getAllTicketWaiting(
                    dataLv1.counterID,
                    dataConnectDesktop.OfficeID
                )
                break
            }
            case typesKeyDetailPopup.logOutCounterWeb: {
                let {
                    dataLogin,
                    dataConnectDesktop,
                } = this.props.counterReducer
                this.props.tellerLogoutCounter({
                    UID: dataLogin.UID,
                    CounterID: dataLogin.CounterID,
                    DeviceID: dataConnectDesktop.DeviceID,
                })
                break
            }
            case typesKeyDetailPopup.acceptCallMissTiket: {
                await this.props.callTicket(
                    dataLv1.ticketID,
                    this.props.counterReducer.dataConnectDesktop.CounterID,
                    this.props.counterReducer.dataConnectDesktop.OfficeID,
                    dataLv1.status
                )

                const errorCallTicket = this.props.counterReducer.err[
                    CALL_TICKET_ERR
                ]

                if (errorCallTicket) {
                    switch (true) {
                        case errorCallTicket === 12: {
                            const OPEN_POPUP_LEVEL_1 = 1

                            // data follow props in PopupAccept component
                            const dataTranferFollowPopup = {
                                image_noti: iconNotificationClose,
                                title: buildErrorContent(
                                    CALL_TICKET_ERR,
                                    errorCallTicket
                                ),
                                widthPopup: '254px',
                                titleConfirm: 'Xác nhận',
                            }

                            this.props.clearError(CALL_TICKET_ERR)

                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                OPEN_POPUP_LEVEL_1,
                                dataTranferFollowPopup
                            )
                            break
                        }
                        case !!errorCallTicket && errorCallTicket.Code === 76:
                        case !!errorCallTicket && errorCallTicket.Code === 4: {
                            const OPEN_POPUP_LEVEL_1 = 1
                            const {
                                CustomerNumber,
                                CustomerName,
                                TicketNumber,
                                TellerName,
                                OfficeName,
                            } = errorCallTicket
                            // data follow props in PopupAccept component
                            const dataTranferFollowPopup = {
                                image_noti: iconNotificationClose,
                                title: (
                                    <ErrorGetTicket76
                                        CustomerNumber={CustomerNumber}
                                        CustomerName={CustomerName}
                                        TicketNumber={TicketNumber}
                                        TellerName={TellerName}
                                        OfficeName={OfficeName}
                                    />
                                ),
                                widthPopup: '363px',
                                titleConfirm: 'Xác nhận',
                            }

                            this.props.clearError(CALL_TICKET_ERR)

                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                OPEN_POPUP_LEVEL_1,
                                dataTranferFollowPopup
                            )
                            break
                        }
                        default: {
                            const OPEN_POPUP_LEVEL_1 = 1

                            // data follow props in PopupAccept component
                            const dataTranferFollowPopup = {
                                image_noti: iconNotificationClose,
                                title:
                                    'Không thể thực hiện gọi nhỡ vào lúc này, xin vui lòng thử lại',
                                widthPopup: '262px',
                                titleConfirm: 'Xác nhận',
                            }

                            this.props.clearError(CALL_TICKET_ERR)

                            this.props.openPopup(
                                popupBioTypes.PopupAccect,
                                OPEN_POPUP_LEVEL_1,
                                dataTranferFollowPopup
                            )
                            break
                        }
                    }
                    return
                }
                // if(this.props.counterReducer.dataGetTicket.TicketData && !this.props.counterReducer.dataGetTicket.TicketData.CustomerInfos)
                this.props.closePopup(1)
                if (
                    !isDisableEventWithCORPCust(
                        dataLv1.ticketID,
                        this.props.counterReducer
                    )
                )
                    await autoOpenPopupWhenGetTicket(this, dataLv1.ticketID)
                break
            }
            case typesKeyDetailPopup.recallTicketInCounter: {
                await this.props.recallTicket(dataLv1.ticketId)
                await this.props.closePopup(1)
                // debugger
                if (
                    !isDisableEventWithCORPCust(
                        dataLv1.ticketId,
                        this.props.counterReducer
                    )
                )
                    await autoOpenPopupWhenGetTicket(this, dataLv1.ticketId)
                break
            }
            case typesKeyDetailPopup.confirmStopCounter: {
                // console.log('stop counter')
                const {
                    counterState,
                    dataConnectDesktop,
                } = this.props.counterReducer
                if (counterState === counterStates.STOP_COUNTER) {
                    this.props.startOrStopCounter(
                        dataConnectDesktop.CounterID,
                        counterStates.OPEN_COUNTER
                    )
                    this.props.closePopup(1)
                    break
                }
                this.props.startOrStopCounter(
                    dataConnectDesktop.CounterID,
                    counterStates.STOP_COUNTER
                )
                this.props.closePopup(1)
                break
            }
            case typesKeyDetailPopup.reCallTicketInFormAuthCustomer: {
                await this.props.recallTicket(dataLv1.ticketId)
                await this.props.closePopup(1)
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const POPUP_LEVEL_1 = 1
                this.props.openPopup(
                    popupBioTypes.CheckCustomerActions,
                    POPUP_LEVEL_1,
                    { ticketId: dataLv1.ticketId }
                )
                break
            }
            default:
                this.props.closePopup(1)
                break
        }
    }

    handleAcceptActionLv2 = async (data) => {
        const { dataLv2 } = this.props.popupBioManagerReducer
        switch (dataLv2.key) {
            case typesKeyDetailPopup.confirmSendSupervisorTeller: {
                const ticketId = dataLv2.ticketId
                const { counterReducer } = this.props
                const enrollId = counterReducer.enrollId[ticketId]
                if (!enrollId) return

                if (counterReducer.dataLogin.Roles.includes(TELLER)) {
                    await this.props.removeStatusWaitingBioData(ticketId)
                    await this.props.completeBio(enrollId, ticketId)
                } else {
                    await this.props.removeStatusWaitingBioDataCollector(
                        ticketId
                    )
                    await this.props.completeBioCollector(enrollId)
                    await this.props.getCurrentBioEnrollCollector(
                        counterReducer.dataConnectDesktop.CounterID,
                        enrollId
                    )
                }

                if (this.props.counterReducer.err[COMPLETE_BIO_ERR]) {
                    // switch(this.props.counterReducer.err[COMPLETE_BIO_ERR]){
                    //   case 8: {
                    const err = buildErrorContent(
                        COMPLETE_BIO_ERR,
                        this.props.counterReducer.err[COMPLETE_BIO_ERR]
                    )
                    // debugger;
                    const POPUP_LEVEL_2 = 2
                    this.props.openPopup(
                        popupBioTypes.PopupCounterFail,
                        POPUP_LEVEL_2,
                        { icon: ic_cancel, label: err, width: '270px' }
                    )
                    this.props.clearError(COMPLETE_BIO_ERR)
                    // break;
                    // }

                    // default:
                    // break;
                    // }
                    return
                }
                if (
                    !this.props.counterReducer.err ||
                    (typeof this.props.counterReducer.err === 'object' &&
                        !Object.keys(this.props.counterReducer.err).includes(
                            COMPLETE_BIO_COLLECTOR_ERR
                        ) &&
                        !Object.keys(this.props.counterReducer.err).includes(
                            COMPLETE_BIO_ERR
                        ))
                ) {
                    // const POPUP_LEVEL_1 = 1;
                    this.props.closePopup(2)
                    openPopupNotifySendSuccess(
                        this,
                        {},
                        'completeBioKSV - PopupUserManage.js'
                    )
                    return
                }
                break
            }
            case typesKeyDetailPopup.addReasonNotFillFullData: {
                const { ticketId } = dataLv2
                const { counterReducer } = this.props
                const currentTicket = counterReducer.listCurrentTicket.find(
                    (item) => item.TicketID === ticketId
                )
                // debugger;
                await this.props.removeStatusWaitingBioDataCollector(ticketId)
                await this.props.updateBiometricNoteCollector(
                    counterReducer.enrollId[ticketId],
                    currentTicket?.tellerNoteDuplicateBio || '',
                    data.note
                )
                await this.props.completeBioCollector(
                    counterReducer.enrollId[ticketId]
                )
                await this.props.getCurrentBioEnrollCollector(
                    counterReducer.dataConnectDesktop.CounterID,
                    counterReducer.enrollId[ticketId]
                )
                await this.props.closePopup(1)
                await this.props.closePopup(2)
                // notice success if not err
                if (
                    !this.props.counterReducer.err ||
                    (typeof this.props.counterReducer.err === 'object' &&
                        !Object.keys(this.props.counterReducer.err).includes(
                            COMPLETE_BIO_COLLECTOR_ERR
                        ))
                ) {
                    // const POPUP_LEVEL_1 = 1;
                    openPopupNotifySendSuccess(
                        this,
                        {},
                        'handleAcceptActionLv2 - PopupContainerRedux.js'
                    )
                    // return;
                }
                break
            }
            case typesKeyDetailPopup.confirmSendSupervisor: {
                const ticket = dataLv2.dataTicket
                openPopupAddReasonNotFillFullData(
                    this,
                    { ticketId: ticket.TicketID },
                    'handleAcceptActionLv2 - PopupContainerRedux.js'
                )
                break
            }
            case typesKeyDetailPopup.applyCifsForTicketAlreadyHaveCifs: {
                // debugger;
                // this.props.clearCustomerInfosInStore();
                await this.props.removeDataBioOfTicketInStore(dataLv2.ticketId)
                await this.props.addCustomerInfos(
                    dataLv2.customerInfo,
                    dataLv2.ticketId,
                    true
                )
                const errorAddCusInfos = this.props.counterReducer.err[
                    ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
                ]
                if (errorAddCusInfos && errorAddCusInfos.Code === 76) {
                    const OPEN_POPUP_LEVEL_2 = 2
                    const {
                        CustomerNumber,
                        CustomerName,
                        TicketNumber,
                        TellerName,
                        OfficeName,
                    } = errorAddCusInfos
                    // data follow props in PopupAccept component
                    const dataTranferFollowPopup = {
                        image_noti: iconNotificationClose,
                        title: (
                            <ErrorGetTicket76
                                CustomerNumber={CustomerNumber}
                                CustomerName={CustomerName}
                                TicketNumber={TicketNumber}
                                TellerName={TellerName}
                                OfficeName={OfficeName}
                            />
                        ),
                        widthPopup: '363px',
                        titleConfirm: 'Xác nhận',
                    }

                    this.props.clearError(
                        ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
                    )

                    this.props.openPopup(
                        popupBioTypes.PopupAccect,
                        OPEN_POPUP_LEVEL_2,
                        dataTranferFollowPopup
                    )
                    break
                }
                await this.props.addBiometricExistFromCustomerInfos(
                    dataLv2.bioInfos,
                    dataLv2.ticketId
                )
                this.props.closePopup(2)
                this.props.closePopup(1)
                if (
                    !isDisableEventWithCORPCust(
                        dataLv2.ticketId,
                        this.props.counterReducer
                    )
                ) {
                    autoOpenPopupWhenGetTicket(this, dataLv2.ticketId)
                }
                break
            }
            case typesKeyDetailPopup.confirmRemoveBioData: {
                const isTeller = this.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                // debugger;
                if (dataLv2.bioType === FACE) {
                    isTeller &&
                        this.props.requestRemoveBioData(
                            dataLv2.enrollId,
                            dataLv2.bioType,
                            dataLv2.faceId,
                            dataLv2.ticketId
                        )
                    !isTeller &&
                        this.props.removeBioDataCollector(
                            dataLv2.enrollId,
                            dataLv2.bioType,
                            dataLv2.faceId,
                            dataLv2.ticketId
                        )

                    this.props.removeDuplicateFace(dataLv2.ticketId)
                } else {
                    const { enrollId, bioType, ticketId } = dataLv2
                    //remove REGISTER
                    const { biometricReducer } = this.props
                    const lstEnrollData =
                        biometricReducer[ticketId][bioType][REGISTER]
                    // debugger;
                    for (const finger of lstEnrollData) {
                        isTeller &&
                            (await this.props.requestRemoveBioData(
                                enrollId,
                                bioType,
                                finger,
                                ticketId
                            ))

                        !isTeller &&
                            (await this.props.removeBioDataCollector(
                                enrollId,
                                bioType,
                                finger,
                                ticketId
                            ))
                    }
                    //remove CONFIRM
                    //remove usedToAuthenticateTransaction
                    this.props.resetConfirmBioData(ticketId, bioType)
                }
                this.handleClosePopupLv2()
                break
            }

            case typesKeyDetailPopup.fillReasonWhyNotRegisterBioData: {
                this.handleClosePopupLv2()
                this.props.closePopup(1)
                break
            }
            default:
                this.handleClosePopupLv2()
                break
        }
    }

    handleOpenPopupLv1 = () => {
        let { typeLv1, dataLv1 } = this.props.popupBioManagerReducer
        let contentLv1
        switch (typeLv1) {
            case popupBioTypes.PopupShowCustDetailsBl: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupShowCustDetailsBl
                        handleClose={this.handleClosePopupLv1}
                        {...dataLv1}
                    />
                )
                break
            }
            case popupBioTypes.PopupDetailBiometric: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupDetailBiometric
                        closePopup={this.handleClosePopupLv1}
                        {...dataLv1}
                    />
                )
                break
            }
            case popupBioTypes.PopupQueries: {
                this.setState({ nonOverlay: true })
                contentLv1 = <Queries closePopup={this.handleClosePopupLv1} />
                break
            }
            case popupBioTypes.PopupFillClientName: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupFillClientName
                        closePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupWaitingEvalueateQualityService: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupWaitingEvalueateQualityService
                        ticketId={dataLv1.ticketId}
                        closePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupSearchCifs: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupSearchCifs
                        ticketId={dataLv1.ticketId}
                        cancelAction={() => this.handleClosePopupLv1()}
                        acceptAction={this.handleAcceptAction}
                    />
                )
                break
            }
            case popupBioTypes.PopupUserManage: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupUserManage
                        flowUpdate={dataLv1.flowUpdate}
                        dataGetTicket={dataLv1}
                        cancelAction={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupCounterFail: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupCouterFail
                        data={dataLv1}
                        label={dataLv1.label}
                        icon={dataLv1.icon}
                        width={dataLv1.width}
                        onClosePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupConfirm: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupConfirm
                        data={dataLv1}
                        content={dataLv1.content && dataLv1.content}
                        width={dataLv1.width && dataLv1.width}
                        contentDelete={
                            dataLv1.contentDelete && dataLv1.contentDelete
                        }
                        fontSizeNote={dataLv1.fontSizeNote}
                        btnLeft={dataLv1.btnLeft}
                        btnRight={dataLv1.btnRight}
                        cancelAction={() => this.handleClosePopupLv1()}
                        acceptAction={() => {
                            this.handleAcceptAction()
                        }}
                    />
                )
                break
            }
            case popupBioTypes.PoupNotRegister: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PoupNotRegister
                        data={dataLv1}
                        cancelAction={() => this.handleClosePopupLv1()}
                        acceptAction={this.handleAcceptAction}
                    />
                )
                break
            }
            case popupBioTypes.PopupAccect: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupAccept
                        data={dataLv1}
                        onClose={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.CheckCustomerActions: {
                // this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupCheckCustomer
                        data={dataLv1}
                        cancelAction={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupPassTicket: {
                this.setState({ nonOverlay: false })
                contentLv1 = (
                    <PopupPassTicket
                        data={dataLv1}
                        onClosePopup={() => this.handleClosePopupLv1()}
                        onAccept={this.handleAcceptAction}
                    />
                )
                break
            }
            case popupBioTypes.PopupCouterLogin: {
                this.setState({ nonOverlay: true })
                contentLv1 = (
                    <PopupCouterLogin
                        data={dataLv1}
                        onClosePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupCouterSuccess: {
                const { dataConnectDesktop } = this.props.counterReducer
                this.setState({ nonOverlay: true })
                contentLv1 = (
                    <PopupCouterSuccess
                        counterReducer={this.props.counterReducer}
                        disconnectCounter={() =>
                            this.props.disconnectDesktop(
                                dataConnectDesktop.CounterID,
                                dataConnectDesktop.OfficeID,
                                dataConnectDesktop.DeviceID
                            )
                        }
                        onClosePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowNotice: {
                this.setState({ nonOverlay: true, isOnOffShowNotice: true })
                contentLv1 = (
                    <PopupShowNotice
                        onMouseDown={() => {
                            this.props.closePopup(1)
                        }}
                        locationComponent={dataLv1.offset}
                        onClosePopup={() => this.handleClosePopupLv1()}
                    />
                )
                break
            }
            default:
                break
        }
        this.setState({ contentLv1 })
    }

    handleOpenPopupLv2 = () => {
        let { typeLv2, dataLv2 } = this.props.popupBioManagerReducer
        let contentLv2

        switch (typeLv2) {
            case popupBioTypes.QueryFingerResults: {
                contentLv2 = (
                    <QueryFingerResults
                        {...dataLv2}
                        handleClose={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowUserFoundByBioImage: {
                contentLv2 = (
                    <QueryResults
                        {...dataLv2}
                        handleClose={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowFaceQueries: {
                contentLv2 = (
                    <PopupShowFace
                        {...dataLv2}
                        handleClose={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowFingerQueries: {
                contentLv2 = (
                    <PopupShowFinger
                        {...dataLv2}
                        handleClose={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupNotRegisterFingerButFace: {
                contentLv2 = (
                    <PopupNotRegisterFingerButFace
                        data={dataLv2}
                        onClosePopup={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupRefuseRegFinger: {
                contentLv2 = (
                    <PopupRefuseRegFinger
                        data={dataLv2}
                        onClosePopup={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupNotify: {
                contentLv2 = (
                    <PopupNotify
                        data={dataLv2}
                        onClosePopup={this.handleClosePopupLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupMixResultConfirmFace: {
                contentLv2 = (
                    <PopupMixResultConfirmFace
                        ticketId={dataLv2.ticketId}
                        closePopup={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.PopupFaceUpdateFlow: {
                contentLv2 = (
                    <FaceUpdateFlow
                        ticketId={dataLv2.ticketId}
                        closePopup={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowErrorExistedDataBiometric: {
                contentLv2 = (
                    <PopupShowErrorExistedDataBiometric
                        typeExistedData={dataLv2.typeExistedData}
                        data={dataLv2.data}
                        ticketId={dataLv2.ticketId}
                        closePopup={() => this.handleClosePopupLv2()}
                        acceptAction={this.handleAcceptActionLv2}
                    />
                )
                break
            }
            case popupBioTypes.PoupNotRegister: {
                // this.setState({ nonOverlay: false })
                contentLv2 = (
                    <PoupNotRegister
                        data={dataLv2}
                        cancelAction={() => this.handleClosePopupLv2()}
                        acceptAction={this.handleAcceptActionLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowReasonRejectBio: {
                contentLv2 = (
                    <PopupShowReasonRejectBio
                        data={dataLv2}
                        ticketId={dataLv2.ticketId}
                        type={dataLv2.type}
                        onClosePopup={() => this.handleClosePopupLv2()}
                        onClickBtnSubmit={this.handleAcceptActionLv2}
                    />
                )
                break
            }
            case popupBioTypes.PopupFillReasonCusNotAuth: {
                contentLv2 = (
                    <PopupFillReasonCusNotAuth
                        ticketId={dataLv2.ticketId}
                        onClosePopup={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.PopupCounterFail: {
                contentLv2 = (
                    <PopupCouterFail
                        data={dataLv2}
                        label={dataLv2.label}
                        icon={dataLv2.icon}
                        width={dataLv2.width}
                        onClosePopup={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.RegisterFingerIDControl: {
                contentLv2 = (
                    <RegisterFingerIDControl
                        data={dataLv2}
                        flowUpdate={dataLv2.flowUpdate}
                        onClose={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.ShowFaceImage: {
                contentLv2 = (
                    <PopupShowFaceImage
                        sourceImg={dataLv2.faceID}
                        dataBio={dataLv2}
                        onClosePopup={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.HistoryCustomer: {
                contentLv2 = (
                    <PopupHistoryCustomer
                        data={dataLv2}
                        onClosePopup={() => {
                            this.handleClosePopupLv2()
                        }}
                    />
                )
                break
            }
            case popupBioTypes.PopupConfirm: {
                contentLv2 = (
                    <PopupConfirm
                        data={dataLv2}
                        content={dataLv2.content && dataLv2.content}
                        contentDelete={
                            dataLv2.contentDelete && dataLv2.contentDelete
                        }
                        width={dataLv2.width && dataLv2.width}
                        fontSizeNote={dataLv2.fontSizeNote}
                        btnLeft={dataLv2.btnLeft}
                        btnRight={dataLv2.btnRight}
                        cancelAction={() => this.handleClosePopupLv2()}
                        acceptAction={() => {
                            this.handleAcceptActionLv2()
                        }}
                    />
                )
                break
            }
            // case popupBioTypes.PoupNotRegister:
            //   {
            //     contentLv2 = <PoupNotRegister
            //       data={dataLv2}
            //       cancelAction={() => this.handleClosePopupLv2()}
            //       acceptAction={()=>this.closePopup(2)}
            //     />
            //     break;
            //   }
            case popupBioTypes.PopupAccect: {
                contentLv2 = (
                    <PopupAccept
                        data={dataLv2}
                        onClose={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.PopupPrintText: {
                // contentLv2 = <FormRegisterPrint
                //   data={dataLv2}
                //   onClose={() => this.handleClosePopupLv2()}
                // />
                contentLv2 = (
                    <PopupPrintV2
                        data={dataLv2}
                        onClose={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            case popupBioTypes.PopupBioFingerTransaction: {
                contentLv2 = (
                    <PopupBioFingerTransaction
                        data={dataLv2}
                        flowUpdate={dataLv2.flowUpdate}
                        onClose={() => this.handleClosePopupLv2()}
                    />
                )
                break
            }
            default:
                break
        }
        this.setState({ contentLv2 })
    }

    handleOpenPopupLv3 = () => {
        let { typeLv3, dataLv3 } = this.props.popupBioManagerReducer
        let contentLv3
        // contentLv3 = <PopupNotify
        //   data={{ titlePopup:"Cập nhật khuôn mặt", detailNotify:"cập nhật khuôn mặt thành công", titleBtn:"Xác nhận", isSuccess:true }}
        //   onClosePopup={this.handleClosePopupLv3}
        // />
        // this.setState({ contentLv3 })
        // return;
        switch (typeLv3) {
            case popupBioTypes.GTTTQueries: {
                contentLv3 = (
                    <GTTTQueries
                        data={dataLv3}
                        handleClose={this.handleClosePopupLv3}
                    />
                )
                break
            }
            case popupBioTypes.confirmReLoadPage: {
                contentLv3 = (
                    <PopupAccept
                        data={dataLv3}
                        onClose={() => window.location.reload()}
                    />
                )
                break
            }
            case popupBioTypes.PopupControlTablet: {
                this.setState({ haveOverlay: true })
                contentLv3 = (
                    <PopupControlTablet
                        closePopup={() => this.handleClosePopupLv3()}
                    />
                )
                break
            }
            case popupBioTypes.PopupAccect: {
                contentLv3 = (
                    <PopupAccept
                        data={dataLv3}
                        onClose={() => this.handleClosePopupLv3()}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowReasonRejectBio: {
                contentLv3 = (
                    <PopupShowReasonRejectBio
                        data={dataLv3}
                        ticketId={dataLv3.ticketId}
                        type={dataLv3.type}
                        onClosePopup={() => this.handleClosePopupLv3()}
                        onClickBtnSubmit={this.handleAcceptActionLv3}
                    />
                )
                break
            }
            case popupBioTypes.PopupNotify: {
                contentLv3 = (
                    <PopupNotify
                        data={dataLv3}
                        onClosePopup={this.handleClosePopupLv3}
                    />
                )
                break
            }
            case popupBioTypes.PopupShowErrorExistedDataBiometric: {
                contentLv3 = (
                    <PopupShowErrorExistedDataBiometric
                        typeExistedData={dataLv3.typeExistedData}
                        data={dataLv3.data}
                        closePopup={() => this.handleClosePopupLv3()}
                    />
                )
                break
            }
            case popupBioTypes.PopupHistoryFinger: {
                contentLv3 = (
                    <PopupHistoryFinger
                        data={dataLv3}
                        onClosePopup={() => this.handleClosePopupLv3()}
                    />
                )
                break
            }
            case popupBioTypes.PopupConfirm: {
                this.setState({ nonOverlay: false })
                contentLv3 = (
                    <PopupConfirm
                        data={dataLv3}
                        content={dataLv3.content && dataLv3.content}
                        width={dataLv3.width && dataLv3.width}
                        contentDelete={
                            dataLv3.contentDelete && dataLv3.contentDelete
                        }
                        fontSizeNote={dataLv3.fontSizeNote}
                        btnLeft={dataLv3.btnLeft}
                        btnRight={dataLv3.btnRight}
                        cancelAction={() => this.handleClosePopupLv3()}
                        acceptAction={() => {
                            this.handleAcceptActionLv3()
                        }}
                    />
                )
                break
            }
            case popupBioTypes.RegisterFingerIDControl: {
                contentLv3 = (
                    <RegisterFingerIDControl
                        data={dataLv3}
                        flowUpdate={dataLv3.flowUpdate}
                        onClose={() => this.handleClosePopupLv3()}
                    />
                )
                break
            }
            default:
                break
        }
        this.setState({ contentLv3 })
    }

    handleClosePopupLv1 = async () => {
        const { dataLv1 } = this.props.popupBioManagerReducer
        // debugger;
        switch (dataLv1.key) {
            case typesKeyDetailPopup.updateBioIpay: {
                const popupBiometric = popupBioTypes.PopupUserManage
                const dataGetTicket = {
                    ...dataLv1.dataTicket,
                    autoNextBiometric: true,
                }
                await this.props.closePopup(1)
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                this.props.openPopup(popupBiometric, 1, dataGetTicket)
                break
            }
            case typesKeyDetailPopup.searchCifsInFormAuthCustomer:
            case typesKeyDetailPopup.reCallTicketInFormAuthCustomer: {
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const POPUP_LEVEL_1 = 1
                this.props.openPopup(
                    popupBioTypes.CheckCustomerActions,
                    POPUP_LEVEL_1,
                    { ticketId: dataLv1.ticketId }
                )
                break
            }
            case typesKeyDetailPopup.moveTicketToOtherCounterInFormAuthCustomer: {
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const POPUP_LEVEL_1 = 1
                this.props.openPopup(
                    popupBioTypes.CheckCustomerActions,
                    POPUP_LEVEL_1,
                    { ticketId: dataLv1.TicketID }
                )
                break
            }
            case typesKeyDetailPopup.moveTicketToMissCallInFormAuthCustomer: {
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const POPUP_LEVEL_1 = 1
                this.props.openPopup(
                    popupBioTypes.CheckCustomerActions,
                    POPUP_LEVEL_1,
                    { ticketId: dataLv1.ticketID }
                )
                break
            }
            case typesKeyDetailPopup.forceRegisterBiometric: {
                await checkApplyBiometricForTicket(
                    this,
                    dataLv1.ticket.TicketID
                )
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                const POPUP_LEVEL_1 = 1
                this.props.openPopup(
                    popupBioTypes.PopupUserManage,
                    POPUP_LEVEL_1,
                    { ...dataLv1.ticket, autoNextBiometric: true }
                )
                break
            }
            case typesKeyDetailPopup.requestAuthCusBeforeCompleteTicket: {
                const POPUP_LEVEL_1 = 1
                await checkApplyBiometricForTicket(
                    this,
                    dataLv1.ticket.TicketID
                )
                const isVirtualTablet = this.props.counterReducer.dataConnectDesktop.VirtualTablet
                if(isVirtualTablet) break;
                this.props.openPopup(
                    popupBioTypes.CheckCustomerActions,
                    POPUP_LEVEL_1,
                    {
                        ticketId: dataLv1.ticket.TicketID,
                        isAuthenBeforeCompleteTicket: true,
                        isFingerHanging: false,
                    }
                )

                break
            }
            case typesKeyDetailPopup.noticeNoneHaveBioInfos:
                this.props.openPopup(popupBioTypes.PoupNotRegister, 1, {
                    key: typesKeyDetailPopup.fillReasonWhyNotRegisterBioData,
                    dataTicket: dataLv1.dataTicket,
                })
                break
            default:
                this.props.closePopup(1)
                break
        }
    }
    handleClosePopupLv2 = async () => {
        const { dataLv2 } = this.props.popupBioManagerReducer
        // debugger;
        switch (dataLv2.key) {
            case typesKeyDetailPopup.updateBioIpay: {
                // const popupBiometric = popupBioTypes.PopupUserManage;
                // const dataGetTicket = { ...dataLv1.dataTicket, autoNextBiometric: true };
                await this.props.closePopup(2)
                // this.props.openPopup(popupBiometric, 1, dataGetTicket)
                break
            }
            case typesKeyDetailPopup.updateFaceSuccess: {
                const currentTicket = this.props.counterReducer.listCurrentTicket.find(
                    (item) => item.TicketID === dataLv2.ticketId
                )
                if (!currentTicket) break
                if (
                    currentTicket?.duplicateFaces &&
                    currentTicket?.duplicateFaces?.length
                ) {
                    const POPUP_LEVEL_2 = 2
                    this.props.openPopup(
                        popupBioTypes.PopupMixResultConfirmFace,
                        POPUP_LEVEL_2,
                        { ticketId: dataLv2.ticketId }
                    )
                    break
                }
                this.props.closePopup(2)
                break
            }
            case typesKeyDetailPopup.confirmSendSupervisor: {
                this.props.closePopup(2)
                break
            }
            default:
                this.props.closePopup(2)
                break
        }
    }
    handleClosePopupLv3 = () => {
        this.props.closePopup(3)
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevProps.popupBioManagerReducer) !==
            JSON.stringify(this.props.popupBioManagerReducer)
        ) {
            this.handleOpenPopupLv1()
            this.handleOpenPopupLv2()
            this.handleOpenPopupLv3()
        }
    }

    componentDidMount() {
        // this.handleOpenPopupLv2();
        // this.handleOpenPopupLv3();
    }

    turnOnOffShowNotice = () => {
        if (this.state.isOnOffShowNotice) {
            this.setState({
                isOnOffShowNotice: false,
            })
            this.props.closePopup(1)
        }
    }

    render() {
        let {
            showPopupLv1,
            showPopupLv2,
            showPopupLv3,
        } = this.props.popupBioManagerReducer
        return (
            <React.Fragment>
                {showPopupLv1 && (
                    <BoundPopupLv1
                        nonOverlay={this.state.nonOverlay}
                        onMouseDown={() => {
                            if (
                                this.state.isOnOffShowNotice &&
                                !this.isMouseEnterPopup
                            ) {
                                this.turnOnOffShowNotice()
                            }
                        }}
                    >
                        {/* <div
              onMouseEnter={() => {
                return this.isMouseEnterPopup = true
              }}
              onMouseOver={() => {
                if (!this.isMouseEnterPopup) {
                  this.isMouseEnterPopup = true
                }
              }}
              onMouseLeave={() => {
                return this.isMouseEnterPopup = false
              }}
            > */}
                        {/* /// Show Popup /// */}
                        {this.state.contentLv1}
                        {/* </div> */}
                    </BoundPopupLv1>
                )}
                {showPopupLv2 && (
                    // true &&
                    <BoundPopupLv2>
                        {/* <div
              onMouseEnter={() => {
                return this.isMouseEnterPopup = true
              }}
              onMouseOver={() => {
                if (!this.isMouseEnterPopup)
                  this.isMouseEnterPopup = true
              }}
              onMouseLeave={() => {
                return this.isMouseEnterPopup = false
              }}
            > */}
                        {this.state.contentLv2}
                        {/* </div> */}
                    </BoundPopupLv2>
                )}
                {showPopupLv3 && (
                    // true &&
                    <BoundPopupLv3 haveOverlay={this.state.haveOverlay}>
                        {/* <div
              onMouseEnter={() => {
                return this.isMouseEnterPopup = true
              }}
              onMouseOver={() => {
                if (!this.isMouseEnterPopup)
                  this.isMouseEnterPopup = true
              }}
              onMouseLeave={() => {
                return this.isMouseEnterPopup = false
              }}
            > */}
                        {this.state.contentLv3}

                        {/* </div> */}
                    </BoundPopupLv3>
                )}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    popupBioManagerReducer: state.popupBioManagerReducer,
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})
const mapDispatchToProps = (dispatch) => ({
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    completeTicket: (counterId, ticketId, forceComplete = false) =>
        dispatch(completeTicket(counterId, ticketId, forceComplete)),
    moveToMissCall: (counterId, ticketId) =>
        dispatch(moveToMissCall(counterId, ticketId)),
    getAllTicketWaiting: (counterId, officeId) =>
        dispatch(getAllTicketWaiting(counterId, officeId)),
    removeStatusWaitingBioData: (ticketId) =>
        dispatch(removeStatusWaitingBioData(ticketId)),
    completeBio: (enrollId, ticketId, forceComplete = true) =>
        dispatch(completeBio(enrollId, ticketId, forceComplete)),
    tellerLogoutCounter: (dataLogout) =>
        dispatch(tellerLogoutCounter(dataLogout)),
    disconnectDesktop: (counterId, officeId, deviceId) =>
        dispatch(disconnectDesktop(counterId, officeId, deviceId)),
    clearError: (typeError) => dispatch(clearError(typeError)),
    callTicket: (ticketID, counterID, officeID, ticketStatus) =>
        dispatch(callTicket(ticketID, counterID, officeID, ticketStatus)),
    recallTicket: (ticketId) => dispatch(recallTicket(ticketId)),
    addCustomerInfos: (customerInfos, ticketID, isChangeTicket = false) =>
        dispatch(addCustomerInfos(customerInfos, ticketID, isChangeTicket)),
    addBiometricExistFromCustomerInfos: (bioInfos, ticketId) =>
        dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
    getInfoCustomer: (counterId, customerNumber, typeSearch = 0) =>
        dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
    addInprogressBioExistFromCustomerInfo: (inprogressBio, ticketId) =>
        dispatch(
            addInprogressBioExistFromCustomerInfo(inprogressBio, ticketId)
        ),
    getCurrentBioEnroll: (counterId, ticketId, customerNumber = undefined) =>
        dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
    requestRemoveBioData: (
        enrollId,
        bioType,
        bioId,
        ticketId,
        index = undefined
    ) =>
        dispatch(
            requestRemoveBioData(enrollId, bioType, bioId, ticketId, index)
        ),
    resetConfirmBioData: (ticketId, bioType) =>
        dispatch(resetConfirmBioData(ticketId, bioType)),
    removeDuplicateFace: (ticketId) => dispatch(removeDuplicateFace(ticketId)),
    clearCustomerInfosInStore: () => dispatch(clearCustomerInfosInStore()),
    removeDataBioOfTicketInStore: (ticketId) =>
        dispatch(removeDataBioOfTicketInStore(ticketId)),
    removeBioDataCollector: (
        enrollId,
        bioType,
        bioId,
        ticketId,
        index = undefined
    ) =>
        dispatch(
            removeBioDataCollector(enrollId, bioType, bioId, ticketId, index)
        ),
    completeBioCollector: (enrollId, forceComplete = true) =>
        dispatch(completeBioCollector(enrollId, forceComplete)),
    updateBiometricNoteCollector: (enrollId, note, noteNoteEnoughtFinger) =>
        dispatch(
            updateBiometricNoteCollector(enrollId, note, noteNoteEnoughtFinger)
        ),
    removeStatusWaitingBioDataCollector: (ticketId, justDispatch = false) =>
        dispatch(removeStatusWaitingBioDataCollector(ticketId, justDispatch)),
    getCurrentBioEnrollCollector: (counterId, enrollId) =>
        dispatch(getCurrentBioEnrollCollector(counterId, enrollId)),
    updateRemoveBio: (enrollId, bioType, note) =>
        dispatch(updateRemoveBio(enrollId, bioType, note)),
    requestUpDateBio: (ticketId, cusNumber) =>
        dispatch(requestUpDateBio(ticketId, cusNumber)),
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    updateRemoveBioCollector: (enrollId, bioType, note) =>
        dispatch(updateRemoveBioCollector(enrollId, bioType, note)),
    updateRevertRemoveBioCollector: (enrollId, bioType) =>
        dispatch(updateRevertRemoveBioCollector(enrollId, bioType)),
    finishTransInTask: (ticketId) => dispatch(finishTransInTask(ticketId)),
    startOrStopCounter: (counterId, counterState) =>
        dispatch(startOrStopCounter(counterId, counterState)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PopupContainerRedux)
