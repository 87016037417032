import React from 'react'

export default function IcExportTicket(props) {
    return (
        <svg
            {...props}
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6185 4.38146C12.4368 5.19975 13.7635 5.19975 14.5818 4.38146L16 5.79966L5.79966 16L4.38146 14.5818C5.19975 13.7635 5.19975 12.4368 4.38146 11.6185C3.56318 10.8003 2.23648 10.8003 1.4182 11.6185L0 10.2003L10.2003 0L11.6185 1.4182C10.8003 2.23648 10.8003 3.56318 11.6185 4.38146ZM8.64504 5.05831L7.97185 4.38512L7.29865 5.05831L7.97185 5.73151L8.64504 5.05831ZM9.30029 5.71356L9.97348 6.38675L9.30029 7.05995L8.62709 6.38675L9.30029 5.71356ZM11.3019 7.71519L10.6287 7.042L9.95553 7.71519L10.6287 8.38839L11.3019 7.71519Z"                
                fill={props.maincolor?props.maincolor:"#005993"}
            />
        </svg>
    )
}
