import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// image
import iconNotificationClose from '../../../../images/ic_counter_notification_close.svg'

// Data
import { CALL_TICKET_ERR } from '../../../../actions/types'
import { popupBioTypes } from '../../../../data/PopupTypes'
import typesKeyDetailPopup from '../../../../data/typesKeyDetailPopup'
import { MOVING } from '../../../../data/ticketStatuses'

// Helper
import { buildErrorContent } from '../../../../helper/tool'

//Action
import {
    getAllTicketWaiting,
    getListTypeCounterServices,
    callTicket,
    getInfoCustomer,
    addBiometricExistFromCustomerInfos,
} from '../../../../actions/counterAction'
import { getCurrentBioEnroll } from '../../../../actions/biometricAction'
import { openPopup, closePopup } from '../../../../actions/popupBioAction'
import { clearError } from '../../../../actions/commonAction'

//Component
import TickyGridItemV2 from './TickyGridItemV2'
import ErrorGetTicket76 from '../../../../helper/ErrorGetTicket76'

// helper
import autoOpenPopupWhenGetTicket from '../../../../helper/autoOpenPopupWhenGetTicket'
import isDisableEventWithCORPCust from '../../../../helper/isDisableEventWithCORPCust'
import { COORDINATOR, COUNSELOR, TELLER } from '../../../../data/userRole'
import { APPONLINE } from '../../../../constant/keys'

const Bound = styled.div`
    margin-top: 20px;
    display: inline-block;
`

class TickyContainer extends React.Component {
    state = {
        dataWaiting: [],
        dataMissing: [],
        listMoveTo: [],
        dataTicketOnline: [],
    }

    onCallTicket = async (ticketID, status) => {
        await this.props.callTicket(
            ticketID,
            this.props.counterReducer.dataConnectDesktop.CounterID,
            this.props.counterReducer.dataConnectDesktop.OfficeID,
            status
        )
        const errorCallTicket = this.props.counterReducer.err[CALL_TICKET_ERR]
        if (errorCallTicket) {
            switch (true) {
                case errorCallTicket === 12: {
                    const OPEN_POPUP_LEVEL_1 = 1

                    const dataTranferFollowPopup = {
                        image_noti: iconNotificationClose,
                        title: buildErrorContent(
                            CALL_TICKET_ERR,
                            errorCallTicket
                        ),
                        widthPopup: '254px',
                        titleConfirm: 'Xác nhận',
                    }

                    this.props.clearError(CALL_TICKET_ERR)

                    this.props.openPopup(
                        popupBioTypes.PopupAccect,
                        OPEN_POPUP_LEVEL_1,
                        dataTranferFollowPopup
                    )
                    break
                }
                case !!errorCallTicket && errorCallTicket.Code === 76: {
                    const OPEN_POPUP_LEVEL_1 = 1
                    const {
                        CustomerNumber,
                        CustomerName,
                        TicketNumber,
                        TellerName,
                        OfficeName,
                    } = errorCallTicket
                    const dataTranferFollowPopup = {
                        image_noti: iconNotificationClose,
                        title: (
                            <ErrorGetTicket76
                                CustomerNumber={CustomerNumber}
                                CustomerName={CustomerName}
                                TicketNumber={TicketNumber}
                                TellerName={TellerName}
                                OfficeName={OfficeName}
                            />
                        ),
                        widthPopup: '363px',
                        titleConfirm: 'Xác nhận',
                    }

                    this.props.clearError(CALL_TICKET_ERR)

                    this.props.openPopup(
                        popupBioTypes.PopupAccect,
                        OPEN_POPUP_LEVEL_1,
                        dataTranferFollowPopup
                    )
                    break
                }
                default: {
                    const OPEN_POPUP_LEVEL_1 = 1

                    const dataTranferFollowPopup = {
                        image_noti: iconNotificationClose,
                        title:
                            'Không thể thực hiện gọi vé vào lúc này, xin vui lòng thử lại',
                        widthPopup: '262px',
                        titleConfirm: 'Xác nhận',
                    }

                    this.props.clearError(CALL_TICKET_ERR)

                    this.props.openPopup(
                        popupBioTypes.PopupAccect,
                        OPEN_POPUP_LEVEL_1,
                        dataTranferFollowPopup
                    )
                    break
                }
            }
            return
        }

        if (
            !isDisableEventWithCORPCust(
                this.props.counterReducer.dataGetTicket.TicketData.TicketID,
                this.props.counterReducer
            )
        )
            autoOpenPopupWhenGetTicket(
                this,
                this.props.counterReducer.dataGetTicket.TicketData.TicketID
            )
    }

    onCallMissTicket = (ticketID, status) => {
        if (this.props.counterReducer.listCurrentTicket.length === 2) {
            this.onCallTicket(ticketID, status)
            return
        }

        const OPEN_POPUP_LEVEL_1 = 1
        const dataTranferFollowPopup = {
            key: typesKeyDetailPopup.acceptCallMissTiket,
            content: 'Gọi vé từ danh sách gọi nhỡ vào quầy giao dịch?',
            paddingPopup: '20px 47px 15px 47px',
            ticketID,
            status,
        }

        this.props.openPopup(
            popupBioTypes.PopupConfirm,
            OPEN_POPUP_LEVEL_1,
            dataTranferFollowPopup
        )
    }

    render() {
        let {
            dataWaiting,
            dataMissing,
            listMoveTo,
            dataTicketOnline,
        } = this.state
        let { isTypeUser } = this.props
        const {
            waitingTicket,
            listVipTicket,
            dataLogin,
            listAppointmentOnline,
        } = this.props.counterReducer

        if (listMoveTo.length > 0) {
            listMoveTo.forEach((item) => {
                item.moveTo = true
            })
        }
        // VIP counter
        if (dataLogin.IsVIP) {
            dataWaiting = [...waitingTicket, ...listMoveTo]
        } else {
            dataWaiting = [...listVipTicket, ...listMoveTo, ...waitingTicket]
        }
        dataWaiting = dataWaiting.map((item) =>
            item.Status === MOVING ? { ...item, moveTo: true } : item
        )
        dataMissing = dataMissing.map((item) =>
            item.Status === MOVING ? { ...item, moveTo: true } : item
        )
        if (listAppointmentOnline?.length > 0) {
            dataTicketOnline = listAppointmentOnline.map((item) => {
                return {
                    From: APPONLINE,
                    TicketID: item.AppoinmentID,
                    AtOfficeID: item.OfficeID,
                    Number: item.AppointmentCode,
                    AdviseStatus: item.AdviseStatus,
                    TakeTicketStatus: item.TakeTicketStatus,
                    AppointmenAt: item.AppointmenAt,
                    ListService: item.ListService,
                    EmployeeName: item.EmployeeName,
                    CustomerInfos: {
                        CustType: '',
                        CustomerNumber: '',
                        Email: item.Email,
                        FaceID: '',
                        FullNameVi: item.Fullname,
                        Gender: '',
                        IdIssueDate: '',
                        IdIssuePlace: '',
                        IdNumber: item.Nid,
                        MobileNumber: '',
                        Name: item.Fullname,
                        PriorityCustomer: '',
                        ReasonNR: '',
                        ReasonNRFinger: '',
                        RefuseGetBio: true,
                        Resident: '',
                        Segment: '',
                        SegmentDesc: item.SegmentDesc,
                    },
                }
            })
        }
        return (
            <Bound>
                {dataWaiting?.length > 0 && (
                    <TickyGridItemV2
                        dataWaitingTicket={dataWaiting}
                        counterReducer={this.props.counterReducer}
                        showCheck={true}
                        isChecked={true}
                        type="WAITING_QUEUE"
                        name="SỐ VÉ HÀNG ĐỢI"
                        height={dataWaiting.length * 44}
                        width={
                            dataWaiting?.length > 5
                                ? 'calc(100% + 5px)'
                                : 'unset'
                        }
                        onCallTicket={this.onCallTicket}
                        isTypeUser={isTypeUser}
                    />
                )}
                {dataMissing?.length > 0 && (
                    <TickyGridItemV2
                        dataWaitingTicket={dataMissing}
                        counterReducer={this.props.counterReducer}
                        type="MISSCALL_QUEUE"
                        name="SỐ VÉ GỌI NHỠ"
                        height={dataMissing.length * 44}
                        width={
                            dataMissing?.length > 5
                                ? 'calc(100% + 5px)'
                                : 'unset'
                        }
                        onCallTicket={this.onCallMissTicket}
                        isTypeUser={isTypeUser}
                    />
                )}
                {dataTicketOnline?.length > 0 && (
                    <TickyGridItemV2
                        dataWaitingTicket={dataTicketOnline}
                        counterReducer={this.props.counterReducer}
                        type="WAITING_QUEUE"
                        name="MÃ ĐẶT VÉ ONLINE"
                        height={dataTicketOnline.length * 44}
                        width={
                            dataTicketOnline?.length > 5
                                ? 'calc(100% + 5px)'
                                : 'unset'
                        }
                        onCallTicket={this.onCallMissTicket}
                        isTypeUser={isTypeUser}
                        isTicketOnline={true}
                    />
                )}
            </Bound>
        )
    }
    componentDidMount() {
        this.props.getAllTicketWaiting(
            this.props.counterReducer.dataConnectDesktop.CounterID,
            this.props.counterReducer.dataConnectDesktop.OfficeID
        )
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.counterReducer !== nextProps.counterReducer)
            this.setState({
                dataWaiting: nextProps.counterReducer.waitingTicket,
                dataMissing: nextProps.counterReducer.listMissCalled,
                listMoveTo: nextProps.counterReducer.listMoveTo,
            })
    }
}
const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => {
    return {
        getAllTicketWaiting: (counterId, officeId) =>
            dispatch(getAllTicketWaiting(counterId, officeId)),
        getListTypeCounterServices: (userId) =>
            dispatch(getListTypeCounterServices(userId)),
        callTicket: (ticketID, counterID, officeID, ticketStatus) =>
            dispatch(callTicket(ticketID, counterID, officeID, ticketStatus)),
        openPopup: (typePopup, levelPopup, data) =>
            dispatch(openPopup(typePopup, levelPopup, data)),
        closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
        clearError: (typeErr) => dispatch(clearError(typeErr)),
        getInfoCustomer: (counterId, customerNumber, typeSearch = 0) =>
            dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
        addBiometricExistFromCustomerInfos: (bioInfos, ticketId) =>
            dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
        getCurrentBioEnroll: (
            counterId,
            ticketId,
            customerNumber = undefined
        ) => dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(TickyContainer)
