// types V2 30/01/2024
export const GET_TIME_WAIT_CONFIG = 'GET_TIME_WAIT_CONFIG'
export const GET_TIME_WAIT_CONFIG_ERR = 'GET_TIME_WAIT_CONFIG_ERR'

export const CANCEL_POPUP_COUNSELOR = 'CANCEL_POPUP_COUNSELOR'

export const SET_INFO_GET_APPOINTMENT = 'SET_INFO_GET_APPOINTMENT'

export const GET_PROCESSING_ADVISE = 'GET_PROCESSING_ADVISE'
export const GET_PROCESSING_ADVISE_ERR = 'GET_PROCESSING_ADVISE_ERR'

export const SET_IS_SHOW_POPUP_COUNSELOR = 'SET_IS_SHOW_POPUP_COUNSELOR'
export const SET_IS_SHOW_POPUP_REQUIRE_COMPLETE_ADVISE = 'SET_IS_SHOW_POPUP_REQUIRE_COMPLETE_ADVISE'

export const GET_TICKET_PROCESSING = 'GET_TICKET_PROCESSING'

export const START_TAKE_TICKET = 'START_TAKE_TICKET'
export const START_TAKE_TICKET_ERR = 'START_TAKE_TICKET_ERR'

export const CANCEL_TAKE_TICKET = 'CANCEL_TAKE_TICKET'
export const CANCEL_TAKE_TICKET_ERR = 'CANCEL_TAKE_TICKET_ERR'

export const START_ADVISE = 'START_ADVISE'
export const START_ADVISE_ERR = 'START_ADVISE_ERR'

export const END_ADVISE = 'END_ADVISE'
export const END_ADVISE_ERR = 'END_ADVISE_ERR'

export const GET_CURRENT_ADVISE = 'GET_CURRENT_ADVISE'
export const GET_CURRENT_ADVISE_ERR = 'GET_CURRENT_ADVISE_ERR'

export const CANCEL_APPOINMENT_ONLINE = 'CANCEL_APPOINMENT_ONLINE'
export const CANCEL_APPOINMENT_ONLINE_ERR = 'CANCEL_APPOINMENT_ONLINE_ERR'

export const CLEAR_DATA_COUNSELOR = 'CLEAR_DATA_COUNSELOR'

export const SET_DATA_TICKET_COUNSELOR = 'SET_DATA_TICKET_COUNSELOR'
export const CLEAR_DATA_GET_TICKET = 'CLEAR_DATA_GET_TICKET'

export const GET_URL_ICON = 'GET_URL_ICON'
export const GET_URL_ICON_ERR = 'GET_URL_ICON_ERR'

export const GET_TICKET_QUEUE_NUMBER = 'GET_TICKET_QUEUE_NUMBER'
export const GET_TICKET_QUEUE_NUMBER_ERR = 'GET_TICKET_QUEUE_NUMBER_ERR'

export const GET_LIST_ADVISE_SERVICE = 'GET_LIST_ADVISE_SERVICE'
export const GET_LIST_ADVISE_SERVICE_ERR = 'GET_LIST_ADVISE_SERVICE_ERR'

export const GET_INFO_APPOINTMENT_ONLINE = 'GET_INFO_APPOINTMENT_ONLINE'
export const GET_INFO_APPOINTMENT_ONLINE_ERR = 'GET_INFO_APPOINTMENT_ONLINE_ERR'

export const RESET_GET_NUMBER = 'RESET_GET_NUMBER'
export const SET_STEP_SELECT = 'SET_STEP_SELECT'
export const SET_LIST_KEY_SELECT = 'SET_LIST_KEY_SELECT'
export const RESET_LIST_KEY_SELECT = 'RESET_LIST_KEY_SELECT'
export const RESET_BOOK_APPOINTMENT = 'RESET_BOOK_APPOINTMENT'
export const SET_STEP_SELECT_BOOKING = 'SET_STEP_SELECT_BOOKING'
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO'
export const SET_LIST_TRANSACTION = 'SET_LIST_TRANSACTION'
export const SET_LIST_TRANSACTION_GET_NUMBER = 'SET_LIST_TRANSACTION_GET_NUMBER'
export const SET_PERSONAL_INFO_GET_NUMBER = 'SET_PERSONAL_INFO_GET_NUMBER'
export const SET_CHECKBOX_ACC_NUMBER = 'SET_CHECKBOX_ACC_NUMBER'
//counselor
export const SET_SHOW_COUNSELOR_PAGE = 'SET_SHOW_COUNSELOR_PAGE'
export const SET_LIST_COUNSELOR_SUCCESS = 'SET_LIST_COUNSELOR_SUCCESS'

//////////////////////////////////////////////////////////
export const CONNECTTING_SOCKET = "CONNECTTING_SOCKET";
export const FAILED_TO_CONNECT_SOCKET = "FAILED_TO_CONNECT_SOCKET";
export const SUCCESS_TO_CONNECT_SOCKET = "SUCCESS_TO_CONNECT_SOCKET";
export const SET_STATUS_DEVICE = "SET_STATUS_DEVICE";
export const GET_CLIENT_NAME = "GET_CLIENT_NAME";
export const CLEAR_ERR = "CLEAR_ERR";
export const RUN_LOADING = "RUN_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";
export const ALERT_LOGOUT = "ALERT_LOGOUT";
export const ADD_VALIDATE_INFO = "ADD_VALIDATE_INFO";
export const REMOVE_BIO_DATA_OF_TICKET_IN_STORE = "REMOVE_BIO_DATA_OF_TICKET_IN_STORE";

export const GET_COUNTER_DETAILS_ERR = "GET_COUNTER_DETAILS_ERR";

export const GET_LIST_HISTORY_CUST = "GET_LIST_HISTORY_CUST";
export const GET_LIST_HISTORY_CUST_ERR = "GET_LIST_HISTORY_CUST_ERR";
export const CLEAR_LIST_HISTORY_CUST = "CLEAR_LIST_HISTORY_CUST";

export const CLEAR_CUST_INFOS_IN_STORE = "CLEAR_CUST_INFOS_IN_STORE"
export const ERROR_CONNECTION = "ERROR_CONNECTION";

export const COUNTER_LOGIN = "COUNTER_LOGIN";
export const COUNTER_LOGIN_ERR = "COUNTER_LOGIN_ERR";

export const FIND_DUPL_FACE = "FIND_DUPL_FACE";
export const FIND_DUPL_FACE_ERR = "FIND_DUPL_FACE_ERR";

export const COUNTER_LOGOUT = "COUNTER_LOGOUT";
export const COUNTER_LOGOUT_ERR = "COUNTER_LOGOUT_ERR";

export const CONNECT_DESKTOP = "CONNECT_DESKTOP";
export const CONNECT_DESKTOP_ERR = "CONNECT_DESKTOP_ERR";

export const DISCONNECT_DESKTOP = "DISCONNECT_DESKTOP";
export const DISCONNECT_DESKTOP_ERR = "DISCONNECT_DESKTOP_ERR";

export const GET_LIST_TYPE_COUNTER_SERVICES = "GET_LIST_TYPE_COUNTER_SERVICES";
export const GET_LIST_TYPE_COUNTER_SERVICES_ERR = "GET_LIST_TYPE_COUNTER_SERVICES_ERR";

export const GET_CUSTOMER_INFORMATION = "GET_CUSTOMER_INFORMATION";
export const GET_CUSTOMER_INFORMATION_ERR = "GET_CUSTOMER_INFORMATION_ERR";

export const GET_TICKET = "GET_TICKET";
export const GET_TICKET_ERR = "GET_TICKET_ERR";

export const COMPLETE_TICKET = "COMPLETE_TICKET";
export const COMPLETE_TICKET_ERR = "COMPLETE_TICKET_ERR";

export const COMPLETE_FAKE_TICKET = "COMPLETE_FAKE_TICKET";
export const COMPLETE_FAKE_TICKET_ERR = "COMPLETE_FAKE_TICKET_ERR";

export const GET_ALL_TICKET_WAITING = "GET_ALL_TICKET_WAITING";
export const GET_ALL_TICKET_WAITING_ERR = "GET_ALL_TICKET_WAITING_ERR";

export const REQUEST_ENROLL = "REQUEST_ENROLL";
export const REQUEST_ENROLL_ERR = "REQUEST_ENROLL_ERR";

export const GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO = "GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO";
export const GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR = "GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR";

export const REQUEST_UPDATE_BIO = "REQUEST_UPDATE_BIO";
export const REQUEST_UPDATE_BIO_ERR = "REQUEST_UPDATE_BIO_ERR";

export const REMOVE_BIO_IN_UPDATE_THREAD = "REMOVE_BIO_IN_UPDATE_THREAD";
export const REMOVE_BIO_IN_UPDATE_THREAD_ERR = "REMOVE_BIO_IN_UPDATE_THREAD_ERR";

export const REVERT_REMOVE_BIO_IN_UPDATE_THREAD = "REVERT_REMOVE_BIO_IN_UPDATE_THREAD";
export const REVERT_REMOVE_BIO_IN_UPDATE_THREAD_ERR = "REVERT_REMOVE_BIO_IN_UPDATE_THREAD_ERR";

export const REQUEST_GET_BIO_DATA = "REQUEST_GET_BIO_DATA";
export const REQUEST_GET_BIO_DATA_ERR = "REQUEST_GET_BIO_DATA_ERR";

export const REQUEST_REMOVE_BIO_DATA = "REQUEST_REMOVE_BIO_DATA";
export const REQUEST_REMOVE_BIO_DATA_ERR = "REQUEST_REMOVE_BIO_DATA_ERR";

export const REQUEST_CONFIRM_BIO_DATA = "REQUEST_CONFIRM_BIO_DATA";
export const REQUEST_CONFIRM_BIO_DATA_ERR = "REQUEST_CONFIRM_BIO_DATA_ERR";

export const REMOVE_STATUS_WAITING_BIO = "REMOVE_STATUS_WAITING_BIO";
export const REMOVE_STATUS_WAITING_BIO_ERR = "REMOVE_STATUS_WAITING_BIO_ERR";

export const GET_CURRENT_TICKETS = "GET_CURRENT_TICKETS";
export const GET_CURRENT_TICKETS_ERR = "GET_CURRENT_TICKETS_ERR";

export const PAUSE_TICKET = "PAUSE_TICKET";
export const PAUSE_TICKET_ERR = "PAUSE_TICKET_ERR";

export const UNPAUSE_TICKET = "UNPAUSE_TICKET";
export const UNPAUSE_TICKET_ERR = "UNPAUSE_TICKET_ERR";

export const COMPLETE_BIO = "COMPLETE_BIO";
export const COMPLETE_BIO_ERR = "COMPLETE_BIO_ERR";

export const GET_CURRENT_BIO_ENROLL = "GET_CURRENT_BIO_ENROLL"
export const GET_CURRENT_BIO_ENROLL_ERR = "GET_CURRENT_BIO_ENROLL_ERR"

export const REMOVE_DUPLICATE_FACE = "REMOVE_DUPLICATE_FACE";

export const CHANGE_STATE_COUNTER = "CHANGE_STATE_COUNTER";
export const CHANGE_STATE_COUNTER_ERR = "CHANGE_STATE_COUNTER_ERR";

export const ADD_NEW_NOTIFY = "ADD_NEW_NOTIFY";
export const SET_STATUS_SEEN = "SET_STATUS_SEEN";

export const ADD_CUST_INFO_TO_LIST_CURRENT_TICKET = "ADD_CUST_INFO_TO_LIST_CURRENT_TICKET";
export const ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR = "ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR"

export const ADD_BIOMETRIC_EXIST = "ADD_BIOMETRIC_EXIST"

export const ADD_INPROGRESS_BIO_EXIST = "ADD_INPROGRESS_BIO_EXIST";

export const MOVE_TICKET_TO_MISS_CALL = "MOVE_TICKET_TO_MISS_CALL";
export const MOVE_TICKET_TO_MISS_CALL_ERR = "MOVE_TICKET_TO_MISS_CALL_ERR";

export const MOVE_ADVISE_TICKET_TO_MISS_CALL = "MOVE_ADVISE_TICKET_TO_MISS_CALL";
export const MOVE_ADVISE_TICKET_TO_MISS_CALL_ERR = "MOVE_ADVISE_TICKET_TO_MISS_CALL_ERR";

export const GET_OTHER_COUNTER_INFOS = "GET_OTHER_COUNTER_INFOS";
export const GET_OTHER_COUNTER_INFOS_ERR = "GET_OTHER_COUNTER_INFOS_ERR";

export const MOVE_TICKET_TO_OTHER_COUNTER = "MOVE_TICKET_TO_OTHER_COUNTER";
export const MOVE_TICKET_TO_OTHER_COUNTER_ERR = "MOVE_TICKET_TO_OTHER_COUNTER_ERR";

export const RESET_ITEM_BIO_DATA_CONFIRM = "RESET_ITEM_BIO_DATA_CONFIRM";

export const RECALL_TICKET_ERR = "RECALL_TICKET_ERR";

export const CALL_TICKET = "CALL_TICKET";
export const CALL_TICKET_ERR = "CALL_TICKET_ERR";

export const CREATE_NEW_TICKET = "CREATE_NEW_TICKET";
export const CREATE_NEW_TICKET_ERR = "CREATE_NEW_TICKET_ERR";

export const UPDATE_BIO_INFO = "UPDATE_BIO_INFO";
export const UPDATE_BIO_INFO_ERR = "UPDATE_BIO_INFO_ERR";
export const REASON_NOT_REGISTER = "REASON_NOT_REGISTER";
export const REASON_NOT_REGISTER_ERR = "REASON_NOT_REGISTER_ERR";
export const REASON_NOT_REGISTER_FINGER_BUT_FACE = "REASON_NOT_REGISTER_FINGER_BUT_FACE";
export const REASON_NOT_REGISTER_FINGER_BUT_FACE_ERR = "REASON_NOT_REGISTER_FINGER_BUT_FACE_ERR";

export const EDIT_FINGER_INFO_IN_REGISTER_THREAD = "EDIT_FINGER_INFO_IN_REGISTER_THREAD";
export const EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR = "EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR";

export const EDIT_FINGER_INFO_IN_UPDATE_THREAD = "EDIT_FINGER_INFO_IN_UPDATE_THREAD";
export const EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR = "EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR";

export const REQUEST_AUTHENTICATE_CLIENT = "REQUEST_AUTHENTICATE_CLIENT";
export const SOCKET_RECEIVE_RESULT_AUTHENTICATE = "SOCKET_RECEIVE_RESULT_AUTHENTICATE";

export const COUNT_FINGER_TRANS = "COUNT_FINGER_TRANS";
export const RESET_COUNT_FINGER_TRANS = "RESET_COUNT_FINGER_TRANS";
/////// Bio Collector

export const REQUEST_ENROLL_COLLECTOR = "REQUEST_ENROLL_COLLECTOR";
export const REQUEST_ENROLL_COLLECTOR_ERR = "REQUEST_ENROLL_COLLECTOR_ERR";

export const GET_CUSTOMER_INFORMATION_COLLECTOR = "GET_CUSTOMER_INFORMATION_COLLECTOR";
export const GET_CUSTOMER_INFORMATION_COLLECTOR_ERR = "GET_CUSTOMER_INFORMATION_COLLECTOR_ERR";

export const REQUEST_GET_BIO_DATA_COLLECTOR = "REQUEST_GET_BIO_DATA_COLLECTOR";
export const REQUEST_GET_BIO_DATA_COLLECTOR_ERR = "REQUEST_GET_BIO_DATA_COLLECTOR_ERR";

export const REQUEST_REMOVE_BIO_COLLECTOR = "REQUEST_REMOVE_BIO_COLLECTOR";
export const REQUEST_REMOVE_BIO_COLLECTOR_ERR = "REQUEST_REMOVE_BIO_COLLECTOR_ERR";

export const REQUEST_CONFIRM_BIO_DATA_COLLECTOR = "REQUEST_CONFIRM_BIO_DATA_COLLECTOR";
export const REQUEST_CONFIRM_BIO_DATA_COLLECTOR_ERR = "REQUEST_CONFIRM_BIO_DATA_COLLECTOR_ERR";

export const UPDATE_BIO_INFO_COLLECTOR = "UPDATE_BIO_INFO_COLLECTOR";
export const UPDATE_BIO_INFO_COLLECTOR_ERR = "UPDATE_BIO_INFO_COLLECTOR_ERR";

export const COMPLETE_BIO_COLLECTOR = "COMPLETE_BIO_COLLECTOR";
export const COMPLETE_BIO_COLLECTOR_ERR = "COMPLETE_BIO_COLLECTOR_ERR";

export const GET_CURRENT_BIO_ENROLL_COLLECTOR = "GET_CURRENT_BIO_ENROLL_COLLECTOR";
export const GET_CURRENT_BIO_ENROLL_COLLECTOR_ERR = "GET_CURRENT_BIO_ENROLL_COLLECTOR_ERR";

export const EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR = "EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR";
export const EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR = "EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR"

export const VERIFY_FINGER = "VERIFY_FINGER"
export const VERIFY_FINGER_ERR = "VERIFY_FINGER_ERR"
export const SOCKET_VERIFY_FINGER_SEEN = "SOCKET_VERIFY_FINGER_SEEN"

export const REMOVE_STATUS_WAITING_VERIFY_FINGER = "REMOVE_STATUS_WAITING_VERIFY_FINGER"
export const REMOVE_STATUS_WAITING_VERIFY_FINGER_ERR = "REMOVE_STATUS_WAITING_VERIFY_FINGER_ERR"
export const VERIFY_CUSTOMER_RESULT = "VERIFY_CUSTOMER_RESULT"

export const REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR = "REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR";
export const REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR_ERR = "REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR_ERR";

export const CONTINUE_GET_BIO = "CONTINUE_GET_BIO";
export const CONTINUE_GET_BIO_ERR = "CONTINUE_GET_BIO_ERR";

export const SET_DUPLICATE_BIO_FACE = "SET_DUPLICATE_BIO_FACE";
export const UPDATE_BIOMETRIC_DUPLICATE_NOTE = "UPDATE_BIOMETRIC_DUPLICATE_NOTE";
export const UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR = "UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR";

export const FINISH_WAITING_EVALUEATE = "FINISH_WAITING_EVALUEATE"
export const UPDATE_TICKET_INFOS = "UPDATE_TICKET_INFOS"

export const CONFIRM_USER_PRINTED = "CONFIRM_USER_PRINTED";
export const CLEAR_STATUS_PRINTED = "CLEAR_STATUS_PRINTED";

////// Popup Container Redux
export const OPEN_POPUPLV1 = "OPEN_POPUPLV1"
export const OPEN_POPUPLV2 = "OPEN_POPUPLV2"
export const OPEN_POPUPLV3 = "OPEN_POPUPLV3"

export const CLOSE_POPUPLV1 = "CLOSE_POPUPLV1"
export const CLOSE_POPUPLV2 = "CLOSE_POPUPLV2"
export const CLOSE_POPUPLV3 = "CLOSE_POPUPLV3"

export const RESET_POPUP = "RESET_POPUP";

export const REASON_REFUSE_REG_FINGER = "REASON_REFUSE_REG_FINGER"

// socket
export const SOCKET_ENQUEUE_TICKET = "SOCKET_ENQUEUE_TICKET";
export const SOCKET_DEQUEUE_TICKET = "SOCKET_DEQUEUE_TICKET";
export const SOCKET_RECEIVE_TICKET_FROM_OTHER_COUNTER = "SOCKET_RECEIVE_TICKET_FROM_OTHER_COUNTER";
export const SOCKET_SET_STATUS_BIOMETRIC_WITH_TICKET = "SOCKET_SET_STATUS_BIOMETRIC_WITH_TICKET";

export const SOCKET_REGISTER_FACE = "SOCKET_REGISTER_FACE";
export const SOCKET_CONFIRM_FACE = "SOCKET_CONFIRM_FACE";
export const SOCKET_REMOVE_FACE = "SOCKET_REMOVE_FACE";

export const SOCKET_REGISTER_THUMB_LEFT = "SOCKET_REGISTER_THUMB_LEFT";
export const SOCKET_CONFIRM_THUMB_LEFT = "SOCKET_CONFIRM_THUMB_LEFT";
export const SOCKET_REMOVE_THUMB_LEFT = "SOCKET_REMOVE_THUMB_LEFT";


export const SOCKET_REGISTER_INDEX_LEFT = "SOCKET_REGISTER_INDEX_LEFT";
export const SOCKET_CONFIRM_INDEX_LEFT = "SOCKET_CONFIRM_INDEX_LEFT";
export const SOCKET_REMOVE_INDEX_LEFT = "SOCKET_REMOVE_INDEX_LEFT";

export const SOCKET_REGISTER_MIDDLE_LEFT = "SOCKET_REGISTER_MIDDLE_LEFT";
export const SOCKET_CONFIRM_MIDDLE_LEFT = "SOCKET_CONFIRM_MIDDLE_LEFT";
export const SOCKET_REMOVE_MIDDLE_LEFT = "SOCKET_REMOVE_MIDDLE_LEFT";

export const SOCKET_REGISTER_RING_LEFT = "SOCKET_REGISTER_RING_LEFT";
export const SOCKET_CONFIRM_RING_LEFT = "SOCKET_CONFIRM_RING_LEFT";
export const SOCKET_REMOVE_RING_LEFT = "SOCKET_REMOVE_RING_LEFT";

export const SOCKET_REGISTER_PINKY_LEFT = "SOCKET_REGISTER_PINKY_LEFT";
export const SOCKET_CONFIRM_PINKY_LEFT = "SOCKET_CONFIRM_PINKY_LEFT";
export const SOCKET_REMOVE_PINKY_LEFT = "SOCKET_REMOVE_PINKY_LEFT";

export const SOCKET_REGISTER_THUMB_RIGHT = "SOCKET_REGISTER_THUMB_RIGHT";
export const SOCKET_CONFIRM_THUMB_RIGHT = "SOCKET_CONFIRM_THUMB_RIGHT";
export const SOCKET_REMOVE_THUMB_RIGHT = "SOCKET_REMOVE_THUMB_RIGHT";

export const SOCKET_REGISTER_INDEX_RIGHT = "SOCKET_REGISTER_INDEX_RIGHT";
export const SOCKET_CONFIRM_INDEX_RIGHT = "SOCKET_CONFIRM_INDEX_RIGHT";
export const SOCKET_REMOVE_INDEX_RIGHT = "SOCKET_REMOVE_INDEX_RIGHT";

export const SOCKET_REGISTER_MIDDLE_RIGHT = "SOCKET_REGISTER_MIDDLE_RIGHT";
export const SOCKET_CONFIRM_MIDDLE_RIGHT = "SOCKET_CONFIRM_MIDDLE_RIGHT";
export const SOCKET_REMOVE_MIDDLE_RIGHT = "SOCKET_REMOVE_MIDDLE_RIGHT";

export const SOCKET_REGISTER_RING_RIGHT = "SOCKET_REGISTER_RING_RIGHT";
export const SOCKET_CONFIRM_RING_RIGHT = "SOCKET_CONFIRM_RING_RIGHT";
export const SOCKET_REMOVE_RING_RIGHT = "SOCKET_REMOVE_RING_RIGHT";

export const SOCKET_REGISTER_PINKY_RIGHT = "SOCKET_REGISTER_PINKY_RIGHT";
export const SOCKET_CONFIRM_PINKY_RIGHT = "SOCKET_CONFIRM_PINKY_RIGHT";
export const SOCKET_REMOVE_PINKY_RIGHT = "SOCKET_REMOVE_PINKY_RIGHT";

export const DISPATCH_USER_FROM_TABLET = 'DISPATCH_USER_FROM_TABLET'
export const CLEAR_DATA_USER_FROM_TABLET = 'CLEAR_DATA_USER_FROM_TABLET'

export const GET_USER_BY_FINGER_IMG = 'GET_USER_BY_FINGER_IMG'

export const SET_LOADING_GET_USER_BY_FACE = 'SET_LOADING_GET_USER_BY_FACE'
export const SET_LOADING_GET_USER_BY_FINGER = 'SET_LOADING_GET_USER_BY_FINGER'
