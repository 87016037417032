import Axios from 'axios'

// data
import * as types from './types'
import { host } from '../host'


export const resetBookAppointment = () => ({
    type: types.RESET_BOOK_APPOINTMENT,
})

export const setStepSelectBooking = (step) => ({
    type: types.SET_STEP_SELECT_BOOKING,
    step,
})

export const setPersonalInfo = (details) => ({
    type: types.SET_PERSONAL_INFO,
    details,
})

export const setListTransaction = (listTrans) => ({
    type: types.SET_LIST_TRANSACTION,
    listTrans
})