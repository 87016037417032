import React, { useState } from 'react'
import { useEffect } from 'react'
import {
    WrapContentCounselor,
    WrapContentPage,
    WrapFooterPage,
} from './index.styles'

import { useDispatch, useSelector } from 'react-redux'

//imgs
import IcUserMini from '../../../images/SVG/IcUserMini'
import IcClosePopup from '../../../images/SVG/IcClosePopup'
import IcCheckWhite from '../../../images/SVG/IcCheckWhite'
import IcBank from '../../../images/SVG/IcBank'
import IcPopupTicketCode from '../../../images/SVG/IcPopupTicketCode'
import IcSelected from '../../../images/SVG/IcSelected'
import IcNoSelect from '../../../images/SVG/IcNoSelect'

//control
import ButtonControl from '../../../controls/ButtonControl'
import CountDownTimeControl from '../../../../Control/CountDownTimeControl'
import Portal from '../../../controls/Portal'
import PopupEndCounselor from '../PopupEndCounselor'

//redux
import {
    clearDataCounselor,
    setIsShowPopupRequireCompleteAdvise,
    setListCounselorSuccess,
    setShowContentCounselorPage,
} from '../../../../../actions/counselorAction'
import { adviseStatus } from '../../../../../constant/keys'
import GetTimeSuccess from '../../../controls/GetTimeSuccess'
import PopupRequireCompleteAdvise from '../../PopupConsultantTeller/PopupRequireCompleteAdvise'


export default function ContentCounselor() {
    const dispatch = useDispatch()
    const {
        listServiceCounselor,
        dataTicketCounselor,
        dataConnectDesktop,
        detailsCurrentAdvise,
        detailsProcessingAdvise,
        isShowPopupRequireCompleteAdvise
    } = useSelector((state) => ({
        listServiceCounselor: state.counterReducer.listServiceCounselor,
        dataTicketCounselor: state.counselor.dataTicketCounselor,
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        detailsCurrentAdvise: state.counselor.detailsCurrentAdvise,
        detailsProcessingAdvise: state.counselor.detailsProcessingAdvise,
        isShowPopupRequireCompleteAdvise: state.counselor.isShowPopupRequireCompleteAdvise
    }))
    const [serviceSelect, setServiceSelect] = useState([
        listServiceCounselor[0],
    ])
    const [textResult, setTextResult] = useState('')
    const [isShowPopupEnd, setIsShowPopupEnd] = useState(false)
    const [isEndSuccess, setIsEndSuccess] = useState(false)
    const [errTextResult, setErrTextResult] = useState('')
    const [isShowPopupRequire, setIsShowPopupRequire] = useState(false)

    useEffect(() => {
        if (!isShowPopupRequireCompleteAdvise) return
        setIsShowPopupRequire(isShowPopupRequireCompleteAdvise)
    }, [isShowPopupRequireCompleteAdvise])

    useEffect(() => {
        if (textResult?.length >= 255) {
            setErrTextResult('Độ dài từ 1 đến 255 ký tự')
        }
    }, [textResult])

    const handleEnd = () => {
        if (!textResult.length) {
            setErrTextResult('Vui lòng nhập kết quả bán')
            return
        }
        setIsShowPopupEnd(true)
    }

    const handleSelectService = (obj) => {
        let newList = []
        if (
            serviceSelect?.filter((item) => item.AdviseType === obj?.AdviseType)
                ?.length > 0
        ) {
            newList = serviceSelect?.filter(
                (item) => item.AdviseType !== obj?.AdviseType
            )
        } else {
            newList = [...serviceSelect, obj]
        }
        setServiceSelect(newList)
    }

    const handleNext = () => {
        // dispatch(setListCounselorSuccess(dataTicketCounselor?.Number))
        // dispatch(setShowContentCounselorPage(false))
        dispatch(clearDataCounselor())
    }
    // console.log(dataTicketCounselor)
    useEffect(() => {
        if (!detailsCurrentAdvise?.Number) return
        setTextResult(detailsCurrentAdvise?.Result)
        setServiceSelect(detailsCurrentAdvise?.ListService)
        setIsEndSuccess(true)
    }, [detailsCurrentAdvise])

    const handleClosePopupRequire = () => {
        setIsShowPopupRequire(false)
        dispatch(setIsShowPopupRequireCompleteAdvise(false))
    }

    return (
        <WrapContentCounselor>
            {(detailsCurrentAdvise?.Number ||
                isEndSuccess ||
                !listServiceCounselor?.length) && (
                    <IcClosePopup className="ic-close" onClick={handleNext} />
                )}
            <div className="title-page">
                <div className="bold">Tư vấn khách hàng</div>
                <div className="normal">
                    <IcPopupTicketCode />
                    Mã vé:{' '}
                    <span>
                        {dataTicketCounselor?.Number ||
                            detailsCurrentAdvise?.Number ||
                            detailsProcessingAdvise?.Number}
                    </span>
                </div>
            </div>
            <WrapContentPage
                isSuccess={detailsCurrentAdvise?.Number || isEndSuccess}
            >
                <div className="wrap-container">
                    <div className="wrap-list-info">
                        <div className="title-content">Thời gian tư vấn</div>
                        <div className="wrap-countdown">
                            {detailsCurrentAdvise?.StatusAdvise ===
                                adviseStatus.COMPLETED ? (
                                <GetTimeSuccess
                                    startTime={detailsCurrentAdvise?.StartAt}
                                    endTime={detailsCurrentAdvise?.CompleteAt}
                                    isSuccess={true}
                                    classname="countdown"
                                />
                            ) : (
                                <CountDownTimeControl
                                    class="countdown"
                                    isCounselor={true}
                                    isEndSuccess={isEndSuccess}
                                    data={
                                        detailsProcessingAdvise?.StartAt
                                            ? detailsProcessingAdvise?.StartAt
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="wrap-list-info first">
                        <div className="title-content">
                            Thông tin khách hàng
                        </div>
                        <div className="list-info">
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcUserMini />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">Mã vé</div>
                                    <div className="value-text">
                                        {dataTicketCounselor?.Number ||
                                            detailsCurrentAdvise?.Number ||
                                            detailsProcessingAdvise?.Number}
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcUserMini />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        Tên khách hàng
                                    </div>
                                    <div className="value-text">
                                        {dataTicketCounselor?.Number
                                            ? dataTicketCounselor?.CustomerInfos
                                                ?.FullNameVi
                                                ? dataTicketCounselor
                                                    ?.CustomerInfos
                                                    ?.FullNameVi
                                                : dataTicketCounselor
                                                    ?.CustomerInfos?.Name
                                                    ? dataTicketCounselor
                                                        ?.CustomerInfos?.Name
                                                    : 'KH vãng lai'
                                            : detailsCurrentAdvise?.Fullname
                                                ? detailsCurrentAdvise?.Fullname
                                                : detailsProcessingAdvise?.Fullname
                                                    ? detailsProcessingAdvise?.Fullname
                                                    : 'KH vãng lai'}
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcUserMini />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        Hạng khách hàng
                                    </div>
                                    <div className="value-text">
                                        {dataTicketCounselor?.CustomerInfos
                                            ?.SegmentDesc ||
                                            detailsCurrentAdvise?.SegmentDesc ||
                                            detailsProcessingAdvise?.Rank}
                                    </div>
                                </div>
                            </div>
                            <div className="block-info">
                                <div className="wrap-icon">
                                    <IcBank />
                                </div>
                                <div className="wrap-text">
                                    <div className="key-text">
                                        PGD/ Chi nhánh
                                    </div>
                                    <div className="value-text">
                                        {dataConnectDesktop?.OfficeName || '-'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-list-info">
                        <div className="title-content">Dịch vụ tư vấn</div>
                        <div className="list-service">
                            {listServiceCounselor?.length > 0 ? (
                                listServiceCounselor?.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="service-item"
                                            onClick={() =>
                                                handleSelectService(item)
                                            }
                                        >
                                            {serviceSelect?.filter(
                                                (ser) =>
                                                    ser.AdviseType ===
                                                    item?.AdviseType
                                            )?.length > 0 ? (
                                                <IcSelected />
                                            ) : (
                                                <IcNoSelect />
                                            )}{' '}
                                            {item?.Name}
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="note-no-list-service">
                                    * Không có danh sách dịch vụ tư vấn, vui
                                    lòng kiểm tra lại dữ liệu dịch vụ tư vấn
                                    trên hệ thống
                                </div>
                            )}
                        </div>
                        {serviceSelect?.length === 0 && (
                            <div className="note-no-list-select">
                                * Không có dịch vụ tư vấn được chọn, vui lòng
                                chọn dịch vụ tư vấn
                            </div>
                        )}
                    </div>
                    <div className="wrap-list-info">
                        <div className="title-content">
                            Kết quả bán <span>*</span>
                        </div>
                        {detailsCurrentAdvise?.Number || isEndSuccess ? (
                            <div className="details-result">
                                {textResult?.split('\n')?.map((text, index) => {
                                    return (
                                        <p className="text-result" key={index}>
                                            {text}
                                        </p>
                                    )
                                })}
                            </div>
                        ) : (
                            <div className="wrap-input-result">
                                <textarea
                                    className="input-result"
                                    placeholder="Nhập kết quả bán"
                                    onChange={(e) => {
                                        setTextResult(e.target.value)
                                        if (errTextResult) {
                                            setErrTextResult('')
                                        }
                                    }}
                                    value={textResult}
                                    maxLength={255}
                                />
                            </div>
                        )}

                        <div className="err-text">{errTextResult}</div>
                    </div>
                </div>
            </WrapContentPage>
            <WrapFooterPage>
                <div className="wrap-btn">
                    {detailsCurrentAdvise?.Number || isEndSuccess ? (
                        <div className="end-success">
                            <span>
                                <IcCheckWhite />
                            </span>
                            Đã hoàn tất
                        </div>
                    ) : (
                        <ButtonControl
                            text="Kết thúc"
                            className="btn-next"
                            onClick={handleEnd}
                            isDisable={
                                !textResult || serviceSelect?.length === 0
                            }
                        />
                    )}
                </div>
            </WrapFooterPage>
            {isShowPopupEnd && (
                <Portal>
                    <PopupEndCounselor
                        onClose={() => setIsShowPopupEnd(false)}
                        setIsEndSuccess={() => setIsEndSuccess(true)}
                        textResult={textResult}
                        serviceSelect={serviceSelect}
                    />
                </Portal>
            )}
            {
                isShowPopupRequire &&
                <Portal>
                    <PopupRequireCompleteAdvise onClose={handleClosePopupRequire} />
                </Portal>
            }
        </WrapContentCounselor>
    )
}
