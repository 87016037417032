export const POPUP_GET_TICKET_NUMBER = 'POPUP_GET_TICKET_NUMBER'
export const POPUP_GET_TICKET_NUMBER_ONLINE = 'POPUP_GET_TICKET_NUMBER_ONLINE'
export const POPUP_CLIENT_CONSULTANT = 'POPUP_CLIENT_CONSULTANT'

export const APPONLINE = 'APPONLINE'
export const QUEUETICKET = 'QUEUETICKET'

export const ACTIVE = 'ACTIVE'
export const SUCCESS = 'SUCCESS'

export const PRINT_FAIL = 'PRINT_FAIL'
export const PRINT_SUCCESS = 'PRINT_SUCCESS'
// Step 1
export const ENTER_PERSONAL_INFO = "ENTER_PERSONAL_INFO"
// Step 2
export const SELECT_TRANSACTION = "SELECT_TRANSACTION  "
// Step 3
export const CONFIRM_INFOMATION = "CUSTOMER_LIST_SCREEN"
// Step 4
export const ISSUE_TICKET = "ISSUE_TICKET"

export const SAVING = "SAVINGDEP" // gửi tiết kiệm
export const DEPOSIT_ANOTHER_BANK = "DEPEXT" // ck ngoài vietinbank
export const DEPOSIT_VIETIN_BANK = "DEPINT" // ck trong vietinbank
export const TRANSFER = "TRANSINT" //Chuyển khoản trong Vietinbank
export const TRANSFER_OUTSITE = "TRANSEXTACC" //Chuyển khoản nhanh ngoài VietinBank đến số tài khoản (≤300trđ)
export const TRANSFER_OUTSITE_TO_CARD = "TRANSEXTCARD" //Chuyển khoản nhanh ngoài VietinBank đến số thẻ (≤300trđ)
export const TRANSFER_OUTSITE_NORMAL = "TRANSEXT" //Chuyển khoản ngoài Vietinbank thông thường
export const WITHDRAW_MONEY = "WITHDRAW" //Rút tiền
export const FOREIGN_TRADING = "FCY" //Giao dịch ngoại tệ
export const CARD_TRADING = "CARD" //Giao dịch thẻ
export const ANOTHER_TRANS = "OTHER" //Khác
export const WDSVG = 'WDSVG' //Rút tiết kiệm
export const DIRECTCALL = 'DIRECTCALL' //Trống (vé load CIF)


export const INDIVIDUAL_CUSTOMER = "PERCUST"
export const BUSINES_CUSTOMER = "CORPCUST"

//key dịch vụ tư vấn
export const HEALTH_INSURANCE = "HEALTH"
export const MANULIFE_INSURANCE = "MANUALIFE"
export const CREDIT_CARD = "CREDITCARD"
export const OTHER_SERVICE = "OTHER"

//key trạng thái tư vấn
export const adviseStatus = {
    WAITING: 'WAITING',
    COMPLETED: 'COMPLETED',
    INPROCESSING: 'INPROCESSING',
    PROCESSING: 'PROCESSING',
}
