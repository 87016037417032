import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
// import moment from "moment";

// styles
import { Bound } from './TickyGridItemV2.styles'

// image
import moveto from '../../../../images/move-to.svg'

// component
import CountDownTimeControl from '../../../Control/CountDownTimeControl'
import { countDuplicated } from '../../../../helper/tool'

// action
import { openPopup } from '../../../../actions/popupBioAction'
import { ADVISETICKET, COORDINATOR, COORLOBBY, COUNSELOR, MOVETICKET, TAKETICKET, TELLER } from '../../../../data/userRole'
import IcCounselor from '../../../ComponentV2/images/SVG/IcCounselor'
import Portal from '../../../ComponentV2/controls/Portal'
import PopupConfirmCounselor from '../../../ComponentV2/screens/CounselorPage/PopupConfirmCounselor'
import IcCounselorSuccess from '../../../ComponentV2/images/SVG/IcCounselorSuccess'
import IcExportTicket from '../../../ComponentV2/images/SVG/IcExportTicket'
import GetNumberTicketOnline from '../../../ComponentV2/screens/GetNumberTicketOnline'
import IcCallCounter from '../../../ComponentV2/images/SVG/IcCallCounter'
import moment from 'moment'
import { QUEUETICKET, adviseStatus } from '../../../../constant/keys'
import {
    getCurrentAdvise,
    setIsShowPopupRequireCompleteAdvise,
    setShowContentCounselorPage,
} from '../../../../actions/counselorAction'
import {
    setTicketProcessing,
    setInfoGetAppointment,
    startTakeTicket,
    callTicket,
} from '../../../../actions/counterAction'
import IcInprocessing from '../../../ComponentV2/images/SVG/IcInprocessing'
import GetTimeSuccess from '../../../ComponentV2/controls/GetTimeSuccess'
import { MOVING } from '../../../../data/ticketStatuses'
import IcMoveGreen from '../../../ComponentV2/images/SVG/IcMoveGreen'
import IcNextBlue from '../../../ComponentV2/images/SVG/IcNextBlue'
import PopupProcessingCounselor from '../../../ComponentV2/screens/CounselorPage/PopupProcessingCounselor'
import { CALL_TICKET_ERR } from '../../../../actions/types'

class TickyGridItemV2 extends React.Component {
    state = {
        isChecked: this.props.isChecked,
        isShowPopupCounselor: false,
        isShowPopupExportTicketOnline: false,
        dataTicket: {},
        isShowPopupProcessing: false,
        dataPopupProcessing: {}
    }

    handleReviewAdvise = (officeId, counterId, ticketID, from) => {
        const callbackSuccess = () => {
            this.props.setShowContentCounselorPage()
        }
        this.props.getCurrentAdvise(
            officeId,
            counterId,
            ticketID,
            from,
            callbackSuccess
        )
    }

    onClickShowPopup = (data) => {
        this.setState({ isShowPopupCounselor: true, dataTicket: data })
    }

    onClickShowPopupProcessing = (data) => {
        const callbackError = () => {
            //console.log("callbackError");
            this.setState({ isShowPopupProcessing: true, dataPopupProcessing: data })
        }
        this.props.callTicket(
            data.TicketID,
            this.props.counterReducer.dataConnectDesktop.CounterID,
            this.props.counterReducer.dataConnectDesktop.OfficeID,
            data.Status,
            callbackError
        )
    }

    onClickShowPopupExportTicketOnline = (
        officeId,
        counterId,
        appointmentId,
        appointmentNumber
    ) => {
        this.props.setInfoGetAppointment(appointmentId, appointmentNumber)
        setTimeout(() => {
            this.setState({ isShowPopupExportTicketOnline: true })
        }, 100)
    }

    onChange = (event) => {
        var target = event.target
        var name = target.name
        var value = target.checked
        this.setState({
            [name]: value,
        })
    }

    onCallTicket = (ticketID, status) => {
        this.props.onCallTicket(ticketID, status)
    }

    handleShowTooltipTransactionLst = (e, dataTrans) => {
        e.preventDefault()
        const getRectTooltipTrans = this.tooltipTrans.getBoundingClientRect()
        this.tooltipTrans.style.top =
            e.clientY - getRectTooltipTrans.height - 15 + 'px'
        this.tooltipTrans.style.left =
            e.clientX - getRectTooltipTrans.width / 2 + 'px'
        this.tooltipTrans.style.visibility = 'visible'
        const TransLst = ({ props }) => (
            <React.Fragment>
                {dataTrans.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </React.Fragment>
        )
        this.tooltipTrans.innerHTML = ReactDOMServer.renderToString(
            <TransLst />
        )
    }

    handleHideTooltipTransactionLst = (e) => {
        e.preventDefault()
        this.tooltipTrans.style.visibility = 'hidden'
    }

    handleMoveTicket = (e, ticketId) => {
        e.preventDefault()
        this.props.openPopup(10, 1, { TicketID: ticketId })
    }

    handleStartTime = (e) => {
        if (e.Status === MOVING) {
            let deltaTime = (new Date(e.MoveAt).getTime()) - (new Date(e.FirstCallAt).getTime())
            let timeStart = new Date(new Date(e.CreateAt).getTime() + (deltaTime)).toUTCString()
            return timeStart
        } else {
            let timeStart = e.CreateAt
            return timeStart
        }
    }

    handleEndProcessing = () => {
        this.setState({ isShowPopupProcessing: false })
        this.props.setTicketProcessing({})
        this.props.setIsShowPopupRequireCompleteAdvise(false)
    }

    renderTable() {
        const {
            counterReducer,
            dataWaitingTicket,
            isTypeUser,
            isTicketOnline,
        } = this.props
        const someTicketInprocess = this.props.counterReducer.listCurrentTicket.some(
            (ticket) => ticket.Status !== 'PAUSED'
        )
        return dataWaitingTicket.map((e, index) => {
            let dataTransaction = []
            let nameTypeServices = ''

            if (e?.ListService?.length > 0) {
                for (let i = 0; i < e.ListService?.length; i++) {
                    nameTypeServices = `${e.ListService[i]?.Name}`
                    dataTransaction.push(nameTypeServices)
                }
            } else {
                //KHDN có mã KH là 1
                if (e?.CustType === '1' || e?.CustomerInfos?.CustType === '1') {
                    dataTransaction.push('Khách hàng doanh nghiệp')
                }
            }
            return (
                <React.Fragment key={index}>
                    <div className="item ticky-block">
                        <div
                            className="ticky-style"
                            style={{
                                color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                            }}
                        >
                            {e.Number}
                        </div>
                        {e.moveTo && (
                            <div className="icon-moveTo">
                                <img src={moveto} alt="moveto" />
                            </div>
                        )}
                    </div>
                    <div className="item customer-name"
                        style={{
                            color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                        }}
                    >
                        <span
                            title={
                                e?.CustomerInfos
                                    ? e.CustomerInfos.FullNameVi ||
                                    e.CustomerInfos.Name
                                    : e?.Fullname
                                        ? e?.Fullname
                                        : 'KH vãng lai'
                            }
                        >
                            {e?.CustomerInfos
                                ? e.CustomerInfos.FullNameVi ||
                                e.CustomerInfos.Name
                                : e?.Fullname
                                    ? e?.Fullname
                                    : 'KH vãng lai'}
                        </span>
                    </div>
                    <div className="item customer-type"
                        style={{
                            color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                        }}
                    >
                        <span
                            title={
                                e.CustomerInfos
                                    ? e.CustomerInfos.SegmentDesc
                                    : e.CustomerInfos === null && null
                            }
                        >
                            {e.CustomerInfos
                                ? e.CustomerInfos.SegmentDesc
                                : e.CustomerInfos === null && null}
                        </span>
                    </div>
                    <div className="item transaction"
                        ref={(ref) => (this.transaction = ref)}
                    >
                        <div
                            className="text"
                            style={{
                                color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                            }}
                            onMouseEnter={(e) =>
                                this.handleShowTooltipTransactionLst(
                                    e,
                                    dataTransaction
                                )
                            }
                            onMouseMove={(e) =>
                                this.handleShowTooltipTransactionLst(
                                    e,
                                    dataTransaction
                                )
                            }
                            onMouseLeave={this.handleHideTooltipTransactionLst}
                        >
                            {dataTransaction.join(', ')}
                        </div>
                    </div>
                    <div className="item time">
                        {(this.props.isTypeUser === TELLER ||
                            this.props.isTypeUser === COUNSELOR ||
                            this.props.isTypeUser === COORDINATOR ||
                            this.props.isTypeUser === COORLOBBY) &&
                            this.props.isTicketOnline ? (
                            <div className="time-order">
                                {moment(e.AppointmenAt).format('HH:mm')}
                                -
                                {moment(e.AppointmenAt).add(30, 'm').format('HH:mm')}
                            </div>
                        ) : (
                            <CountDownTimeControl
                                data={this.handleStartTime(e)}
                                ticketNumber={e.Number}
                            />
                        )}
                    </div>
                    <div className="item time">
                        {e.AdviseStatus === adviseStatus.COMPLETED &&
                            !e.From ? (
                            <GetTimeSuccess
                                startTime={e.AdviseStartAt}
                                endTime={e.AdviseCompleteAt}
                                isSuccess={
                                    e.AdviseStatus === adviseStatus.COMPLETED
                                }
                                ticketId={e.TicketID}
                            />
                        ) : (e.AdviseStatus !== adviseStatus.COMPLETED &&
                            !e.From) ? (
                            <GetTimeSuccess
                                startTime={e.AdviseStartAt}
                                endTime={e.AdviseCompleteAt}
                                isSuccess={
                                    e.AdviseStatus === adviseStatus.COMPLETED
                                }
                                ticketId={e.TicketID}
                            />
                        ) : (
                            <CountDownTimeControl
                                data={e.AppointmenAt}
                                ticketNumber={e.Number}
                                isCounselor={true}
                                isTimeCountdown={true}
                                ticketId={e.TicketID}
                            />
                        )}
                    </div>
                    {
                        isTypeUser === COUNSELOR ?
                            (
                                this.props.listCounselorSuccess.includes(e.Number) ||
                                    e.AdviseStatus === adviseStatus.COMPLETED ? (
                                    <div className="item button-icon">
                                        <div
                                            className="tooltip"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                opacity: someTicketInprocess
                                                    ? 0.5
                                                    : 'unset',
                                                cursor: someTicketInprocess
                                                    ? 'default'
                                                    : 'pointer',
                                                //marginRight: '20px',
                                            }} >
                                            <span className="tooltiptext">Đã tư vấn</span>
                                            <IcCounselorSuccess
                                                onClick={() =>
                                                    this.handleReviewAdvise(
                                                        counterReducer?.dataConnectDesktop
                                                            ?.OfficeID,
                                                        counterReducer?.dataConnectDesktop
                                                            ?.CounterID,
                                                        e.TicketID,
                                                        e.From ? e.From : QUEUETICKET
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : isTypeUser === COUNSELOR && e.TakeTicketStatus !== adviseStatus.PROCESSING &&
                                    e.AdviseStatus === adviseStatus.WAITING ? (
                                    <div className="item button-icon" >
                                        <div
                                            className="tooltip"
                                            style={{
                                                //width: '20px',
                                                //height: '20px',
                                                // gridColumnStart: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                opacity: someTicketInprocess
                                                    ? 0.5
                                                    : 'unset',
                                                cursor: someTicketInprocess
                                                    ? 'default'
                                                    : 'pointer',
                                                //marginRight: '20px',
                                            }}>
                                            <span className="tooltiptext">Tư vấn KH</span>
                                            <IcCounselor onClick={() => this.onClickShowPopup(e)} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="item button-icon">
                                        <div
                                            className="tooltip"
                                            style={{
                                                //width: '20px',
                                                //height: '20px',
                                                // gridColumnStart: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                opacity: someTicketInprocess
                                                    ? 0.5
                                                    : 'unset',
                                                cursor: someTicketInprocess
                                                    ? 'default'
                                                    : 'pointer',
                                                //marginRight: '20px',
                                            }}>
                                            <span className="tooltiptext">
                                                {
                                                    e.AdviseStatus === adviseStatus.INPROCESSING ?
                                                        `KH đang được tư vấn bởi ${e.EmployeeName}`
                                                        :
                                                        e.TakeTicketStatus === adviseStatus.PROCESSING &&
                                                        `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                }
                                            </span>
                                            <IcInprocessing />
                                        </div>
                                    </div>
                                )
                            )
                            : isTypeUser === COORDINATOR ?
                                <>
                                    {
                                        isTypeUser === COORDINATOR && !isTicketOnline ? (
                                            <div className="item button-icon">
                                                <div
                                                    className="tooltip"
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        opacity: someTicketInprocess
                                                            ? 0.5
                                                            : 'unset',
                                                        cursor: someTicketInprocess
                                                            ? 'default'
                                                            : 'pointer',
                                                        //marginRight: '20px',
                                                    }}
                                                >
                                                </div>
                                            </div>
                                        ) : isTicketOnline && (
                                            (e.TakeTicketStatus === adviseStatus.WAITING || e.TakeTicketStatus === adviseStatus.PROCESSING) && isTicketOnline &&
                                            <div className="item button-icon">
                                                <div
                                                    className="tooltip"
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        opacity: someTicketInprocess
                                                            ? 0.5
                                                            : 'unset',
                                                        cursor: someTicketInprocess
                                                            ? 'default'
                                                            : 'pointer',
                                                        //marginRight: '20px',
                                                    }}>
                                                    <span className="tooltiptext">
                                                        {
                                                            e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                "Xuất vé"
                                                                :
                                                                `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                        }
                                                    </span>
                                                    <IcExportTicket
                                                        onClick={() =>
                                                            this.onClickShowPopupExportTicketOnline(
                                                                counterReducer?.dataConnectDesktop
                                                                    ?.OfficeID,
                                                                counterReducer?.dataConnectDesktop
                                                                    ?.CounterID,
                                                                e.TicketID,
                                                                e.Number
                                                            )
                                                        } />
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                                : isTypeUser === TELLER ?
                                    <>
                                        {
                                            isTypeUser === TELLER && isTicketOnline ? (
                                                (e.TakeTicketStatus === adviseStatus.WAITING || e.TakeTicketStatus === adviseStatus.PROCESSING) &&
                                                    e.AdviseStatus === adviseStatus.WAITING ? (
                                                    <div className="item button-icon" >
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Tư vấn KH</span>
                                                            <IcCounselor onClick={() => this.onClickShowPopup(e)} />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                                //marginRight: '20px',
                                                            }}>
                                                            <span className="tooltiptext">
                                                                {
                                                                    e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                        "Xuất vé"
                                                                        :
                                                                        `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                }
                                                            </span>
                                                            <IcExportTicket
                                                                onClick={() => {
                                                                    if (someTicketInprocess) return
                                                                    this.onClickShowPopupExportTicketOnline(
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.OfficeID,
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.CounterID,
                                                                        e.TicketID,
                                                                        e.Number
                                                                    )
                                                                }} />
                                                        </div>
                                                    </div>
                                                ) : e.AdviseStatus === adviseStatus.INPROCESSING ? (
                                                    <div className="item button-icon">
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}
                                                        >
                                                            <span className="tooltiptext">
                                                                {`KH đang được tư vấn bởi ${e.EmployeeName}`}
                                                            </span>
                                                            <IcInprocessing />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                                //marginRight: '20px',
                                                            }}>
                                                            <span className="tooltiptext">
                                                                {
                                                                    e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                        "Xuất vé"
                                                                        :
                                                                        `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                }
                                                            </span>
                                                            <IcExportTicket
                                                                onClick={() => {
                                                                    if (someTicketInprocess) return
                                                                    this.onClickShowPopupExportTicketOnline(
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.OfficeID,
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.CounterID,
                                                                        e.TicketID,
                                                                        e.Number
                                                                    )
                                                                }} />
                                                        </div>
                                                    </div>
                                                ) : e.AdviseStatus === adviseStatus.COMPLETED && (
                                                    <div className="item button-icon">
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }} >
                                                            <span className="tooltiptext">Đã tư vấn</span>
                                                            <IcCounselorSuccess
                                                                onClick={() =>
                                                                    this.handleReviewAdvise(
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.OfficeID,
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.CounterID,
                                                                        e.TicketID,
                                                                        e.From ? e.From : QUEUETICKET
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                                //marginRight: '20px',
                                                            }}>
                                                            <span className="tooltiptext">
                                                                {
                                                                    e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                        "Xuất vé"
                                                                        :
                                                                        `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                }
                                                            </span>
                                                            <IcExportTicket
                                                                onClick={() => {
                                                                    if (someTicketInprocess) return
                                                                    this.onClickShowPopupExportTicketOnline(
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.OfficeID,
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.CounterID,
                                                                        e.TicketID,
                                                                        e.Number
                                                                    )
                                                                }} />
                                                        </div>
                                                    </div>
                                                )
                                            ) : !isTicketOnline && (
                                                e.AdviseStatus === adviseStatus.WAITING ? (
                                                    <div className="item button-icon">
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Tư vấn KH</span>
                                                            <IcCounselor onClick={() => this.onClickShowPopup(e)} />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Gọi KH vào phiên</span>
                                                            <IcMoveGreen onClick={() => someTicketInprocess ? {} : this.onCallTicket(e.TicketID, e.Status)} />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                //marginRight: '20px',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Chuyển quầy</span>
                                                            <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                        </div>
                                                    </div>
                                                ) : e.AdviseStatus === adviseStatus.INPROCESSING ? (
                                                    <div className="item button-icon">
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                // gridColumnStart: 2,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}
                                                        >
                                                            <span className="tooltiptext">
                                                                {`KH đang được tư vấn bởi ${e.EmployeeName}`}
                                                            </span>
                                                            <IcInprocessing />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Gọi KH vào phiên</span>
                                                            <IcMoveGreen onClick={() => this.onClickShowPopupProcessing(e)} />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                //marginRight: '20px',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Chuyển quầy</span>
                                                            <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                        </div>
                                                    </div>
                                                ) : e.AdviseStatus === adviseStatus.COMPLETED && (
                                                    <div className="item button-icon">
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }} >
                                                            <span className="tooltiptext">Đã tư vấn</span>
                                                            <IcCounselorSuccess
                                                                onClick={() =>
                                                                    this.handleReviewAdvise(
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.OfficeID,
                                                                        counterReducer?.dataConnectDesktop
                                                                            ?.CounterID,
                                                                        e.TicketID,
                                                                        e.From ? e.From : QUEUETICKET
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                opacity: someTicketInprocess
                                                                    ? 0.5
                                                                    : 'unset',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Gọi KH vào phiên</span>
                                                            <IcMoveGreen onClick={() => someTicketInprocess ? {} : this.onCallTicket(e.TicketID, e.Status)} />
                                                        </div>
                                                        <div
                                                            className="tooltip"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                //marginRight: '20px',
                                                                cursor: someTicketInprocess
                                                                    ? 'default'
                                                                    : 'pointer',
                                                            }}>
                                                            <span className="tooltiptext">Chuyển quầy</span>
                                                            <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        }
                                    </>
                                    : isTypeUser === COORLOBBY &&
                                    <>
                                        {
                                            isTicketOnline && (this.props.Roles.includes(ADVISETICKET) || this.props.Roles.includes(TAKETICKET) || this.props.Roles.includes(MOVETICKET)) ?
                                                (
                                                    (e.TakeTicketStatus === adviseStatus.WAITING || e.TakeTicketStatus === adviseStatus.PROCESSING) &&
                                                        e.AdviseStatus === adviseStatus.WAITING ? (
                                                        <div className="item button-icon" >
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">Tư vấn KH</span>
                                                                    <IcCounselor onClick={() => this.onClickShowPopup(e)} />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(TAKETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                        //marginRight: '20px',
                                                                    }}>
                                                                    <span className="tooltiptext">
                                                                        {
                                                                            e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                                "Xuất vé"
                                                                                :
                                                                                `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                        }
                                                                    </span>
                                                                    <IcExportTicket
                                                                        onClick={() => {
                                                                            if (someTicketInprocess) return
                                                                            this.onClickShowPopupExportTicketOnline(
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.OfficeID,
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.CounterID,
                                                                                e.TicketID,
                                                                                e.Number
                                                                            )
                                                                        }} />
                                                                </div>
                                                            }
                                                        </div>
                                                    ) : e.AdviseStatus === adviseStatus.INPROCESSING ? (
                                                        <div className="item button-icon">
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}
                                                                >
                                                                    <span className="tooltiptext">
                                                                        {`KH đang được tư vấn bởi ${e.EmployeeName}`}
                                                                    </span>
                                                                    <IcInprocessing />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(TAKETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                        //marginRight: '20px',
                                                                    }}>
                                                                    <span className="tooltiptext">
                                                                        {
                                                                            e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                                "Xuất vé"
                                                                                :
                                                                                `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                        }
                                                                    </span>
                                                                    <IcExportTicket
                                                                        onClick={() => {
                                                                            if (someTicketInprocess) return
                                                                            this.onClickShowPopupExportTicketOnline(
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.OfficeID,
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.CounterID,
                                                                                e.TicketID,
                                                                                e.Number
                                                                            )
                                                                        }} />
                                                                </div>
                                                            }
                                                        </div>
                                                    ) : e.AdviseStatus === adviseStatus.COMPLETED && (
                                                        <div className="item button-icon">
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }} >
                                                                    <span className="tooltiptext">Đã tư vấn</span>
                                                                    <IcCounselorSuccess
                                                                        onClick={() =>
                                                                            this.handleReviewAdvise(
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.OfficeID,
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.CounterID,
                                                                                e.TicketID,
                                                                                e.From ? e.From : QUEUETICKET
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(TAKETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                        //marginRight: '20px',
                                                                    }}>
                                                                    <span className="tooltiptext">
                                                                        {
                                                                            e.TakeTicketStatus === adviseStatus.WAITING ?
                                                                                "Xuất vé"
                                                                                :
                                                                                `KH đang được lấy vé bởi ${e.EmployeeName}`
                                                                        }
                                                                    </span>
                                                                    <IcExportTicket
                                                                        onClick={() => {
                                                                            if (someTicketInprocess) return
                                                                            this.onClickShowPopupExportTicketOnline(
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.OfficeID,
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.CounterID,
                                                                                e.TicketID,
                                                                                e.Number
                                                                            )
                                                                        }} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                ) : !isTicketOnline && (
                                                    e.AdviseStatus === adviseStatus.WAITING ? (
                                                        <div className="item button-icon">
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">Tư vấn KH</span>
                                                                    <IcCounselor onClick={() => this.onClickShowPopup(e)} />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(MOVETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        //marginRight: '20px',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">Chuyển quầy</span>
                                                                    <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    ) : e.AdviseStatus === adviseStatus.INPROCESSING ? (
                                                        <div className="item button-icon">
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        // gridColumnStart: 2,
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">
                                                                        {`KH đang được tư vấn bởi ${e.EmployeeName}`}
                                                                    </span>
                                                                    <IcInprocessing />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(MOVETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        //marginRight: '20px',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">Chuyển quầy</span>
                                                                    <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    ) : e.AdviseStatus === adviseStatus.COMPLETED && (
                                                        <div className="item button-icon">
                                                            {
                                                                this.props.Roles.includes(ADVISETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        opacity: someTicketInprocess
                                                                            ? 0.5
                                                                            : 'unset',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }} >
                                                                    <span className="tooltiptext">Đã tư vấn</span>
                                                                    <IcCounselorSuccess
                                                                        onClick={() =>
                                                                            this.handleReviewAdvise(
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.OfficeID,
                                                                                counterReducer?.dataConnectDesktop
                                                                                    ?.CounterID,
                                                                                e.TicketID,
                                                                                e.From ? e.From : QUEUETICKET
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                this.props.Roles.includes(MOVETICKET) &&
                                                                <div
                                                                    className="tooltip"
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        //marginRight: '20px',
                                                                        cursor: someTicketInprocess
                                                                            ? 'default'
                                                                            : 'pointer',
                                                                    }}>
                                                                    <span className="tooltiptext">Chuyển quầy</span>
                                                                    <IcNextBlue onClick={(evt) => this.handleMoveTicket(evt, e.TicketID)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                )
                                        }
                                    </>
                    }
                </React.Fragment>
            )
        })
    }
    render() {
        return (
            <>
                <Bound
                    showCheck={this.props.showCheck}
                    names={this.props.name}
                    width={this.props.width}
                    ref={(ref) => (this.waitingTicketTable = ref)}
                    type={this.props.type}
                    isTypeUser={this.props.isTypeUser}
                >
                    <div className="head-table">
                        <div className="font-head name-ticky">
                            <div className="ticky">{this.props.name}</div>
                        </div>
                        <div className="font-head customer-name">
                            TÊN KHÁCH HÀNG
                        </div>
                        <div className="font-head customer-type">
                            HẠNG KHÁCH HÀNG
                        </div>
                        <div className="font-head transaction special">
                            GIAO DỊCH
                        </div>
                        {(this.props.isTypeUser === TELLER ||
                            this.props.isTypeUser === COUNSELOR ||
                            this.props.isTypeUser === COORDINATOR || this.props.isTypeUser === COORLOBBY) &&
                            this.props.isTicketOnline ? (
                            <div className="font-head time">TG ĐẶT HẸN</div>
                        ) : (
                            <div className="font-head time">TG CHỜ</div>
                        )}
                        {this.props.isTypeUser === TELLER && this.props.isTicketOnline ? (
                            <div className="font-head time">TG CÒN LẠI</div>
                        ) : (this.props.isTypeUser === COUNSELOR && !this.props.isTicketOnline) ||
                            (this.props.isTypeUser === COORDINATOR && !this.props.isTicketOnline) ||
                            (this.props.isTypeUser === TELLER && !this.props.isTicketOnline) ||
                            (this.props.isTypeUser === COORLOBBY && !this.props.isTicketOnline) ? (
                            <div className="font-head time">TG TƯ VẤN</div>
                        ) : (
                            <div className="font-head time">TG CÒN LẠI</div>
                        )}
                        <div className="font-head button-tranfer"></div>
                    </div>
                    <div
                        className="scroll"
                        style={{ height: this.props.height + 'px' }}
                        ref={(ref) => (this.scrollTable = ref)}
                    >
                        <div className="body-table">{this.renderTable()}</div>
                    </div>
                    <div
                        className="transaction-tooltip"
                        ref={(ref) => (this.tooltipTrans = ref)}
                    ></div>
                </Bound>
                {this.state.isShowPopupProcessing && (
                    <Portal>
                        <PopupProcessingCounselor
                            handleCallTicket={() => this.props.callTicket(
                                this.state.dataPopupProcessing?.TicketID,
                                this.props.counterReducer.dataConnectDesktop.CounterID,
                                this.props.counterReducer.dataConnectDesktop.OfficeID,
                                this.state.dataPopupProcessing?.Status,
                            )}
                            handleEnd={this.handleEndProcessing}
                            timeCountDown={10}
                            data={this.state.dataPopupProcessing}
                        />
                    </Portal>
                )}
                {this.state.isShowPopupCounselor && (
                    <Portal>
                        <PopupConfirmCounselor
                            onClose={() =>
                                this.setState({
                                    isShowPopupCounselor: false,
                                })
                            }
                            dataTicket={this.state.dataTicket}
                        />
                    </Portal>
                )}
                {this.state.isShowPopupExportTicketOnline && (
                    <Portal>
                        <GetNumberTicketOnline
                            onClose={() =>
                                this.setState({
                                    isShowPopupExportTicketOnline: false,
                                })
                            }
                        />
                    </Portal>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
    listCounselorSuccess: state.counselor.listCounselorSuccess,
    Roles: state.counterReducer.dataLogin.Roles,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (typePopup, levelPopup, data) =>
        dispatch(openPopup(typePopup, levelPopup, data)),
    getCurrentAdvise: (officeId, counterId, ticketID, from, callbackSuccess) =>
        dispatch(
            getCurrentAdvise(
                officeId,
                counterId,
                ticketID,
                from,
                callbackSuccess
            )
        ),
    setShowContentCounselorPage: () =>
        dispatch(setShowContentCounselorPage(true)),
    setInfoGetAppointment: (appointmentId, appointmentNumber) =>
        dispatch(setInfoGetAppointment(appointmentId, appointmentNumber)),
    setTicketProcessing: (data) => dispatch(setTicketProcessing(data)),
    callTicket: (ticketID, counterID, officeID, ticketStatus, callbackError) =>
        dispatch(callTicket(ticketID, counterID, officeID, ticketStatus, callbackError)),
    setIsShowPopupRequireCompleteAdvise: (isShowPopupRequire) => dispatch(setIsShowPopupRequireCompleteAdvise(isShowPopupRequire)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TickyGridItemV2)
