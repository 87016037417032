import React, { Component } from 'react'
import styled from 'styled-components'
//images 
import ic_close from '../../images/ic_close_gray.svg'

const Bound = styled.div`
  position:absolute;
  top:61px;
  left:60px;
  padding: 14px;
  width: 212px;
  height: 96px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  font-family: SVN-Gilroy;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  animation: counterSuccess .5s;
  transition: 5s all ease-in-out;
    @keyframes counterSuccess {
      from {top: 30px;}
      to {top: 61px;}
    }
  .popupHeader{
    display:flex;
    justify-content:space-between;
    .title{
      font-size: 24px;
      font-weight: bold;
      line-height: 1.08;
      color: #005993;
    }
    .ic_close{
      cursor: pointer;
    }
  }
  .showID{
    margin: 10px 0 5px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.42;
    color: #c8c8c8;
  }
  .popupFooter{
    display:flex;
    justify-content:space-between;
    align-items: center;
    .title{
      font-size: 14px;
      font-weight: bold;
      color: #222222;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 9px;
    }
    .btn_out{
      display:flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 36px;
      border-radius: 18px;
      background-color: #f31919;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;

    }
  }
`

export default class PopupCouterSuccess extends Component {
  render() {
    // let {dataCounter} = this.props;
    
    const { dataConnectDesktop } = this.props.counterReducer;
    return (
      <Bound>
        <div className="popupHeader">
          <div className="title">
            {/* {dataCounter.name} */}
            {`Quầy số ${dataConnectDesktop.CounterNum}`}
          </div>
          <div className="ic_close"
          onClick={this.props.onClosePopup}
          >
            <img src={ic_close} alt="hinhanh"/>
          </div>
        </div>
        <div className="showID">
          {/* ID: {dataCounter.id} */}
          ID: {dataConnectDesktop.CounterID}
        </div>
        <div className="popupFooter">
          <div className="title">
            {/* PGD Bến Thành */}
            {dataConnectDesktop.OfficeName}
          </div>
          <div className="btn_out"
          onClick={() => {
            this.props.disconnectCounter();
            this.props.onClosePopup();
          }}
          >
            THOÁT
          </div>
        </div>
      </Bound>
    )
  }
}
