import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'

import ButtonControl from '../../MainPage/Mainscreen/Task/ButtonControl';
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';

///images
import img_check from '../../../images/fingerprint.svg'
import icSearchBlue from '../../../images/ic_search_blue.svg'
import btn_Recall from '../../../images/btn_Recall.svg'
import btn_Misscall_Red from '../../../images/btn_Misscall_red.svg'
import btn_Misscall_Blue from '../../../images/btn_Misscall_blue.svg'
import btn_TranferTicket from '../../../images/btn_TranferTicket.svg'
import iconNotificationFailed from "../../../images/ic_counter_notification_close.svg";

// action
import { openPopup } from "../../../actions/popupBioAction";
import { verifyFinger } from "../../../actions/counterAction";

// data
import { popupBioTypes } from "../../../data/PopupTypes";
import typesKeyDetailPopup from  "../../../data/typesKeyDetailPopup";
import { VERIFY_FINGER_ERR } from '../../../actions/types';

// hepler
import { 
  openPopupFillReasonNotAuth, 
  openPopupSearchCif,
  openPopupRecallTicket
} from "../../../helper/actionOpenPopup";
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket';
import verifyFingerStatusTypes from '../../../data/verifyFingerStatusTypes';
import { errorData } from '../../../data/errorData';
import { buildErrorContent } from '../../../helper/tool';
import { openPopupNotifyErrorLv3 } from "../../../helper/actionOpenPopup";

const Bound = styled.div`
  .start_check {
    padding: 36px 0 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    box-shadow: 0px 2.5px 0px #a19f9f38;
    .btn {
      display: flex;
      & > :first-child {
        margin-right: 10px;
      }
    }
  }
  .block_check {
    .img_check {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
  .notice{
    margin: 16px 0 0 0;
    width: 216px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    color: #979797;
    text-align: center;
  }
  .btn {
    margin: 28px 0 0 0;
  }
`
const BoundActions = styled.div`
  padding: 20px 0;
  height: 100% ;
  width:100%;
  display: flex;
  justify-content:center;
  align-items: center;
  .btn{
    margin: 0 10px 0 0;
  }
`

class CheckCustomerActions extends Component {
  render() {
    const { ticketId } = this.props;
    const currentTicket = this.props.counterReducer.listCurrentTicket.find(
      item => item.TicketID === ticketId
    )
    if(!currentTicket) return null;
    const isDisableBtnSearchCifs = 
      String(currentTicket.statusBiometric) !== typeStatusBiometrics.UNDEFINED ||
      // currentTicket.IsVerifyFinger ||
      currentTicket.VerifyFingerStatus !== verifyFingerStatusTypes.NV ||
      Object.keys(this.props.counterReducer.enrollId).includes(currentTicket.TicketID)
      
    return (
        <Bound>
            <div className="start_check">
                <div className="block_check">
                    <img
                        className="img_check"
                        src={img_check}
                        alt="img_check"
                    />
                </div>
                <p className="notice">
                    Bấm “Bắt đầu” để bật chức năng tại thiết bị hỗ trợ
                </p>
                <div className="btn">
                    <ButtonConfirmControl
                        titleConfirm="bắt đầu"
                        bg_color="#0FB11F"
                        onSubmit={this.handleStartVerifyFinger}
                    />
                    <ButtonConfirmControl
                        width="150px"
                        bg_color="#F31919"
                        titleConfirm="Không xác thực"
                        onSubmit={this.handleSubmitNotAuth}
                    />
                </div>
            </div>
            <BoundActions>
                {
                    // !this.props.isAuthenBeforeCompleteTicket ?
                    <React.Fragment>
                        {!isDisableBtnSearchCifs && (
                            <div className="btn">
                                <ButtonControl
                                    bgColor="var(--peacock-blue)"
                                    width="105px"
                                    height="36px"
                                    padding="0px"
                                    mr_img="0px"
                                    icon={icSearchBlue}
                                    content="Tìm cif mới"
                                    onSubmit={this.handleSearchCif}
                                />
                            </div>
                        )}
                        <div className="btn">
                            <ButtonControl
                                bgColor="var(--peacock-blue)"
                                width="92px"
                                height="36px"
                                padding="0px"
                                mr_img="0px"
                                icon={btn_Recall}
                                content="Gọi lại"
                                onSubmit={this.handleRecallTicket}
                            />
                        </div>
                        <div className="btn">
                            <ButtonControl
                                bgColor="var(--peacock-blue)"
                                width="120px"
                                height="36px"
                                padding="0px"
                                mr_img="0px"
                                icon={btn_TranferTicket}
                                content="Chuyển quầy"
                                onSubmit={this.handleMoveTicketToOtherCounter}
                            />
                        </div>
                        <div className="btn">
                            <ButtonControl
                                bgColor="var(--peacock-blue)"
                                width="106px"
                                height="36px"
                                padding="0px"
                                mr_img="0px"
                                icon={btn_Misscall_Blue}
                                content="QUẢN LÝ TB"
                                onSubmit={this.handleControlTablet}
                            />
                        </div>
                        <div className="btn">
                            <ButtonControl
                                bgColor="#F31919"
                                width="92px"
                                height="36px"
                                padding="0px"
                                mr_img="0px"
                                icon={btn_Misscall_Red}
                                content="Gọi nhỡ"
                                onSubmit={this.handleMoveTicketToMissCall}
                            />
                        </div>
                    </React.Fragment>
                    // :
                    // null
                }
            </BoundActions>
        </Bound>
    )
  }

  handleSubmitNotAuth = (e) => {
    // e.preventDefault();
    openPopupFillReasonNotAuth(
      this, 
      { 
        key: typesKeyDetailPopup.fillReasonNotAuth,
        ticketId: this.props.ticketId
      }, 
      "CheckCustomerActions.js"
    )
  }

  handleSearchCif = (e) => {
    e.preventDefault();
    const POPUP_LEVEL_2 = 2;
    openPopupSearchCif(
      this, 
      {
        key: typesKeyDetailPopup.searchCifsInFormAuthCustomer,
        ticketId: this.props.ticketId
      }, 
      "handleSearchCif - CheckCustomerActions.js",
      POPUP_LEVEL_2
    )
  }

  handleStartVerifyFinger = async () => {
    const {ticketId} = this.props;
    const {CustomerNumber: customerNumber} = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === ticketId).CustomerInfos;
    await this.props.verifyFinger(ticketId, customerNumber);
    const { err } = this.props.counterReducer;
    // debugger;
    if(err && 
      typeof(err) === "object" && 
      Object.keys(err).includes(VERIFY_FINGER_ERR)){
        switch(true){
          case Object.keys(errorData[VERIFY_FINGER_ERR]).includes(String(err[VERIFY_FINGER_ERR])):
            openPopupNotifyErrorLv3(
              this,
              {
                image_noti: iconNotificationFailed,
                title: buildErrorContent(VERIFY_FINGER_ERR, err[VERIFY_FINGER_ERR]),
                widthPopup: "266px",
                titleConfirm: "Xác nhận"
              },
              "handleStartVerifyFinger - CheckCustomerAction.js"
            )
            break;
          default:
            openPopupNotifyErrorLv3(
              this,
              {
                image_noti: iconNotificationFailed,
                title: err[VERIFY_FINGER_ERR],
                widthPopup: "180px",
                titleConfirm: "Xác nhận"
              },
              "handleStartVerifyFinger - CheckCustomerAction.js"
            )
            break;
        }
    }
  }

  handleRecallTicket = (e) => {
    e.preventDefault();
    const currentTicket = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === this.props.ticketId);

    openPopupRecallTicket(
      this, 
      {
        key: typesKeyDetailPopup.reCallTicketInFormAuthCustomer,
        callAt: currentTicket && currentTicket.CallAt,
        ticketId: this.props.ticketId
      },
      "handleRecallTicket - CheckCustomerActions.js"
    )
  }

  handleMoveTicketToOtherCounter = () => {
    const { ticketId } = this.props;
    const POPUP_LEVEL_1 = 1;
    this.props.openPopup(
      popupBioTypes.PopupPassTicket,
      POPUP_LEVEL_1,
      {
        key: typesKeyDetailPopup.moveTicketToOtherCounterInFormAuthCustomer,
        TicketID: ticketId
      }
    )
  }

  handleControlTablet = () => {
    this.props.openPopup(popupBioTypes.PopupControlTablet, 3, {});
  }

  handleMoveTicketToMissCall = () => {
    const {ticketId: ticketID} = this.props;
    const {CounterID: counterID} = this.props.counterReducer.dataLogin
    const POPUP_LEVEL_1 = 1;
    const dataTranferToPopup = {
      counterID,
      ticketID,
      content: 'Chuyển vé được chọn vào danh sách gọi nhỡ?',
      // key: 'moveTomissCall',
      key: typesKeyDetailPopup.moveTicketToMissCallInFormAuthCustomer
    }
    this.props.openPopup(
      popupBioTypes.PopupConfirm,
      POPUP_LEVEL_1,
      dataTranferToPopup
    )
  }
}

const mapStateToProps = state => ({
  counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
  openPopup: (typePopup, levelPopup, data) => dispatch(openPopup(typePopup, levelPopup, data)),
  verifyFinger: (ticketId, customerNumber) => dispatch(verifyFinger(ticketId, customerNumber))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckCustomerActions)
