import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";

// image
import icCloseNotification from "../../images/ic_counter_notification_close.svg";
import icCloseGray from "../../images/ic_close_gray.svg";
// import imgUser from "../../images/img_user.svg";
import arrowMoveLeft from "../../images/arrow-move-left.svg"
import arrowMoveRight from "../../images/arrow-move-right.svg"

// action
import {
	apiGetBioFaceImage,
	apiGetBioFingerImage,
	// apiGetBioFile,
	updateBiometricNote
} from "../../actions/biometricAction";
import {
	updateBiometricNoteCollector,
	apiGetBioFaceImageCollector,
	apiGetBioFingerImageCollector
} from "../../actions/biometricWithInBioCollectorAction";

// data
import { typeStatusBiometrics } from "../../data/statusBiometricWithTicket";
import { TELLER } from "../../data/userRole";
import { nameFingerPrint } from "../../data/bioTypes";

// helper
import getFromChannel from "../../helper/getFromChannel";
import getChannelName from "../../helper/getChannelName";

const BoundItem = styled.div`
	width: 100%;
	margin-top: 20px;
	.wrap-duplicate-data {
		max-height: 40vh;
    	overflow-y: scroll;
		overflow-x: hidden;
		min-width: 644px;
		::-webkit-scrollbar {
			width: 3px;
		}

		::-webkit-scrollbar-thumb {
			background: #c8c8c8; 
		}

		.wrap-list {
			width: max-content;
			.content__row {
				display: flex;
				&.row-face {
					/* max-width: 472px; */
					box-shadow: rgba(0,0,0,0.3) 3px 3px 30px -14px;;
					padding: 16px;
					border-radius: 4px;
					&:not(:first-child){
						margin-top: 12px;
					}
				}

				&.info {
					width: 30ch;
				}

				&__col {

					&.col-face {
						margin-right: 36px;
						overflow: hidden;
						flex-shrink: 0;

						.cif-number {
							font-family: "SVN-Gilroy";
							font-size: 14px;
							line-height: 15px;
							margin-bottom: 7px;
							span {
								text-transform: uppercase;
								font-weight: bold;
								color: var(--peacock-blue);
							}
						}

						.channel {
							margin-top: 10px;
							font-family: 'SVN-Gilroy';
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 110%;
							color: #717171;
						}
					}

					.img-face {
						/* width: 108px; */
						height: 108px;
						border-radius: 5px;
					}

					.day-register {
						/*display: flex;*/
						font-family: "SVN-Gilroy";
						font-size: 10px;
						line-height: 130%;
						color: var(--peacock-blue);
						white-space: nowrap;
						display: none;
					}

					.title, .content {
						font-family: "SVN-Gilroy";
						font-size: 14px;
						line-height: 15px;
					}

					.title {
						max-width: 60ch;
					}

					.info-title {
						/* width: 17ch; */
						white-space: nowrap;
						color: var(--peacock-blue);
						margin-right: 5px;
					}

					.content {
						/* width: 30ch; */
					}
				}
				.address-content {
					max-width: 54ch;
				}
			}
		}
    	
	}
	

	.mg-t-10 {
		margin-top: 10px;
	}

	.mg-r-10 {
		margin-right: 10px;
	}

	.pagination {
		margin-top: 10px;
		width: 100%;
		text-align: right;
		color: #979797;
		font-family: "SVN-Gilroy";
		font-size: 12px;
		line-height: 130%;
		font-weight: 500;

		span {
			color: #222222;
		}

		img:hover {
			cursor: pointer;
		}

		img:first-child {
			margin-right: 12px;
		}

		img:last-child {
			margin-left: 12px;
		}
	}

	.note {
		margin-top: 22px;
		.title {
			text-transform: uppercase;
			color: var(--peacock-blue);
			font-family: SVN-Gilroy;
			font-size: 11px;
			line-height: 126%;
			font-weight: bold;
		}

		textarea {
			margin-top: 8px;
			width: 100%;
			outline: none;
			border: 0.5px solid #D6D6D6;
			border-radius: 4px;
			resize: none;
			font-family: "SVN-Gilroy";
			font-size: 14px;
			line-height: 128%;
			padding: 9px;
		}
	}
`;
class FaceError extends Component {
	state = {
		data: this.props.data.slice(0, 2),
		total: this.props.data.length,
		currentPage: 1,
		totalPage: Math.round(this.props.data.length / 2)
	}
	genNum = (num) => ("0" + num).slice(-2)
	nextPage = () => {
		const { currentPage, totalPage } = this.state;
		if (currentPage === totalPage) return;
		this.setState({
			currentPage: currentPage + 1,
			data: this.props.data.slice(2 * currentPage, 2 * currentPage + 2)
		})
	}

	prevPage = () => {
		const { currentPage } = this.state;
		if (currentPage === 1) return;

		this.setState({
			currentPage: currentPage - 1,
			data: this.props.data.slice(2 * (currentPage - 2), 2 * (currentPage - 2) + 2)
		})
	}

	render() {
		const { data } = this.state;
		const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
		const linkFace = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector;
		return (
			<BoundItem
				isDisableTextArea={this.props.isDisableTextArea}
			>
				<div className="wrap-duplicate-data">
					<div className="wrap-list">
						{
							data.map((item, i) => (
								<div className="content__row row-face" key={i}>
									<div className="content__row__col col-face">
										<div className="cif-number">
											<span>cif: </span>{item.CustomerNumber}
										</div>
										<img src={linkFace(item.FaceID)} alt="face" className="img-face" />
										{/* <div className="day-register">
											{`Ngày đăng ký: ${moment(item.CreateAt).format("DD/MM/YY")}`}
										</div> */}
										<div className="channel">Kênh tác nghiệp: {getChannelName(getFromChannel(item.FromChannel))}</div>
									</div>
									<div className="content__row__col">
										<div
											className="title"
											style={{
												textTransform: "uppercase",
												marginBottom: "13px",
												fontWeight: "bold"
											}}
										>
											{item.Name}
										</div>
										<div className="content__row mg-t-10">
											<div className="content__row info mg-r-10">
												<div className="title info-title">GTTT:</div>
												<div className="content">{item.IdNumber}</div>
											</div>
											<div className="content__row info">
												<div className="title info-title">PGD:</div>
												<div className="content">{item.OfficeName}</div>
											</div>
										</div>
										<div className="content__row mg-t-10">
											<div className="content__row info mg-r-10">
												<div className="title info-title">Ngày cấp:</div>
												<div className="content">{item.IdIssueDate}</div>
											</div>
											<div className="content__row info">
												<div className="title info-title">GDV/Điện toán:</div>
												<div className="content">{item.TellerName}</div>
											</div>
										</div>
										<div className="content__row mg-t-10">
											<div className="content__row info mg-r-10">
												<div className="title info-title">Nơi cấp:</div>
												<div className="content">{item.IdIssuePlace}</div>
											</div>
											<div className="content__row info">
												<div className="title info-title">KSV/BGD:</div>
												<div className="content">{item.SupervisorName}</div>
											</div>
										</div>
										<div className="content__row mg-t-10">
											<div className="content__row info mg-r-10">
												<div className="title info-title">SĐT:</div>
												<div className="content">{item.PhoneNumber}</div>
											</div>
											<div className="content__row info">
												<div className="title info-title">Thời gian phê duyệt:</div>
												<div className="content" style={{ flexShrink: 0 }}>{moment(item.CreateAt).format("HH:mm DD/MM/YYYY")}</div>
											</div>
										</div>
										<div className="content__row mg-t-10">
											<div className="title info-title">Địa chỉ:</div>
											<div className="content address-content">{item.Address}</div>
											{/* <div className="content">Tồn tại Hình ảnh STH vân tay chưa được xóa. Vui lòng xóa STH vân tay trước khi chuyển phê duyệt</div> */}
										</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
				{
					this.state.totalPage > 1 &&
					<div className="pagination">
						<img
							src={arrowMoveLeft}
							alt="left"
							onClick={this.prevPage}
						/>
						<span>{this.genNum(this.state.currentPage)}</span>
						{`/${this.genNum(this.state.totalPage)}`}
						<img
							src={arrowMoveRight}
							alt="right"
							onClick={this.nextPage}
						/>
					</div>
				}
				<div className="note">
					<div className="title">nội dung ghi chú</div>
					<textarea
						placeholder="Nhập nội dung lưu trữ...không quá 200 kí tự"
						rows={3}
						maxLength="200"
						disabled={this.props.isDisableTextArea}
						onChange={this.props.handleChangeNote}
						value={this.props.defaultNote}
					></textarea>
				</div>
			</BoundItem>
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data)
			this.setState({
				data: this.props.data.slice(0, 2),
				total: this.props.data.length,
				currentPage: 1,
				totalPage: Math.round(this.props.data.length / 2)
			})
	}

}

const BoundFinger = styled.div`
	margin-top: 43px;
	margin-bottom: 37px;
	min-width: 800px;
	.head {
		display: flex;
		justify-content: space-between;
		& > * {
			font-family: "SVN-Gilroy";
			font-size: 11px;
			line-height: 126%;
			font-weight: bold;
		}

		&__title {
			text-transform: uppercase;
		}
		&__cifs {
			display: flex;
			& > :first-child {
				color: var(--peacock-blue);
				margin-right: 5px;
			}
		}
	}

	.content__row {
		display: flex;
		margin-top: 12px;
		&.row-infos {
			width: 36ch;
			margin-top: 0;
		}

		.flex-column {
			display: flex;
			flex-direction: column;
			/* justify-content: space-between; */
		}

		&__col {
			&:first-child {
				margin-right: 39px;

				&.cus-infos {
					margin-right: 5px;
					white-space: nowrap;
				}
			}

			.cifs {
				font-size: 18px;
    			font-weight: bold;
				margin-bottom: 12px;
				span {
					color: var(--peacock-blue);
					margin-right: 5px;
				}
			}

			.cus-name {
				font-size: 18px;
    			font-weight: bold;
			}

			.img-finger {
				width: 140px;
				height: 140px;
				border: 0.5px solid #D6D6D6;
			    border-radius: 5px;
			}

			.day-register, .type-finger {
				/*display: none;*/
				font-family: "SVN-Gilroy";
				font-size: 14px;
				line-height: 130%;
				color: var(--peacock-blue);
				white-space: nowrap;
			}
			
			.type-finger {

			}

			.day-register {
				display: none;
				color: #222222;
			}


			.title, .content {
				font-family: "SVN-Gilroy";
				font-size: 14px;
				line-height: 15px;
			}

			.content {
				/* width: 20ch; */
			}

			.info-title {
				/* width: 17ch; */
				color: #979797;
			}

			.info-address {
				width: auto;
				max-width: 60ch;
			}
		
		}
	}

	.mg-t-10 {
		margin-top: 10px;
	}
`;

const FingerError = (props) => {
	const {
		cifs,
		invalidFingerPrint,
		bioType
	} = props.data;
	const linkGetFinger = props.counterReducer.dataLogin.Roles.includes(TELLER) ?
		apiGetBioFingerImage : apiGetBioFingerImageCollector
	return (
		<BoundFinger>
			{/* <div className="head">
				<div className="head__title">Thông tin khách hàng trùng</div>
				<div className="head__cifs">
					<div>CIF:</div>
					<div>{cifs.CustomerNumber}</div>
				</div>
			</div> */}
			<div className="content__row">
				<div className="content__row__col flex-column" style={{ alignItems: "center" }}>
					<div className="cifs"><span>CIF:</span>{cifs.CustomerNumber}</div>
					<img
						className="img-finger"
						src={linkGetFinger(invalidFingerPrint[0])}
						alt="finger"
					/>
					<div className="type-finger">{bioType && nameFingerPrint[bioType].name}</div>
					{/* <div className="day-register">Ngày đăng ký: 15/08/19</div> */}
					<div className="channel">Kênh tác nghiệp: {getChannelName(getFromChannel(cifs.FromChannel))}</div>
				</div>
				<div className="content__row__col">
					<div className="cus-name">{/* cifs.FullNameVi ||  */cifs.Name}</div>
					<div className="content__row ">
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">GTTT:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.IdNumber}</div>
							</div>
						</div>
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">PGD Đ.Ký:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.OfficeName}</div>
							</div>
						</div>
					</div>
					<div className="content__row">
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">Ngày cấp:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.IdIssueDate}</div>
							</div>
						</div>
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">GDV Đ.Ký:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.TellerName}</div>
							</div>
						</div>
					</div>
					<div className="content__row">
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">Nơi cấp:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.IdIssuePlace}</div>
							</div>
						</div>
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">KSV P.Duyệt:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.SupervisorName}</div>
							</div>
						</div>

					</div>
					<div className="content__row">
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">SĐT:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{cifs.MobileNumber}</div>
							</div>
						</div>
						<div className="content__row row-infos">
							<div className="content__row__col cus-infos">
								<div className="title info-title">Thời gian Đ.ký:</div>
							</div>
							<div className="content__row__col">
								<div className="content">{moment(cifs.AcceptAt).format("HH:mm ngày DD/MM/YYYY")}</div>
							</div>
						</div>
					</div>
					<div className="content__row">
						<div className="content__row__col cus-infos">
							<div className="title info-title">Địa chỉ:</div>
						</div>
						<div className="content__row__col">
							<div className="content info-address">{cifs.Address}</div>
						</div>
					</div>
				</div>
			</div>
		</BoundFinger>
	)
}

const dataGenerate = {
	FACE: {
		title: "Khuôn mặt trùng khớp",
		errorVi: "STH khuôn mặt của KH đang giao dịch",
		component: (data, isDisableTextArea, defaultNote, handleChangeNote, counterReducer, ticketId) => {
			const currentTicket = counterReducer.listCurrentTicket.find(
				item => item.TicketID === ticketId
			)
			if (!currentTicket)
				return <FaceError
					data={data}
					isDisableTextArea={isDisableTextArea}
					defaultNote={defaultNote}
					handleChangeNote={handleChangeNote}
					counterReducer={counterReducer}
				/>
			const { dataWasAuthen, CustomerInfos } = currentTicket;
			if (!!dataWasAuthen && dataWasAuthen.BiometricActive !== "0") {
				const dataNotIncludesThisCust = data.filter(item =>
					item.CustomerNumber !== CustomerInfos.CustomerNumber
				)
				return <FaceError
					data={dataNotIncludesThisCust}
					isDisableTextArea={isDisableTextArea}
					defaultNote={defaultNote}
					handleChangeNote={handleChangeNote}
					counterReducer={counterReducer}
				/>

			}
			return <FaceError
				data={data}
				isDisableTextArea={isDisableTextArea}
				defaultNote={defaultNote}
				handleChangeNote={handleChangeNote}
				counterReducer={counterReducer}
			/>
		}
	},
	FINGER: {
		title: "Hình ảnh vân tay trùng khớp",
		errorVi: "vân tay đăng ký",
		component: (data, isDisableTextArea, defaultNote, handleChangeNote, counterReducer, ticketId) =>
			<FingerError
				data={data}
				counterReducer={counterReducer}
			/>
	}
}

const Bound = styled.div`
	background: #fff;
	/* max-width: 522px; */
	position: ${props => props.isDisabledCenter ? "unset" : "absolute"};
	top: ${props => props.isDisabledCenter ? "unset" : "50%"};
	left: ${props => props.isDisabledCenter ? "unset" : "50%"};
	transform: ${props => props.isDisabledCenter ? "unset" : "translate(-50%, -50%)"};
	border-radius: 15px;
    /* box-shadow: rgba(0,0,0,0.3) 4px 4px 20px 0px; */
    box-shadow: rgba(0,0,0,0.3) 8px 4px 10px 2px;
	/*padding: 12px 20px;*/
	& * {
		box-sizing: border-box;
	}
	.popup__head {
		padding: 12px 20px;
	    display: flex;
    	justify-content: space-between;
    	font-family: "SVN-Gilroy";
		font-size: 16px;
		line-height: 21px;
		font-weight: 500;

		img {
			cursor: pointer;
		}
	}

	.popup__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 25px;

		&__error-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: "SVN-Gilroy";
			font-size: 16px;
			line-height: 21px;
			font-weight: 500;

			img {
				width: 52px;
			    height: 52px;
			    margin-top: 5px;
			    margin-bottom: 12px;
			    
			}
		}

		&__detail {
			width: 100%;
		}
	}

	.popup__footer {
		text-align: center;
		button {
			border: none;
			outline: none;
			background: var(--peacock-blue);
			color: #fff;
		    padding: 9px 20px;
    		border-radius: 18px;
    		text-transform: uppercase;
    		font-family: "SVN-Gilroy";
			font-size: 13px;
			line-height: 14px;
			font-weight: bold;
			margin-bottom: 20px;
			opacity: ${props => props.isDisableBtn ? 0.5 : 1};

			:hover {
				cursor: pointer;
			}
		}
	}
`;

class PopupShowErrorExistedDataBiometric extends Component {

	state = {
		note: ""
	}

	handleChangeNote = (e) => {
		// debugger;
		this.setState({ note: e.target.value })
	}

	isMatchToSubmitFace = () => {
		// debugger;
		const {
			// typeExistedData,
			// data,
			ticketId
		} = this.props;
		const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === ticketId);
		if (!currentTicket) return false;

		const isNotHaveStatusSupervisor = !currentTicket.statusBiometric || String(currentTicket.statusBiometric) === typeStatusBiometrics.UNDEFINED;
		const isInprogressBio = currentTicket.statusCustomer === "InprogressBio";
		// debugger
		if (!isNotHaveStatusSupervisor || isInprogressBio) return false;

		return true;
	}

	handleSubmit = () => {
		const {
			typeExistedData,
			// data,
			ticketId
		} = this.props;
		if (typeExistedData === "FACE") {
			if (this.isMatchToSubmitFace()) {
				const enrollId = this.props.counterReducer.enrollId[ticketId];
				const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
				const curentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === ticketId);
				isTeller && this.props.updateBiometricNote(enrollId, this.state.note.trim())
				!isTeller && this.props.updateBiometricNoteCollector(enrollId, this.state.note.trim(), curentTicket.tellerNoteNotEnoughFinger || "")
			}
		}
		this.props.closePopup();
	}

	render() {
		const {
			typeExistedData,
			data,
			ticketId,
			counterReducer
		} = this.props;
		// const data = [
		// 	...this.props.data, 
		// 	...this.props.data, 
		// 	...this.props.data, 
		// 	...this.props.data, 
		// 	...this.props.data, 
		// 	...this.props.data, 
		// 	...this.props.data
		// ];
		const isTypeMatch = ["FINGER", "FACE"];
		if (!isTypeMatch.includes(typeExistedData)) return null;
		// debugger;
		const isDisable = !this.isMatchToSubmitFace();

		return (
			<Bound
				isDisableBtn={
					!this.state.note.trim() &&
					typeExistedData === "FACE" &&
					isDisable
				}
				isDisabledCenter={this.props.isDisabledCenter}
			>
				<div className="popup__head">
					<div className="title">{/* {dataGenerate[typeExistedData].title} */}</div>
					<img
						src={icCloseGray}
						alt="close"
						onClick={this.props.closePopup}
					/>
				</div>
				<div className="popup__content">
					<div className="popup__content__error-content">
						<img src={icCloseNotification} alt="error-alert" />
						<div className="error">
							{`Hình ảnh ${dataGenerate[typeExistedData].errorVi} trùng với KH khác trong hệ thống`}
						</div>
						{/* <div className="error">
							trùng với KH khác trong hệ thống
						</div> */}
					</div>
					<div
						className="popup__content__detail"
						ref={ref => this.popupContent = ref}>
						{
							dataGenerate[typeExistedData].component(
								data,
								isDisable,
								this.state.note,
								this.handleChangeNote,
								counterReducer,
								ticketId
							)
						}
					</div>
				</div>
				<div className="popup__footer">
					<button
						onClick={
							typeExistedData === "FACE" && !this.state.note.trim() ?
								() => { }
								:
								this.handleSubmit
						}
						style={{
							opacity: typeExistedData === "FACE" && !this.state.note.trim() ? "0.5" : "unset"
						}}
					>
						Xác nhận
					</button>
				</div>
			</Bound>
		)
	}

	componentDidMount() {
		const curentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === this.props.ticketId)
		if (!curentTicket) return;
		// debugger;
		this.setState({ note: curentTicket.tellerNoteDuplicateBio || "" })
	}
}

/**
 * prop = {
 * 	typeExistedData: FACE \| FINGER
 * 	data: array-FACE \| object-FINGER
 * 	ticketId: string
 * }
 */

const mapStateToProps = state => ({
	counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
	updateBiometricNote: (enrollId, note) => dispatch(updateBiometricNote(enrollId, note)),
	updateBiometricNoteCollector: (enrollId, note, noteNotEnoughFinger) => dispatch(updateBiometricNoteCollector(enrollId, note, noteNotEnoughFinger))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupShowErrorExistedDataBiometric)