import styled from 'styled-components'

const WrapEnterPersonalInfo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .title-page {
        color: var(--Black, #191919);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        padding: 16px 20px;
        .normal {
            color: var(--Gray, #636060);
            font-size: 14px;
            font-weight: 500;
            line-height: 128%;
            margin-top: 2px;
        }
    }
`

const WrapContentPage = styled.div`
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    .wrap-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 22px;
        grid-row-gap: 22px;
        padding: 0 20px;
        .wrap-type-customer {
            grid-column: 1 / 3;
            display: flex;
            flex-direction: column;
            width: 100%;
            /* height: 87px; */
            padding: 20px 0;
            gap: 4px;
            border-bottom: 1px solid #e7e6e6;
            border-top: 1px solid #e7e6e6;
            .title-select {
                color: var(--Gray, #636060);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: -0.28px;
                span {
                    color: #f31919;
                }
            }
            .content-select {
                display: flex;
                align-items: center;
                gap: 22px;
                .radio {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    cursor: pointer;
                    color: var(--Black, #191919);
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: -0.34px;
                }
            }
        }
        .form-input {
            width: 345px;
            height: 50px;
            .wrap-input {
                height: 50px;
            }
        }
    }
`

const WrapFooterPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #e7e6e6;
    .wrap-btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        .btn-next {
            display: flex;
            height: 44px;
            width: 150px;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background-color: #005993;
        }
    }
`
export { WrapEnterPersonalInfo, WrapContentPage, WrapFooterPage }
