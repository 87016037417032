import Axios from 'axios'

import * as types from './types'
import * as typeCodeResponses from './typeCodeResponses'
import { host } from '../host'
import * as typeRequestBios from './typeRequestBio'

// helper
import catchErrAction from '../helper/catchErrAction'

// action
import { addBiometricExistFromCustomerInfos } from './counterAction'
import { addLoading, removeLoading } from './commonAction'

const apiRequestGetBioData = host + 'counter/get-bio-data'
const apiRequestRemoveBioData = host + 'counter/remove-bio-data'
const apiRequestConfirmBioData = host + 'counter/confirm-bio'
const apiCompleteBio = host + 'counter/complete-bio'
const apiGetCurrentBioEnroll = host + 'counter/getCurrentBioEnroll'
const apiUpdateConfirmFinger = host + 'counter/update-confirm-finger'
const apiUpdateBiometricNote = host + 'counter/update-biometric-note'
const apiUpdateRemoveBio = host + 'counter/update-remove-bio'
const apiUpdateRevertRemoveBio = host + 'counter/update-revert-remove-bio'
const apiFindDuplFace = host + 'counter/find-dup-face'
export const apiFinishWaitingSocketFinger =
    host + 'counter/closed-verify-finger'

export const apiGetBioFile = (bioId) => `${host}counter/get/bio-file/${bioId}`
export const apiGetBioFaceImage = (bioID) =>
    `${host}counter/get/bio-face-image/${bioID}`
export const apiGetBioFingerImage = (bioID) =>
    `${host}counter/get/bio-finger-image/${bioID}`

export const findDuplFace = (CustomerNumber, callback) => async dispatch => {
    dispatch(addLoading())
    const dataRequest = JSON.stringify({
        CustomerNumber
    })

    try {
        const res = await Axios.post(apiFindDuplFace, dataRequest)
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            callback(data.Cifs || [])
        }
    }
    catch(err) {
        catchErrAction(
            err,
            dispatch,
            types.FIND_DUPL_FACE_ERR,
            false
        )
    }
    finally {
        dispatch(removeLoading())
    }
}

export const updateRemoveBio = (enrollId, bioType, removeNote) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
        RemoveNote: removeNote,
    })

    try {
        const res = await Axios.post(apiUpdateRemoveBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            const store = getState()
            const lstEnrollId = store.counterReducer.enrollId
            const ticketId = Object.keys(lstEnrollId).find(
                (item) => lstEnrollId[item] === enrollId
            )
            dispatch({
                type: types.REMOVE_BIO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
                removeNote,
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_BIO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

export const updateRevertRemoveBio = (enrollId, bioType) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
    })
    try {
        const res = await Axios.post(apiUpdateRevertRemoveBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            const store = getState()
            const lstEnrollId = store.counterReducer.enrollId
            const ticketId = Object.keys(lstEnrollId).find(
                (item) => lstEnrollId[item] === enrollId
            )
            dispatch({
                type: types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

export const setBiometricData = (
    dataReceive,
    ticketId,
    bioType,
    typeRequestBio
) => (dispatch) => {
    let type = ''
    let bioId = ''
    let code = 0
    switch (typeRequestBio) {
        case typeRequestBios.REGISTER:
            type = `SOCKET_${typeRequestBios.REGISTER}_${bioType}`
            bioId = dataReceive
            dispatch({
                type,
                payload: {
                    ticketId,
                    bioType,
                    typeRequestBio,
                    bioId,
                },
            })
            break
        case typeRequestBios.CONFIRM:
            type = `SOCKET_${typeRequestBios.CONFIRM}_${bioType}`
            code = dataReceive
            dispatch({
                type,
                payload: {
                    ticketId,
                    bioType,
                    typeRequestBio,
                    code,
                },
            })
            break
        default:
            break
    }
    return
}

export const setDuplicateBioFace = (
    ticketId,
    duplicateFaces,
    tellerNote = ''
) => ({
    type: types.SET_DUPLICATE_BIO_FACE,
    ticketId,
    duplicateFaces,
    tellerNote,
})

export const resetStore = () => ({
    type: types.COUNTER_LOGOUT,
})

/**
 *
 * @param {{
 *  CounterID*: string,
 *  TicketID*: string,
 *  EnrollID*: string, //from api /counter/request-enroll
 *  BioType*: was describe in "../data/bioTypes"
 * }} dataRequest
 */
export const requestGetBioData = (dataRequest) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        ...dataRequest,
    })

    try {
        const res = await Axios.post(apiRequestGetBioData, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        dispatch(removeLoading())
        if (isSuccess) {
            dispatch({
                type: types.REQUEST_GET_BIO_DATA,
                bioType: dataRequest.BioType,
                typeRequest: typeRequestBios.REGISTER,
                ticketId: dataRequest.TicketID,
            })

            if (process.env.NODE_ENV === "development") {
                setTimeout(() => {
                    window.counterSocketDev.send(JSON.stringify({
                        Event: 'CT_BIO_DATA',
                        Data: {
                            EnrollID: dataRequest.EnrollID,
                            BioID: "dasdsaD",
                            BioType: dataRequest.BioType
                        }
                    }))
                }, 2000)
            }
        } else
            dispatch({
                type: types.REQUEST_GET_BIO_DATA_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.REQUEST_GET_BIO_DATA_ERR, false)
    }
}

export const completeBio = (enrollId, ticketId, forceComplete = true) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        TicketID: ticketId,
        ForceComplete: forceComplete,
    })

    try {
        const res = await Axios.post(apiCompleteBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.COMPLETE_BIO,
                ticketId,
            })
        else
            dispatch({
                type: types.COMPLETE_BIO_ERR,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.COMPLETE_BIO_ERR)
    }
}

export const setStatusBiometricWithTicket = (data) => ({
    type: types.SOCKET_SET_STATUS_BIOMETRIC_WITH_TICKET,
    payload: {
        ticketId: data.TicketID,
        enrollId: data.PendingBioID,
        bioInfosType: data.BioInfosType || '',
        statusBiometric: data.PendingStatus || undefined,
        noteRejectBio: data.Note || '',
        reVerify: data.ReVerify || false,
    },
})

export const getCurrentBioEnroll = (
    counterId,
    ticketId,
    customerNumber = undefined
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        TicketID: ticketId,
        CustomerNumber: customerNumber,
    })
    try {
        const res = await Axios.post(apiGetCurrentBioEnroll, queryDataFormat)
        const { data } = res

        const {
            BioInfos: bioInfos,
            PendingBioInfos: pendingBioInfos,
            InprogressBio: inprogressBio,
        } = data
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        dispatch(removeLoading())
        if (isSuccess && bioInfos) {
            dispatch({
                type: types.GET_CURRENT_BIO_ENROLL,
                payload: {
                    ticketId,
                    enrollId:
                        bioInfos.ID ||
                        pendingBioInfos?.ID ||
                        inprogressBio?.ID,
                    customerInfo:
                        bioInfos.CustomerInfos ||
                        pendingBioInfos?.CustomerInfos ||
                        inprogressBio?.CustomerInfos ||
                        {},
                    bioDatas:
                        bioInfos.BioDatas ||
                        pendingBioInfos?.BioDatas ||
                        inprogressBio?.BioDatas ||
                        [],
                    bioInfosType:
                        bioInfos?.BioInfosType ||
                        pendingBioInfos?.BioInfosType ||
                        '',
                    statusBiometric:
                        (!bioInfos.BioDatas && pendingBioInfos?.Status) ||
                        undefined,
                    noteRejectBio:
                        (!bioInfos.BioDatas && pendingBioInfos?.Note) ||
                        '',
                    statusCustomer:
                        (!bioInfos.BioDatas &&
                            !pendingBioInfos &&
                            !!inprogressBio &&
                            'InprogressBio') ||
                        '',
                    reasonRefuseRegFinger: 
                        bioInfos.ReasonNRFinger ||
                        pendingBioInfos?.ReasonNRFinger

                },
            })
            dispatch(
                addBiometricExistFromCustomerInfos(
                    {
                        BioDatas:
                            bioInfos.CurrentBioData ||
                            pendingBioInfos?.CurrentBioData ||
                            inprogressBio?.CurrentBioData,
                    },
                    ticketId
                )
            )
            const bioDatas =
                bioInfos.BioDatas ||
                pendingBioInfos?.BioDatas ||
                inprogressBio?.BioDatas
            if (!bioDatas) return
            const tellerNote =
                bioInfos.DuplicateFaceNote ||
                pendingBioInfos?.DuplicateFaceNote ||
                inprogressBio?.DuplicateFaceNote
            const faceBio = bioDatas.find((item) => item.BioType === 'FACE')
            if (!faceBio) return
            dispatch(
                setDuplicateBioFace(
                    ticketId,
                    faceBio.DuplicateFaces || [],
                    tellerNote
                )
            )
        } else if (isSuccess && !bioInfos) {
            dispatch({
                type: types.GET_CURRENT_BIO_ENROLL,
                payload: {
                    ticketId,
                    enrollId:
                        pendingBioInfos?.ID ||
                        inprogressBio?.ID ||
                        null,
                    customerInfo:
                        pendingBioInfos?.CustomerInfos ||
                        inprogressBio?.CustomerInfos ||
                        null,
                    bioDatas:
                        pendingBioInfos?.BioDatas ||
                        inprogressBio?.BioDatas ||
                        [],
                    bioInfosType:
                        pendingBioInfos?.BioInfosType || '',
                    statusBiometric:
                        pendingBioInfos?.Status ||
                        undefined,
                    noteRejectBio:
                        pendingBioInfos?.Note || '',
                    statusCustomer:
                        (!pendingBioInfos &&
                            !!inprogressBio &&
                            'InprogressBio') ||
                        '',
                    reasonRefuseRegFinger: 
                        pendingBioInfos?.ReasonNRFinger
                },
            })
            dispatch(
                addBiometricExistFromCustomerInfos(
                    {
                        BioDatas: pendingBioInfos?.CurrentBioData ||
                            inprogressBio?.CurrentBioData,
                    },
                    ticketId
                )
            )

            const bioDatas =
                pendingBioInfos?.BioDatas ||
                inprogressBio?.BioDatas
            if (!bioDatas) return
            const tellerNote =
                pendingBioInfos?.DuplicateFaceNote ||
                inprogressBio?.DuplicateFaceNote
            const faceBio = bioDatas.find((item) => item.BioType === 'FACE')
            if (!faceBio) return
            dispatch(
                setDuplicateBioFace(
                    ticketId,
                    faceBio.DuplicateFaces || [],
                    tellerNote
                )
            )
        }
    } catch (err) {
        catchErrAction(err, dispatch, types.GET_CURRENT_BIO_ENROLL_ERR, false)
    }
}

/**
 *
 * @param {string} enrollId enroll id
 * @param {string} bioType type follow ../data/bioTypes
 * @param {string} ticketId id of ticket
 */
export const requestConfirmBioData = (enrollId, bioType, ticketId) => async (
    dispatch
) => {

    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiRequestConfirmBioData, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS

        if (isSuccess) {
            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA,
                bioType,
                typeRequest: typeRequestBios.CONFIRM,
                ticketId,
            })
            if (process.env.NODE_ENV === "development") {
                setTimeout(() => {
                    window.counterSocketDev.send(JSON.stringify({
                        Event: 'CT_CONFIRM_BIO',
                        Data: {
                            Code: 0,
                            EnrollID: enrollId,
                            BioType: bioType,
                            DuplicateFaces: null
                        }
                    }))
                }, 2000)
            }
        } else
            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        if (
            err.response &&
            err.response.status === 400 &&
            err.response.data.Code >= 100
        ) {
            const {
                OfficeID,
                OfficeName,
                SupervisorID,
                SupervisorName,
                TellerID,
                TellerName,
                AcceptAt,
            } = err.response.data
            const moreInfosError = {
                OfficeID,
                OfficeName,
                SupervisorID,
                SupervisorName,
                TellerID,
                TellerName,
                AcceptAt,
            }
            const cifs =
                (!!err.response.data.Cifs && {
                    ...err.response.data.Cifs,
                    ...moreInfosError,
                }) ||
                (!!err.response.data.Cif && {
                    ...err.response.data.Cif,
                    ...moreInfosError,
                }) ||
                {}

            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA_ERR,
                payload: {
                    bioType,
                    typeRequest: typeRequestBios.CONFIRM,
                    ticketId,
                    code: err.response.data.Code,
                    cifs,
                    invalidFingerPrint:
                        err.response.data.InvalidFingerPrint || [],
                    bioTypeDuplicate: err.response.data.BioType || '',
                },
            })
            return
        }
        catchErrAction(err, dispatch, types.REQUEST_CONFIRM_BIO_DATA_ERR, false)
    }
}

/**
 *
 * @param {string} enrollId enroll id
 * @param {string} bioType type follow ../data/bioTypes
 * @param {string} bioId bioId receive from notify
 * @param {string} ticketId id of ticket
 * @param {number} index index of element in array REGISTER if FINGER else undefined
 */
export const requestRemoveBioData = (
    enrollId,
    bioType,
    bioId,
    ticketId,
    index = undefined
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
        BioID: bioId,
    })

    try {
        const res = await Axios.post(apiRequestRemoveBioData, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REQUEST_REMOVE_BIO_DATA,
                bioType,
                ticketId,
                index,
            })
        } else
            dispatch({
                type: types.REQUEST_REMOVE_BIO_DATA_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.REQUEST_REMOVE_BIO_DATA_ERR, false)
    }
}

/**
 * @param  {string} ticketId [description]
 * @param  {string} enrollId  [description]
 * @param  {string} bioType  [description]
 * @param  {boolean} isAuthenticate    true if use this bioType to authenticate transaction
 * @return void
 */
export const updateFingerInfoInRegisterThread = (
    ticketId,
    enrollId,
    bioType,
    isAuthenticate
) => async (dispatch) => {

    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        BioType: bioType,
        EnrollID: enrollId,
        IsConfirmFinger: isAuthenticate,
        IsActive: isAuthenticate ? true : false,
    })

    try {
        const res = await Axios.post(apiUpdateConfirmFinger, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_REGISTER_THREAD,
                ticketId,
                bioType,
                isAuthenticate,
            })
        else
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR,
                err: {
                    [types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR]: data.Code,
                },
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR,
            false
        )
    }
}

export const editStatusFingerInRegisterThread = (
    ticketId,
    bioType,
    isAuthenticate
) => {
    return {
        type: types.EDIT_FINGER_INFO_IN_REGISTER_THREAD,
        ticketId,
        bioType,
        isAuthenticate,
    }
}
/**
 * @param  {string} ticketId [description]
 * @param  {string} enrollId  [description]
 * @param  {string} bioType  [description]
 * @param  {boolean} isAuthenticate  true if use this bioType to authenticate transaction
 * @param  {boolean} isHang  true if use this bioType was hang
 * @return void
 */
export const updateFingerInfoInUpdateThread = (
    ticketId,
    enrollId,
    bioType,
    isAuthenticate,
    isHang
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        BioType: bioType,
        EnrollID: enrollId,
        IsConfirmFinger: isAuthenticate,
        IsActive: !isHang,
    })

    try {
        const res = await Axios.post(apiUpdateConfirmFinger, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
                isAuthenticate,
                isHang,
            })
        else
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR,
                err: {
                    [types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR]: data.Code,
                },
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

export const removeStatusWaitingBioData = (
    ticketId,
    justDispatch = false
) => async (dispatch, getState) => {
    if (justDispatch) {
        dispatch({
            type: types.REMOVE_STATUS_WAITING_BIO,
            ticketId,
        })
        return
    }
    dispatch(addLoading())
    const currentState = getState()
    const counterId = currentState.counterReducer.dataConnectDesktop.CounterID
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CounterID: counterId,
    })

    try {
        const res = await Axios.post(
            apiFinishWaitingSocketFinger,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REMOVE_STATUS_WAITING_BIO,
                ticketId,
            })
        } else
            dispatch({
                type: types.REMOVE_STATUS_WAITING_BIO_ERR,
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_STATUS_WAITING_BIO_ERR,
            false
        )
    }
}

export const resetConfirmBioData = (ticketId, bioType) => (dispatch) => {
    dispatch({
        type: types.RESET_ITEM_BIO_DATA_CONFIRM,
        ticketId,
        bioType,
    })
}

export const updateBiometricNote = (enrollId, note) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        DuplicateFaceNote: note,
        RejectRegisterNote: '',
    })

    try {
        const res = await Axios.post(apiUpdateBiometricNote, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.UPDATE_BIOMETRIC_DUPLICATE_NOTE,
                enrollId,
                note,
            })
        } else {
            dispatch({
                type: types.UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR,
                err: {
                    [types.UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_STATUS_WAITING_BIO_ERR,
            false
        )
    }
}

export const removeDuplicateFace = (ticketId) => ({
    type: types.REMOVE_DUPLICATE_FACE,
    ticketId,
})

export const removeDataBioOfTicketInStore = (ticketId) => ({
    type: types.REMOVE_BIO_DATA_OF_TICKET_IN_STORE,
    ticketId,
})
