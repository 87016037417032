import styled from "styled-components";

const WrapPopupEndService = styled.div`
    width: 340px;
    height: 275px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;
    border-radius: 10px;
    background: var(--White, #FFF);
    .wrap-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            margin: 16px 0;
            color: var(--Black, #191919);
            text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 128%;
        }
        .desc{
            color: #005993;
            text-align: center;
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 128%;
        }
    }
    .wrap-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        .btn-back{
            width: 96px;
            height: 33px;
            padding: 10px 18px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18px;
            background: var(--Gray-3, #E6E6E6);
            color: var(--Gray, #636060);
            text-align: center;
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }
        .btn-take-ticket{
            width: 96px;
            height: 33px;
            padding: 10px 16px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18px;
            background: var(--primary-1, #005993);
            color: var(--White, #FFF);
            text-align: center;
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
        }
    }

`
export { WrapPopupEndService }