import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//css
import { WrapSelectTransaction, WrapContentPage, WrapFooterPage } from './index.styles'
//img
import pattern_vtb from '../../../images/PNG/pattern_vtb.png'
import { ACTIVE, SUCCESS } from '../../../../../constant/keys'
import IcArrowLeft from '../../../images/SVG/IcArrowLeft'
import IcCheckBlueMini from '../../../images/SVG/IcCheckBlueMini'


//component
import ButtonControl from '../../../controls/ButtonControl'
import StepByStepGetNumber from '../../StepByStepGetNumber'

//data
import { dataListTransaction } from '../../../data/dataGetTicket.jsx'

//action
import { resetListKeySelect, setListKeySelect, setListTransaction, setStepSelect } from '../../../../../actions/getNumberAction'
import { apiGetIcon } from '../../../../../actions/counterAction'
import { CORPCUST } from '../../../../../data/serviceTypes'
import ItemTransaction from './ItemTransaction'



const SelectTransaction = ({ onNext, onBack }) => {

    const dispatch = useDispatch()

    const { listTransaction, listKeySelect, listTypeCounterServices, personalInfo, listCorpCustomer, listPersonCustomer } = useSelector(state => ({
        listTransaction: state.getNumber.listTransaction,
        listKeySelect: state.getNumber.listKeySelect,
        listTypeCounterServices: state.counterReducer.listTypeCounterServices,
        listCorpCustomer: state.counterReducer.listCorpCustomer,
        listPersonCustomer: state.counterReducer.listPersonCustomer,
        personalInfo: state.getNumber.personalInfo
    }))

    const [listData, setListData] = useState([])
    const [arrSelected, setArrSelected] = useState([])
    const [arrKeySelected, setArrKeySelected] = useState([])


    useEffect(() => {
        if (personalInfo?.customerType === CORPCUST) {
            setListData(listCorpCustomer)
            return
        }
        setListData(listPersonCustomer)
    }, [listCorpCustomer, listPersonCustomer, personalInfo?.customerType])

    useEffect(() => {
        setArrSelected(listTransaction)
    }, [listTransaction])

    useEffect(() => {
        setArrKeySelected(listKeySelect)
    }, [listKeySelect])

    const handleNext = () => {
        dispatch(setStepSelect({
            step1: SUCCESS,
            step2: SUCCESS,
            step3: ACTIVE,
            step4: "",
        }))
        dispatch(setListTransaction(arrSelected))
        dispatch(setListKeySelect(arrKeySelected))
        setTimeout(() => {
            onNext()
        }, 200);
    }

    const handleMultiSelected = (item) => {
        let newListKeySelect
        let newListSelect
        if (arrKeySelected.includes(item.ServiceType)) {
            newListKeySelect = arrKeySelected.filter(
                (key) => key !== item.ServiceType
            )
            newListSelect = arrSelected.filter((ser) => ser.ServiceType !== item.ServiceType)
        } else {
            newListKeySelect = [...arrKeySelected, item.ServiceType]
            newListSelect = [...arrSelected, item]
        }
        setArrKeySelected(newListKeySelect)
        setArrSelected(newListSelect)
    }

    const handleBack = () => {
        dispatch(setStepSelect({
            step1: ACTIVE,
            step2: "",
            step3: "",
            step4: "",
        }))
        // dispatch(resetListKeySelect([]))
        // dispatch(setListTransaction([]))
        setTimeout(() => {
            onBack()
        }, 100);
    }

    return (
        <WrapSelectTransaction>
            <div className='title-page'>
                <div className='bold'>Lấy số xếp hàng</div>
                <div className='normal'>Lấy số cho khách hàng vào quầy</div>
            </div>
            <StepByStepGetNumber />
            <WrapContentPage>
                <div className='wrap-list-transaction'>
                    {
                        listData?.map((item, index) => {
                            return (
                                <ItemTransaction
                                    key={index}
                                    data={item}
                                    listKeySelect={arrKeySelected}
                                    handleSelected={handleMultiSelected}
                                />
                            )
                            //return (
                            //    <div className='wrap-group-transaction' key={index}>
                            //        <div className='group-name'>{item?.Name}</div>
                            //        <div className='group-item'>
                            //            {item?.Children.map((trans, i) => {
                            //                let itemChoose = arrKeySelected.filter(value => value === trans?.ServiceType)
                            //                return (
                            //                    <div key={i} className={trans?.ServiceType === (itemChoose[0]) ? 'block-transaction active' : 'block-transaction'} onClick={() => handleMultiSelected(trans)}>
                            //                        <IcCheckBlueMini className={trans?.ServiceType === (itemChoose[0]) ? 'ic-check active' : 'ic-check'} />
                            //                        <div className={trans?.ServiceType === (itemChoose[0]) ? 'icon active' : 'icon'}>
                            //                            <img src={apiGetIcon(trans?.ID, 48)} alt='service' />
                            //                        </div>
                            //                        <div className='text'>{trans?.Name}</div>
                            //                    </div>
                            //                )
                            //            })}
                            //        </div>
                            //    </div>
                            //)
                        })
                    }
                </div>
            </WrapContentPage>
            <WrapFooterPage>
                {/* <img src={pattern_vtb} alt='pattern_vtb' /> */}
                <div className='wrap-btn'>
                    <div className='btn-back' onClick={handleBack}>
                        <IcArrowLeft />
                        <span>Quay lại</span>
                    </div>
                    <div className='wrap-service'>
                        <div className='service'>
                            <div className='wrap-text'>
                                <p className='text'>Dịch vụ đã chọn:</p>
                                <IcCheckBlueMini className='icon' />
                            </div>
                            <span>{arrKeySelected.length <= 9 ? `0${arrKeySelected.length}` : arrKeySelected.length || 0}</span>
                        </div>
                        <ButtonControl
                            text="Tiếp theo"
                            className="btn-next"
                            onClick={handleNext}
                            isDisable={arrKeySelected.length !== 0 ? false : true}
                        />
                    </div>
                </div>
            </WrapFooterPage>
        </WrapSelectTransaction>
    )
}

export default SelectTransaction