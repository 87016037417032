import React from 'react'

const IcCheckBoxActive = (props) => {
    return (
        <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={18} height={18} rx={4} fill="#005993" />
            <path d="M5.29395 9.08356L7.6345 11.4241L12.7057 6.35291" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCheckBoxActive