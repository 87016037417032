const checkValidation = {
    limitRange: (str, start, end) => str?.length < start || str?.length > end,
    limitSpace: (str) => /\s+/g.test(str),
    errorChar: (str, validReg) => str.replace(validReg, ""),
    errorEmail: (str) => {
        // RFC 2822 standard email validation
        const regCheckEmail = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
        return !regCheckEmail.test(str)
    },
    errorEmailDomain: (str) => {
        const regCheckEmailDomain = /r^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+$/
        return !regCheckEmailDomain.test(str)
    },
    errorStrIsSpace: (str) => /^\s+$/.test(str),
    errorStrMustHaveNumAndChar: (str) => !(/[0-9]/.test(str) && /[a-zA-Z]/.test(str)),
    errorNumberAndMax: (str, max) => !(/^[0-9]+$/.test(str) && str.length === max),
    errorNumber: (str) => !(/^[0-9]+$/.test(str)),
    limitChar: (str) => /^[\da-zA-Z]*$/.test(str),
    errorEmpty: (str) => str?.length === 0 || !str?.trim(),
    errorFullname: (str) => {
        const regCheckFullname = /^[a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\ ]+$/
        return !regCheckFullname.test(str)
    },
    errorCharacterSpecial: (str) => {
        const regCharacter = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        return !regCharacter.test(str)
    },
    errorAllowSomethingCharacterSpecial: (str) => {
        const regCharacter = /[`!@#$%^&*()+\=\[\]{};':"|,.<>\?~]/
        return !regCharacter.test(str)
    },
    errorNotNumber: (str) => {
        const regStr = /[0-9]/
        return !regStr.test(str)
    },
}

const getStrValidation = {
    limitRange: (start, end) => `Giới hạn từ ${start}-${end} ký tự`,
    limitSpace: "Không cho phép khoảng trắng",
    errorChar: (invalidChars) => `Nội dung chứa ký tự không hợp lệ`,
    errorCharPass: "Password không đúng định dạng",
    errorEmail: "Không đúng định đạng email",
    errorStrIsSpace: "Không cho phép chuỗi là khoảng trắng",
    errorFullname: "Không chứa ký tự đặc biệt",
    errorStrMustHaveNumAndChar: (subject) => `${subject} bắt buộc phải có: chữ và số`,
    errorNumberAndMax: (max) => `Bắt buộc có ${max} số `,
    errorNumber: `Chỉ được phép nhập số `,
    limitChar: "Không chứa ký tự đặc biệt",
    limitLength: (maxLength) => `Giới hạn ${maxLength} ký tự`,
    limitStartDateVal: "Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại",
    limitEndDateVal: "Ngày kết thúc không được nhỏ hơn ngày hiện tại và phải lớn hơn ngày bắt đầu.",
    limitEndDateVal1: "Ngày kết thúc không được nhỏ hơn ngày bắt đầu",
    errorFormat: "Nội dung không đúng định dạng. Vui lòng nhập lại",
    errorEmpty: "Thông tin không được để trống",
    errorCharacterSpecial: "Không chứa ký tự đặc biệt",
    errorAllowSomethingCharacterSpecial: "Chỉ cho phép ký tự '/', '\\', '-', '_'",
    errorNotNumber: "Không chứa ký tự số",
}

export {
    checkValidation,
    getStrValidation
}