import React from 'react'

export default function IcClosePopup(props) {
    return (
        <svg
            {...props}
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5.18604"
                y="6.81396"
                width="2.44467"
                height="17.1127"
                rx={1}
                transform="rotate(-45 5.18604 6.81396)"
                fill="#C4C4C4"
            />
            <rect
                x="17.2866"
                y="5.08545"
                width="2.44467"
                height="17.1127"
                rx={1}
                transform="rotate(45 17.2866 5.08545)"
                fill="#C4C4C4"
            />
        </svg>
    )
}
