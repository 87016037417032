import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//css
import { WrapPopupProcessingCounselor } from './index.styles'
//control
import OverlayFullScreen from '../../../controls/OverlayFullScreen'
//imgs
import IcPopConfirmCounselor from '../../../images/SVG/IcPopConfirmCounselor'
//action
import { callTicket, getAllTicketWaiting, moveAdviseTicketToMissCall } from '../../../../../actions/counterAction'

export default function PopupProcessingCounselor({ data, timeCountDown, handleEnd, handleCallTicket }) {

    const dispatch = useDispatch()

    const { dataConnectDesktop, ticketProcessing } = useSelector(state => ({
        dataConnectDesktop: state.counterReducer?.dataConnectDesktop,
        ticketProcessing: state.counterReducer?.ticketProcessing,
    }))

    const [timeCount, setTimeCount] = useState(timeCountDown)

    useEffect(() => {
        if (ticketProcessing?.TicketID === data?.TicketID) {
            handleCallTicket && handleCallTicket()
            handleEnd()
        }
    }, [ticketProcessing?.TicketID, data?.TicketID])

    const handleMoveAdviseTicketToMissCall = () => {       
        dispatch(moveAdviseTicketToMissCall(
            dataConnectDesktop?.CounterID,
            data
        ))
    }

    useEffect(() => {
        let timer = setInterval(() => {
            setTimeCount(timeCount - 1)
            if (timeCount === 0) {
                handleMoveAdviseTicketToMissCall()
                handleEnd()
                return;
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, [timeCount])

    return (
        <OverlayFullScreen>
            <WrapPopupProcessingCounselor>
                <IcPopConfirmCounselor />
                <div className="wrap-title">
                    <div className="title">
                        Khách hàng đang được tư vấn bởi {data?.EmployeeName}
                    </div>
                    <div className="desc">
                        Vui lòng liên hệ nhân viên tư vấn để kết thúc quá trình phục vụ.
                    </div>
                </div>
                <div className='note'>
                    (*) Vé 1234 sẽ vào hàng gọi nhỡ sau {timeCount} giây.
                </div>
            </WrapPopupProcessingCounselor>
        </OverlayFullScreen>
    )
}
