import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//css
import { WrapGetNumberTicket, WrapScreenPage } from './index.styles'

//support

//component
import SearchCustomer from './SearchCustomer'
import Portal from '../../controls/Portal'
import PopupEndService from './PopupEndService'
import OverlayFullScreen from '../../controls/OverlayFullScreen'
import PopupPrintFail from '../PopupPrintFail'
import IssueTicket from '../GetNumberTicket/IssueTicket'

//constant
import useStepStack from '../../hooks/useStepStack'
import { CONFIRM_INFOMATION, ISSUE_TICKET } from '../../../../constant/keys'
import IcClosePopup from '../../images/SVG/IcClosePopup'
import { cancelTakeTicket, clearDataGetTicket } from '../../../../actions/counterAction'

const initialStepStack = [CONFIRM_INFOMATION]

const GetNumberTicketOnline = ({onClose}) => {
    const dispatch = useDispatch()

    const { personalInfo, listTransaction, dataConnectDesktop,appointmentId } = useSelector((state) => ({
        personalInfo: state.bookAppointment.personalInfo,
        listTransaction: state.bookAppointment.listTransaction,
        dataConnectDesktop: state.counterReducer.dataConnectDesktop,
        appointmentId: state.counterReducer.appointmentId
    }))

    const [isShowPopupEnd, setIsShowPopupEnd] = useState(false)
    const [isExportFailed,setIsExportFailed] = useState(false)

    const { stepStack, pushStep, popStep, lastStep } = useStepStack(
        initialStepStack
    )

    const handleBack = () => {
        popStep()
    }

    const handleClosePopup = () => {
        if(lastStep === CONFIRM_INFOMATION && appointmentId){
            dispatch(cancelTakeTicket(dataConnectDesktop.OfficeID,dataConnectDesktop.CounterID,appointmentId))
        }
        dispatch(clearDataGetTicket())
        onClose()
    }

    const handleEnd = () => {}

    const renderScreenPage = () => {
        // Step 1
        if (lastStep === CONFIRM_INFOMATION) {
            const handleNext = () => {
                pushStep(ISSUE_TICKET)
            }
            return <SearchCustomer onNext={handleNext} setIsFail={()=>setIsExportFailed(true)} />
        }
        // Step 2
        if (lastStep === ISSUE_TICKET) {
            return <IssueTicket listTransaction={listTransaction} isBookingOnline={true} />
        }
    }

    return (
        <OverlayFullScreen>
            {isExportFailed ? (
                <PopupPrintFail setIsFail={()=>setIsExportFailed(false)} onClose={handleClosePopup} />
            ) : (
                <WrapGetNumberTicket>
                    <IcClosePopup className="ic-close" onClick={handleClosePopup}/>
                    <WrapScreenPage>{renderScreenPage()}</WrapScreenPage>
                    {isShowPopupEnd && (
                        <Portal>
                            <PopupEndService
                                handleContinue={() => setIsShowPopupEnd(false)}
                                handleEnd={handleEnd}
                            />
                        </Portal>
                    )}
                </WrapGetNumberTicket>
            )}
        </OverlayFullScreen>
    )
}

export default GetNumberTicketOnline
