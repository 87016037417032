import styled from 'styled-components'

const WrapConfirmInfomation = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .title-page {
        color: var(--Black, #191919);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        padding: 16px 20px;
        .normal {
            color: var(--Gray, #636060);
            font-size: 14px;
            font-weight: 500;
            line-height: 128%;
            margin-top: 2px;
        }
    }
`
const WrapContentPage = styled.div`
    width: 100%;
    box-sizing: border-box;
    .wrap-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: overlay;
        overflow-x: hidden;
        .wrap-list-info {
            border-top: 1px solid #e7e6e6;
            padding: 16px 20px;
            box-sizing: border-box;
            .title-content {
                color: var(--Gray, #636060);
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.28px;
                margin-bottom: 12px;
            }
            .list-info {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 16px 16px;
                .block-info {
                    /* width: 344px; */
                    width: 100%;
                    height: 52px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: flex-start;
                    gap: 6px;
                    .wrap-icon {
                        width: 40px;
                        height: 40px;
                        flex-shrink: 0;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(
                            --Gradient-Light-Blue,
                            linear-gradient(225deg, #7ed3f7 0%, #3baddd 100%)
                        );
                        img{
                            display: block;
                            width: 24px;
                            height: 24px;
                            box-sizing: border-box;
                            object-fit: cover;
                        }
                        .symsbol {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .wrap-text {
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                        .key-text {
                            color: var(--Gray, #636060);
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            letter-spacing: -0.26px;
                        }
                        .key-value {
                            color: var(--Black, #191919);
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: -0.34px;
                        }
                    }
                }
            }
        }
    }
`

const WrapFooterPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 84px;
    border-top: 1px solid var(--Gray-3, #e6e6e6);
    .wrap-btn {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .btn-back {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--Gray, #636060);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.28px;
            cursor: pointer;
        }
        .btn-next {
            display: flex;
            height: 44px;
            width: 150px;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background-color: #005993;
        }
    }
`

export { WrapConfirmInfomation, WrapContentPage, WrapFooterPage }
