import styled  from "styled-components";

const WrapFormTransactionReceipt = styled.div`
    width: 262px;
    /* height: 100%; */
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 6px;   
    /* margin-top: 20px; */
    position: relative;
    .content{
        &-header{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 14px;
        }
        &-number{
            height: 79px;
            border: 1px solid #E7E6E6;
            border-radius: 6px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 35px 16px;           
            &-transaction{
                position: absolute;
                top: -10px;
                left: 33%;
                width: 40%;
                background: #fff;
                color: var(--Gray, #636060);
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.26px;
            }
            &-bill{
                color: var(--primary-1, #005993);
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.96px;
            }
        }

        &-desp{
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #FFFFFF;
            padding: 0 16px 19px 16px;
            &-value{
                color: var(--Gray, #636060);
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; 
                letter-spacing: -0.26px;
            }
            &-list{
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 9px;                
                margin-top: 10px;
                &-service{
                    color: var(--Black, #191919);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.4px;
                }
            }
            
        }       
        &-bottom{
            margin: 50px 0 0 0;
            color: var(--Gray, #636060);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.24px;
        }
    }
    .slide{
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
    }
`

export {
    WrapFormTransactionReceipt
}