import styled from "styled-components";

const WrapPopupRequireCompleteAdvise = styled.div`
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 10px;
    width: 340px;
    min-height: 245px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .wrap-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-family: 'SVN-Gilroy';
            font-size: 17px;
            font-weight: 500;
            line-height: 21.76px;
            text-align: center;
            color: #191919;
            width: 300px;
        }
        .desc{
            font-family: 'SVN-Gilroy';
            font-size: 17px;
            font-weight: 500;
            line-height: 21.76px;
            text-align: center;
            color: #191919;
        }
    }
    .wrap-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 22px;
        .btn-done{
            background: #005993;
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            text-align: center;
            color:#FFFFFF;
            text-transform: uppercase;
            width: 96px;
            height: 33px;
        }
    }
`
export { WrapPopupRequireCompleteAdvise }