import * as types from "../actions/types";

export const initialState = {
  typeLv1: -1,
  typeLv2: -1,
  typeLv3: -1,
  showPopupLv1: false,
  showPopupLv2: false,
  showPopupLv3: false,
  dataLv1: {},
  dataLv2: {},
  dataLv3: {},
};

const popupBioManagerReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.OPEN_POPUPLV1:
      return {
        ...state,
        typeLv1: action.typePopup,
        dataLv1: action.data,
        showPopupLv1: action.levelPopup === 1 && true,
      };
    case types.OPEN_POPUPLV2:
      return {
        ...state,
        typeLv2: action.typePopup,
        dataLv2: action.data,
        showPopupLv2: action.levelPopup === 2 && true,
      };
    case types.OPEN_POPUPLV3:
      return {
        ...state,
        typeLv3: action.typePopup,
        dataLv3: action.data,
        showPopupLv3: action.levelPopup === 3 && true,
      };

    case types.CLOSE_POPUPLV1:
      return {
        ...state,
        typeLv1: -1,
        showPopupLv1: false,
        dataLv1: {}
      };
    case types.CLOSE_POPUPLV2:
      return {
        ...state,
        typeLv2: -1,
        showPopupLv2: false,
        dataLv2: {}
      };
    case types.CLOSE_POPUPLV3:
      return {
        ...state,
        typeLv3: -1,
        showPopupLv3: false,
        dataLv3: {}
      };
    case types.RESET_POPUP:
      return {
        typeLv1: -1,
        typeLv2: -1,
        typeLv3: -1,
        showPopupLv1: false,
        showPopupLv2: false,
        showPopupLv3: false,
        dataLv1: {},
        dataLv2: {},
        dataLv3: {},
      };
    default:
      return state;
  }
};

export default popupBioManagerReducer;