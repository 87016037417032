import React from 'react'

export default function IcCounselor(props) {
    return (
        <svg
            {...props}
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.68176 1C4.1501 1 3.71911 1.43099 3.71911 1.96265V5.93177C3.71911 6.46342 4.1501 6.89442 4.68176 6.89442H6.8928L7.67677 7.69746C7.84782 7.87267 8.12934 7.87342 8.30133 7.69914L9.09543 6.89442H11.2946C11.8263 6.89442 12.2573 6.46342 12.2573 5.93177V1.96265C12.2573 1.43099 11.8263 1 11.2946 1H4.68176ZM6.02832 4.64737C6.42401 4.64737 6.74477 4.32661 6.74477 3.93092C6.74477 3.53524 6.42401 3.21447 6.02832 3.21447C5.63264 3.21447 5.31187 3.53524 5.31187 3.93092C5.31187 4.32661 5.63264 4.64737 6.02832 4.64737ZM8.70994 3.93092C8.70994 4.32661 8.38917 4.64737 7.99348 4.64737C7.5978 4.64737 7.27703 4.32661 7.27703 3.93092C7.27703 3.53524 7.5978 3.21447 7.99348 3.21447C8.38917 3.21447 8.70994 3.53524 8.70994 3.93092ZM9.95864 4.64737C10.3543 4.64737 10.6751 4.32661 10.6751 3.93092C10.6751 3.53524 10.3543 3.21447 9.95864 3.21447C9.56296 3.21447 9.24219 3.53524 9.24219 3.93092C9.24219 4.32661 9.56296 4.64737 9.95864 4.64737Z"
                fill="#005993"
            />
            <circle cx="4.19863" cy="9.23914" r="2.00428" fill="#005993" />
            <path
                d="M7.39735 14.4551C7.39735 14.7545 7.05856 14.8907 7.05856 14.8907H1.36249C1.36249 14.8907 1 14.8315 1 14.4551C1 12.6885 2.19444 11.4302 4.19868 11.4302C6.20291 11.4302 7.39735 12.6885 7.39735 14.4551Z"
                fill="#005993"
            />
            <path
                d="M14.9999 14.4551C14.9999 14.7545 14.6611 14.8907 14.6611 14.8907H8.96508C8.96508 14.8907 8.60258 14.8315 8.60258 14.4551C8.60258 12.6885 9.79702 11.4302 11.8013 11.4302C13.8055 11.4302 14.9999 12.6885 14.9999 14.4551Z"
                fill="#005993"
            />
            <circle cx="11.8013" cy="9.23914" r="2.00428" fill="#005993" />
        </svg>
    )
}
