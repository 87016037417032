import styled from 'styled-components'

const WrapStepByStepBookingOnline = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid var(--Gray-3, #e6e6e6);
    .wrap-step {
        display: flex;
        align-items: center;
        .step {
            display: flex;
            align-items: center;
            width: 346px;
            height: 35px;
            gap: 6px;
            .icon {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: #f7f7f7;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #c4c4c4;
                text-align: center;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.3px;
            }
            &.frist {
                margin-left: 20px;
            }
            &.active {
                border-bottom: 3px solid var(--primary-1, #005993);
                .icon {
                    color: #ffffff;
                    background-color: #005993;
                }
            }
            &.success{
                .icon{
                  color: #ffffff;
                  background-color: #005993;
                }
            }
            .step-text {
                color: #c4c4c4;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: -0.28px;
                line-height: 18px;
                &.active {
                    color: #191919;
                }
            }
        }
    }
`

export { WrapStepByStepBookingOnline }
